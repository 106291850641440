import React from "react"
import {styles} from "../../res";
import ComponentUtils from "library-react/ComponentUtils";
import Text from "library-react/component/Text";

export default function Head({...props}){
	props.style = ComponentUtils.defaultStyle(props.style, defaultStyle);
	return <Text {...props}/>;
}

const defaultStyle = {
	fontSize: 25,
	borderColor: styles.color.azure2,
	borderStyle: styles.borderStyle.solid,
	borderBottomWidth: 1,
	color: styles.color.azure2,
};
