import React, { useState } from "react";
import use from "../../../../library-react/hook";
import Head from "../../../component/Head";
import FilterBar from "./FiltersBar";
import FILTERS, { Filter } from "./filters";
import OrderList from "./OrderList";
import OrdersListFrame from "./OrdersListFrame";

function OrdersListUI() {
	const [filter, setFilter] = useState<Filter>(FILTERS[0]);

	return (
		<OrdersListFrame>
			<Head style={localStyles.head}>
				{`Commandes`}
			</Head>

			<FilterBar 
				{...{filter, setFilter}}
				style={localStyles.filter} />

			<OrderList filter={filter} />
		</OrdersListFrame>
	);
}

export default React.memo(OrdersListUI);

const localStyles = {
	layout: {
		paddingVertical: 42,
		flexGrow: 1,
	},

	head: {
		alignSelf: "flex-start",
		marginBottom: 25,
		marginHorizontal: 42,
	},
	filter: {
		flexDirection: "row",
		marginBottom: 25,
		marginLeft: 30,
	},
} as const;
