import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {strings, styles} from "../../res"
import Text from "library-react/component/Text";
import TextInput from "library-react/component/input/TextInput";
import Button from "./Button";
import AuthManager from "library-js/AuthManager";
import Objects from "library-js/utils/Objects";
import {retailer as Server} from "library-js/app/Server"

function PasswordForm({onPasswordChanged, ...props}) {
	const [submitting, setSubmitting] = use.state(false);
	const [submitted, setSubmitted] = use.state(false);
	const [message, setMessage] = use.state(null);
	const inputs = use.instances({
		current: null,
		new: null,
		confirm: null,
	});

	const passToNewPasswordInput = use.callback(() => inputs.new?.focus());
	const passToConfirmationPasswordInput = use.callback(() => inputs.confirm?.focus());

	const inPasswordRenewalTime = Date.now() < AuthManager.claims.passwordRenewalExpiration;
	const getValid = (submitted) => {
		const basicValidate = input => !submitted || (input?.value?.length > 1);

		return {
			current: inPasswordRenewalTime || basicValidate(inputs.current),
			new: basicValidate(inputs.new),
			confirm: !submitted || basicValidate(inputs.confirm) && inputs?.confirm?.value === inputs.new?.value,
		}
	};
	const submit = use.asyncCallback(shouldStop =>
		() => {
			setSubmitted(true);

			const valid = Objects.every(
				getValid(true),
				(key, valid) => valid,
			);

			if (valid) {
				setSubmitting(true);
				Promise.process(shouldStop)
					.then(() => {
						const currentPassword = inputs.current?.value;
						const newPassword = inputs.new?.value;
						const confirmPassword = inputs.confirm?.value;
						// return Promise.await(4000).then(() => new Response(Response.Status.BUG, "abc"));
						return Server.auth.renewPassword(currentPassword, newPassword, confirmPassword);
					})
					.result((response, error) => {
						setSubmitting(false);

						if (response?.ok) {
							setMessage({
								good: true,
								content: `Mot de passe mis à jour !`,
							});

							AuthManager.authenticate(response.content);
							if (onPasswordChanged)
								onPasswordChanged();
						} else {
							setMessage({
								good: false,
								content: strings.onError.dot,
							});

							if (error)
								console.warn(error);
							else
								response.log();
						}
					})
			}
		}
	);

	const valid = getValid(submitted);
	const forceUpdate = use.forceUpdate();

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<Title renewPassword={!inPasswordRenewalTime}/>

			{/* Message */}
			<Text style={localStyles.message(message?.good)}>
				{!submitting && message?.content}
			</Text>

			{/* Current password */}
			{// check expiration
				!inPasswordRenewalTime &&
				<TextInput
					ref={inputs.set.current}
					autoCompleteType="password"
					textContentType="password"
					secureTextEntry

					placeholder={`Mot de passe actuel`}
					placeholderTextColor={styles.color.secondary}
					disabled={submitting}
					onValueChanged={submitted ? forceUpdate : undefined}
					onSubmitted={passToNewPasswordInput}
					style={[localStyles.input.currentPassword, localStyles.errorInput(!valid.current)]}
				/>
			}

			{/* New password */}
			<TextInput
				ref={inputs.set.new}
				autoCompleteType="password"
				textContentType="newPassword"
				secureTextEntry

				placeholder={`Nouveau mot de passe`}
				placeholderTextColor={styles.color.secondary}
				disabled={submitting}
				onValueChanged={submitted ? forceUpdate : undefined}
				onSubmitted={passToConfirmationPasswordInput}
				style={[localStyles.input.newPassword, localStyles.errorInput(!valid.new)]}
			/>

			{/* New password confirmation */}
			<TextInput
				ref={inputs.set.confirm}
				autoCompleteType="password"
				textContentType="newPassword"
				secureTextEntry

				placeholder={`Verification nouveau mot de passe`}
				placeholderTextColor={styles.color.secondary}
				disabled={submitting}
				onValueChanged={submitted ? forceUpdate : undefined}
				onSubmitted={submit}
				style={[localStyles.input.newPasswordConfirmation, localStyles.errorInput(!valid.confirm)]}
			/>

			{/* Submit */}
			<Button
				onPress={submit}
				loading={submitting}
				raised={!submitting}
				style={localStyles.submit}>
				{`Valider`}
			</Button>
		</RNView>
	);
}

export default React.memo(PasswordForm);

function  Title({renewPassword, ...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<Text style={localStyles.formTitle}>
			{renewPassword ? `Changez votre mot de passe` : `Definissez votre mot de passe`}
		</Text>
	);
}


const localStyles = {
	layout: {},

	formTitle:{
		fontSize: 25,
		color: styles.color.azure2,
		marginBottom: 30,
	},

	message: styles.static.bool(
		{marginBottom: 15},
		{color: styles.color.good},
		{color: styles.color.error},
	),

	input: styles.static(
		{
			fontSize: 19,
			marginBottom: 44,
			borderBottomWidth: .5,
			lineHeight: 31,
			borderBottomColor: styles.color.secondary,
		},
		{
			currentPassword: null,
			newPassword: null,
			newPasswordConfirmation: {marginBottom: 64},
		}
	),

	errorInput: invalid => invalid && {borderBottomColor: styles.color.error},

	submit: {
		paddingHorizontal: 80,
		alignSelf: styles.alignSelf.flexEnd,
	},
};
