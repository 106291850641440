import useLoadable from "../useLoadable";
import {Server} from "../../library-js/app";
import useMemo from "../useMemo";
import Reservation from "../../library-js/app/model/entity/Reservation";
import useSubscribe from "../useSubscribe";
import useMe from "./useMe";
import useSyncLoadable from "../useSyncLoadable";
import useSyncEffect from "../useSyncEffect";
import {useState} from "react";
import useVersion from "../useVersion";
import {isNil} from "ramda";

export default function useReservationEditor({id, userId}) {
	const {shop} = useMe().shop;
	const userLoadable = useLoadable.server(!id && userId &&
		function () {
			return Server.retailer.client.get(userId)
				.updateContent(client => client.user);
		}
	);


	const loadable = useLoadable.server(
		id && function () {
			return Server.retailer.reservation.get(id);
		},
		[id],
	);
	useSyncLoadable(loadable);

	const [editionVersion, resetEdition] = useVersion();
	const edition = useMemo(() => {
			let editableReservation;

			if (loadable) { // edit
				const {reservation, currentState} = loadable.value || {};
				if (reservation?.time > Date.now() && !currentState.type.is.refused)
					editableReservation = reservation.copy(); // OK
			} else { // create
				if (!userLoadable || userLoadable.value) { // wait to load predefined user
					editableReservation = new Reservation()
						.setShopId(shop.id);

					const defaultUser = userLoadable?.value;
					attachUserInfos(defaultUser, editableReservation);
				}
			}

			if (editableReservation) {
				editableReservation.bindSetters();
				editableReservation.timeZone = shop.timeZone;

				const user = userLoadable?.value?.user;
				Object.entries({
					shopId: shop.id,
					fullName: user?.name,
					email: user?.email,
					phone: user?.phone,
				})
					.forEach(([field, defaultValue]) => {
						if (isNil(editableReservation[field]))
							editableReservation[field] = defaultValue;
					});
			}

			return editableReservation;
		},
		[loadable?.value, userLoadable?.value, editionVersion],
	);

	useSubscribe(edition?.onPropertyChanged, [edition]);


	const loadedUser = loadable?.value?.vUser?.user;
	let [user, setUser] = useState();
	// set loaded user
	useSyncEffect((shouldUseLoadedUser, defaultUser) => {
		if (shouldUseLoadedUser)
			setUser(user = loadedUser);
		else if (!user && defaultUser)
			setUser(user = defaultUser);
	}, [loadedUser && !user, userLoadable?.value]);

	if (edition)
		edition.userId = user?.id;

	return useMemo(
		{
			id,
			edition,
			loadable,
			user,
			setUser: edition &&
			function (user){
				setUser(user);
				attachUserInfos(user, edition);
			},

			reset: loadable?.value
				&& edition
				&& resetEdition
		},
		[id, edition, loadable, user]
	);
}

function attachUserInfos(user, edition){
	if (user){
		edition.fullName = user.name;
		edition.email = user.email;
		if (user.phone)
			edition.phone = user.phone;
	}
}
