import React from "react"
import {ActivityIndicator} from "react-native";

import use from "library-react/hook";
import {retailer as Server} from "library-js/app/Server";
import SimpleButton from "library-react/component/SimpleButton";
import View from "library-react/component/View";
import Text from "library-react/component/Text";
import {strings, styles} from "../../../res";
import Row from "./ItemRow";

export default React.memo(
	function ClientList({query, filter, ...props}) {
		const iterator = use.infiniteIterator(
			() => Server.client.getIterator(query, filter),
			[query, filter],
		);

		return (
			<View {...props}>
			{
				Boolean(iterator.items) && Boolean(iterator.items.length) &&
				<React.Fragment>
					<Row.Header lastBuy={strings.fragment.clientList.lastBuy}
								rate={strings.fragment.clientList.yourRate}/>
					{
						iterator.items.map((item, index) =>
							<Row.Client key={index}
										client={item}
							/>)
					}
				</React.Fragment>
			}
			{
				iterator.failed ?
					<SimpleButton
						border
						onPress={iterator.load}
						style={localStyles.button.layout}
						colors={localStyles.button.colors}>
						{strings.reload}
					</SimpleButton> :
					iterator.loading ?
						<ActivityIndicator style={localStyles.loader}/> :
						iterator.end ?
							!Boolean(iterator.items.length) &&
							<Text style={localStyles.emptyResult}>{strings.emptyResult}</Text> :
							<SimpleButton
								border
								onPress={iterator.load}
								style={localStyles.button.layout}
								colors={localStyles.button.colors}>
								{strings.loadMore}
							</SimpleButton>
			}
		</View>
		);
	})

const localStyles = {
	row: {
		borderBottomWidth: 1,
		borderColor: '#e5e5e5'
	},
	loader: {
		alignSelf: styles.alignSelf.center,
		height: 14,
		width: 14,
		margin: 20,
		paddingVertical: 15,
	},
	button: {
		layout: {
			width: 265,
			height: 55,
			alignSelf: styles.alignSelf.center,
			paddingVertical: 15,
			borderRadius: 50,
			margin: 20
		},
		colors: {
			primary: styles.color.main,
			secondary: styles.color.white,
		}

	},
	emptyResult: {
		alignSelf: styles.alignSelf.center,
		paddingVertical: 15,
		margin: 20
	}
};
