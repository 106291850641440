import React from "react"
import { View as RNView } from "react-native"
import use from "library-react/hook"
import { fonts, strings, styles } from "../../../../res"
import getObjectIdOf from "../../../../library-react/library-js/utils/getObjectIdOf";
import { append, complement, concat, filter, without } from "ramda";
import OpenHour from "../../../../library-react/library-js/app/model/entity/Shop/OpenHour";
import OpenHourRow from "./OpenHourRow";
import callMethod from "../../../../library-react/library-js/utils/function/callMethod";
import { isOhOfDay } from "./utils";

function WeekDayRow({ day, openHours, edition, setEdition, editable, limit = Infinity, onHourEdited, ...props }) {
	const memory = use.memo({ closed: undefined });

	const opened = (edition || openHours).length > 0;

	const rows = (
		edition.length ? edition
			: memory.closed?.length ? memory.closed.slice(0, 1)
				: memory.closed = [newDefaultOpenHour(day)]
	);

	const { Text, Switch } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			<RNView style={localStyles.head}>
				<Text style={localStyles.title(opened || !edition)}>
					{strings.week[day].toUpperCase()}
				</Text>

				{
					edition &&
					<Switch
						animated
						value={opened}
						editable={editable}
						onValueChanged={opened => {
							if (opened) {
								let ohs = memory.closed || openHours.map(callMethod('copy'));
								if (!ohs.length)
									ohs = [newDefaultOpenHour(day)];

								memory.closed = undefined;
								setEdition(concat(ohs));
							} else {
								memory.closed = edition;
								setEdition(filter(complement(isOhOfDay(day))));
							}
						}}
					/>
				}
			</RNView>

			{
				edition ? (
					<RNView style={localStyles.form}>
						{
							rows.map((openHour, index, { length }) =>
								<OpenHourRow
									key={getObjectIdOf(openHour)}
									item={openHour}
									editable={editable && opened}
									onEdited={onHourEdited}
									remove={
										(length > 1) &&
										function removeIt() {
											setEdition(without([openHour]));
										}
									} />
							)
						}


						{
							(edition && (limit > rows?.length)) &&
							<Text
								onPress={
									editable ? (() => {
										setEdition(append(newDefaultOpenHour(day)));
									}) : undefined
								}
								numberOfLines={1}
								style={localStyles.addInput}>
								{`Ajouter une plage horaire`}
							</Text>
						}
					</RNView>
				) : (
					<RNView>
						{
							openHours?.length ? (
								openHours.reduce((rows, openHour, index) => {
									if (!(rows.last.length >= 2))
										rows.last.push(openHour);
									else
										rows.push([openHour]);
									return rows;
								}, [[]])
									.map((row, index) =>
										<Text
											key={index}
											style={localStyles.row}>
											{
												row.map(openHour =>
													`${openHour.openTime}\t\t${openHour.closeTime}`
												).join('\t|\t')
											}
										</Text>
									)
							) :
								<Text style={localStyles.row}>
									{`Fermé`}
								</Text>
						}
					</RNView>
				)
			}
		</RNView>
	);
}

export default React.memo(WeekDayRow);

const newDefaultOpenHour = (day) => new OpenHour(day, 9 * 60, 17 * 60);


const localStyles = {
	layout: {
		paddingTop: 17,
		paddingBottom: 11,
		paddingHorizontal: 20,
	},

	head: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.spaceBetween,
	},

	title: styles.static.bool({
		fontFamily: fonts.Poppins,
		fontSize: 16,
		color: '#11162e',
	}, null, { opacity: .33 }),

	row: {
		fontSize: 14,
		color: 'black',
		fontFamily: fonts.Poppins,
	},

	form: {},


	addInput: {
		// flex: 1,
		flexShrink: 1,
		flexGrow: 1,
		alignSelf: styles.alignSelf.flexEnd,
		bold: true,
		color: '#0d44f7',
		fontSize: 10,
		fontFamily: fonts.Poppins,
		paddingTop: 14,
	},
};
