import React from "react"
import {TextInput} from "react-native";
import View from "library-react/component/View/v2";
import {strings, styles} from "../../../res";
import ClientList from "./ClientList";
import Head from "../../component/Head";
import ComponentUtils from "library-react/ComponentUtils";
import Icon from "library-react/component/Icon";
import use from "library-react/hook";
import Filter from "./Filter";
import FragmentLayout from "../../component/FragmentLayout";


export default function ClientListFragment({query: initialQuery, filter: initialFilter, ...props}) {
	const [query, setQuery] = use.state(initialQuery);
	const [filter, setFilter] = use.state(initialFilter);

	props.contentContainerStyle = ComponentUtils.defaultStyle(props.contentContainerStyle, localStyles.layout);

	return (
		<FragmentLayout {...props}>

			{/*Header*/}
			<View style={[localStyles.header.layout, localStyles.leftMargin]}>
				<Head>
					{strings.fragment.clientList.title}
				</Head>
			</View>

			{/*Search*/}
			<View style={[localStyles.search.layout, localStyles.leftMargin]}>
				<Icon name="MaterialIcons/search"
					  style={localStyles.search.icon}/>

				<TextInput
					placeholder={strings.fragment.clientList.search}
					placeholderTextColor={styles.color.main}
					onChangeText={newQuery => setQuery(newQuery)}
					returnKeyType="search"
					style={localStyles.search.input}/>
			</View>
			{/*Filters*/}
			<Filter onValueChanged={setFilter}
					style={[localStyles.filter, localStyles.leftMargin]}/>

			<ClientList query={query}
						filter={filter}/>
		</FragmentLayout>
	);
}

const localStyles = {
	layout: {
		paddingTop: 28,
		flexGrow: 1,
	},

	leftMargin: {
		marginLeft: 35,
	},

	header: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			flexWrap: styles.flexWrap.wrap,
			justifyContent: styles.justifyContent.spaceBetween,
		},
	},
	search: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.flexStart,
			borderBottomWidth: 1,
			borderBottomColor: styles.color.main,
			marginTop: 25,
			maxWidth: 950,
			marginRight: 50,
		},

		icon: {
			height: 24,
			width: 24,
			color: styles.color.main + "82",
		},

		input: {
			flex: 1,
			fontSize: 19,
			paddingLeft: 10,
			paddingBottom: 10,
		}
	},
	filter: {
		flexDirection: styles.flexDirection.row,
		marginBottom: 30,
		marginTop: 35,
	},
};
