import React from "react";
import use from "library-react/hook";
import {styles} from "@src/res";
import FragmentLayout from "@main/component/FragmentLayout";
import View from "library-react/component/View/v2";
import Text from "library-react/component/Text/v2";
import Portal from "library-react/component/Portal";
import screens from "@main/screens";
import PasswordForm from "@main/component/PasswordForm";

export default function WelcomeUI({...props}) {
	const {Text} = use.theme();
	const [passwordChanged, setPasswordChanged] = use.state.bool();

	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.layout);
	return (
		<FragmentLayout {...props}>

			<Text style={localStyles.title}>
				{`Bienvenue dans la communauté Shopinzon !`}
			</Text>
			<Text style={localStyles.subtitle}>
				{`Vous êtes à quelques clics de la mise en ligne de votre point de vente.`}
			</Text>
			{
				!passwordChanged ?
					<>
						<Text style={localStyles.description}>
							{`Première étape : Veuillez définir le mot de passe de votre compte Shopinzon`}
						</Text>
						<PasswordForm style={localStyles.password}
									  onPasswordChanged={setPasswordChanged.true}/>
					</> :
					<>
						<Text style={localStyles.description}>
							{`Deuxième étape : Cliquez ci-dessous sur « Éditer ma boutique » afin de renseigner \nvos informations principales telles que : l’adresse, le numéro de téléphone, la  description.`}
						</Text>
						<Text style={localStyles.description}>
							{`Troisième étape : Cliquez sur « Ajouter un produit » pour mettre en ligne vos articles ou vos services.`}
						</Text>
						<View style={localStyles.actions.layout}>
							<Portal to={screens.shop}>
								<Text style={localStyles.actions.button}>
									{`Éditer ma boutique`}
								</Text>
							</Portal>
							<Portal to={screens.editProduct}>
								<Text style={localStyles.actions.button}>
									{`Ajouter un produit`}
								</Text>
							</Portal>
						</View>
					</>
			}
		</FragmentLayout>
	)
}
const localStyles = {
	layout: {
		padding: 50,

	},
	title: {
		textAlign: styles.textAlign.center,
		fontSize: 24,
		bold: true,
		marginBottom: 30,
		color: styles.color.azure2,
	},
	subtitle: {
		textAlign: styles.textAlign.center,
		fontSize: 22,
		marginBottom: 5
	},

	password: {
		alignSelf: styles.alignSelf.center,
		margin: 20,
		padding: 20,
		borderStyle: styles.borderStyle.dashed,
		borderWidth: 1,
		borderColor: styles.color.background,
		borderRadius: 20,

		width: 475,
		maxWidth: "100%"
	},
	description: {
		marginTop: 3,
		textAlign: styles.textAlign.center,
		fontSize: 20,
	},
	actions: {
		layout: {
			flexDirection: styles.flexDirection.row,
			justifyContent: styles.justifyContent.spaceEvenly,
		},
		button: {
			marginTop: 30,
			alignSelf: styles.alignSelf.flexEnd,
			paddingHorizontal: 30,
			paddingVertical: 10,
			borderRadius: 10,
			backgroundColor: '#1c6abe',
			marginLeft: 10,
			color: 'white',
			fontSize: 23,
		}
	}
}