import React from "react";
import { StyleSheet, View as RNView } from "react-native";
import { useReservationSpansLoadable } from "../../../library-react/hook/pro/useReservationSpans";
import useFocusTime from "../../../library-react/hook/navigation/useFocusTime";
import { styles } from "../../../res";
import FragmentLayout from "../../component/FragmentLayout";
import use from "../../../library-react/hook";
import { pipe, map, prop, uniq, join, juxt, andThen, without } from "ramda"
import callMethod from "../../../library-react/library-js/utils/function/callMethod";
import useMe from "../../../library-react/hook/pro/useMe";
import screens from "../../screens";
import Icon from "../../../library-react/component/Icon/v2";
import Portal from "../../../library-react/component/Portal";
import useToast from "../../../library-react/hook/useToast";
import willCatchAndToast from "../../../library-react/utils/willCatchAndToast"
import useApp from "../../../library-react/app/useApp";
import willDeleteReservationSpanFactory from "./utils/willDeleteReservationSpanFactory";
import { parallel } from "../../../library-react/library-js/utils/function";
import produce from "immer"
import bindActionToBoolState from "../../../library-react/utils/action/bindActionToBoolState";
import willDeleteReservationClosureFactory from "./utils/willDeleteReservationClosureFactory";


function ReservationSpansListUI() {
	const shop = useMe()?.shop.shop;
	const focusTime = useFocusTime();
	const loadable = useReservationSpansLoadable(focusTime);
	const { spans, closures } = loadable.value || {};

	const toast = useToast();
	const app = useApp();

	const willDeleteSpan = willDeleteReservationSpanFactory({
		toast, app,
		onRunning: (promise, { willParams: [span] }) => promise.then(() => // once the span deleted
			loadable.setValue(
				produce(loadable.value, (loaded) => {
					loaded.spans = without([span], loaded.spans)	 // remove it from the list
				})
			)
		)
	});

	const willDeleteClosure = willDeleteReservationClosureFactory({
		toast, app,
		onRunning: (promise, { willParams: [closure] }) => promise.then(() => // once the span deleted
			loadable.setValue(
				produce(loadable.value, (loaded) => {
					loaded.closures = without([closure], loaded.closures)	 // remove it from the list
				})
			)
		)
	});

	const { Spinner, Text, ErrorView } = use.theme();

	return (
		<FragmentLayout contentContainerStyle={localStyles.layout}>
			{
				spans ?
					<>
						{
							[
								{
									title: `Services`,
									rows: spans.slice(0, 3)
										.map(span => ({
											id: span.id,
											title: span.name,
											description: `${span.numberOfPlaces} couverts\n${pipe(
												map(prop('formatted')),
												uniq,
												join(', ')
											)(span.openHours)}`,

											deleteIt: willDeleteSpan(span),
										})),
									addScreen: screens.editReservationSpan,
									empty: `Aucun service`,
								},
								{
									title: `Périodes de fermetures`,
									rows: closures.slice(0, 3)
										.map(closure => ({
											id: closure.id,
											title: closure.title,
											description: `Du ${pipe(
												juxt([callMethod('getStartMoment', shop.timezone), callMethod('getLastMoment', shop.timezone)]),
												map(mom => mom.format('DD/MM/YY')),
												join(' au '),
											)(closure)}`,

											deleteIt: willDeleteClosure(closure),
										})),
									addScreen: screens.editReservationClosure,
									empty: `Aucune période de fermeture`,
								},
							].map(({ title, rows, addScreen, empty }, index) => (
								<RNView
									key={index}
									style={localStyles.section}>
									<RNView style={localStyles.header}>
										<Text style={localStyles.title}>
											{title}
										</Text>

										<Portal to={addScreen}>
											<Text style={localStyles.create}>
												Ajouter ›
											</Text>
										</Portal>
									</RNView>

									{
										rows.map((props, index) => <Row key={index} addScreen={addScreen} {...props} />)
									}

									{
										!rows.length &&
										<Text style={{ textAlign: 'center', marginVertical: 30 }}>
											{empty}
										</Text>
									}
								</RNView>
							))
						}
					</> :

					<RNView style={[styles.center, { flex: 1 }]}>
						{
							loadable.loading ?
								<Spinner size={40} /> :
								<ErrorView onLoadClicked={loadable.load} />
						}
					</RNView>

			}
		</FragmentLayout>
	);
}

export default React.memo(ReservationSpansListUI);

function Row({ id, title, description, addScreen, deleteIt, ...props }) {

	const [deleting, setDeleting] = use.state.bool();

	const { Spinner, Text } = use.theme();

	return (
		<RNView
			{...props}
			style={localStyles.row}>
			<RNView style={localStyles.infos}>
				<Text style={localStyles.rowTitle}>
					{title}
				</Text>

				<Text style={localStyles.rowDescription}>
					{description}
				</Text>
			</RNView>

			<Portal to={addScreen} params={{ id }}>
				<Icon
					name="MaterialIcons/edit"
					size={30}
					style={localStyles.action} />
			</Portal>

			{
				deleting ?
					<Spinner style={localStyles.action} /> :

					<Icon
						name="MaterialIcons/delete-outline"
						size={30}
						onPress={bindActionToBoolState(setDeleting, deleteIt)}
						style={localStyles.action} />
			}
		</RNView>
	)
}

const localStyles = {
	layout: {
		padding: 40,
		flexGrow: 1,
		alignSelf: 'center',
		width: "90%",
		maxWidth: 700,
	},

	header: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'baseline',
	},

	section: {
		marginBottom: 50,
	},

	title: {
		fontSize: 22,
		color: styles.color.azure2,
		textDecorationLine: 'underline',
		marginBottom: 30,
		textUnderlineOffset: 5,
	},

	create: {
		color: styles.color.azure2,
		fontSize: 18,
		paddingRight: 20,
	},

	row: {
		flexDirection: 'row',
		padding: 20,
	},

	infos: {
		flex: 1,
		justifyContent: 'center',
	},

	rowTitle: {
		fontSize: 18,
		bold: true,
		marginBottom: 10,
	},

	rowDescription: {
	},

	action: {
		width: 50,
		minHeight: 50,
		color: 'lightgrey'
	},


};