import React from "react";
import use from "library-react/hook";
import Text from "library-react/component/Text/v2";
import Row from "library-react/component/Row";
import {styles} from "@src/res";
import Portal from "library-react/component/Portal";
import screens from "@main/screens";
import moment from "moment";
import getResourcesForTransactionState from "library-js/res/getResourcesForTransactionState";
import View from "library-react/component/View/v2";

const TransactionItem = React.memo(
	function TransactionItem({item, ...props}) {
		const {id, creationDate, orderId, amount, fees, IBAN, state, message} = item;

		props.style = use.defaultStyle(props.style, localStyles.layout);
		return (
			<Row{...props} columnsStyles={columnsStyles}>
				<Text style={localStyles.id}>
					{id}
				</Text>
				<Text style={localStyles.date}>
					{moment(creationDate).format("DD/MM/YYYY")}
				</Text>
				<Text style={localStyles.amount}>
					{amount.formatted}
				</Text>
				<Text style={localStyles.fees}>
					{fees.formatted}
				</Text>
				<Portal to={orderId && screens.order} params={{id: orderId}}>
					<Text style={localStyles.orderId}>{orderId}</Text>
				</Portal>
				<View style={localStyles.state.layout}>
					<Text style={localStyles.state.title}>
						{getResourcesForTransactionState(state).title()}
					</Text>
					<Text style={localStyles.state.message}>
						{message}
					</Text>
				</View>
				<Text style={localStyles.iban}>
					{IBAN}
				</Text>
			</Row>
		)
	}
)

TransactionItem.Header = function Header({...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<Row{...props} columnsStyles={columnsStyles}>
			<Text>
				{`ID`}
			</Text>
			<Text>
				{`Date de création`}
			</Text>
			<Text>
				{`Montant`}
			</Text>
			<Text>
				{`Frais`}
			</Text>
			<Text>
				{`Numéro de commande`}
			</Text>
			<Text>
				{`Statut`}
			</Text>
			<Text>
				{`IBAN`}
			</Text>
		</Row>
	)
}

export default TransactionItem;
const columnsStyles = [

	{ // ID
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.center,
		maxWidth: 100,
	},
	{ // Date
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.center,
		maxWidth: 200,
	},
	{ // Amount
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.center,
		maxWidth: 150,
	},
	{ // Fees
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.center,
		maxWidth: 150,
	},
	{ // Order Id
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.center,
		maxWidth: 200,
	},
	{ // State
		alignItems: styles.alignItems.flexStart,
		justifyContent: styles.justifyContent.center,
		maxWidth: 100,
	},
	{ // IBAN
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.center,
		maxWidth: 400,
	}
];

const localStyles = {
	layout: {},
	id: {
		fontSize: 20,
	},
	date: {
		fontSize: 20,
	},
	amount: {
		fontSize: 20,
	},
	fees: {
		fontSize: 20,
	},
	orderId: {
		fontSize: 20,
		color: styles.color.azure2,
		textDecorationLine: styles.textDecorationLine.underline,
	},
	state: {
		layout: {
			flexDirection: styles.flexDirection.column,
			justifyContent: styles.justifyContent.center,
		},
		state: {
			fontSize: 20,
		},
		message: {
			fontSize: 12,
		},
	},
	iban: {
		fontSize: 14,
	}

}