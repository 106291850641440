import View from "library-react/component/View";
import React from "react"
import {styles} from "../../../../../res"
import Text from "library-react/component/Text";
import Image from "library-react/component/Image";
import Icon from "library-react/component/Icon/v2";
import use from "library-react/hook";

export default function Preview({image, title, subtitle, displayTitle, ...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<Text style={localStyles.label}>
				{'Aperçu'}
			</Text>
			<View style={localStyles.image}>
				{
					image &&
					<Image
						source={image}
						resizeMode="cover"
						style={[styles.fit, {zIndex: -1}]}
					/>
				}
				{
					displayTitle &&
					<View style={localStyles.banner.layout}>
						<Text style={localStyles.banner.title}>
							{title}
						</Text>
						{
							Boolean(subtitle) &&
							<View style={localStyles.banner.subtitle.layout}>
								<Text style={localStyles.banner.subtitle.text}>
									{subtitle}
								</Text>
								<Icon name='MaterialCommunityIcons/arrow-right' size={12}/>
							</View>
						}
					</View>
				}

			</View>
		</View>
	);
}
const localStyles = {
	layout: {},
	label: {
		fontSize: 13,
		color: styles.color.main,
		marginBottom: 6,
	},
	image: {
		width: 288,
		height: 185,
		...styles.center,
	},
	banner: {
		layout: {
			backgroundColor: styles.color.white,
			paddingVertical: 10,
			paddingHorizontal: 20,
		},
		title: {
			fontSize: 15
		},
		subtitle: {
			layout: {
				flexDirection: styles.flexDirection.row,
				alignItems: styles.alignItems.center,
				justifyContent: styles.justifyContent.spaceBetween,
			},
			text: {
				fontSize: 6,
				marginRight: 20
			},
		}
	}
};
