import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "../../../res"
import TextInput from "library-react/component/input/TextInput";
import Text from "library-react/component/Text";
import Server from "library-js/app/Server"

export default React.memo(
	React.forwardRef(
		function DomainInput({submitted, onValidChanged, ...props}, refProp) {
			const [domain, setDomain] = use.state("");

			const forceUpdate = use.forceUpdate();
			const domainsAvailable = use.memo({});
			use.effect(
				() => {
					const valid = validate(domain);
					const notDefined = domainsAvailable[domain] === undefined;

					if (valid && notDefined) {
						let canceled = false;

						// check
						Promise.process(() => canceled)
							.then(() => Promise.await(700))
							.then(() => Server.retailer.me.isDomainAvailable(domain))
							.then(response => {
								if (response.ok)
									domainsAvailable[domain] = response.content;
								else
									response.log();
							})
							.then(forceUpdate);

						return () => (canceled = true);
					}
				},
				[domain]
			);

			const available = use.memo(available => available, [domainsAvailable[domain]]);
			const valid = validate(domain) && (available !== false);
			use.onChange(onValidChanged, [valid]);

			const ok = valid || (!domain && !submitted);
			const state = !ok || (available === false) ? State.bad
				: available ? State.good
					: State.ok;

			props.style = use.defaultStyle(props.style, localStyles.layout);

			return (
				<RNView {...props}>
					<RNView style={localStyles.inputWrapper}>
						<TextInput
							ref={refProp}
							onValueChanged={setDomain}
							style={localStyles.input(state)}
						/>

						<Text style={localStyles.suffix}>
							.shopinzon.com
						</Text>
					</RNView>

					<Text style={localStyles.available[available]}>
						{
							available === false ?
								`Le domaine ${domain} n'est pas disponible.`
								: available && `Le domaine ${domain} est disponible.`
						}
					</Text>
				</RNView>
			);
		}
	)
);

function validate(value) {
	return Boolean((value || "").match(/(?!^v[0-9]+$)(?=^[a-z0-9]+([a-z0-9_-]*[a-z0-9]+)?$)/));
}

const State = {
	good: "good",
	ok: "ok",
	bad: "bad",
};


const localStyles = {
	layout: {
	},

	inputWrapper: {
		flexDirection: styles.flexDirection.row,
	},

	input: styles.static(
		{
			flex: 1,
			borderWidth: 1,
			borderRightWidth: 0,
			borderTopLeftRadius: 30,
			borderBottomLeftRadius: 30,
			padding: 10,
			paddingLeft: 20,
			width: 300,
			fontSize: 20,
		},
		{
			good: {borderColor: styles.color.azure2},
			ok: {borderColor: styles.color.lightgrey},
			bad: {borderColor: styles.color.error},
		},
	),

	suffix: {
		backgroundColor: styles.color.azure2,
		color: styles.color.white,
		...styles.newPaddings(10, 20, 15, 15),
		borderTopRightRadius: 30,
		borderBottomRightRadius: 30,
	},

	available: styles.static.bool(
		{
			fontSize: 12,
		},
		{color: styles.color.deepSkyBlue},
		{color: styles.color.error},
	)
};
