import { ApolloClient, gql, useApolloClient } from "@apollo/client";
import { prop } from "ramda";
import React from "react";
import use from "../../../hook";
import TagsInput from "../TagsInput";

export * from "./hook";

export default function ShopTagsInput({ ...props }) {
	const apollo = useApolloClient();
	return (
		<TagsInput
			autocomplete={willAutocomplete(apollo)}
			editorHeader={<TagsWarning />}
			{...props}
		/>
	);
}

/**
 * @param {ApolloClient<object>} apollo 
 * @returns {string[]}
 */
function willAutocomplete(apollo) {
	return async function autocomplete(tag) {
		const response = await apollo.query({
			query: SUGGESTIONS_QUERY,
			variables: { word: `%${tag.toLowerCase()}%` },
		});

		let suggestions = [];
		if (response.data)
			suggestions = response.data.tag.map(prop('name'));
		if (response.errors)
			console.error(`Could not autocomplete: `, response.errors[0]);

		return suggestions;
	};
}

const SUGGESTIONS_QUERY = gql`#graphql
query autocomplete_tags($word: String!) {
	tag (where: {name: {_ilike: $word}}, limit: 20) {
		id
		name
	}	
}
`;

function TagsWarning() {
	const { Text } = use.theme();
	return (
		<Text style={{ padding: 20, marginBottom: 10, fontSize: 12, }}>
			{`Les mots-clés choisis apparaitront dans les titre de vos pages Web (balises title) et dans le lien URL de chacune d’entre elles. Nous insistons donc sur l’importance de la sélection des  mots clés afin de garantir un référencement naturel réussi sur GOOGLE.\n Attention, les mots-clés tels que le nom de votre boutique et la ville sont pré-remplis donc merci de ne pas les rééditer.`}
		</Text>
	);
}