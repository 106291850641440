import React from "react"
import {FlatList, View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "../../res"
import View from "library-react/component/View/v2";
import Icon from "library-react/component/Icon/v2";
import Image from "library-react/component/Image";
import {assoc, eqProps, has, identity, ifElse, omit, prop, T} from "ramda";
import {is} from "library-js/utils";
import {Server} from "library-js/app";
import useMe from "library-react/hook/pro/useMe";
import Response from "library-js/app/Server/Response";
import SimpleButton from "library-react/component/SimpleButton";
import {parallel} from "library-js/utils/function";
import {Brand} from "library-js/app/model/entity";

function SelectBrandModal({multiple, initialValue, onceConfirmed, close, ...props}) {
	const initialNames = use.memo(() => {
		if (!(initialValue instanceof Array))
			initialValue = [initialValue];

		return initialValue.filter(item => is(item, String))
			.map(Brand.parseName)
			.filter(Boolean);
	});

	const {shop} = useMe();
	const initialItemsLoadable = use.loadable.server(
		(initialNames.length > 0) ?
			(() => Server.public.brand.getDetailed(initialNames, {shopId: shop.id})) :
			(() => new Response(Response.Status.Success, {})),
	);
	const initialItems = initialItemsLoadable.value;

	const [query, setQuery] = use.state('');
	const iterator = use.infiniteIterator(() =>
			Server.public.brand.getDetailedList.getIterator({query, shopId: shop.id}).setDelay(800),
		[query, shop.id]
	);

	const items = query ? iterator.items :
		initialItems && iterator.page > 0 &&
		Object.values(initialItems)
			.concat(iterator.items.filter(vBrand => !initialItems[vBrand.parsedName]))
			.distinct(eqProps('parsedName'));

	const [brands, setBrands] = use.state(() => initialNames.toObject(identity, T));
	const toggleSelectionOf = use.callback(parsedName => setBrands(
		!multiple ? {[parsedName]: true} :
			ifElse(has(parsedName),
				omit([parsedName]),
				assoc(parsedName, true),
			)
	), [multiple]);

	const {Dialog, TextInput, Spinner} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<Dialog {...props}>
			<TextInput
				placeholder={`Rechercher une marque...`}
				autoFocus
				onValueChanged={setQuery}
				style={localStyles.query}/>

			<FlatList
				data={items || []}
				keyExtractor={prop('parsedName')}
				renderItem={({item}) =>
					<BrandRow
						item={item}
						selected={brands[item.parsedName]}
						toggleSelectionOf={multiple ? toggleSelectionOf : parallel(onceConfirmed, close)}/>
				}
				onEndReached={initialItems && iterator.loadNextPage}
				onEndReachedThreshold={.1}
				ListFooterComponent={
					(initialItemsLoadable.loading || !iterator.end) &&
					<Spinner style={{margin: 20}}/>
				}
				{...localStyles.listProps}
			/>

			{
				multiple &&
				<RNView style={localStyles.footer}>
					<SimpleButton onPress={close}
								  style={localStyles.button}>
						{`Annuler`}
					</SimpleButton>

					<SimpleButton
						colors={{primary: styles.color.azure}}
						onPress={() => {
							if (onceConfirmed) {
								let result = Object.keys(brands);
								if (!multiple)
									result = result.first;
								onceConfirmed(result);
							}

							close();
						}}
						style={localStyles.button}>
						{`Confirmer`}
					</SimpleButton>
				</RNView>
			}
		</Dialog>
	);
}

export default React.memo(SelectBrandModal);

const BrandRow = React.memo(({selected, item: vBrand, toggleSelectionOf, ...props}) => {
	const {Text} = use.theme();

	props.onPress = use.memo((toggle, parsedName) =>
			() => toggle(parsedName),
		[toggleSelectionOf, vBrand.parsedName]
	);

	props.style = localStyles.row;
	return (
		<View {...props}>
			{SELECTED_LABEL[Boolean(selected)]}

			<Image source={vBrand.logo} resizeMode="contain" style={localStyles.logo}/>

			<RNView style={localStyles.infos}>
				<Text style={localStyles.name}>
					{vBrand.name}
				</Text>

				<Text style={localStyles.productsCount}>
					{vBrand.numberOfAvailableProducts || 0}{` articles`}
				</Text>
			</RNView>
		</View>
	);
});


const SELECTED_LABEL = {
	true: (
		<Icon
			name="MaterialIcons/check"
			size={30}
			style={{color: styles.color.azure, width: 60}}
		/>
	),

	false: <RNView style={{width: 60}}/>
};


const localStyles = {
	layout: {
		...styles.newPaddings(0, 0, 0, 0),
		height: 700,
		maxHeight: '100%',
		width: 500,
		maxWidth: '100%',
	},

	query: {
		borderBottomWidth: 1,
		borderBottomColor: styles.color.lightgrey,
		marginHorizontal: 30,
		paddingTop: 50,
		paddingBottom: 20,
		fontSize: 20,
		bold: true,
	},

	listProps: {
		contentContainerStyle: {
			paddingVertical: 30,
		},

		style: {
			flex: 1,
		},
	},

	row: {
		flexDirection: styles.flexDirection.row,
		paddingRight: 20,
	},

	logo: {
		...styles.square(50),
		marginRight: 20,
		alignSelf: styles.alignSelf.center,
	},

	infos: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		borderBottomWidth: 1,
		borderColor: styles.color.lightgrey,
		height: 70,
		flex: 1,
	},

	name: {
		bold: true,
		flex: 1,
	},

	productsCount: {
		italic: true,
		color: 'grey',
		marginLeft: 5
	},

	footer: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
	},

	button: {
		paddingVertical: 20,
		paddingHorizontal: 40,
	}
};
