export default function override(fn, over){
	if (!(over instanceof Function))
		return fn;

	if (!fn)
		fn = defaultFN;

	function overrideWrapper(...params){
		return over.apply(this, [params, fn.bind(this)]);
	}

	if (fn?.length > 0){
		Object.defineProperties(overrideWrapper, {
			name: {
				configurable: true,
				writable: true,
				value: `${over} > ${fn.name}`,
			},

			length: {
				configurable: true,
				writable: true,
				value: fn.length,
			}
		});
	}

	return overrideWrapper;
}

const defaultFN = () => undefined;
