import use from "library-react/hook";
import View from "library-react/component/View";
import Label from "./Label";
import React from "react";
import {TextInput} from "react-native-paper";
import BooleanInput from "library-react/component/input/BooleanInput";
import globals from "../../../../globals";

export default function HomeInput({defaultTitle, title, subtitle, submittedVersion, displayTitle, link, onTitleChanged: onTitleChangedProps, onSubtitleChanged, onDisplayTitleChanged, onLinkChanged, ...props}) {
	const [error, setError] = use.state(false);

	use.onChange(() => {
		if (!title)
			setError(true);
	}, [submittedVersion]);

	use.onChange(
		() => {
			if (error && Boolean(title))
				setError(false);
		},
		[title]
	);
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<BooleanInput label={'Ajouter un titre'}
						  value={displayTitle}
						  squareStyle={localStyles.checkbox.squareStyle}
						  style={localStyles.checkbox.layout}
						  onValueChanged={onDisplayTitleChanged}
			/>
			{
				displayTitle &&
				<View style={localStyles.infos}>
					<Label subtitle={'Mettre une phrase'}/>
					<TextInput label={'Titre principal'}
							   value={title}
							   error={error}
							   onChangeText={onTitleChangedProps}
					/>
					<TextInput label={'Sous titre'}
							   value={subtitle}
							   onChangeText={onSubtitleChanged}
					/>
					<TextInput label={'Lien cible'}
							   value={link || `https://${globals.me.shop.shop.domain}.shopinzon.com/product`}
							   onChangeText={onLinkChanged}
					/>
				</View>
			}


		</View>
	);
}
const localStyles = {
	layout: {
		minWidth: 400,
	},
	infos: {
		marginLeft: 30
	},
	checkbox: {
		layout: {
			paddingHorizontal: 0,
		},
		squareStyle: {
			width: 20,
			height: 20,
		}
	}
};
