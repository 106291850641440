import React, { useState } from "react";
import { gql, Order_V2, Query_Root, useQuery } from "../../../library-react/library-js/app/graphql";
import getFragmentName from "../../../library-react/library-js/app/graphql/fragment/getFragmentName";
import OrderRow, { ORDER_ROW_FRAGMENT } from "../../component/OrderRow";
import { ClientUITab } from "./TabsSection";

export default function useOrdersTab(userId: string): ClientUITab<Order_V2> {
	const { data, loading, error, fetchMore } = useQuery(CLIENT_UI_ORDERS_QUERY, { variables: { userId } });
	const items = retrieveItems(data) || [];

	const [end, setEnd] = useState(false);
	const loadMore = end || loading ? undefined :
		function () {
			const offset = items?.length;
			const promise = fetchMore({ variables: { offset } });
			promise.then(({ data }) => {
				if (retrieveItems(data)?.length! < PAGE_SIZE)
					setEnd(true);
			});
			return promise;
		};

	return {
		icon: 'orders',
		title: 'COMMANDES',
		renderItem: (item: Order_V2) =>
			<OrderRow
				key={item.id}
				noUser
				item={item} />,
		items,
		loading,
		loadMore,
		error,
	};
}

function retrieveItems(data: Query_Root | undefined) {
	return data?.order_v2;
};

const PAGE_SIZE = 30;
const CLIENT_UI_ORDERS_QUERY = gql`query proweb_clientUI_ordersTab($userId: String!, $offset: Int = 0){
	order_v2(
		where: {userId: {_eq: $userId}}, 
		offset: $offset, 
		limit: ${PAGE_SIZE}
	) {
		...${getFragmentName(ORDER_ROW_FRAGMENT)}
	}
} ${ORDER_ROW_FRAGMENT}`;