import React from "react";
import DefaultConfirmationModal from "../../library-react/component/DefaultConfirmationModal";
import use from "../../library-react/hook";
import { styles } from "../../res";

/**
 * @param {import("../../library-react/component/DefaultConfirmModal").DefaultConfirmModalProps}
 */
function ProConfirmModal({ title, body, ...props }) {
	const { Text } = use.theme();

	props.title = (
		<Text style={localStyles.title}>
			{title}
		</Text>
	);

	props.body = (
		<Text style={localStyles.body}>
			{body}
		</Text>
	);

	if (!props.primaryColor)
		props.primaryColor = styles.color.azure;

	props.buttonTextStyle = localStyles.buttonTextStyle;

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return <DefaultConfirmationModal {...props} />
}

export default React.memo(ProConfirmModal);

const localStyles = {
	layout: {
		maxWidth: 500,
	},

	title: {
		color: styles.color.azure,
	},

	body: {
		fontSize: 18,
	},

	buttonTextStyle: {
		bold: true,
		fontSize: 18,
	},
};