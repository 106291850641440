import React from "react"
import Tabs from "../../component/Tabs"
import {strings, styles} from "../../../res"
import {onSwitch} from "library-js/utils";
import InfosPage from "./InfosPage";
import MediasPage from "./MediasPage";
import use from "library-react/hook";
import FragmentLayout from "../../component/FragmentLayout";

function ShopUI({...props}) {
	const [tab, setTab] = use.state(0);

	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.layout);

	return (
		<FragmentLayout {...props}>
			<Tabs
				tabs={TABS}
				selected={tab}
				style={localStyles.tabs}
				onSelectedChanged={setTab}/>
			{
				onSwitch(tab)
					.case(0, () => <InfosPage/>)
					.case(1, () => <MediasPage/>)
					.run()
			}
		</FragmentLayout>
	)
}

export default React.memo(ShopUI);

const TABS = [
	{
		icon: "shop",
		title: strings.fragment.shop.tab.infos,
	},
	{
		icon: "picture",
		title: strings.fragment.shop.tab.imageManagement,
	},
];

const localStyles = {
	layout: {},

	tabs: {
		borderBottomWidth: 1,
		borderColor: styles.color.separator,
	},
};
