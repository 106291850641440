import { isNil, equals } from "ramda";
import useInputState from "./useInputState";

/**
 * The state is a string[].
 */
export default function useTagsInputState(props) {
	const [value, setValue, valid] = useInputState(props, { correctValue: willCorrectValue() });
	return { value, setValue, valid };
}

const willCorrectValue = () => value => {
	if (!isNil(value)) {
		const correction = [value].flat().filter(Boolean).map(String);
		return equals(correction, value) ? value : correction;
	}
};