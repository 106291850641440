import React from "react"
import ComponentUtils from "library-react/ComponentUtils";
import LinearGradient from "react-native-linear-gradient";
import styles from "../../res/styles";
import View from "library-react/component/View";

export default function Gradient({disabled = Gradient.default.disabled, onPress, onClick, onLongPress, onFocus, onBlur, children, ...props}){
	props = ComponentUtils.mergeProps(props, Gradient.default, ["disabled"]);
	if (disabled)
		props.colors = disabledColors;

	return (
		<LinearGradient {...props}>
			{children}

			{// clickable mask over the view
				(onPress || onClick || onLongPress || onFocus || onBlur) &&
				<View {...{onPress, onClick, onLongPress, onFocus, onBlur}} style={styles.fit}/>
			}
		</LinearGradient>
	);
}
Gradient.default = {
	colors : [styles.color.deepSkyBlue, styles.color.aquaBlue],
};

const disabledColors = [styles.color.transparent, styles.color.transparent];
