import React from "react"
import { View as RNView } from "react-native"
import use from "../../../hook"
import Text from "../../../component/Text";
import Icon from "../../../component/Icon/v2";
import { styles } from "../../../res";
import ImagePlaceholder from "../../../component/ImagePlaceholder";
import images from "../../../res/image";
import View from "../../../component/View/v2";

function ProfileAppBar({ interlocutorLoadable, interlocutor, icon, color, ...props }) {
	if (!interlocutor)
		interlocutor = interlocutorLoadable?.value;
	use.subscribe(
		!interlocutor && interlocutorLoadable?.onStateChanged,
		[interlocutorLoadable, interlocutor]);

	const imageLayoutStyle = use.memo(
		localStyles.image.layout,
		[color]
	);
	const iconStyle = use.memo(
		localStyles.image.icon,
		[color]
	);

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			{
				interlocutor &&
				<>
					<RNView style={imageLayoutStyle}>
						<ImagePlaceholder
							source={interlocutor.picture}
							defaultSource={images.interlocutor}
							resizeMode="cover"
							style={localStyles.image.logo}
						/>

						{
							icon &&
							<Icon
								name={icon}
								size={6}
								style={iconStyle}
							/>
						}
					</RNView>

					<Text
						numberOfLines={1}
						style={localStyles.name}>
						{interlocutor.name}
					</Text>
				</>
			}
		</View>
	);
}

export default React.memo(ProfileAppBar);


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		paddingLeft: 16,
		flex: 1,
	},

	image: {
		layout: borderColor => {
			const size = 37;
			return {
				...styles.center,
				height: size,
				width: size,
				borderRadius: size / 2,
				borderWidth: 1,
				borderStyle: styles.borderStyle.dashed,
				marginRight: 10,
				borderColor,
			};
		},

		logo: (size => ({
			height: size,
			width: size,
			borderRadius: size / 2,
		}))(30),

		icon: backgroundColor => {
			const size = 13;
			return {
				position: styles.position.absolute,
				top: -2,
				right: -2,

				height: size,
				width: size,
				borderRadius: size / 2,
				padding: 4,
				color: styles.color.white,
				backgroundColor,
			};
		}
	},

	name: {
		fontSize: 19,
		bold: true,
		flex: 1,
	}
};
