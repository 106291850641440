import {View as RNView, Animated} from "react-native"
import styles from "../res/styles"
import React from "react"
import use from "../hook";
import debounce from "library-js/utils/debounce";

export default function ProgressBar({progress, color, children, ...props}) {
	const toValue = Math.trunc((Number(progress) || 0) * 10000) / 10000;
	const av = use.memo(() => new Animated.Value(0));

	const [width, setWidthNow] = use.state();
	const setWidth = debounce(setWidthNow, 200);
	props.onLayout = (event) => setWidth(event.nativeEvent.layout.width);

	const barStyle = use.memo(() => ({
		flexBasis: `100%`,
		backgroundColor: color || 'black',
		transform: [{
			translateX: av.interpolate({
				inputRange: [0, 1],
				outputRange: [-width, 0],
				extrapolate: "clamp",
			})
		}],
	}), [color, av, width]);

	use.effect(() => {
		const animation = Animated.timing(av, {
			duration: 500,
			toValue,
			useNativeDriver: true,
		});
		animation.start();
		return () => animation.stop();
	}, [toValue]);


	props.style = use.defaultStyle(props.style, styleSheet.layout);
	return (
		<RNView {...props}>
			{
				width !== undefined &&
				<Animated.View style={barStyle}/>
			}

			{children}
		</RNView>
	);
}

const styleSheet = {
	layout: {
		flexDirection: styles.flexDirection.row,
		overflow: styles.overflow.hidden,
	},

	bar: {},


};
