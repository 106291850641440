import use from "../../hook";
import {View as RNView} from "react-native";
import React from "react";
import ArticleInput from "./ArticleInput";
import Showcase from "../../library-js/app/model/entity/Showcase";
import {styles} from "../../res";
import AddArticle from "./AddArticle";

export default function ArticlesInput({
										  showcase,
										  submitted: submittedProps,
										  disabled,
										  ImageInput,
										  ...props
									  }) {
	let [submitted, setSubmitted] = use.state(submittedProps);
	if (!submitted && submittedProps)
		submitted = submittedProps;


	const forceUpdate = use.forceUpdate();

	const onAddArticle = use.callback(() => {
			if (showcase.articles?.some(article => article.getInvalidFields().length)) {
				setSubmitted(true);
				return
			}
			if (!showcase.articles)
				showcase.articles = [];

			showcase.spliceArticle(showcase?.articles?.length, 0, new Showcase.Article());
			setSubmitted(false);

			forceUpdate();
		},
		[showcase?.articles]
	);

	const onRemoveArticle = use.callback((index) => {
			showcase.spliceArticle(index, 1);

			if (!showcase.articles?.some(article => article.getInvalidFields().length))
				setSubmitted(false);

			forceUpdate();
		},
		[showcase?.articles]
	);

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			{
				showcase?.articles?.map(
					(article, index) =>
						<ArticleInput
							key={JSON.stringify(article.media)}
							value={article}
							multiline
							disabled={disabled}
							submitted={submitted}
							ImageInput={ImageInput}
							onRemove={!disabled && (() => onRemoveArticle(index))}
							style={localStyles.item}
						/>
				)
			}
			{!disabled && <AddArticle onPress={onAddArticle} style={localStyles.add}/>}
		</RNView>
	);
}
const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.column,
	},
	item: {
		marginBottom: 10,
		alignSelf: styles.alignSelf.center,
		height: 300,
		width: '80%'
	},
	add: {
		marginBottom: 10,
	}
}