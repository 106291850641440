import React from "react"
import {View as RNView} from "react-native"
import use from "../hook"
import {styles} from "../res"
import ComponentUtils from "../ComponentUtils";
import {is, when} from "ramda";

function ConfirmModal({...props}) {
	const {DefaultModal, Text} = use.theme();

	props.children = ComponentUtils.childrenToArray(props.children)
		.map(when(is(String), child => <Text key={child} children={child} />));

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<DefaultModal {...props}/>
	);
}

ConfirmModal.Title = ({...props}) => {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, {bold: true, fontSize: 21, marginBottom: 20});
	return <Text {...props}/>;
};

ConfirmModal.Footer = ({...props}) => {
	props.style = use.defaultStyle(props.style, {flexDirection: styles.flexDirection.row, marginTop: 20});
	return <RNView {...props}/>;
};

export default ConfirmModal;


const localStyles = {
	layout: {},
};
