import React from "react"
import use from "library-react/hook"
import AbstractChatListFragment from "library-react/fragment/chat/ChatListFragment"
import {Server} from "library-js/app";
import {Objects} from "library-js/utils";
import Interlocutor from "library-react/fragment/chat/Interlocutor";

function ChatListUI({host, ...props}) {

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<AbstractChatListFragment
			setAppBar={host?.setAppBar}
			loadInterlocutors={loadInterlocutors}
			{...props}
		/>
	);
}

export default React.memo(ChatListUI);


function loadInterlocutors(...ids){
	return Server.retailer.client.getUsers(...ids)
		.updateContent(content =>
			Objects.map(content, (id, user) => Interlocutor.fromUser(user))
		);
}

const localStyles = {
	layout: {},
};
