import screens from "@main/screens";
import OrdersListUI from "@main/ui/order/OrdersListUI";
import ReservationsListUI from "@main/ui/reservation/ReservationsListUI";
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import isEligibleForReservations from "library-js/app/isEligibleForReservations";
import use from "library-react/hook";
import useMe from "library-react/hook/pro/useMe";
import React from "react";
import { View as RNView } from "react-native";


function OrdersAndReservationsUI({ ...props }) {
	const categoryId = useMe().shop.shop.categoryId;

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			<Navigator 
			tabBar={NO_TAB_BAR} 
			screenOptions={{headerShown: false}}
			sceneContainerStyle={{ flex: 1, backgroundColor: 'transparent' }} >
				<Screen name={screens.orderList} component={OrdersListUI} />
				{
					isEligibleForReservations(categoryId) &&
					<Screen name={screens.reservationsList} component={ReservationsListUI} />
				}
			</Navigator>
		</RNView>
	);
}

export default React.memo(OrdersAndReservationsUI);

const NO_TAB_BAR = () => null;

const { Navigator, Screen } = createBottomTabNavigator();

const localStyles = {
	layout: {
		flex: 1,
	},
};
