import React from 'react';
import { View as RNView } from 'react-native'
import { is } from "ramda";
import useTheme, { DEFAULT_THEME } from "../../hook/useTheme";
import SimpleButton from '../SimpleButton'
import useDefaultStyle from '../../hook/useDefaultStyle';


function DefaultConfirmModal({
	title,
	body,
	yes,
	close,
	primaryColor = 'black',
	no,
	noTextStyle,
	buttonTextStyle,
	yesTextStyle,
	tasking,
	yesText,
	noText,
	Modal,
	...props
}) {
	function confirm() {
		yes?.();
		close?.();
	}

	function cancel() {
		no?.();
		close?.();
	}

	const { DefaultModal, Text } = useTheme();
	if (!Modal)
		Modal = DefaultModal;

	if (is(String, body))
		body = (
			<Text>
				{body}
			</Text>
		);


	return (
		<Modal {...props}>
			<Text style={localStyles.title}>
				{title}
			</Text>

			{body}

			<RNView style={localStyles.footer}>
				{
					!tasking ?
						<>
							<SimpleButton onPress={cancel}>
								<Text style={[noTextStyle, buttonTextStyle].flat()}>
									{noText || `Annuler`}
								</Text>
							</SimpleButton>

							<SimpleButton
								onPress={confirm}
								filled
								colors={{ primary: primaryColor, secondary: 'white' }}>
								<Text style={[yesTextStyle, buttonTextStyle].flat()}>
									{yesText || `Confirmer`}
								</Text>
							</SimpleButton>
						</> :

						is(String, tasking) ?
							<Text>{tasking}</Text> :
							tasking
				}
			</RNView>
		</Modal>
	);
}

DEFAULT_THEME.ConfirmModal = DefaultConfirmModal;

export default React.memo(DefaultConfirmModal);

const localStyles = {
	title: {
		fontSize: 25,
		bold: true,
		marginBottom: 20,
	},

	footer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: 20,
	},
}