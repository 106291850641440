import React from "react"
import TagsInput from "../../../library-react/component/input/TagsInput"
import { SIZES_COLLECTIONS } from "../../../library-react/library-js/res/productSizes"

export default function ProductSizesInput({ ...props }) {
	return (
		<TagsInput
			collections={SIZES_COLLECTIONS}
			tagsEditable
			strings={STRINGS}
			{...props}
		/>
	);
}

const STRINGS = {
	add: `Ajouter une taille`,
	tagPlaceholder: `Entrez une taille...`,
	inviteToTap: `Commencez à taper une taille`,
	empty: `Aucune taille`,
}