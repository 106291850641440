import reactGlobals from "library-react/globals"
import { Objects } from "library-js/utils";
import { strings } from "library-react/res";
import AuthManager from "library-js/AuthManager";

const globals = Objects.deepMerge(reactGlobals, {
	apiKeys: {
		google: process.env.REACT_APP_GOOGLE_API_KEY,
		algolia: process.env.REACT_APP_ALGOLIA_API_KEY,
	},
	contactSupportURL: 'https://forms.clickup.com/f/3hxg8-1160/8I2RM2051YRE4HW9JU',
	supportEmail: 'contact@shopinzon.com',
	clientFilters: [
		{
			label: strings.client.filter.all,
			value: {},
		},
		{
			label: strings.client.filter.buyer,
			value: {
				isBuyer: true,
			},
		},
		{
			label: strings.client.filter.member,
			value: {
				isMember: true,
			},
		},
	],
});

const meByUser = {/* user.uid -> Me */ };
Object.defineProperties(globals, {
	me: {
		set(me) {
			if (me)
				meByUser[me.retailer.id] = me;
		},

		get() {
			return meByUser[AuthManager.user?.uid];
		}
	}
});

export default globals;
