import React from "react";
import {Text} from "react-native";
import LinearGradient from "react-native-linear-gradient";
import View from "library-react/component/View";
import ComponentUtils from "library-react/ComponentUtils";
import use from "library-react/hook";
import {styles} from "../../../res";
import globals from "../../globals";


export default React.memo(function ({onValueChanged, initialFilter, ...props}) {
	props.style = ComponentUtils.mergeStyles(localStyles.layout, props.style);
	let [filter, setFilter] = use.state(initialFilter || (globals.clientFilters.first));
	return (
		<View {...props}>
			{
				globals.clientFilters.map((item, index) =>
					<FilterButton
						key={index}
						text={item.label}
						selected={item === filter}
						onPress={() => {
							setFilter(item);
							if (onValueChanged)
								onValueChanged(item.value);
						}}
					/>
				)
			}

		</View>
	);
});


const FilterButton = React.memo(function ({text, selected, ...props}) {
	props.style = ComponentUtils.mergeStyles(localStyles.button, props.style);
	let borderRadius = props.style.borderRadius;

	return (
		<View {...props}>
			{
				selected && <LinearGradient
					style={[styles.fit, {borderRadius, zIndex: -1}]}
					colors={[styles.color.deepSkyBlue, styles.color.aquaBlue]}/>
			}
			<Text style={localStyles.text[Boolean(selected)]}>
				{text}
			</Text>

		</View>
	);
});


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
	},
	button: {
		borderRadius: 50,
		paddingHorizontal: 45,
		paddingVertical: 15,
		minWidth: 130,
		marginRight: 10,
		alignItems: styles.alignItems.center,
	},

	text: ComponentUtils.staticStateStyles(
		{
			fontSize: 17,
			textAlign: styles.textAlign.center,
		},
		{
			true: { // selected
				color: styles.color.white,
			},
			false: {
				color: "rgba(94,99,131,0.52)",
			}
		}
	),
};
