import React from "react";
import View from "../View/v2";
import DefaultTextInput from "../input/TextInput";
import Image from "../Image";

import use from "../../hook";
import { styles } from "../../res";
import Showcase from "../../library-js/app/model/entity/Showcase";
import Icon from "../Icon/v2";

export default function ArticleInput({
	value: article,
	disabled,
	submitted,
	onRemove,
	ImageInput: ImageInputProps,
	TitleInput: TitleInputProps,
	DescriptionInput: DescriptionInputProps,
	...props
}) {
	const { ImageInput: ImageInputContext, TextInput: TextInputContext } = use.theme();

	const ImageInput = ImageInputProps || ImageInputContext || Image;
	const TitleInput = TitleInputProps || TextInputContext || DefaultTextInput;
	const DescriptionInput = DescriptionInputProps || TextInputContext || DefaultTextInput;

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<ImageInput value={article.media}
				source={article.media}
				disabled={disabled}
				placeholder={`Image de l'article`}
				validate={submitted && Showcase.Article.generateValidateOf.media()}
				onValueChanged={value => article.setMedia(value)}
				resizeMode='contain'
				style={localStyles.image} />
			<View style={localStyles.infos.layout}>
				<TitleInput
					placeholder={`Titre de l'article (40 car. max)`}
					maxLength={40}
					disabled={disabled}
					defaultValue={article.title}
					validate={submitted && Showcase.Article.generateValidateOf.title()}
					onValueChanged={value => article.setTitle(value)}
					style={localStyles.infos.title} />
				<DescriptionInput
					placeholder={`Description de l'article (100 car. min)`}
					disabled={disabled}
					defaultValue={article.description}
					validate={submitted && Showcase.Article.generateValidateOf.description()}
					onValueChanged={value => article.setDescription(value)}
					multiline
					style={localStyles.infos.description} />
			</View>
			{
				onRemove &&
				<Icon
					name={"MaterialIcons/close"}
					size={35}
					onPress={onRemove}
					style={localStyles.remove}
				/>
			}
		</View>
	)
}

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
	},
	image: {
		flexBasis: '40%'
	},
	infos: {
		layout: {
			flexBasis: '45%'
		},
		title: {
			fontSize: 35,
			bold: true,
			marginRight: 30,
		},
		description: {
			fontSize: 18,
			flex: 1,
		}
	},
	remove: {
		...styles.absolute({ top: 0, right: 0 }),
		color: 'red',
	}
}