import { LegacyOrderState } from "../../../../graphql";

export default function getOrderStateTargets(as: Role, state: LegacyOrderState): LegacyOrderState[] {
	// @ts-ignore
	return mapPaths[as][state] || [];
}

type Role = keyof (typeof mapPaths);

const mapPaths = {
	user: {
		[LegacyOrderState.prepaymentFailed]: [LegacyOrderState.canceled],
		[LegacyOrderState.reception]: [LegacyOrderState.finished],
	},

	retailer: {
		[LegacyOrderState.preparing]: [LegacyOrderState.ready, LegacyOrderState.canceled],
		[LegacyOrderState.ready]: [LegacyOrderState.canceled],
		[LegacyOrderState.paymentFailed]: [LegacyOrderState.canceled],
	},
} as const;