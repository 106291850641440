import React from "react"
import { View as RNView } from "react-native"
import use from "../../../../hook";
import { styles } from "../../../../res";
import Icon from "../../../Icon/v2";

export default function SequenceHeader({ title, places, ...props }) {
	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			<RNView style={localStyles.body}>
				<Text style={localStyles.title}>
					{title}
				</Text>

				<RNView style={localStyles.places}>
					<Icon name="reservation-restaurant"
						size={11}
						style={localStyles.placesIcon}
					/>

					<Text style={localStyles.numberOfPlaces}>
						{places}
					</Text>
				</RNView>
			</RNView>
		</RNView>
	);
}

const localStyles = {
	layout: {
		backgroundColor: '#f7f7f7',
		...styles.center,
		height: 35,
	},

	body: {
		flexDirection: 'row',
		alignItems: 'center',
		width: '88%',
		justifyContent: 'space-between',
	},

	title: {
		fontSize: 15,
		bold: true,
		color: '#5e6383',
	},

	places: {
		flexDirection: 'row',
		alignItems: 'center',
	},

	placesIcon: {
		color: '#5e6383',
		marginRight: 6,
	},

	numberOfPlaces: {
		minWidth: 40,
		textAlign: 'right',
	}
};