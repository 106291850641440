import { StyleSheet, View, ViewProps } from "react-native";
import use from "../../../../library-react/hook";
import { Order_V2 } from "../../../../library-react/library-js/app/graphql";
import OrderItemRow, { OrderItemRowHeader } from "./ItemRow";

export default function OrderItemList({ order, ...props }: OrderItemListProps) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return ( // layout is reversed in order to allow state button dropdown to be above the next item
		<View {...props}>
			{
				order.items?.map((item) =>
					<OrderItemRow
						key={item.id}
						item={item}
					/>
				)
			}

			<OrderItemRowHeader/>
		</View>
	);
}

interface OrderItemListProps extends ViewProps {
	order: Order_V2,
}

const localStyles = StyleSheet.create({
	layout: {
		flexDirection: "column-reverse",
	}
});
