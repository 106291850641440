import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "../../../../../../../res"
import CatalogSectionDropdownSelection from "../../../../../../component/CatalogSectionDropdownSelection"
import NewSectionForm from "./NewSectionForm";
import NewSubSectionForm from "./NewSubSectionForm";
import Server from "library-js/app/Server";
import globals from "../../../../../../globals";
import CatalogSection from "library-js/app/model/entity/CatalogSection";

export default React.memo(
	function SectionSelection({productId, onValueChanged, ...props}) {
		const {shop} = globals.me.shop;
		const loadable = use.loadable.server(() => Server.public.shop.getSections(shop.id));
		const cupboard = loadable.value;

		const [sections, setSections] = use.state([]);
		use.onChange(onValueChanged, [sections]);

		use.loadable.server(
			productId && (
				() => Server.public.prodshop.getSectionsOf(shop.id, productId)
					.transparent(response => {
						if (response?.ok)
							setSections(response.content);
					})
			),
			[productId],
		);

		const toggle = (section, selected, cupboard) => setSections(
			(sections) => {
				const nextState = !selected;
				if (nextState) { // add parent
					sections = sections.concat(section);
					const parent = cupboard.getParentOf(section);
					if (parent)
						sections = sections.concat(parent);
				} else { // remove children
					const toRemove = cupboard.getChildrenOf(section).concat(section);
					sections = sections.filter(section => !toRemove.some(notAccepted => notAccepted.name === section.name));
				}

				return sections.distinct((section1, section2) => section1.name === section2.name);
			}
		);

		const forceUpdate = use.forceUpdate();
		const add = (sectionName) => {
			if (!cupboard.getSection(sectionName)) {
				const section = new CatalogSection(sectionName);
				cupboard.add([section]);
				toggle(section, false, cupboard);
				forceUpdate();
			}
		};

		props.style = use.defaultStyle(props.style, localStyles.layout);

		return (
			<RNView {...props}>
				<CatalogSectionDropdownSelection
					loadable={loadable}
					refresh={cupboard?.getAll().length}
					productSections={sections}
					toggle={toggle}
					style={localStyles.dropdown}
				/>
				{
					cupboard &&
					<>
						<NewSectionForm
							onSubmitted={add}
							style={localStyles.newSection}/>

						{
							(cupboard.getRootSections().length > 0) &&
							<NewSubSectionForm
								cupboard={cupboard}
								rootSections={cupboard}
								onSubmitted={add}
								style={localStyles.newSubSection}/>
						}
					</>
				}
			</RNView>
		);
	}
);


const localStyles = {
	layout: {
		paddingTop: 27,
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.flexStart,
	},

	dropdown: {
		width: 440,
		marginRight: 40,
	},

	newSection: {
		marginRight: 40,
	}
};
