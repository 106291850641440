import React from "react"
import use from "library-react/hook"
import {styles} from "@src/res"
import useActionsContext from "./useActionsContext";
import PopupMenu from "library-react/component/PopupMenu";
import Icon from "library-react/component/Icon/v2";
import {TextStyleContext} from "library-react/component/Text/v2";
import useApp from "library-react/app/useApp";
import ConfirmModal from "library-react/component/ConfirmModal";
import paragraph from "library-js/utils/function/paragraph";
import SimpleButton from "library-react/component/SimpleButton";

function OtherOptions({remove, ...props}) {
	const {selection, setSelection} = useActionsContext();
	const length = selection.length;

	const app = useApp();
	function onRemoveClicked() {
		const close = app.openModal(
			<ConfirmModal>
				<ConfirmModal.Title>
					{`Attention`}
				</ConfirmModal.Title>

				{paragraph`Vous êtes sur le point de definitivement retirer ${length} article${length > 1 && 's'} de votre catalogue. `}
				<Text style={{bold: true}}>
					{`Cette action est irréversible.`}
				</Text>

				<ConfirmModal.Footer style={{justifyContent: styles.justifyContent.spaceEvenly}}>
					<SimpleButton onPress={() => close()}>
						{`Annuler`}
					</SimpleButton>
					<SimpleButton
						onPress={() => {
							close();
							remove();
						}}
						colors={{primary: 'red'}}>
						{`Retirer`}
					</SimpleButton>
				</ConfirmModal.Footer>
			</ConfirmModal>
		);
	}

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<PopupMenu
			{...props}
			popupStyle={localStyles.popup}
			alignBottom
			trigger={
				<Icon
					name="SimpleLineIcons/options-vertical"
					size={35}
					style={localStyles.trigger}
				/>
			}
		>

			<TextStyleContext.Provider value={localStyles.deleteText}>
				<PopupMenu.Item
					onPress={onRemoveClicked}
					style={localStyles.item}>
					<Icon
						name="MaterialIcons/delete-outline"
						size={30}
						style={localStyles.itemIcon}
					/>

					<Text style={localStyles.itemText}>
						{`Supprimer `}
						{
							length > 1 ? `les ${length} articles selectionnés` :
								`l'article selectionné`
						}
					</Text>
				</PopupMenu.Item>
			</TextStyleContext.Provider>

			<PopupMenu.Item
				onPress={() => setSelection([])}
				style={localStyles.item}>
				<Icon
					size={30}
					style={localStyles.itemIcon}
				/>

				<Text style={localStyles.itemText}>
					{`Annuler la sélection`}
				</Text>
			</PopupMenu.Item>

		</PopupMenu>
	);
}

export default React.memo(OtherOptions);


const localStyles = {
	layout: {
		paddingHorizontal: 15,
		...styles.center,
	},

	trigger: {
		color: 'white'
	},

	popup: {
		bottom: '90%',
		right: 20,
		width: 330,
	},

	item: {
		justifyContent: styles.justifyContent.flexStart,
		paddingHorizontal: 5,
	},

	itemIcon: {
		paddingHorizontal: 10,
		alignSelf: styles.alignSelf.stretch,
	},

	itemText: {
		fontSize: 15,
	},

	deleteText: {
		color: 'red',
	}
};
