import React from "react";
import Icon from "library-react/component/Icon/v2";
import {View as RNView} from "react-native-web";
import Text from "library-react/component/Text/v2";
import ModalFrame from "library-react/component/ModalFrame";
import {styles} from "@src/res";
import useMe from "library-react/hook/pro/useMe";
import use from "library-react/hook";
import mailToUrl from "library-js/utils/mailToUrl";
import globals from "@main/globals";
import useApp from "library-react/app/useApp";

export default function SupportButton({...props}) {
	const app = useApp();

	const shop = useMe().shop.shop;
	const href = use.memo(
		(id, name) =>
			mailToUrl({
				to: globals.supportEmail,
				subject: `SHOP ${id} - ${name}: Assistance pour configuration du paiement`,
			}),
		[shop.id, shop.name]
	);
	props.onPress = use.callback(
		() => app.openModal(
			<ModalFrame.Dialog>
				<RNView style={localStyles.dialog.layout}>
					<Text style={localStyles.dialog.title}>
						{`Vous avez des difficultés à entrer ou modifier certaines configurations du paiements ?`}
					</Text>

					<Text style={localStyles.dialog.text}>
						{`Contactez nos équipes de support.`}
					</Text>
					<Text style={localStyles.dialog.text}>
						{`Elles se feront un plaisir de vous accompagner lors de cette étape.`}
					</Text>

					<Text style={localStyles.dialog.text}>
						{`Merci de détailler votre problème au maximum.`}
					</Text>
					<Text style={localStyles.dialog.button} accessibilityRole='link' href={href} target="_blank">
						{`Nous contacter`}
					</Text>
				</RNView>
			</ModalFrame.Dialog>
		),
		[href]
	);
	return (
		<Icon name="MaterialIcons/contact-support" {...props}/>
	)
}

const localStyles = {
	dialog: {
		layout: {
			flexDirection: styles.flexDirection.column,
			justifyContent: styles.justifyContent.spaceBetween,
			width: 500,
		},
		title: {
			textAlign: styles.textAlign.center,
			fontSize: 25,
			marginBottom: 40,
			bold: true,
		},
		text: {
			textAlign: styles.textAlign.left,
			fontSize: 20,
			marginBottom: 10,
		},
		button: {
			marginTop: 30,
			alignSelf: styles.alignSelf.flexEnd,
			paddingHorizontal: 30,
			paddingVertical: 10,
			borderRadius: 10,
			backgroundColor: '#1c6abe',
			marginLeft: 10,
			color: 'white',
			fontSize: 23,
		}
	},
}