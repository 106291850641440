import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@src/res"
import {SortableElement, SortableHandle} from "react-sortable-hoc";
import Icon from "library-react/component/Icon/v2";
import Text from "library-react/component/Text/v2";

function PinRow({children, onRemoveClicked, warning, ...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			<RNView style={localStyles.side}>
				<DragHandle style={localStyles.handle}/>
				<Icon
					name="MaterialCommunityIcons/delete"
					size={30}
					onPress={onRemoveClicked}
					style={localStyles.action}
				/>

				{
					Boolean(warning) &&
					<Icon
						name="MaterialIcons/warning"
						size={30}
						focusable
						activeOpacity={1}
						style={localStyles.action}>
						{
							({focus}) =>
								focus &&
								<Text style={{
									backgroundColor: 'white',
									zIndex: 1,
									...styles.absolute({left: 0, top:0}),
									padding: 20,
									width: 300,
									...styles.newShadow(0, 3, 5, .1),
									borderRadius: 8,
								}}>
									{warning}
								</Text>
						}
					</Icon>
				}
			</RNView>

			<RNView style={localStyles.content}>
				{children}
			</RNView>

		</RNView>
	);
}

export default SortableElement(PinRow);

const DragHandle = SortableHandle(props => <Icon name="MaterialCommunityIcons/drag" size={40} {...props}/>);

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		backgroundColor: styles.color.white,
		...styles.newBorder(1, 'lightgrey'),
		borderRadius: 10,
		paddingVertical: 10,
	},

	content: {
		flex: 1,
		paddingLeft: 15,
	},

	side: {
		zIndex: 1,
	},

	handle: {
		paddingBottom: 5,
		color: styles.color.black,
		paddingRight: 10,
	},

	action: {
		color: 'grey',
		paddingVertical: 5,
		paddingRight: 10,
	}
};
