import React from 'react'
import {ActivityIndicatorProps} from "react-native"
import Icon from '../../library-react/component/Icon/v2';
import use from '../../library-react/hook';
import { styles } from '../../res';

export default function Spinner({color, ...props}: ProSpinnerProps){
	props.style = use.defaultStyle(props.style, defaultStyle, [color]);
	return <Icon name="loading" size={30} {...props} />;
}

export type ProSpinnerProps = Omit<ActivityIndicatorProps, "size"> & {size?: number};

function defaultStyle(color: string) {
	return {
		color: color || styles.color.azure2,
	};
};
