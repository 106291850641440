import React from "react"
import { StyleSheet, View as RNView, View, ViewProps } from "react-native"
import Icon from "../../../../library-react/component/Icon/v2";
import Image from "../../../../library-react/component/Image";
import Portal from "../../../../library-react/component/Portal";
import Row from "../../../../library-react/component/Row";
import use from "../../../../library-react/hook";
import { OrderItem } from "../../../../library-react/library-js/app/graphql";
import { formatPrice } from "../../../../library-react/library-js/app/model/utils/price";
import { styles } from "../../../../res";
import screens from "../../../screens";


export default function OrderItemRow({ item, ...props }: OrderItemRowProps) {
	const product = item.prodshop?.product;
	const image = product?.galleryList?.[0]?.url;

	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<Row
			{...props}
			columnsStyles={columnsStyles}>

			<RNView />

			{/* Product */}
			<Portal to={product && screens.product} params={{ id: product?.id }}>
				<RNView style={{ flexDirection: styles.flexDirection.rows }}>
					<View style={localStyles.main}>
						{/* Picture */}
						<View style={localStyles.picture}>
							<Image
								source={{ uri: image }}
								resizeMode="cover"
								style={styles.fit}
							/>
						</View>

						{/* Main infos */}
						<View style={localStyles.infos}>
							<Text
								numberOfLines={2}
								style={localStyles.title}>
								{item.title}
							</Text>

							<Text
								numberOfLines={1}
								style={localStyles.subtitle}>
								{item.subtitle}
							</Text>
						</View>
					</View>
				</RNView>
			</Portal>

			{/* Quantity */}
			<RNView>
				<Text style={localStyles.quantity}>
					{item.quantity || 0}
				</Text>
			</RNView>

			{/* Price */}
			<Text
				numberOfLines={1}
				style={localStyles.price}>
				{formatPrice(item.price, item.currency)}
			</Text>


			<Icon
			name=""
				// name="MaterialIcons/edit"
				size={30}
				style={localStyles.edit} />
		</Row>
	);
}

interface OrderItemRowProps extends ViewProps {
	item: OrderItem,
}

export function OrderItemRowHeader({ ...props }: ViewProps) {
	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<Row
			{...props}
			columnsStyles={columnsStyles}>

			{/* Icon state */}
			<View />

			{/* Product */}
			<View />

			{/* Quantity */}
			<Text style={localStyles.title}>
				{`Quantité`}
			</Text>

			{/* Price */}
			<Text style={localStyles.title}>
				{`Prix`}
			</Text>

			{/* Chat button */}
			<View />
		</Row>
	);
}

const columnsStyles = [
	{ // icon
		maxWidth: 50,
		alignItems: styles.alignItems.flexStart,
		paddingRight: 20,
	},
	{ // product
		flexGrow: 2
	},
	{ // quantity
		alignItems: styles.alignItems.center,
		maxWidth: 100,
	},
	{ // price
		alignItems: styles.alignItems.center,
	},
	{ // edit icon
		flex: 0,
		maxWidth: 50,
		alignItems: styles.alignItems.flexStart,
		paddingRight: 26,
	}
];

const localStyles = StyleSheet.create({
	layout: {
		alignItems: "center",
		paddingRight: 67,
		paddingLeft: 44,
		paddingVertical: 12,
	},

	main: {
		flexDirection: "row",
	},

	picture: {
		width: 100,
		height: 100,
		borderRadius: 10,
		overflow: styles.overflow.hidden,
		...styles.newShadow(0, 3, 6, .16),
	},

	infos: {
		marginLeft: 26,
		flex: 1,
	},

	title: {
		bold: true,
		fontSize: 19,
	},

	subtitle: {
		fontSize: 17,
		color: styles.color.secondary,
	},

	quantity: {
		fontWeight: 'bold',
		textAlign: "center",
		paddingVertical: 10,
	},

	price: {
		fontSize: 25,
	},

	edit: {
		color: "lightgrey",
	},
});
