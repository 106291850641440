import React from "react"
import { View as RNView } from "react-native"
import use from "library-react/hook"
import { styles } from "../../../../res"
import TimeInput from "../../../../library-react/component/input/TimeInput";
import Icon from "../../../../library-react/component/Icon/v2";
import { __, divide, pipe, T } from "ramda";

function OpenHourRow({ item: openHour, editable = true, onEdited, remove, ...props }) {
	openHour.bindSetters();
	use.subscribe(openHour?.onPropertyChanged, [openHour]);

	const willNotify = field => () => onEdited?.(openHour, field);

	props.style = use.defaultStyle(props.style, localStyles.layout, [editable]);
	return (
		<RNView {...props}>
			<TimeInput
				defaultValue={openHour.open * Date.MINUTE}
				editable={editable}
				onValueChanged={pipe(divide(__, Date.MINUTE), openHour.setOpen, willNotify('open'))}
				style={localStyles.inputTimeFirst} />

			<TimeInput
				defaultValue={openHour.close * Date.MINUTE}
				invalid={openHour.open >= openHour.close}
				onValueChanged={pipe(divide(__, Date.MINUTE), openHour.setClose, willNotify('close'))}
				editable={editable} />

			{
				remove &&
				<Icon
					name="EvilIcons/close"
					size={15}
					onPress={remove}
					style={localStyles.removeRow}
				/>
			}
		</RNView>
	);
}

export default React.memo(OpenHourRow);

const localStyles = {
	layout: styles.static.bool({
		flexDirection: styles.flexDirection.row,
		marginVertical: 7
	},
		{ opacity: .999 }, // changing from opacity 1 will make a glitch with animateNextRender() the 1st time
		{ opacity: .33 }
	),


	inputTimeFirst: {
		marginRight: 16,
	},
	removeRow: {
		width: 30,
		borderRadius: 5,
		backgroundColor: '#e4e4e4',
		color: '#454f63',
		marginLeft: 10,
	}
};
