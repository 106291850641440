import React from "react";
import use from "../../../hook";
import { styles } from "../../../res";
import Icon from "../../Icon/v2";
import View from "../../View/v2";


function TagRow({ children: value, isDragging, remove, isLast, drag, startEdit, disabled, ...props }) {
	const { Text } = use.theme();

	props.style = use.defaultStyle(props.style, localStyles.entry, [isDragging]);
	return (
		<View {...props}>
			{
				<Icon
					name="MaterialIcons/drag-handle"
					onLongPress={drag}
					delayLongPress={1}
					size={30}
					style={localStyles.action(disabled)}
				/>
			}

			<View
				onPress={startEdit}
				style={localStyles.tagWrapper}>
				<Text style={localStyles.tag(disabled)}>
					{value}
				</Text>
			</View>

			{
				!disabled && !isDragging &&
				<Icon
					name="MaterialIcons/close"
					onPress={remove}
					size={30}
					style={localStyles.action}
				/>
			}
		</View>
	)
}

export default React.memo(TagRow);

const localStyles = {
	entry: isDragging => ({
		flexDirection: styles.flexDirection.row,
		backgroundColor: isDragging ? '#eeeeee99' : 'white',
		marginLeft: 25,
		marginRight: 20,
	}),

	tagWrapper: {
		flex: 1,
		justifyContent: 'center',
		height: 50,
	},

	tag: styles.static.bool(
		{},
		{
			color: 'grey',
		}
	),

	action: styles.static.bool(
		{
			paddingRight: 15,
			paddingLeft: 10,
			color: 'grey',
		},
		{
			opacity: 0
		}
	),
}
