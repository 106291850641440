import React from "react"
import use from "../../../hook"
import ChatList from "./ChatList"

function ChatListFragment({setAppBar, ...props}) {
	use.effect(() => {
		if (setAppBar)
			setAppBar(`Echanges`);
	});

	props.style = use.defaultStyle(props.style, localStyles.layout);
	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.contentContainer);

	return (
		<ChatList
			{...props}/>
	);
}

export default React.memo(ChatListFragment);


const localStyles = {
	layout: {
		backgroundColor: '#f7f8fa',
	},

	contentContainer: {
		marginTop: 10,
		flexGrow: 1,
	}
};
