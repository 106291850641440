import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "../../../../res"
import Text from "library-react/component/Text";
import {TextInput} from "react-native-paper";
import CatalogSection from "library-js/app/model/entity/CatalogSection";
import Portal from "library-react/component/Portal";
import screens from "../../../screens";

function NewSectionForm({onSubmitted, whatsWrong, ...props}) {
	const [name, setName] = use.state("");

	const error = name.includes('/') ? `Le nom ne doit pas contenir de /.`
		: whatsWrong?.(name);

	function submit(){
		if (name && !error) { // validate
			onSubmitted?.(name); // submit
			setName(""); // reset
		}
	}


	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<Text style={localStyles.subtitle}>
				{`Ajouter une nouvelle catégorie à vos articles.`}
			</Text>

			<TextInput
				label="Nouvelle catégorie"
				value={name}
				onChangeText={setName}
				onSubmitEditing={submit}
				style={localStyles.addInput}
			/>

			<Text style={localStyles.message(!error)}>
				{
					error || (
						name ? `Tapez "Entrer" pour ajouter la section.` :
							<>
								{`Cette catégorie sera attribuée au menu de votre `}
								<Portal to={screens.website}>
									<Text style={localStyles.websiteLink}>
										{`site internet`}
									</Text>
								</Portal>
							</>
					)
				}
			</Text>
		</RNView>
	);
}

export default React.memo(NewSectionForm);


const localStyles = {
	layout: {
		marginBottom: 50,
		alignSelf: styles.alignSelf.flexStart,
	},

	subtitle: {
		fontSize: 18,
		marginBottom: 50,
	},

	addInput: {
		fontSize: 18,
	},

	message: styles.static.bool(
		{},
		{},
		{color: styles.color.error},
	),

	addInputDescription: {
		paddingTop: 5,
		fontSize: 14,
	},

	websiteLink: {
		color: '#1dafee',
	},
};
