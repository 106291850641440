import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import AbstractChatFragment from "library-react/fragment/chat/ChatFragment"
import {Server} from "library-js/app";
import Interlocutor from "library-react/fragment/chat/Interlocutor";
import ProfileAppBar from "library-react/fragment/chat/ChatFragment/ProfileAppBar";
import StandardAppBar from "library-react/component/AppBar/StandardAppBar";
import SelectProductDialog from "@main/component/SelectProductDialog";
import AuthManager from "library-js/AuthManager";
import useApp from "library-react/app/useApp";
import {styles} from "@src/res";
import {identity} from "ramda";
import screens from "@main/screens";
import useMe from "library-react/hook/pro/useMe";

function ChatUI({id, onBackClicked, ...props}, ref) {
	const me = useMe();
	const hidePrices = Boolean(me?.shop.shop.commercialization?.is.withoutPrices);

	const interlocutorLoadable = use.loadable.server(
		() => loadInterlocutor(id),
		[id]
	);

	const app = useApp();
	const selectProduct = use.callback(() =>
		new Promise(select => {
			const close = app.openModal(
				<SelectProductDialog
					title={`Selectionnez un article`}
					shopId={AuthManager.claims?.shopId}
					inShop
					onCloseClicked={() => close()}
					onSelected={vProdshop => {
						select(vProdshop);
						close();
					}}
				/>
			);
		})
	);

	const onProductClicked = use.callback(({id}) => app.navigation?.navigate(screens.product, {id}));
	const onOrderClicked = use.callback(({id}) => app.navigation?.navigate(screens.order, {id}));
	const onOrderItemClicked = use.callback(({orderId}) => app.navigation?.navigate(screens.order, {id: orderId}));
	const onReservationClicked = use.callback(({reservation}) => app.navigation?.navigate(screens.reservation, {id: reservation.id}));
	const navigateToUserProfile = use.callback(() => app.navigation?.navigate(screens.client, {id}), [id]);


	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<StandardAppBar onBack={onBackClicked} style={localStyles.appBar}>
				<ProfileAppBar
					interlocutorLoadable={interlocutorLoadable}
				onPress={navigateToUserProfile}/>
			</StandardAppBar>

			<RNView style={localStyles.container}>

				<AbstractChatFragment
					ref={ref}
					id={id}
					shop={me?.shop?.shop}
					hidePrice={hidePrices}
					onProductClicked={onProductClicked}
					onOrderClicked={onOrderClicked}
					onOrderItemClicked={onOrderItemClicked}
					onReservationClicked={onReservationClicked}
					loadInterlocutor={loadInterlocutor}
					selectProduct={selectProduct}
					style={localStyles.chat}
				/>
			</RNView>

		</RNView>
	);
}

export default React.memo(React.forwardRef(ChatUI));

const loadInterlocutor = id => Server.retailer.client.getUsers(id)
	.updateContent(content => Interlocutor.fromUser(content[id]));


const CHAT_ZOOM = 1;
const CHAT_ZOOM_TRANSLATION = `${(1 - 1 / CHAT_ZOOM) / 2 * 100}%`;

const localStyles = {
	layout: {},

	appBar: {
		height: 70
	},

	container: {
		flex: 1,
		overflow: styles.overflow.hidden,
	},

	chat: {
		transform: [{scale: CHAT_ZOOM}],
		...styles.absolute(['top', 'right', 'bottom', 'left'].toObject(identity, () => CHAT_ZOOM_TRANSLATION))
	},
};
