import React from "react"
import use from "../../../../library-react/hook"

export default function ApplyHoursToAllWeekDialog({ openHour, ...props }) {
	const { ConfirmModal } = use.theme();
	return (
		<ConfirmModal
			title={`Voulez vous appliquer ces horaires pour tous les jours de la semaine ?`}
			body={openHour.formatted}

			{...props} />
	);
}