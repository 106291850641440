import React from "react"
import use from "library-react/hook"
import AppTextInput from "../../../../component/AppTextInput";
import ComponentUtils from "library-react/ComponentUtils";
import BrandAutocompletion from "library-react/component/BrandAutocompletion";
import Objects from "library-js/utils/Objects";
import {INPUT_PROPS} from "library-react/component/input/TextInput";

function BrandInput({...props}) {
	const inputProps = Objects.retrieve(props, INPUT_PROPS);
	const [propStyle, inputStyle] = use.memo(() => {
		const styleObject = ComponentUtils.style.merge(props.style);
		const inputStyle = {};
		['paddingLeft', 'paddingRight', 'paddingTop', 'paddingBottom', 'paddingVertical', 'paddingHorizontal', 'fontSize', 'bold']
			.forEach(key => {
				inputStyle[key] = styleObject[key];
				delete styleObject[key];
			});

		return [styleObject, inputStyle];
	}, [props.style]);

	props.style = propStyle;

	return (
		<BrandAutocompletion {...props}>
			<AppTextInput {...inputProps} style={inputStyle}/>
		</BrandAutocompletion>
	);
}

export default BrandInput;
