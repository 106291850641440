import { StackScreenProps } from "@react-navigation/stack";
import "firebase/database";
import React from "react";
import { StyleSheet } from 'react-native';
import LoaderHall from "../../../../library-react/component/LoaderHall";
import { gql, useApolloClient, useQuery } from "../../../../library-react/library-js/app/graphql";
import invalidateCacheEntry from "../../../../library-react/library-js/utils/apollo/invalidateEntityInCache";
import FragmentLayout from "../../../component/FragmentLayout";
import { RootStackParamsList } from "../../../screens";
import ClientView from "./ClientView";
import Footer from "./Footer";
import Head from "./Head";
import OrderItemList from "./OrderItemList";

function OrderUI({ route }: OrderUIProps) {
	const id = route.params?.id;
	const loader = useQuery(gql`query proweb_OrderUI($id: bigint!){
			order_v2_by_pk(id: $id) {
				id
				currentState {state}
				
				customerName
				customerEmail
				customerAddressFormated
				customerPhone

				shopByShopid {
					id
					paymentMeans paymentMeansList @client
				}

				userByUserId {
					id
					id_firebase
					name
					email
					photo
					country locality postal_code route street_number
				}

				items {
					id
					prodshop { 
						id 
						product {
							id 
							gallery galleryList @client
						} 
					}
					title subtitle
					totalPrice
					price
					currency
					quantity
				}

				receptionMean
				expectedPaymentMean
				paymentMean
				paymentAlias
				payments(where: {status: {_eq: "requires_capture"}}) {
					id
					amount
				}

				items_aggregate { aggregate { sum { totalPrice } } }
				
				# to generate pdf hash
				shopName
				creationDate
			}
		}`,
		{ variables: { id } }
	);

	const order = loader.data?.order_v2_by_pk;

	const {cache} = useApolloClient();
	function invalidate(){
		invalidateCacheEntry(cache, "order_v2", id);
	}

	return (
		<FragmentLayout contentContainerStyle={localStyles.frame}>
			<LoaderHall loader={loader} spinnerSize={50} style={localStyles.hall}>
				{
					order &&
					<>
						<Head
							order={order}
							onStateUpdated={invalidate} />

						{
							order.userByUserId &&
							<ClientView order={order} style={localStyles.client} />
						}

						<OrderItemList order={order} style={localStyles.list} />

						<Footer
							order={order}/>
					</>
				}
			</LoaderHall>
		</FragmentLayout>
	);
}

export default React.memo(OrderUI);

type OrderUIProps = StackScreenProps<RootStackParamsList, "order">;

const localStyles = StyleSheet.create({
	frame: {
		paddingTop: 0,
		paddingBottom: 50,
		flexGrow: 1,
	},

	hall: {
		paddingVertical: 20,
		flexGrow: 1,
	},

	client: {
		marginBottom: 20,
	},

	list: {
		marginBottom: 50,
	}
});