import React from "react";
import View from "library-react/component/View";
import Head from "../../component/Head";
import {strings, styles} from "../../../res";
import Text from "library-react/component/Text";
import use from "library-react/hook";
import Switch from "../../component/Switch";
import {Enum} from "library-js/class";
import Server from "library-js/app/Server"
import DomainInput from "./DomainInput";
import Button from "../../component/Button";
import WebsiteConfiguration from "./WebsiteConfigurationFrame";
import useMe from "library-react/hook/pro/useMe";
import FragmentLayout from "../../component/FragmentLayout";
import showSocialModal from "library-react/utils/showSocialModal";
import useApp from "library-react/app/useApp";

function WebsiteUI({...props}) {
	const {shop} = useMe().shop;
	const {domain, id, activated} = shop;
	const previewURL = activated ? `https://${domain}.shopinzon.com`
		: `https://preview-dot-shop-template-dot-rcm55-bagshop.appspot.com/?${URL.Params.encode({domain})}`

	const [state, setState] = use.state(State.idle);
	const [version, upgrade] = use.version();
	const openEdition = use.callback(() => setState(State.editing));

	const disable = use.asyncCallback(shouldStop =>
		() => Promise.process(shouldStop)
			.then(() => setState(State.disabling))
			.then(() => Server.retailer.me.editWebTemplate(null))
			.then(response => {
				if (response.ok)
					shop.domain = null;
				else
					throw response;
			})
			.catch(error => console.warn(error))
			.result(() => setState(State.idle))
	);

	const instances = use.instances({domainInput: null});

	const [valid, setValid] = use.state(false);
	const [submitted, setSubmitted] = use.state(false);
	const submit = use.asyncCallback(shouldStop =>
			() => {
				const domain = instances.domainInput.value;
				setSubmitted(true);

				if (valid)
					Promise.process(shouldStop)
						.then(() => setState(State.enabling))
						.then(() => Server.retailer.me.editWebTemplate(domain))
						.then(response => {
							if (response.ok) {
								shop.domain = domain;
								upgrade();
							} else
								throw response;
						})
						.result((_, error) => {
							setState(error ? State.editing : State.idle);

							if (error)
								console.warn(error);
						});
			},
		[valid]
	);
	const app = useApp();

	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.layout);

	return (
		<FragmentLayout {...props}>
			<View style={localStyles.header.layout}>
				<Head style={localStyles.header.title}>
					{strings.fragment.website.title}
				</Head>
				{
					activated && Boolean(shop.domain) &&
						<Text onPress={() => showSocialModal(app, shop)}
							  style={localStyles.header.share}>
							{`Partager mon site`}
						</Text>
				}

			</View>
			{/* Indication */}
			<View style={localStyles.currentState.layout}>
				{
					// idle
					state.is.idle ? (
							domain ?
								<Text style={localStyles.currentState.text.enabled}>
									{
										activated ?
											`Votre site internet ` :
											`En attendant votre activation, vous pouvez avoir un aperçu de votre site internet `
									}
									{/* Link to website */}
									<Text
										onPress={() => window.open(previewURL, '_blank')}
										style={localStyles.currentState.link}>
										{domain}{`.shopinzon.com`}
									</Text>
									{activated ? `est en ligne` : `en ligne`}
								</Text> :

								<Text style={localStyles.currentState.text.disabled}>
									{`Votre site internet est désactivé`}
								</Text>
						) :

						// tasking
						<Text style={localStyles.currentState.text.switching}>
							{
								state.is.editing ?
									`Choisissez un nom de domaine pour votre site internet:` :

									state.is.enabling ?
										`Activation en cours..` :

										state.is.disabling &&
										`Désactivation en cours..`
							}
						</Text>
				}

				{
					state.is.idle &&
					<Switch
						size={41}
						value={Boolean(domain)}
						onValueChanged={domain ? disable : openEdition}
					/>
				}
			</View>

			{
				<View style={localStyles.form.layout(state.is.editing)}>
					<DomainInput
						ref={instances.set.domainInput}
						onValidChanged={setValid}
						submitted={submitted}
						style={localStyles.form.input}/>

					<Button
						onPress={submit}
						style={localStyles.form.submit}>
						{`Valider`}
					</Button>
				</View>
			}

			<WebsiteConfiguration
				key={version}
				style={{flex: 1}}/>
		</FragmentLayout>
	);
}

export default React.memo(WebsiteUI);


const State = new Enum("idle", "editing", "enabling", "disabling");
State.domains.tasking = [State.enabling, State.disabling];


const localStyles = {
	layout: {
		paddingTop: 20,
		paddingLeft: 20,
		alignItems: styles.alignItems.flexStart,
		flexGrow: 1,
		overflow: styles.overflow.hidden,
	},

	header: {
		layout: {
			flexDirection: styles.flexDirection.row,
			justifyContent: styles.justifyContent.spaceBetween,
			alignItems: styles.alignItems.center,
			width: "100%",
		},
		title: {
			marginBottom: 20,
		},
		share: {
			marginRight: 22,
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			justifyContent: styles.justifyContent.center,
			textDecorationLine: styles.textDecorationLine.underline,
		}
	},

	currentState: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			marginBottom: 20,
		},

		text: styles.static(
			{marginRight: 10},
			{
				enabled: {bold: true},
				switching: {},
				disabled: {color: styles.color.grey}
			},
		),

		link: {
			bold: false,
			color: styles.color.azure2,
			textDecorationLine: styles.textDecorationLine.underline,
		}
	},

	form: {
		layout: styles.static.bool(
			{
				flexDirection: styles.flexDirection.row,
				alignItems: styles.alignItems.flexStart,
				marginBottom: 20
			},
			null,
			styles.gone,
		),

		input: {
			marginRight: 20,
		},

		submit: {}
	}
};
