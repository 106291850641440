import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {strings, styles} from "@src/res"
import Image from "library-react/component/Image";
import View from "library-react/component/View/v2";
import Icon from "library-react/component/Icon/v2";
import SimpleButton from "library-react/component/SimpleButton";
import {Server} from "library-js/app";
import useMe from "library-react/hook/pro/useMe";
import isLinkChangeOk from "./isLinkChangeOk";
import useApp from "library-react/app/useApp";
import Switch from "@main/component/Switch";
import {MAX_SHOP_SHOWCASES_ACTIVE} from "library-js/app/Server";

function ShowcaseRow({vShowcase, vShowcaseShop, inMarket, onSelectedChanged, ...props}) {
	const itemsCount = vShowcaseShop?.itemsCount;
	if (!vShowcase)
		vShowcase = vShowcaseShop?.vShowcase;

	const {shop} = useMe().shop;
	const [saving, setSavingTo] = use.state.bool();
	let [errorMessage, setErrorMessage] = use.state();
	use.effect(() => {
		if (saving)
			setErrorMessage(errorMessage = undefined);
	}, [saving]);

	const app = useApp();
	const toggleLink = (event) => {
		event.preventDefault();

		const newState = !vShowcaseShop ? false : null;
		isLinkChangeOk(vShowcaseShop || vShowcase, newState, app)
			.then(ok => {
				if (!ok) return;

				setSavingTo(true);
				Server.retailer.showcase.link(vShowcase.id, shop.id, newState)
					.then(response => {
						if (response.ok) {
							if (onSelectedChanged)
								onSelectedChanged(newState === false, vShowcase);
						} else
							response.log();
					})
					.finally(setSavingTo.false);
			});
	};

	const toggleActivation = () => {
		const newState = !vShowcaseShop.activated;

		setSavingTo(true);
		Promise.resolve()
			.then(async () => {
				if (newState) {
					const response = await Server.public.showcase.shop.getShowcases(shop.id);
					if (response.ok) {
						const count = response.content?.length || 0;
						if (count >= MAX_SHOP_SHOWCASES_ACTIVE) {
							setErrorMessage(`Vous ne pouvez pas avoir plus de 5 vitrines activées.`);
							throw new Error('Max active showcases reached');
						}
					}
				}

				return Server.retailer.showcase.link(vShowcase.id, shop.id, newState);
			})
			.then(response => {
				if (response.ok) {
					vShowcaseShop.showcaseShop.activated = newState;
				} else
					response.log();
			})
			.catch(console.error)
			.finally(setSavingTo.false);
	};

	const {Text, Spinner} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View focusable={!inMarket} {...props}>
			<Image
				source={{width: 800, uri: vShowcase.banner.url}}
				style={styles.fit}/>

			<RNView style={localStyles.content}>
				<RNView style={localStyles.infos}>
					<Text numberOfLines={2} style={localStyles.title}>
						{vShowcase.title}
					</Text>

					<Text style={{color: "red", fontSize: 12, bold: true}}>
						{errorMessage}{strings.invisibleChar}
					</Text>


					<Text numberOfLines={2} style={localStyles.description}>
						{
							inMarket ?
								vShowcase.description :
								`${itemsCount || 0} produits associés`
						}
					</Text>
				</RNView>


				<RNView style={localStyles.controls}>
					{
						saving ? <Spinner color="white"/> :
							inMarket ?
								<SimpleButton
									onPress={toggleLink}
									border
									filled
									colors={{
										primary: styles.color.azure2,
										secondary: styles.color.white,
									}}
									style={localStyles.select}>
									{
										vShowcaseShop ? <Icon name="MaterialIcons/check" size={25}/> :
											`Ajouter`
									}
								</SimpleButton> :

								<>
									<Switch
										initialValue={vShowcaseShop.activated}
										onValueChanged={toggleActivation}
									/>

									<Icon
										name="MaterialIcons/remove"
										size={22}
										onPress={!vShowcaseShop.activated && toggleLink}
										style={[localStyles.action, !vShowcaseShop.activated ? localStyles.remove : styles.invisible]}
									/>
								</>
					}
				</RNView>
			</RNView>
		</View>
	);
}

export default React.memo(ShowcaseRow);


const localStyles = {
	layout: {
		minHeight: 200,
	},

	content: {
		backgroundColor: 'rgba(0,0,0,.3)',
		flexGrow: 1,
		paddingVertical: 10,
		paddingHorizontal: 20,
		flexDirection: styles.flexDirection.row,
	},

	infos: {
		flex: 1,
		justifyContent: styles.justifyContent.spaceEvenly,
	},

	title: {
		color: styles.color.white,
		bold: true,
		fontSize: 24,
		marginBottom: 5,
	},

	description: {
		color: styles.color.white,
		lineHeight: 22,
	},

	controls: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		paddingLeft: 10,
	},

	select: {
		borderRadius: 5,
		minWidth: 100,
		borderWidth: 3,
		paddingVertical: 3
	},

	action: {
		...styles.circle(40),
		borderWidth: 2,
		color: styles.color.white,
		borderColor: styles.color.white,
		marginLeft: 17,
	},
	remove: {
		color: 'red',
		borderColor: 'red',
	}
};
