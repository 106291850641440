import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook";
import SizesInput from "library-react/component/SizesInput";
import {strings, styles} from "../../../../../res";
import AppTextInput from "../../../../component/AppTextInput";
import CategoryInput from "../../../../component/CategoryInput";
import Text from "library-react/component/Text/v2";
import BrandInput from "./BrandInput";
import useApp from "library-react/app/useApp";

function AdditionalInformation({vProdshop, inputStyle: propsInputStyle, ...props}) {

	const onBrandChanged = use.callback(brand => vProdshop.vProduct.product.brand = brand, [vProdshop]);
	const onReferenceChanged = use.callback(reference => vProdshop.vProduct.product.reference = reference, [vProdshop]);
	const onDescriptionChanged = use.callback(description => vProdshop.vProduct.product.description = description, [vProdshop]);
	const onCategoryChanged = use.callback(category => vProdshop.vProduct.product.category = category, [vProdshop]);
	const onSizesChanged = use.callback(sizes => vProdshop.vProduct.product.sizes = sizes, [vProdshop]);
	const onColorChanged = use.callback(color => vProdshop.vProduct.product.color = color, [vProdshop]);

	const app = useApp();

	props.style = use.defaultStyle(props.style, localStyles.layout);
	const inputStyle = use.defaultStyle(propsInputStyle, {
		maxWidth: 350,
		width: "40%",
	});
	return (
		<RNView {...props}>
			{/*Title*/}
			<Text style={localStyles.title}>
				{strings.fragment.addProduct.additionalInformation.title}
			</Text>
			<Text style={localStyles.subtitle}>
				{strings.fragment.addProduct.additionalInformation.subtitle}
			</Text>
			{/*Form*/}
			<RNView style={localStyles.form.layout}>
				{/*Brand*/}
				<BrandInput
					placeholder={strings.fragment.addProduct.brand.firstUp}
					defaultValue={vProdshop.vProduct.product.brand}
					onValueChanged={onBrandChanged}
					style={inputStyle}
				/>

				{/*Reference*/}
				<AppTextInput
					placeholder={strings.fragment.addProduct.reference.firstUp}
					defaultValue={vProdshop.vProduct.product.reference}
					onValueChanged={onReferenceChanged}
					style={inputStyle}
				/>

				{/*Category*/}
				<CategoryInput
					placeholder={strings.component.CategoryInput.category}
					defaultValue={vProdshop.vProduct.product.category}
					dropdownStyle={localStyles.form.multiSelectionDropdown.layout}
					textStyle={localStyles.form.multiSelectionDropdown.text}
					itemStyle={localStyles.form.multiSelectionDropdown.item}
					onValueChanged={onCategoryChanged}
					style={{flex: 1, flexBasis: "100%"}}
				/>

				<Separator/>
				{/*Color*/}
				<AppTextInput
					placeholder={strings.fragment.addProduct.color.firstUp}
					defaultValue={vProdshop.vProduct.product.color}
					style={inputStyle}
					onChangeText={onColorChanged}
				/>

				{/*Sizes*/}
				<SizesInput
					placeholder={strings.component.SizeInput.size}
					defaultValue={vProdshop.vProduct.product.sizes}
					displayEditor={editor => app.openModal(<EditorModal editor={editor}/>)}
					onValueChanged={onSizesChanged}
					style={localStyles.form.sizes}
				/>
				{/*Description*/}
				<AppTextInput
					multiline
					placeholder={strings.fragment.addProduct.description.firstUp}
					defaultValue={vProdshop.vProduct.product.description}
					style={[inputStyle, localStyles.form.description]}
					onChangeText={onDescriptionChanged}
				/>
				<Separator/>
			</RNView>
		</RNView>
	);
}

function EditorModal({editor, ...props}){
	const {DefaultModal} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.editorModal);
	return (
		<DefaultModal {...props}>
			{editor}
		</DefaultModal>
	);
}

export default React.memo(AdditionalInformation);

function Separator() {
	return (
		<RNView style={{
			flexBasis: "100%",
		}}/>
	);
}

let localStyles;
localStyles = {
	layout: {
		flexDirection: styles.flexDirection.column,
	},
	title: {
		fontSize: 26,
		color: styles.color.azure2,
	},
	subtitle: {
		fontSize: 18,
		color: styles.color.main,
	},
	editorModal: {
		maxWidth: 800,
		width: "40%",
	},
	form: {
		layout: {
			flexDirection: styles.flexDirection.row,
			flexWrap: styles.flexWrap.wrap,
			justifyContent: styles.justifyContent.spaceBetween,
			maxWidth: 980,
			zIndex: 1,
		},
		description: {
			width: "100%",
			height: 100,
			//Don't remove because this style is concated with input's one that contain a maxWidth
			maxWidth: undefined,
		},
		dropdown: {
			maxWidth: 350,
			minWidth: 150,
			width: "40%",
			marginTop: 46,
			paddingBottom: 15,
			paddingTop: 8,
			borderBottomWidth: 0.5,
			borderColor: styles.color.secondary,
		},
		sizes: {
			marginTop: 46,
			flex: 1,
			flexBasis: "100%",
		},
		multiSelectionDropdown: {
			layout: {
				minWidth: 150,
				width: "22%",
				marginTop: 46,
				paddingBottom: 15,
				paddingTop: 8,
				borderBottomWidth: 0.5,
				borderColor: styles.color.secondary,
				position: null,
			},
			text: {},
			item: {
				minWidth: 350,
				paddingHorizontal: 12,
				paddingVertical: 16,
			},
		}
	},
};
