import React from "react"
import {Switch, View as RNView} from 'react-native'
import {styles} from "@src/res";
import {filter, invoker, map, pipe, prop} from "ramda";
import Icon from "library-react/component/Icon/v2";
import useActionsContext from "./useActionsContext";
import PopupMenu from "library-react/component/PopupMenu";
import use from "library-react/hook";
import NumberInput from "library-react/component/input/NumberInput/v2";
import Button from "@main/component/Button";
import View from "library-react/component/View/v2";
import PopupHeader from "./PopupHeader";
import Tooltip from "@main/component/Tooltip";

function PriceButton({...props}) {
	const {selection, update, persisting} = useActionsContext();
	const discounted = selection.some(({prodshop}) => prodshop.discount);
	const [percent, setPercent] = use.state();
	const [rounding, setRoundingTo] = use.state.bool();

	const removeDiscounts = pipe(
		() => selection,
		filter(({prodshop}) => prodshop.discount),
		map(updateACopy({prodshop: {discount: null}})),
		items => update(`Retrait des réductions de ${items.length} article${items.length > 1 ? 's' : ''}`, items),
	);

	const applyDiscount = percent && !persisting
		&& pipe(
			() => selection,
			map(item => {
				const oldValue = item.prodshop.price.value;
				let value = Math.trunc(oldValue * (100 - percent)) / 100;
				if (rounding)
					value = Math.trunc(value);

				return item.updateACopy({prodshop: {discount: {value}}});
			}),
			update(`Application de la réduction sur ${selection.length} article${selection.length > 1 ? 's' : ''}`),
		);

	props.disabled = persisting;

	const [hovered, setHovered] = use.state.bool();

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, [
		localStyles.layout,
		discounted
		&& localStyles.icon,
	], [discounted]);

	return (
		discounted ?
			<Icon
				name={'MaterialIcons/money-off'}
				size={50}
				onPress={removeDiscounts}
				onHoverChanged={setHovered}
				{...props}>
				{
					hovered &&
						<Tooltip>
							{`Retirer les réductions`}
						</Tooltip>
				}
			</Icon> :
			<PopupMenu
				trigger={
					<>
						<Icon
							name={'MaterialIcons/attach-money'}
							size={50}
							style={localStyles.icon}/>

						{
							hovered &&
							<Tooltip>
								{`Appliquer une réduction`}
							</Tooltip>
						}
					</>
				}
				popupStyle={localStyles.popup}
				persistAfterClick
				alignBottom
				onHoverChanged={setHovered}
				{...props}>

				<PopupHeader>
					{`Appliquer une réduction`}
				</PopupHeader>

				<RNView style={localStyles.content}>

				<RNView style={localStyles.inputWrapper}>
					<Icon
						name="MaterialCommunityIcons/minus"
						size={30}
						style={localStyles.minusIcon}
					/>

					<NumberInput
						value={percent}
						onValueChanged={setPercent}
						autoFocus
						min={0}
						max={100}
						decimals={0}
						onSubmitted={applyDiscount}
						style={localStyles.input}
					/>

					<Icon
						name="MaterialCommunityIcons/percent"
						size={35}
						style={localStyles.discountIcon}
					/>
				</RNView>

				<View
					onPress={setRoundingTo.toggle}
					style={localStyles.option}>
					<Switch
						value={rounding}
						style={localStyles.optionSwitch}/>

					<Text style={localStyles.optionText}>
						{`Arrondir à l'unité`}
					</Text>
				</View>

				<Button
					onPress={applyDiscount}
					style={localStyles.submit(percent)}>
					{`Appliquer -`}
					{percent}
					{'%'}
				</Button>
				</RNView>

			</PopupMenu>
	);
}

export default React.memo(PriceButton);

const updateACopy = invoker(1, 'updateACopy');

const localStyles = {
	layout: {
		paddingHorizontal: 15,
		...styles.center,
	},

	icon: {
		color: 'white',
	},

	popup: {
		bottom: '90%',
	},

	content: {
		padding: 30,
	},

	inputWrapper: {
		flexDirection: styles.flexDirection.row,
		height: 50,
		borderRadius: 25,
		borderWidth: 1,
		borderColor: 'grey',
		marginBottom: 20,
		overflow: styles.overflow.hidden,
	},

	minusIcon: {
		marginLeft: 20,
		alignSelf: styles.alignSelf.center,
	},
	discountIcon: {
		paddingRight: 20,
		paddingLeft: 10,
		color: 'white',
		backgroundColor: styles.color.azure2,
	},

	input: {
		width: 140,
		fontSize: 20,
		paddingHorizontal: 20,
		textAlign: styles.textAlign.center,
		fontWeight: 'bold',
	},

	option: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		marginBottom: 20,
		alignSelf: styles.alignSelf.flexStart,
	},
	optionSwitch: {
		marginRight: 10,
		marginLeft: 15,
	},

	optionText: {
		italic: true,
	},

	submit: percent => [
		{paddingHorizontal: 10},
		styles.center,
		styles.phantom(percent, true),
	],
};
