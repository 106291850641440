import React from 'react'
import {isNil, path, pipe} from "ramda";
import use from "../../../hook";
import {TextStyleContext} from "../../Text/v2";
import ComponentUtils from "../../../ComponentUtils";
import inputComponent from "../../abstract/inputComponent";
import moment from "moment";


function /*web*/ TimeInput({value, onValueChanged, editable, style, validate, ...props}) {
	props.onChange = pipe(path(['target', 'value']), toTimestamp, onValueChanged);

	if (!isNil(value) && !isNaN(value)) {
		const valueString = toString(value);
		if (valueString)
			props.value = valueString;
	}

	if (!isNil(editable) && !editable){
		props.disabled = true;
		props.readOnly = true;
	}

	const instances = use.instances({input: null});
	props.type = "time";


	const invalid = validate instanceof Function ? !validate(value) :
		validate ? !value : false;

	const textStyle = use.context(TextStyleContext)
	props.style = use.memo(() =>
			ComponentUtils.style.merge(textStyle, invalid && {color: 'red'}, style),
		[style, textStyle, invalid]
	);
	const id = use.memo(() => 't' + Date.now());

	return (
		<>
			{
				invalid &&
				<style>
					{`#${id}::placeholder { color: red; }`}
				</style>
			}
			<input ref={instances.set.input} id={id} {...props} />
		</>
	);
}

export default inputComponent(TimeInput);

function toTimestamp(timeString) {
	if (timeString?.length === 5) { // Check that we have a valid time string "hh:mm"
		const [hours, minutes] = timeString.split(":").map(Number);
		return hours * Date.HOUR + minutes * Date.MINUTE;
	}
}

function toString(timestamp) {
	const aMoment = moment.utc(timestamp);
	if (aMoment.isValid())
		return aMoment.format('HH:mm');
}
