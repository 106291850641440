import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "../../../../res"
import View from "library-react/component/View/v2";
import Icon from "library-react/component/Icon/v2";
import Text from "library-react/component/Text";
import TextInput from "library-react/component/input/TextInput";
import CatalogSection from "library-js/app/model/entity/CatalogSection";

/**
 * @param {CatalogSection} section
 * @param {Function} onUpdated
 * @param {Function} onDeleteClicked
 * @param {Function} whatsWrong
 * @param {Object} props
 */
export default function SectionView({item: section, validate, onUpdated, onDeleted, whatsWrong, ...props}) {
	const [opened, setOpenedTo] = use.state.bool();
	const [lastName, setLastName] = use.state(section.title);

	const editedSection = section.updateACopy({title: lastName});
	const invalid = lastName.includes('/') ? `Le nom ne doit pas contenir de /.` :
		whatsWrong?.(editedSection, section);

	function submit(){
		if (invalid || !lastName?.trim())
			return;

		setOpenedTo(false);
		onUpdated?.(editedSection, section);
	}


	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<View
				onPress={setOpenedTo.toggle}
				style={localStyles.head}>
				<Text
					numberOfLines={1}
					style={localStyles.name}>
					{section.path.last}
				</Text>

				<Icon
					name="AntDesign/caretdown"
					size={15}
					style={localStyles.icon(opened)}
				/>
			</View>

			{
				opened &&
				<RNView style={localStyles.form.layout}>
					<RNView style={localStyles.form.nameInput.layout}>
						<TextInput
							defaultValue={lastName}
							onValueChanged={setLastName}
							onSubmitted={submit}
							style={localStyles.form.nameInput.self(!invalid)}
						/>

						{/* Error message */}
						<Text style={localStyles.form.nameInput.error}>
							{invalid}
						</Text>
					</RNView>

					<RNView style={localStyles.form.footer.layout}>
						{
							[
								[`Supprimer`, "SimpleLineIcons/close", onDeleted],
								[`Mettre à jour`, "SimpleLineIcons/refresh", submit]
							]
								.map(([label, icon, onPress], index) => (
									<View
										key={index}
										onPress={onPress}
										style={localStyles.form.footer.button.layout}>
										<Icon
											name={icon}
											size={19}
											style={localStyles.form.footer.button.icon}
										/>

										<Text style={localStyles.form.footer.button.label}>
											{label}
										</Text>
									</View>
								))
						}
					</RNView>
				</RNView>
			}
		</View>
	);
}

const localStyles = {
	layout: {
		minWidth: 370,
		borderRadius: 5,
		...styles.newBorder(1, styles.color.background),
		overflow: styles.overflow.hidden,
		backgroundColor: styles.color.white,
	},

	head: {
		backgroundColor: "#f8f9fa",
		flexDirection: styles.flexDirection.row,
		paddingLeft: 30,
		paddingVertical: 10,
		paddingRight: 15,
	},

	name: {
		flex: 1,
	},

	icon: styles.static.bool(
		{color: styles.color.main},
		{transform: [{rotate: "180deg"}]}
	),

	form: {
		layout: {
			...styles.newPaddings(35, 14, 14, 20),
		},

		nameInput: {
			layout: {
				marginBottom: 40, // + 20 for error message height (see style below)
			},

			self: styles.static.bool(
				{
					width: 200,
					fontSize: 17,
					color: styles.color.main,
					paddingBottom: 3,
					borderBottomWidth: 1,
					marginBottom: 2,
				},
				{borderColor: styles.color.azure2},
				{borderColor: styles.color.error}
			),

			error: {
				color: styles.color.error,
				fontSize: 13,
				height: 20,
			}
		},

		footer: {
			layout: {
				flexDirection: styles.flexDirection.row,
				justifyContent: styles.justifyContent.spaceBetween,
			},

			button: {
				layout: {
					flexDirection: styles.flexDirection.row,
					alignItems: styles.alignItems.center,
				},

				icon: {
					marginRight: 9,
				},

				label: {
					fontSize: 15,
					textDecorationLine: styles.textDecorationLine.underline,
					paddingBottom: 3
				}
			}
		}
	},
};
