import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@src/res"
import WebDropdown from "library-react/component/web/Dropdown";
import {useNavigation} from "@react-navigation/native";
import screens from "@main/screens";
import {curry, find, pick, pipe, prop, propEq} from "ramda";
import View from "library-react/component/View/v2";
import Image from "library-react/component/Image";
import {ShowcasePin} from "library-js/app/ShopWebsiteConfiguration/Home/PINS";
import Icon from "library-react/component/Icon/v2";

function ShowcasePinView({pin, update, showcasesLoadable, submitted, wontDisplayNice, ...props}) {
	if (!pin.size)
		pin.size = ShowcasePin.Size.L;

	const {value: showcases, loading} = showcasesLoadable;

	const hasNoShowcases = showcases && showcases.length === 0;

	const findShowcaseBy = curry((by, value) => find(propEq(by, value), showcases || []));
	const showcase = findShowcaseBy('id', pin.id);
	use.effect(() => { // remove invalid showcase
		if (!showcase && showcases && pin.id)
			update({id: undefined});
	}, [showcase, showcases]);

	const navigation = useNavigation();

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			<WebDropdown
				placeholder={
					loading ? `Chargement..` :
						`Selectionez une vitrine *`
				}
				value={showcase?.title || null}
				onValueChanged={
					hasNoShowcases ?
						() => navigation.push(screens.showcasesMarket)
						: pipe(findShowcaseBy('title'), pick(['id']), update)
				}
				options={
					hasNoShowcases ? [`Ajouter une vitrine ↗️`] :
						showcases?.map(prop('title'))
				}
				invalid={!showcase && submitted}
				style={localStyles.dropdown}/>

			{
				Boolean(pin.id) &&
				<RNView style={localStyles.displays}>
					<View onPress={() => update({size: ShowcasePin.Size.M})}>
						<Image
							source={showcase?.portrait}
							resizeMode="cover"
							style={localStyles.sticker}
						/>
						<Text style={localStyles.legend}>
							{`Vignette`}
						</Text>

						{pin.size.is.M && SELECTED_LABEL}
					</View>

					<View onPress={() => update({size: ShowcasePin.Size.L})}>
						<Image
							source={showcase?.banner}
							resizeMode="cover"
							style={localStyles.banner}
						/>
						<Text style={localStyles.legend}>
							{`Bannière`}
						</Text>

						{pin.size.is.L && SELECTED_LABEL}
					</View>
				</RNView>
			}
		</RNView>
	);
}

export default ShowcasePinView;

const SELECTED_LABEL = (
	<Icon
		name="MaterialIcons/check"
		style={{
			...styles.circle(20),
			backgroundColor: styles.color.azure,
			color: styles.color.white,
			...styles.absolute({top: 5, right: 5}),
		}}
	/>
);

const localStyles = {
	layout: {},

	dropdown: {
		fontSize: 25,
		paddingTop: 2,
		paddingBottom: 2,
		paddingLeft: 10,
		paddingRight: 10,
		cursor: 'pointer',
		alignSelf: styles.alignSelf.flexStart,
		textAlignLast: 'center',
		borderRadius: 5,
	},

	displays: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceEvenly,
		paddingVertical: 20,
	},

	sticker: {
		...styles.square(150),
		...styles.newBorder(1, 'lightgrey'),
	},

	banner: {
		height: 150,
		width: 300,
		...styles.newBorder(1, 'lightgrey'),
	},

	legend: {
		fontSize: 14,
		italic: true,
		color: 'grey',
		paddingVertical: 3,
	}
};
