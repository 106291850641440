import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@src/res"
import View from "library-react/component/View/v2";
import Icon from "library-react/component/Icon/v2";
import Tooltip from "@main/component/Tooltip";
import Portal from "library-react/component/Portal";
import screens from "@main/screens";

function AddProductFloatingButton({...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<Portal to={screens.editProduct}>
			<Icon
				name="add"
				size={40}
				hoverable
				{...props}>
				{({hover}) => hover && TOOLTIP}
			</Icon>
		</Portal>
	);
}

export default React.memo(AddProductFloatingButton);

const TOOLTIP = (
	<Tooltip>
		{`Ajouter un article à votre catalogue`}
	</Tooltip>
);


const localStyles = {
	layout: [
		styles.absolute({bottom: 30, right: 30}),
		styles.newShadow(10, 10, 10, .16),
		styles.circle(80),
		{
			overflow: styles.overflow.visible,
			backgroundColor: 'white',
			color: [styles.color.aquaBlue, styles.color.deepSkyBlue],
		},
	],
};
