import Text from "library-react/component/Text";
import View from "library-react/component/View";
import use from "library-react/hook";
import useMe from "library-react/hook/pro/useMe";
import React from "react";
import { Switch, View as RNView } from "react-native";
import { TextInput } from "react-native-paper";
import ShopTagsInput from "../../../../library-react/component/input/ShopTagsInput";
import strings from "../../../../res/strings";
import styles from "../../../../res/styles";
import Card from "../../../component/Card";
import { filter, flatten, map, pipe, toLower } from "ramda";
import useShopCategories from "../../../../library-react/hook/useShopCategories";


export default function InfosCard(
	{
		editedShop,
		willResetFields,
		shopTags,
		editedShopTags,
		setEditedShopTags,
		...props
	}) {
	const { shop } = useMe().shop;
	const setShop = use.callback(editFunction => editFunction(editedShop));

	const [tree = null] = useShopCategories();
	const category = tree?.get(shop.categoryId);

	props.onCanceled = willResetFields(["name", "email", "phone", "description", "requiredAge"]);

	return (
		<Card
			icon="shop"
			title={{
				display: shop.name,
				edit: strings.fragment.shop.generalInfos.title,
			}}
			subtitle={strings.fragment.shop.generalInfos.subtitle}
			{...props}>

			{(editable) =>
				<View style={{ padding: 30, flexGrow: 1 }}>
					{
						editable ?
							// edition
							<>
								{/*Name*/}
								<TextInput
									label={strings.fragment.shop.generalInfos.name.firstUp}
									defaultValue={shop.name}
									onChangeText={text => setShop(shop => shop.name = text)}
									style={localStyles.input}
								/>

								{/*Email*/}
								<TextInput
									label={strings.fragment.shop.generalInfos.email.firstUp}
									style={localStyles.input}
									defaultValue={shop.email}
									onChangeText={text => setShop(shop => shop.email = text)}
								/>

								{/*Phone*/}
								<TextInput
									label={strings.fragment.shop.generalInfos.phone.firstUp}
									style={localStyles.input}
									defaultValue={shop.phone}
									onChangeText={text => setShop(shop => shop.phone = text)}

								/>

								{
									editedShopTags &&
									<RNView style={{ marginBottom: 15 }}>
										<Text style={{ fontSize: 13 }}>
											{`Tags SEO de la boutique en ligne`}
										</Text>

										<ShopTagsInput
											value={editedShopTags}
											placeholder={`Ex: Restaurant, italien, authentique...`}
											reservedKeywords={
												pipe(
													flatten,
													filter(Boolean),
													map(toLower),
												)([shop.name?.split(' '), category?.name.split(' '), shop.address.city.split(' '), shop.address.postalCode])
											}
											onValueChanged={setEditedShopTags}
										/>
									</RNView>
								}


								{/*Description*/}
								<TextInput
									multiline
									label={strings.fragment.shop.generalInfos.description.firstUp}
									style={[localStyles.input, localStyles.descriptionInput]}
									defaultValue={shop.description}
									onChangeText={text => setShop(shop => shop.description = text)}
								/>



								<View
									activeOpacity={1}
									onPress={() => setShop(shop => shop.requiredAge = shop.requiredAge >= 18 ? undefined : 18)}
									style={localStyles[18]}>
									<Text style={{ marginRight: 20, bold: true }}>
										{`Age requis + de 18 ans`}
									</Text>

									<Switch value={editedShop.requiredAge >= 18} />
								</View>
							</> :

							// display
							<>
								{/*Description*/}
								<Text
									numberOfLines={10}
									style={[{ flex: 1 }, localStyles.displayText.layout, localStyles.displayText.text]}>
									{shop.description}
								</Text>


								{
									editedShopTags?.length > 0 &&
									<RNView>
										<Text style={{ fontSize: 13 }}>
											{`Tags SEO de la boutique en ligne`}
										</Text>

										<ShopTagsInput
											key="read-only"
											value={shopTags}
											editable={false} // editable version above
										/>
									</RNView>
								}


								{
									(shop.requiredAge > 0) &&
									<View style={localStyles.displayText.layout}>
										<Text style={localStyles.displayText.label}>
											{strings.fragment.shop.generalInfos.requiredAge.firstUp}
										</Text>

										<Text style={localStyles.displayText.text}>
											{shop.requiredAge}
										</Text>
									</View>
								}

								{/*Email and phone*/}
								<View
									style={[localStyles.displayText.layout, {
										flexDirection: styles.flexDirection.row,
										justifyContent: styles.justifyContent.spaceBetween,
										marginRight: 80,
										marginTop: 30,
									}]}>

									{/*Email*/}
									<View style={{ flexDirection: styles.flexDirection.column }}>
										<Text style={localStyles.displayText.label}>
											{strings.fragment.shop.generalInfos.email.firstUp}
										</Text>
										<Text style={localStyles.displayText.text}>
											{shop.email}
										</Text>
									</View>

									{/*Phone*/}
									<View style={{ flexDirection: styles.flexDirection.column }}>
										<Text style={localStyles.displayText.label}>
											{strings.fragment.shop.generalInfos.phone.firstUp}
										</Text>

										<Text style={localStyles.displayText.text}>
											{shop.phone}
										</Text>
									</View>

								</View>
							</>
					}
				</View>
			}
		</Card>
	);
}

const localStyles = {
	input: {
		marginBottom: 41,
	},

	displayText: {
		layout: {},
		label: {
			fontSize: 15,
			lineHeight: 26,
			marginBottom: 5,
		},
		text: {
			fontSize: 17,
			lineHeight: 26,
			textAlign: styles.textAlign.justify,
		}
	},

	descriptionInput: {
		height: 170,
	},

	18: {
		alignSelf: 'flex-start',
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		paddingVertical: 10,
		marginBottom: 20
	}
};
