import React, {useState} from "react"
import View from "../View";
import WeekDayInput from "./WeekDayInput";
import {Shop} from "../../library-js/app/model/entity"
import TimeInput from "./TimeInput";
import Text from "../Text";
import {strings, styles} from "../../res";
import Icon from "../Icon";
import Environment from "library-js/Environment";

export default function OpenHoursInput({initialValue, onValueChanged, buttonColors, buttonStyle, labelStyle, inputStyle, addButtonStyle, removeIcon, removeStyle, ...props}){
	let [fields, setFields] = useState(() => initialValue ? convert.toFields(initialValue) : []);

	if ("value" in props){
		let valueFields = convert.toFields(props.value);
		delete props.value;

		let last = fields.last;

		// replace all fields
		fields.splice(0, fields.length, ...valueFields);

		if (last && !last.days.includes(true))
			fields.push(last);
	}

	if (!fields.length) // one field at least
		fields.push(newField(0, 0));

	let displayAddButton = fields.last && fields.last.days.includes(true);

	let currentValue = convert.toValue(fields);
	const onFieldsChanged = (differenceInValue) => {
		let newValue = convert.toValue(fields);

		if (onValueChanged && differenceInValue)
			onValueChanged(newValue, currentValue);

		setFields(fields.copy());
	};

	return (
		<View {...props}>
			{
				fields.map(({open, close, days}, index) =>
					<View key={index}
						  style={styles.if(index, {marginTop: 44})}>

						{/*Days*/}
						<WeekDayInput
							value={days}
							colors={buttonColors}
							buttonStyle={buttonStyle}
							onValueChanged={days => {
								fields[index].days = days;
								onFieldsChanged(true);
							}}/>


						{/*Remove*/}
						{
							(fields.length > 1) &&
							<Icon name={removeIcon || "MaterialIcons/close"}
								  style={[localStyles.remove, removeStyle]}
								  onPress={() => {
									  let [field] = fields.splice(index, 1);
									  onFieldsChanged(field.days.includes(true));
								  }}
							/>
						}

						{/*	Open & close times*/}
						<View style={{flexDirection: styles.flexDirection.row, marginTop: 31}}>
							{/*Open*/}
							<View style={{flex: 1, maxWidth: 200, borderBottomWidth: 1, borderColor: styles.color.black}}>
								<Text style={[localStyles.label, labelStyle]}>{strings.component.OpenHoursInput.from}</Text>
								<TimeInput
									value={Environment.select({
										web: open * Date.MINUTE,
										default: open,
									})}
									hourStyle={[localStyles.input.hour, inputStyle]}
									minuteStyle={[localStyles.input.minute, inputStyle]}
									onValueChanged={time => {
										const field = fields[index];
										field.open = Environment.run({
											web: () => Math.trunc(time / Date.MINUTE),
											default: () => time,
										});
										onFieldsChanged(field.days.includes(true));
									}}
								/>
							</View>

							{/*CLose*/}
							<View style={{marginLeft: 30, flex: 1, maxWidth: 200, borderBottomWidth: 1, borderColor: styles.color.black}}>
								<Text style={[localStyles.label, labelStyle]}>{strings.component.OpenHoursInput.to}</Text>
								<TimeInput
									value={Environment.select({
										web: close * Date.MINUTE,
										default: close,
									})}
									hourStyle={[localStyles.input.hour, inputStyle]}
									minuteStyle={[localStyles.input.minute, inputStyle]}
									onValueChanged={time => {
										let field = fields[index];
										field.close = Environment.run({
											web: () => Math.trunc(time / Date.MINUTE),
											default: () => time,
										});
										onFieldsChanged(field.days.includes(true));
									}}
								/>
							</View>
						</View>
					</View>
				)
			}

			<Text
				onPress={displayAddButton ? (() => setFields(fields.repush(newField(0, 0)))) : undefined}
				style={[localStyles.add, addButtonStyle, styles.phantom(displayAddButton, true)]}>
				{strings.component.OpenHoursInput.add}
			</Text>
		</View>
	);
}

const convert = {
	toFields: value => {
		if (!value)
			return [];

		let fields = [];

		value.forEach(openHour => {
			let field = fields.find(field =>
				field.open === openHour.open
				&& field.close === openHour.close
			);

			if (!field)
				fields.push(field = newField(openHour.open, openHour.close));

			field.days[openHour.day.value] = true;
		});

		return fields;
	},

	toValue: fields => {
		if (!fields)
			return [];

		let value = [];

		fields.forEach(field => {
			field.days.forEach((enabled, day) => {
				if (enabled)
					value.push(new Shop.OpenHour(day, field.open, field.close))
			});
		});

		return value;
	},
};

const defaultDays = Array.range(7).map(() => false);
function newField(open, close){
	return {
		days: defaultDays.copy(),
		open,
		close,
	}
}

const localStyles = {
	label: {
		fontSize: 16,
	},

	input: {
		hour: {textAlign: styles.textAlign.left},
		minute: {
			textAlign: styles.textAlign.left,
			width: undefined,
			paddingLeft: 8,
		},
	},

	add: {
		fontSize: 16,
		textDecorationLine: styles.textDecorationLine.underline,
		marginTop: 44.5,
	},

	remove: {
		position: styles.position.absolute,
		top: 0,
		right: 0,
		width: 24,
		height: 24,
	}
};

