import React from "react"
import use from "library-react/hook"
import {styles} from "@src/res"
import PopupMenu from "library-react/component/PopupMenu";
import useApp from "library-react/app/useApp";
import useToast from "library-react/hook/useToast";
import { copyToClipboard } from "../../../../library-react/utils/clipboard";

function ReservationContactButton({vReservation, ...props}) {
	const user = vReservation.vUser?.user;
	const {phone, email} = vReservation?.reservation || {};

	const app = useApp();
	const toast = useToast();

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<PopupMenu
			trigger={
				<Text style={localStyles.trigger}>
					{`Contacter`}
				</Text>
			}
			{...props}>
			{
				[
					phone &&
					{
						title: `WhatsApp`,
						onPress: willOpenLink(`https://wa.me/${toInternationalPhone(phone)}`),
					},
					user &&
					{
						title: 'Chat',
						// onPress: () => app.openChatWith(vUser?.user?.id),
						onPress: () => app.openChatWith(user.id),
					},
					email &&
					{
						title: `Email`,
						onPress: willOpenLink(`mailto:${email}`),
					},
					phone &&
					{
						title: `Copier le téléphone`,
						onPress: () => {
							copyToClipboard(phone);
							toast(`Numéro de téléphone copié!`);
						},
					},
				]
				.filter(Boolean)
				.map(({title, ...props}) => (
					<PopupMenu.Item
						key={title}
						{...props}>
						{title}
					</PopupMenu.Item>
				))
			}
		</PopupMenu>
	);
}

export default React.memo(ReservationContactButton);

const willOpenLink = link => () => window.open(link, '_blank');

function toInternationalPhone(phone) {
	let result = phone.replace(/\ |\.|\+|\-|(^0*)/g, '');
	if (result.length <= 9)
		result = "33" + result; // france by default
	return result;
}

const localStyles = {
	layout: {
		borderRadius: 20,
		height: 40,
		width: 150,
		...styles.center,
		backgroundColor: styles.color.azure,
	},

	trigger: {
		fontSize: 18,
		color: 'white',
		bold: true,
	},
};
