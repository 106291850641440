import { useNavigation } from "@react-navigation/native";
import React from "react"
import Icon from "../../../../library-react/component/Icon/v2";
import Portal from "../../../../library-react/component/Portal";
import ReservationPlanning from "../../../../library-react/component/pro/ReservationsPlanning"
import use from "../../../../library-react/hook";
import { styles } from "../../../../res";
import FragmentLayout from "../../../component/FragmentLayout";
import screens from "../../../screens"
import OrdersListFrame from "../../order/OrdersListUI/OrdersListFrame"

function ReservationsCalendarUI() {
	const { navigate } = useNavigation();

	return (
		<>
			<OrdersListFrame
				containerStyle={localStyles.layout}>

				<Icon
					onPress={() => navigate(screens.ordersAndReservations, { screen: screens.reservationsList })}
					name="MaterialIcons/format-list-bulleted"
					size={30}
					style={{ alignSelf: 'flex-end', padding: 20, paddingRight: 30 }} />
				<ReservationPlanning
					reservationToPortalParams={reservationToPortalParams}
					style={localStyles.body} />

			</OrdersListFrame>



			<Portal to={screens.newReservation}>
				<Icon
					name="MaterialIcons/add"
					size={30}
					style={{
						color: 'white',
						backgroundColor: '#ca293e',
						...styles.circle(70),
						...styles.absolute({ bottom: 30, right: 50 })
					}}
				/>
			</Portal>
		</>
	);
}

export default React.memo(ReservationsCalendarUI);

function reservationToPortalParams({ id }) {
	return {
		to: screens.reservation,
		params: { id }
	};
}

const localStyles = {
	layout: {
		alignSelf: 'center',
		width: "95%",
		maxWidth: 700,
		flexGrow: 1,
		paddingTop: 0,
	},

	planningLink: {
		padding: 20,
		color: 'grey',
		bold: true,
		alignSelf: 'center',
		width: "95%",
		maxWidth: 700,
		textAlign: 'right',
	},


	body: {

	}
};