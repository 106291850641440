import React from "react";
import {View as RNView} from "react-native"
import SectionView from "./SectionView";
import NewSubSectionButton from "./NewSubSectionButton";
import {styles} from "../../../../res";
import use from "library-react/hook";
import Text from "library-react/component/Text";
import Sortable from "@main/component/Sortable";

/**
 * @param cupboard {CatalogCupboard}
 * @param parentSection {CatalogSection}
 * @param creationCheck {Function}
 * @param updateCheck {Function}
 * @param index {Number}
 * @param props {Object}
 */
function SectionList(
	{
		cupboard,
		parentSection,
		creationCheck,
		updateCheck,
		index,
		...props
	}
) {
	function add(section, parentSection) {
		// set index to last
		const lastIndex = cupboard.getLastIndex(parentSection) || -1;
		section.index = lastIndex + 1;
		cupboard.add([section]);
	}


	const sections = cupboard.getChildrenOf(parentSection);
	props.onSortEnd = use.callback(
		({oldIndex, newIndex}) => {
			const sections = cupboard.getChildrenOf(parentSection);
			sections.move(oldIndex, newIndex);
			sections.forEach((section, index) => {
				if (section.index !== index)
					cupboard.update(section.updateACopy({index}), section);
			});
		},
		[cupboard, parentSection]
	);
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<Sortable.Container
			{...props}
			distance={5}
			lockAxis="y">

			{
				sections.map((section, index) => (
					<Sortable.Element
						key={section.name}
						index={index}>

						{/* root section layout */}
						<RNView style={localStyles.rootSectionLayout}>

							{/*	root section */}
							<SectionView
								index={index}
								item={section}
								onUpdated={cupboard.update}
								onDeleted={() => cupboard.delete(section)}
								whatsWrong={updateCheck}
							/>

							{/*	Add subsection form */}
							{
								!parentSection &&
								<NewSubSectionButton
									of={section}
									onSubmitted={add}
									whatsWrong={creationCheck}
									siblings={cupboard.getChildrenOf(section)}
									style={localStyles.newSubSection}
								/>
							}
						</RNView>

						{/* children sections */}
						{
							!parentSection &&
							<SectionList
								parentSection={section}
								cupboard={cupboard}
								creationCheck={creationCheck}
								updateCheck={updateCheck}
								style={localStyles.subSectionsLayout}/>
						}
					</Sortable.Element>
				))
			}

			{// No section at all
				!sections.length && !parentSection &&
				<Text>
					{`Aucune section`}
				</Text>
			}
		</Sortable.Container>
	);
}

export default SectionList;

const localStyles = {
	layout: {},

	rootSectionLayout: {
		flexDirection: styles.flexDirection.row,
		marginBottom: 10,
	},

	newSubSection: {
		marginLeft: 20,
	},

	subSectionsLayout: {
		alignSelf: styles.alignSelf.flexStart,
		paddingLeft: 35,
		marginBottom: 10,
	},
};
