import { add, applyTo, lt, pathEq, pipe, propEq, times, andThen } from "ramda";
import React from "react";
import { View as RNView } from "react-native";
import useApp from "../../../../library-react/app/useApp";
import use from "../../../../library-react/hook";
import { useReservationSpansLoadable } from "../../../../library-react/hook/pro/useReservationSpans";
import useToast from "../../../../library-react/hook/useToast";
import ReservationSpan from "../../../../library-react/library-js/app/model/entity/ReservationSpan";
import Server from "../../../../library-react/library-js/app/Server";
import strictAPI from "../../../../library-react/library-js/app/Server/strictAPI";
import willCatchAndToast from "../../../../library-react/utils/willCatchAndToast";
import FragmentLayout from "../../../component/FragmentLayout";
import { fonts, styles } from "../../../../res";
import WeekDayRow from './WeekDayRow';
import ApplyHoursToAllWeekDialog from "./ApplyHoursToAllWeekDialog";
import { isOhOfDay, WEEK } from "./utils";
import screens from "../../../screens";
import Button from "../../../component/Button"
import willDeleteReservationSpanFactory from "../utils/willDeleteReservationSpanFactory"
import bindPromiseToBoolState from "../../../../library-react/utils/action/bindPromiseToBoolState"
import { parallel } from "../../../../library-react/library-js/utils/function";
import NumberInput from "../../../../library-react/component/input/NumberInput/v2"


function EditReservationSpanUI({ route, navigation }) {
	let id = route.params?.id;
	if (id)
		id = Number(id);

	const loadable = useReservationSpansLoadable();

	const span = use.memorise(([id], lastSpan, [lastId]) => {
		if (lastSpan && id == lastId)
			return lastSpan;

		let editable;
		if (!id)
			editable = new ReservationSpan();
		else {
			const { spans } = loadable.value || {};
			editable = spans?.find(propEq('id', id))?.copy();
		}

		editable?.bindSetters();
		return editable;
	}, [id, loadable.value]);
	use.subscribe(span?.onPropertyChanged, [span]);


	const toast = useToast();
	const [submitted, setSubmitted] = use.state.bool();
	const [submitting, setSubmittingTo] = use.state.bool();

	function submit() {
		setSubmitted(true);

		const valid = (
			span?.name
			&& span.numberOfPlaces > 0
		);

		if (valid)
			persist(span);
	}


	/* intern */ function persist(span) {
		setSubmittingTo(true);
		return strictAPI(Server.retailer.reservation.persistSpan)(id, span)
			.then(() => {
				toast(`Service ${span ? `enregistré` : `supprimé`}!`);
				quit();
			})
			.catch(willCatchAndToast(toast))
			.finally(setSubmittingTo.false);
	}

	/* intern */ function quit() {
		if (navigation.canGoBack())
			navigation.goBack();
		else
			navigation.replace(screens.reservationSpansList);
	}

	const [deleting, setDeletingTo] = use.state.bool();
	const app = useApp();
	const willDelete = willDeleteReservationSpanFactory({
		app, toast,
		onRunning: parallel(bindPromiseToBoolState(setDeletingTo), andThen(quit)),
	});

	const deleteIt = id ? willDelete(span) : quit;

	const editable = !submitting && !deleting;

	let proposeToApplyToAll;
	{
		// --- effect: propose use to apply hours to all week ---
		const [hasProposed, setHasProposed] = use.state.bool();
		const app = useApp();

		if (!id && !hasProposed)
			proposeToApplyToAll = (openHour, field) => {
				if (field === 'close') {
					setHasProposed(true);
					app.openModal(
						<ApplyHoursToAllWeekDialog
							openHour={openHour}
							yes={() => span.openHours = WEEK.map(day => openHour.updateACopy({ day }))} />
					);
				}
			};
	}


	const { Text, Spinner, ErrorView, TextInput } = use.theme();

	return (
		<FragmentLayout
			contentContainerStyle={localStyles.scroll}>
			{
				span ?
					<>
						<RNView style={localStyles.head}>
							<Text style={localStyles.title}>
								{!id && `NOUVEAU `}
								{`SERVICE`}
							</Text>

							<Text
								onPress={!deleting ? deleteIt : undefined}
								style={localStyles.delete}>
								{
									deleting ? `Suppréssion en cours...` :
										id ? `Supprimer` : `Annuler`
								}
							</Text>
						</RNView>

						<TextInput
							placeholder={`Nom du service`}
							autoFocus={!id}
							value={span.name}
							onValueChanged={span.setName}
							validate={submitted}
							editable={editable}
							style={localStyles.input}
						/>

						<NumberInput
							placeholder={`Nombre de couverts`}
							value={span.numberOfPlaces}
							onValueChanged={span.setNumberOfPlaces}
							validate={submitted && lt(0)}
							editable={editable}
							style={localStyles.input}
						/>

						<Text style={[localStyles.title, { marginVertical: 22 }]}>
							{`HORAIRES DU SERVICE`}
						</Text>


						{
							WEEK.map((day, index) =>
								<WeekDayRow
									key={day}
									day={day}
									edition={span.openHours.filter(isOhOfDay(day))}
									limit={1}
									setEdition={pipe(applyTo(span.openHours), span.setOpenHours)}
									onHourEdited={proposeToApplyToAll}
									editable={editable}
									style={localStyles.row(index % 2)}
								/>
							)
						}

						{
							!deleting && (
								submitting ?
									<Spinner style={localStyles.submit} /> :
									<Button
										raised
										onPress={submit}
										style={localStyles.submit}>
										{`Enregistrer`}
									</Button>
							)
						}

					</> :

					// ---
					loadable.loading ?
						<Spinner style={{ marginVertical: 40 }} /> :

						Boolean(id && loadable.value && !span) ?
							<Text style={{ marginVertical: 40, textAlign: 'center' }}>
								{`Cette période de fermeture n'existe pas.`}
							</Text> :

							<ErrorView onLoadClicked={loadable.load} />
			}
		</FragmentLayout>
	);
}

export default React.memo(EditReservationSpanUI);
const localStyles = {
	scroll: {
		padding: 40,
		flexGrow: 1,
		alignSelf: 'center',
		width: "90%",
		maxWidth: 700,
		overflow: "hidden",
	},

	head: {
		flexDirection: "row",
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	title: {
		fontFamily: fonts.Poppins,
		fontSize: 18,
	},

	delete: {
		color: '#eb1b2b',
		fontFamily: fonts.Poppins,
		paddingVertical: 14,
		fontSize: 16,
	},

	input: {
		marginVertical: 16,
		fontSize: 18,
		borderWidth: 1,
		borderColor: 'lightgrey',
		borderRadius: 10,
		padding: 20,
	},


	row: styles.static.bool(
		{},
		{ backgroundColor: '#f7f7f7' },
		{ backgroundColor: 'white' },
	),

	submit: {
		marginTop: 40,
		alignSelf: 'center'
		// borderRadius: 10,
	}
};