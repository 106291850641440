import React from "react"
import {View as RNView} from "react-native"
import VShowcase from "library-js/app/model/view/general/VShowcase";
import use from "library-react/hook";
import SimpleButton from "library-react/component/SimpleButton";
import {parallel} from "library-js/utils/function";
import {styles} from "@src/res";

export default async function isLinkChangeOk(item, willBeLinked, app) {
	const vShowcaseShop = item instanceof VShowcase.Shop ? item : undefined;
	const vShowcase = vShowcaseShop ? vShowcaseShop.vShowcase : item;


	let ok = willBeLinked || (!vShowcaseShop?.itemsCount && vShowcase.isPublic);

	if (!ok)
		ok = await new Promise(resolve => app.openModal(
			<ConfirmShowcaseUnlinkDialog
				showcase={vShowcase.showcase}
				itemsCount={vShowcaseShop?.itemsCount}
				onceConfirmed={() => resolve(true)}/>,
			true,
			() => resolve(false),
		));

	return ok;
}

function ConfirmShowcaseUnlinkDialog({showcase, itemsCount, onceConfirmed, close, ...props}) {
	const {DefaultModal, Text} = use.theme();

	props.style = use.defaultStyle(props.style, {maxWidth: 500});
	return (
		<DefaultModal {...props}>
			<Text style={{bold: true, fontSize: 30}}>
				{`Attention`}
			</Text>
			<Text style={{lineHeight: 24, marginVertical: 30}}>
				{`Vous êtes sur le point de ${showcase.isPublic ? 'retirer' : `supprimer`} la vitrine `}

				<Text style={{bold: true}}>
					{showcase.title}
				</Text>

				{` de votre liste de vitrines. `}

				{
					(itemsCount > 0) &&
					`La vitrine comporte actuellement ${itemsCount} articles. `
				}

				<Text style={{bold: true}}>
					{`Cette action est irréversible.`}
				</Text>
			</Text>

			<RNView style={{flexDirection: styles.flexDirection.row, justifyContent: styles.justifyContent.spaceBetween}}>
				<SimpleButton onPress={close}>
					{`Annuler`}
				</SimpleButton>

				<SimpleButton onPress={parallel(onceConfirmed, close)} colors={{primary: 'red'}}>
					{`Oui, ${showcase.isPublic ? `retirer` : `supprimer`}`}
				</SimpleButton>
			</RNView>
		</DefaultModal>
	);
}
