import { ViewProps } from "react-native";
import Row from "../../../library-react/component/Row";
import use from "../../../library-react/hook";
import rowStyles, { defaultColumnsStyles, noUserColumnStyles } from "./styles";



export default function OrderRowHeader({ noUser, ...props }: OrderRowHeaderProps) {
	const columnsStyles = noUser ? noUserColumnStyles : defaultColumnsStyles;
	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, rowStyles.row);

	return (
		<Row columnsStyles={columnsStyles}
			{...props}>

			{
				TITLES.map((title, index) =>
					<Text
						key={index}
						style={{ fontWeight: 'bold' }}>
						{title}
					</Text>
				)}
		</Row>
	);
};

interface OrderRowHeaderProps extends ViewProps {
	noUser?: any,
}

const TITLES = [
	"",
	"Id",
	"Date de cde",
	"Articles",
	"Total",
	"Statut",
];