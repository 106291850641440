import React from "react"
import use from "library-react/hook";
import View from "library-react/component/View";
import Text from "library-react/component/Text";
import Icon from "library-react/component/Icon";
import AppTextInput from "./AppTextInput";
import {styles} from "../../res";
import Objects from "library-js/utils/Objects";
import {is} from "library-js/utils";

export default React.memo(
	function TagInput({onValueChanged, defaultValue, validate, ...props}) {
		props.style = use.defaultStyle(props.style, localStyles.input);
		const forceUpdate = use.forceUpdate();

		const selectedTags = use.memo(() => {
			if (is(defaultValue, Array))
				return defaultValue.toObject(value => value, () => true);
			return {};
		}, [defaultValue]);

		const addTag = use.callback(
			(tag) => {
				selectedTags[tag] = true;
				onValueChanged(Objects.keysOf(selectedTags));
				forceUpdate();
			},
			[selectedTags]
		);

		const removeTag = use.callback(
			(tag) => {
				if (selectedTags[tag]) {
					delete selectedTags[tag];
					onValueChanged(Objects.keysOf(selectedTags));
					forceUpdate();
				}
			},
			[selectedTags]
		);
		const memory = use.memo({});
		const setInput = use.callback(input => memory.input = input);

		return (
			<View>
				<AppTextInput {...props}
							  ref={setInput}
							  validate={validate && (() => Objects.keysOf(selectedTags).length > 0)}
							  onSubmitEditing={({nativeEvent: {text}}) => {
								  addTag(text);
								  if (memory.input)
									  memory.input.clear();
							  }}
				/>
				{/*Selected Sizes*/}
				{
					Objects.keysOf(selectedTags).length > 0 &&
					<View style={localStyles.tags}>
						{
							Objects.keysOf(selectedTags)
								.map(key =>
									<View key={key}
										  style={localStyles.item.layout}>
										<Text
											style={localStyles.item.text}>
											{key}
										</Text>
										<Icon name="EvilIcons/close"
											  onPress={() => removeTag(key)}
											  style={localStyles.item.icon}/>
									</View>
								)
						}
					</View>
				}
			</View>
		);
	}
)

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
	},
	input: {},
	tags: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		flexWrap: styles.flexWrap.wrap,
		marginTop: 10
	},
	item: {
		layout: {
			borderRadius: 10,
			backgroundColor: styles.color.aquaMarine,
			alignItems: styles.alignItems.center,
			flexDirection: styles.flexDirection.row,
			paddingHorizontal: 20,
			paddingVertical: 15,
			marginRight: 14,
			marginBottom: 10,
		},
		text: {
			color: styles.color.white,
		},
		icon: {
			marginLeft: 10,
			width: 20,
			height: 20,
			color: styles.color.white,
		},
	}
};
