import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "../../res"
import Text from "library-react/component/Text/v2";

function LabeledWrapper({label, children, ...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			<Text style={localStyles.label}>
				{label}
			</Text>

			{children}
		</RNView>
	);
}

export default React.memo(LabeledWrapper);


const localStyles = {
	layout: {},

	label: {
		color: styles.color.black,
		fontSize: 13,
	},
};
