import React from "react"
import use from "library-react/hook"
import {styles} from "@src/res"
import WebDropdown from "library-react/component/web/Dropdown";
import {BrandListPin, BrandPin, SectionPin, ShowcasePin} from "library-js/app/ShopWebsiteConfiguration/Home/PINS";
import Generator from "library-js/utils/Generator";
import Sortable from "@main/component/Sortable";
import PinRow from "./PinRow"
import BrandPinView from "./BrandPinView"
import BrandListPinView from "./BrandListPinView"
import ShowcasePinView from "./ShowcasePinView"
import SectionPinView from "./SectionPinView"
import {map, move, remove, update} from "ramda";
import {Server} from "library-js/app";
import useMe from "library-react/hook/pro/useMe";
import Text from "library-react/component/Text/v2";

function PinsConfigView({home, submitted, ...props}) {
	if (!home.pins)
		home.pins = [];

	const updatePins = update => home.pins = update(home.pins || []);

	const {shop} = useMe().shop;
	const cupboardLoadable = use.loadable.server(() =>
			Server.public.shop.getSections(shop.id),
		[shop.id]);
	const showcasesLoadable = use.loadable.server(() =>
			Server.public.showcase.shop.getShowcases(shop.id)
				.updateContent(map(vShowcaseShop => vShowcaseShop.vShowcase.showcase)),
		[shop.id]);


	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<Sortable.Container
			distance={5}
			lockAxis="y"
			useDragHandle
			onSortEnd={({oldIndex, newIndex}) => updatePins(move(oldIndex, newIndex))}
			{...props}>

			<Text style={localStyles.title}>
				{`En mode avancé, vous pouvez placer et ordonner jusqu'à 10 blocs qui s'afficheront sur la page d'accueil de votre site et le rendront plus chaleureux.`}
			</Text>

			{
				home.pins.map((pin, index) => {
					if (!pin.key)
						pin.key = Generator.getString();

					const PinView = PINS_VIEWS[pin.constructor.type];
					const wontDisplayNice = (pin instanceof ShowcasePin)
						&& pin.size === ShowcasePin.Size.M
						&& home.pins[index + 1]?.size !== ShowcasePin.Size.M
						&& (previous => {
							const mSizesTill = previous.findIndex(pin => pin.size !== ShowcasePin.Size.M);
							if (mSizesTill >= 0)
								previous = previous.slice(0, mSizesTill);

							return (previous.length + /* current */ 1) % 2;
						})(
							home.pins.slice(0, index)
								.reverse()
						);

					return (
						<PinRow
							key={pin.key}
							index={index}
							onRemoveClicked={() => updatePins(remove(index, 1))}
							warning={wontDisplayNice && LONE_M_WARNING}
							style={localStyles.row}>
							<PinView
								{...{pin, cupboardLoadable, showcasesLoadable, submitted}}
								update={updatePin => {
									const copy = pin.copy();
									copy.key = pin.key;
									if (updatePin instanceof Function)
										updatePin(copy);
									else if (updatePin)
										Object.assign(copy, updatePin);

									updatePins(update(index, copy));
								}}/>
						</PinRow>
					);
				})
			}

			{/* Add button */}
			{
				!(home.pins?.length >= 10) ?
					<WebDropdown
						key={home.pins?.length || 0}
						placeholder={`Ajouter un bloc`}
						options={Object.keys(PINS_OPTIONS)}
						onValueChanged={option => home.pins = home.pins.concat(new PINS_OPTIONS[option])}
						invalid={submitted && !home.pins?.length}
						style={localStyles.add}
					/> :
					MAX_SECTIONS_HINT
			}
		</Sortable.Container>
	);
}

export default PinsConfigView;

const LONE_M_WARNING = `L'affichage de cette vignette va être calé sur la gauche. Faites suivre cette vignette par une autre vignette, ou selectionnez l'affichage mode Bannière, afin d'avoir un affichage horizontalement rempli.`;

const MAX_SECTIONS_HINT = (
	<Text
		style={{textAlign: styles.textAlign.center, fontSize: 20, marginVertical: 30, color: 'lightgrey', bold: true}}>
		{`Vous avez atteint le maximum de blocs`}
	</Text>
)

const PINS_OPTIONS = {
	[`Mettre en avant une marque`]: BrandPin,
	[`Placer une de mes vitrines`]: ShowcasePin,
	[`Ajouter une catégorie de mon catalogue`]: SectionPin,
	[`Regrouper plusieurs marques`]: BrandListPin,
};

const PINS_VIEWS = {
	[BrandPin.type]: BrandPinView,
	[BrandListPin.type]: BrandListPinView,
	[ShowcasePin.type]: ShowcasePinView,
	[SectionPin.type]: SectionPinView,
};

const localStyles = {
	layout: {},

	title: {
		bold: true,
		textAlign: styles.textAlign.center,
		marginBottom: 30,
		alignSelf: styles.alignSelf.center,
		width: 500,
		maxWidth: '100%',
	},

	row: {
		marginVertical: 30,
	},

	add: {
		fontSize: 25,
		minWidth: 400,
		alignSelf: styles.alignSelf.center,
		...styles.newBorder(1, 'lightgrey', styles.borderStyle.dashed),
		marginTop: 40,
		height: 70,
		cursor: 'pointer',
		textAlignLast: 'center',
		appearance: 'none',
	},
};
