import useMe from "library-react/hook/pro/useMe";
import use from "library-react/hook";
import mailToUrl from "../../library-react/library-js/utils/mailToUrl";
import Text from "library-react/component/Text/v2";
import React from "react";
import {styles} from "@src/res";
import useApp from "library-react/app/useApp";
import ModalFrame from "library-react/component/ModalFrame";
import {View as RNView} from "react-native-web";
import globals from "@main/globals";

function MailDialogButton({subject, body, title, text, action, children, ...props}) {
	const shop = useMe().shop.shop;
	const href = use.memo(
		(id, name) =>
			mailToUrl({
				to: globals.supportEmail,
				subject: `SHOP ${id} - ${name}: ${subject}`,
				body: `Bonjour, \n${body}\nVoir mon profil: https://master.shopinzon.com/shop?id=${id}.\nMerci`,
			}),
		[shop.id, shop.name]
	);

	const app = useApp();
	props.onPress = use.callback(
		() => {
			const close = app.openModal(
				<ModalFrame.Dialog>
					<RNView style={localStyles.dialog.layout}>
						<Text style={localStyles.dialog.title}>
							{`${title}`}
						</Text>

						<Text style={localStyles.dialog.text}>
							{`${text}`}
						</Text>

						<Text style={localStyles.dialog.button} accessibilityRole='link' href={href} target="_blank">
							{`${action}`}
						</Text>
					</RNView>
				</ModalFrame.Dialog>
			);
		},
		[href]
	);

	props.style = use.defaultStyle(props.style, localStyles.button);
	return (
		<Text {...props}>
			{children || `En savoir plus`}
		</Text>
	);
}

export default React.memo(MailDialogButton);

const ActivateButton = React.memo(
	function ActivateButton({...props}) {
		props.subject = `Demande d'activation`;
		props.body = `Je souhaiterais activer ma boutique.`;

		props.title = `Votre boutique n'a pas encore été activée`;
		props.text = `Une fois vos informations de boutique entrées et vos produits ajoutés vous pouvez demandez l'activation de votre boutique.\nNotre équipe vous contactera alors dans les plus brefs delais afin de vous accompagner lors de l'activation.`;
		props.action = `Demander l'activation`;

		return (
			<MailDialogButton {...props}/>
		)
	}
);

const TransferButton = React.memo(
	function TransferButton({...props}) {
		props.subject = `Demande de transfert`;
		props.body = `Je souhaiterais transferer la somme en attente de ma boutique.`;

		props.title = `Vous avez une somme en attente de transfert ?`;
		props.text = `Il peut arriver qu'une somme d'argent soit en attente de transfert.\nContactez notre équipe pour effectuer le transfert dans les plus brefs delais.`;
		props.action = `Nous contacter`;

		return (
			<MailDialogButton {...props}/>
		)
	}
);

export {ActivateButton, TransferButton};

const localStyles = {
	dialog: {
		layout: {
			flexDirection: styles.flexDirection.column,
			justifyContent: styles.justifyContent.spaceBetween,
			width: 500,
		},
		title: {
			textAlign: styles.textAlign.center,
			fontSize: 25,
			marginBottom: 40,
			bold: true,
		},
		text: {
			textAlign: styles.textAlign.left,
			fontSize: 20,
			marginBottom: 10,
		},
		button: {
			marginTop: 30,
			alignSelf: styles.alignSelf.flexEnd,
			paddingHorizontal: 30,
			paddingVertical: 10,
			borderRadius: 10,
			backgroundColor: '#1c6abe',
			marginLeft: 10,
			color: 'white',
			fontSize: 23,
		}
	},
	button: {
		paddingHorizontal: 30,
		paddingVertical: 10,
		borderRadius: 10,
	},
}
