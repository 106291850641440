import { ApolloCache } from "@apollo/client";
import { curry } from "ramda";
import { OrderItem, Order_V2 } from "../../app/graphql";
import { nil } from "../../types";

function invalidateCacheEntry_core(cache: ApolloCache<any>, __typename: Typename, id: number | string) {
	if (cache)
		cache.modify({
			id: cache.identify({ __typename, id }),
			// https://github.com/apollographql/apollo-client/issues/7060#issuecomment-1160708181
			fields: (_, { DELETE }) => DELETE,
		});
}

const invalidateCacheEntry = curry(invalidateCacheEntry_core);
export default invalidateCacheEntry;

type EntriesTypes = Order_V2 | OrderItem;
export type Typename = Exclude<EntriesTypes["__typename"], nil>;