import React from "react"
import {styles} from "../../../../res"
import View from "library-react/component/View";
import SideBar from "./Sidebar";
import use from "library-react/hook";
import {createStackNavigator} from "@react-navigation/stack";
import screens from "../screens";
import MenuConfigUI from "./MenuConfigUI";
import HomeConfigUI from "./HomeConfigUI";
import {Server, ShopWebsiteConfiguration} from "library-js/app";
import useMe from "library-react/hook/pro/useMe";
import {ConfigLoadableContext} from "./useWebsiteConfig";

export default function WebsiteConfigurationFrame({...props}) {
	const shop = useMe().shop.shop;
	const loadable = use.loadable.server(
		() => Server.public.shop.getConfigOf(shop.id)
			.updateContent(config => {
				if (!config)
					config = new ShopWebsiteConfiguration();

				if (!config.home)
					config.home = ShopWebsiteConfiguration.Home.defaultFromShop(shop);

				if (!config.catalog)
					config.catalog = ShopWebsiteConfiguration.Catalog.default();

				return config;
			}),
		[shop.id]
	);


	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<ConfigLoadableContext.Provider value={loadable}>
			<View {...props}>
				<SideBar/>

				<stack.Navigator headerMode="none">
					<stack.Screen name={screens.home} component={HomeConfigUI}/>
					<stack.Screen name={screens.menu} component={MenuConfigUI}/>
				</stack.Navigator>
			</View>
		</ConfigLoadableContext.Provider>
	);
}

const stack = createStackNavigator();


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		alignSelf: styles.alignSelf.stretch,
	},
};
