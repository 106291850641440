import styles from "../library-react/res/styles"
export default styles;

styles.color.main = "#5e6383";
styles.color.background = "#e5e5e5";
styles.color.lightBackground = "#f4f4f4";
styles.color.darkTransparent = styles.color.black + "9b";
styles.color.good = "#3dbea3";
styles.color.error = "#eb1b2b";
styles.color.azure = "#0b8af3";
styles.color.azure2 = "#0bafee";
styles.color.deepSkyBlue = "#0a80f5";
styles.color.aquaBlue = "#0bcceb";
styles.color.aquaMarine = "#3bcce2";
styles.color.sleeping = "#e0dfdf";
styles.color.sea = "#3c9dad";
styles.color.mint = "#08f575";
styles.color.blueBackground = "#0ab9ed05"; // opacity included
styles.color.separator = "#dbdce4";
styles.color.secondary = "#999999";
styles.color.light = "#ff9901";

styles.popHover = {
	...styles.newShadow(0, 3, 16, .16),
	backgroundColor: styles.color.white,
	borderRadius: 14,
	paddingVertical: 6,
	paddingHorizontal: 18,
	fontSize: 12,
	position: styles.position.absolute,
};
