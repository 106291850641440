import React from "react";
import Server from "library-js/app/Server";
import {Objects} from "library-js/utils";
import useMemo from "../useMemo"
import useInfiniteIterator from "../infiniteIterator"
import useBooleanState from "../useBooleanState";
import useCallback from "../useCallback"
import DefaultLinkedProducts from "../useShowcaseItemEditor/LinkedProducts";


export default function useShowcaseItemEditor(showcaseId, shopId, version = 0) {
	const memo = useMemo({
			editedItems: {},
			getSelectedProduct: () => Objects.valuesOf(memo.editedItems).filter(Boolean),
			getEdited: () => Boolean(Objects.keysOf(memo.editedItems).length),
			getItemsToSubmit: () => Objects.map(memo.editedItems, (_, value) => Boolean(value)),
		}
	);
	const iterator = useInfiniteIterator(
		() => Server.public.showcase.shop.getItems.getIterator(showcaseId, shopId),
		[showcaseId, shopId, version]
	);

	const [edited, setEdited] = useBooleanState(memo.getEdited());
	const LinkedProducts = useCallback(
		({...props}) => (
			<DefaultLinkedProducts
				iterator={iterator}
				editedItems={memo.editedItems}
				onValueChanged={() => setEdited(memo.getEdited())}
				{...props}/>
		),
		[iterator, version]
	);
	const [submitting, setSubmitting] = useBooleanState();
	const submitEdition = useCallback(
		async () => {
			setSubmitting(true);
			const response = await Server.retailer.showcase.editItems(showcaseId, shopId, memo.getItemsToSubmit());
			if (response.ok)
				setEdited(false);

			setSubmitting(false);
		},
		[memo.getItemsToSubmit()]
	);

	return {
		submitting,
		editedLinkedProducts: memo.getSelectedProduct(),
		edited,
		submit: submitEdition,
		load: iterator.load,
		loading: iterator.loading,
		end: iterator.end,
		LinkedProducts,
	};
}

