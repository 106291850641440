import Text from "react-native-web/dist/exports/Text";
import View from "library-react/component/View";
import React from "react";
import {styles} from "../../../../../res";
import {fonts} from "library-react/res";

export default function Label({title, subtitle, ...props}) {
	return (
		<View {...props}>
			<Text style={localStyles.title}>
				{title}
			</Text>
			<Text style={localStyles.subtitle}>
				{subtitle}
			</Text>
		</View>
	)
}
const localStyles = {
	title: {
		fontSize: 21,
		color: styles.color.main,
		font: fonts.Poppins,
	},
	subtitle: {
		fontSize: 17,
		color: styles.color.main,
		font: fonts.Poppins,
	},
};
