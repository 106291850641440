import React from "react";
import View from "library-react/component/View";
import Text from "library-react/component/Text";
import strings from "../../../res/strings";
import {TextInput} from "react-native-paper";
import styles from "../../../res/styles";
import StaticComponent from "library-react/component/StaticComponent";
import Button from "../../component/Button";
import use from "library-react/hook";

export default React.memo(
	function SigninForm({onSubmitted, onToggleForm, ...props}){
		const data = use.memo({});
		const [state, setState] = use.state();
		function submit(){
			setState(States.sending); // display spinner
			onSubmitted(data.email, data.password) // submit
				.catch(error => {
					setState(States.error);
					console.error(error);
				});
		}

		return (
			<View {...props}>
				<Text style={localStyles.welcome}>
					{strings.fragment.signin.signinForm.welcome}
				</Text>

				{
					(state === States.error) &&
					<Error/>
				}

				{/*Login*/}
				<TextInput
					key="email"
					label={strings.fragment.signin.signinForm.name.placeholder}
					onChangeText={email => data.email = email?.trim()}
					style={{marginBottom: 44}}
				/>

				{/*Password*/}
				<TextInput
					key="password"
					label={strings.fragment.signin.signinForm.password.placeholder}
					onChangeText={password => (data.password = password)}
					onSubmitEditing={submit}
					secureTextEntry
				/>

				{/* Bottom part */}
				<View style={localStyles.bottomPart}>
					{/* Forgot password */}
					<Text
						onPress={onToggleForm}
						style={localStyles.forgotPassword}>
						{strings.fragment.signin.signinForm.forgotPassword}
					</Text>

					{/* Submit*/}
					<Button loading={state === States.sending}
							raised
							onPress={submit}>
						{strings.fragment.signin.signinForm.login}
					</Button>
				</View>
			</View>
		)
	}
)

const States = {
	sending : 1,
	error: 2,
};

const Error = new StaticComponent(
	<View style={{borderColor: styles.color.error, borderWidth: 1, borderRadius: 8, padding: 10, marginBottom: 18}}>
		<Text style={{color: styles.color.error, fontSize: 14}}>{strings.fragment.signin.signinForm.error}</Text>
	</View>
);

const localStyles = {
	welcome: {
		fontSize: 19,
		lineHeight: 31,
		marginBottom: 55,
		color: styles.color.main,
	},

	forgotPassword: {
		fontSize: 15,
		textDecorationLine: styles.textDecorationLine.underline
	},

	bottomPart: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.spaceBetween,
		marginTop: 55,
	},
};
