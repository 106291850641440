import React from "react";
import { Switch, View as RNView } from "react-native"
import Card from "../../../../component/Card";
import { strings, styles } from "../../../../../res";
import use from "library-react/hook";
import CheckBox from "../Checkbox";
import PaymentMean from "library-js/app/model/entity/Order/PaymentMean";
import getResourcesForPaymentMean from "library-js/res/getResourcesForPaymentMean";
import Portal from "library-react/component/Portal";
import screens from "@main/screens";
import useMe from "library-react/hook/pro/useMe";
import { intersperse, map, pipe } from "ramda";
import toggleInclusion from "library-js/utils/array/toggleInclusion";
import View from "library-react/component/View/v2";
import Commercialization from "library-js/app/model/entity/Shop/Commercialization";
import { addIndex } from "ramda";


export default function PaymentCard(
	{
		editedShop,
		loading,
		paymentConfigLoadable,
		submittedTime,
		willResetFields,
		...props
	}
) {
	// states
	const { shop } = useMe().shop;

	const { paymentMeans, ordersEnabled } = editedShop;
	const showPrices = !editedShop.commercialization?.is.withoutPrices;

	const onlineOK = paymentConfigLoadable.value?.ok;
	const [triedToEnableOnline, setTriedToEnableOnline] = use.state.bool(false);

	// actions
	const willToggleMean = mean => () => editedShop.paymentMeans = toggleInclusion(mean, editedShop.paymentMeans);

	const memory = use.memo({ means: undefined, unavailableNote: shop.unavailableNote });

	function enableOrders() {
		editedShop.commercialization = Commercialization.withOrders;
		editedShop.paymentMeans = memory.means?.length ? memory.means
			: shop.paymentMeans?.length ? shop.paymentMeans
				: [PaymentMean.cash];
	}

	function disableOrders() {
		memory.means = paymentMeans?.slice();
		editedShop.paymentMeans = [];
	}

	const [tempDisabling, setTempDisablingTo] = use.state.bool(() => !(
		!ordersEnabled && (!showPrices || !editedShop.unavailableNote)
	));

	function toggleTempDisabling() {
		const willBeTemp = !tempDisabling;
		if (willBeTemp) {
			editedShop.unavailableNote = memory.unavailableNote;
			editedShop.commercialization = Commercialization.withOrders;
		} else {
			memory.unavailableNote = editedShop.unavailableNote;
			editedShop.unavailableNote = undefined;
		}

		setTempDisablingTo.toggle();
	}

	props.onCanceled = willResetFields(["paymentMeans", "commercialization", "unavailableNote"]);

	const { Text, TextInput } = use.theme();

	return (
		<Card
			icon="MaterialIcons/payment"
			title={strings.fragment.shop.checkout.title}
			subtitle={strings.fragment.shop.checkout.subtitle}
			{...props}>
			{editable => (
				<RNView style={{ flexGrow: 1, paddingTop: editable ? 0 : 30 }}>
					{
						editable &&
						<View
							onPress={ordersEnabled ? disableOrders : enableOrders}
							style={localStyles.tabs}>

							{
								pipe(
									map((value, index) => {
										const selected = value === ordersEnabled;
										return (
											<RNView
												style={localStyles.tab}>
												<Text style={[localStyles.tabTxt(selected), selected && { color: value ? styles.color.azure2 : 'red' }]}>
													{`Commandes ${value ? 'activées' : 'désactivées'}`}
												</Text>
											</RNView>
										)
									}),
									intersperse(
										<Switch
											value={ordersEnabled}
											style={{ alignSelf: styles.alignSelf.center }} />
									),
									addIndex(map)((element, key) => React.cloneElement(element, { key }))
								)
									([false, true])
							}


						</View>
					}

					<RNView style={localStyles.content}>
						{
							ordersEnabled ?
								<>
									{
										editable &&
										<Text style={localStyles.subtitle}>
											{`Comment acceptez-vous d'être reglé?`}
										</Text>
									}

									{
										PaymentMean.domains.offline.map((mean) => {
											const selected = paymentMeans?.includes(mean);
											const canToggle = !selected || paymentMeans.length > 1;
											return (
												<CheckBox
													key={mean.key}
													label={getResourcesForPaymentMean(mean)?.title()}
													value={selected}
													onValueChanged={canToggle && willToggleMean(mean)}
													editable={editable}
													style={localStyles.checkBox} />
											);
										})
									}

									{/* online */}
									<CheckBox
										label={
											<>
												{getResourcesForPaymentMean(PaymentMean.online)?.title()}
												{
													!paymentConfigLoadable.end ?
														<Text style={{ italic: true, color: 'grey' }}>
															{` (chargement...)`}
														</Text> :
														!onlineOK && triedToEnableOnline &&
														<Portal to={screens.paymentSettings}>
															<Text
																style={{ italic: true, color: 'blue' }}>
																{`  Activer l'encaissement en ligne ›`}
															</Text>
														</Portal>
												}
											</>
										}
										value={
											paymentMeans?.includes(PaymentMean.online)
										}
										onValueChanged={
											onlineOK ? willToggleMean(PaymentMean.online) :
												setTriedToEnableOnline.true
										}
										editable={false /* until new order model is used here */}
										style={localStyles.checkBox} />


									{/* orders always opened */}
									<CheckBox
										label={
											<Text style={{ fontSize: 14 }}>
												{`Permettre les commandes en dehors des horaires d'ouverture\n`}

												{/* subtitle */}
												<Text style={{ fontSize: 12, italic: true, color: 'grey' }}>
													{`Vos clients ${editedShop.ordersAlwaysOpened ? `pourront passer commande`
														: `ne pourront pas commander`
														} durant les horaires de fermeture.`}
												</Text>
											</Text>
										}
										defaultValue={editedShop.ordersAlwaysOpened}
										onValueChanged={editedShop.setOrdersAlwaysOpened}
										editable={editable}
										style={{ marginVertical: 25 }}
									/>
								</> :

								<>

									{
										editable ?
											<>
												<Text style={localStyles.subtitle}>
													{`La fermeture des commandes est-elle temporaire ?`}
												</Text>
												<View
													onPress={toggleTempDisabling}
													style={localStyles.tempDisabling}>
													<Text>
														{`Non`}
													</Text>

													<Switch
														value={tempDisabling}
														style={{ margin: 15 }} />

													<Text>
														{`Oui`}
													</Text>
												</View>

											</> :

											<Text style={localStyles.subtitle}>
												{`Les commandes sont actuellement désactivées.`}
											</Text>
									}


									{
										tempDisabling ?
											<>
												{
													Boolean(!editable && shop.unavailableNote) &&
													<Text>
														{`Votre note laissé à vos client:`}
													</Text>
												}

												{
													Boolean(editable || shop.unavailableNote) &&
													<TextInput
														multiline
														onValueChanged={value => editedShop.unavailableNote = value}
														defaultValue={editedShop?.unavailableNote}
														placeholder={`Laissez ici, optionellement, une note à vos clients afin de leur expliquer votre absence.`}
														editable={editable}
														style={localStyles.unavailableNote} />
												}
											</> :

											editable ?
												<CheckBox
													label={
														<Text style={{ fontSize: 14 }}>
															{`Afficher les prix dans le catalogue\n`}

															{/* subtitle */}
															<Text style={{ fontSize: 12, italic: true, color: 'grey' }}>
																{`Les prix ${editedShop.commercialization?.is.withoutPrices ? `ne seront pas`
																	: `de vos articles seront`
																	} affichés dans votre catalogue en ligne.`}
															</Text>
														</Text>
													}
													defaultValue={!editedShop.commercialization?.is.withoutPrices}
													onValueChanged={show =>
														editedShop.commercialization = show ?
															Commercialization.withOrders
															: Commercialization.withoutPrices
													}
												/> :

												shop.commercialization?.is.withoutPrices &&
												<Text>
													{`Les prix de vos articles ne sont pas affichés à vos clients.`}
												</Text>
									}
								</>
						}
					</RNView>
				</RNView>
			)}
		</Card>
	);
}


const localStyles = {
	tabs: {
		flexDirection: styles.flexDirection.row,
		paddingTop: 15,
	},

	tab: {
		flexDirection: styles.flexDirection.row,
		flex: 1,
		...styles.center,
		height: 60,
	},

	tabCheck: {
		marginRight: 5,
		color: styles.color.azure2,
	},

	tabTxt: styles.static.bool({ bold: true, fontSize: 16 },
		{},
		{ color: 'grey' },
	),

	content: {
		paddingHorizontal: 30,
	},

	subtitle: {
		marginVertical: 20,
		bold: true
	},

	tempDisabling: { flexDirection: styles.flexDirection.row, alignItems: 'center', marginBottom: 20 },

	text: {},

	textLink: {
		marginTop: 5,
		color: 'blue',
	},

	unavailableNote: {
		borderColor: 'lightgrey',
		backgroundColor: styles.color.white,
		borderWidth: 1,
		borderRadius: 5,
		padding: 10,
		minHeight: 100,
		fontStyle: styles.fontStyle.italic,
	},

	descriptionInput: {
		height: 170,
	},

	checkBox: {
		marginBottom: 10,
	},
	form: {
		paddingVertical: 20,
	},

	loading: {
		padding: 30
	}
};
