import VReservation from "../library-js/app/model/view/general/VReservation";
import {path, prop} from "ramda";
import {Trigger} from "../library-js/utils";
import useEffect from "./useEffect";
import useSyncEffect from "./useSyncEffect";

export default function useSyncLoadable(loadable) {
	const value = loadable?.value;
	const {config, trigger} = store.get(value?.constructor) || {};

	// listen
	useEffect(() => trigger?.add((value, id) => {
		if (loadable.value && loadable.value !== value) {
			const currentId = retrieveId(loadable.value, config);
			if (id === currentId)
				loadable.setValue(value);
		}
	}), [trigger]);

	// fire once loaded
	useSyncEffect(() => value && syncValue(value), [value]);
}

// to be fired when edited
export function syncValue(value, id) {
	const trigger = store.get(value?.constructor)?.trigger;
	if (value && !id)
		id = retrieveId(value);

	if (value || id)
		trigger?.fireAsyncOnce(value, id);
}

function retrieveId(value) {
	const config = store.get(value?.constructor)?.config;
	return (config?.getId || getId)(value);
}

const store = new Map();

[
	[VReservation, {getId: path(['reservation', 'id'])}],

].forEach(([Entity, config]) =>
	store.set(Entity, {
		config,
		trigger: new Trigger(),
	})
);

const getId = prop('id');

