import { adjust, assoc, mergeLeft, update } from "ramda";
import { styles } from "../../../res"

// ----- columns -------
export const defaultColumnsStyles = [
	// user
	{
		paddingHorizontal: 50,
		overflow: styles.overflow.hidden,
	},
	// ID
	{
		maxWidth: 130,
		alignItems: styles.alignItems.center,
		flexShrink: 3,
	},
	// Date
	{
		maxWidth: 260,
		alignItems: styles.alignItems.center,
		flexShrink: 2,
	},
	// Number of products
	{
		maxWidth: 300,
		alignItems: styles.alignItems.center,
		flexShrink: 3,
	},
	// Total
	{
		maxWidth: 110,
		alignItems: styles.alignItems.center,
		flexShrink: 3,
	},
	// State
	{
		maxWidth: 320,
		alignItems: styles.alignItems.center,
		paddingRight: 10,
	},
] as const;

export const noUserColumnStyles = update(0, { maxWidth: 90 } as any, defaultColumnsStyles);


export default {
	row: {
		alignItems: "center",
		marginHorizontal: 3,
		paddingVertical: 15,
		borderColor: "transparent",
		borderBottomColor: "#e5e5e5",
		borderLeftWidth: 5,
	},
} as const;
