import React from "react"
import { View as RNView } from "react-native"
import View from "library-react/component/View/v2";
import Head from "../../../component/Head";
import { strings, styles } from "../../../../res";
import Server from "library-js/app/Server"
import moment from "moment";
import Icon from "library-react/component/Icon/v2";
import StarsView from "library-react/component/StarsView";
import Switch from "library-react/component/Switch";
import Gallery from "library-react/component/Gallery";
import CatalogSectionDropdownSelection from "../../../component/CatalogSectionDropdownSelection";
import use from "library-react/hook";
import useMe from "library-react/hook/pro/useMe";
import screens from "../../../screens";
import Portal from "library-react/component/Portal";
import FragmentLayout from "../../../component/FragmentLayout";
import useApp from "library-react/app/useApp";
import ErrorView from "library-react/component/ErrorView";
import Pricing from './Pricing'
import AddButton from "../CatalogUI/AddButton";
import GalleryModal from "library-react/component/GalleryModal";
import showSocialModal from "library-react/utils/showSocialModal";
import { path } from "ramda";

function ProductUI({ route, navigation, ...props }) {
	const id = route.params?.id;
	const { shopId, vShop } = useMe();

	const loadable = use.loadable.server(() =>
		Server.public.prodshop.get({ [shopId]: [id] })
			.updateContent(path([shopId, id])),
		[shopId, id]
	);

	const { value: vProdshop, loading } = loadable;
	const prodshop = vProdshop?.prodshop;

	const [updating, setUpdatingTo] = use.state.bool(); // disable all actions when true
	function persist(prodshop) {
		setUpdatingTo(true);

		const promise = Server.retailer.catalog.persist(id, undefined, prodshop)
			.then(async response => {
				if (response.ok) {
					loadable.value.prodshop = prodshop;
					if (!prodshop) {
						// if deleted
						await Promise.await(5000); // let time to algolia to remove the product from catalog
						navigation.push(screens.catalog);
					}
				} else
					response.throw();
			});

		promise
			.catch(error => console.error('Error while persisting prodshop: ', error))
			.finally(setUpdatingTo.false);

		return promise;
	}

	const [showTopSpinner, setShowTopSpinnerTo] = use.state.bool();

	function persistAndShowSpinner(prodshop) {
		setShowTopSpinnerTo(true);
		persist(prodshop)
			.finally(setShowTopSpinnerTo.false);
	}

	function toggleAvailability() {
		const edition = prodshop.copy();
		edition.available = !edition.available;
		persistAndShowSpinner(edition);
	}

	const remove = () => persistAndShowSpinner(null);


	// ------
	const app = useApp();
	const { rate, product } = vProdshop?.vProduct || {};
	const openFullscreenGallery = () => app.openModal(<GalleryModal items={product.gallery} />);

	const { Text, Spinner } = use.theme();

	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.layout);
	return (
		<FragmentLayout {...props}>
			<RNView style={localStyles.header}>
				<Head>
					{strings.fragment.product.title}
				</Head>

				<AddButton />
			</RNView>

			{
				product ?
					// ---- Product ----
					<RNView style={localStyles.content}>
						<Gallery
							items={product.gallery}
							resizeMode={styles.resizeMode.cover}
							onFullScreenClicked={openFullscreenGallery}
							imageStyle={{ borderRadius: 12 }}
							style={localStyles.gallery} />

						<RNView style={{ flexGrow: 1, width: 450, maxWidth: "100%", marginTop: 42 }}>
							{/*Name & actions*/}
							<RNView style={{
								flexDirection: styles.flexDirection.row,
								alignItems: styles.alignItems.center
							}}>
								{/*Name*/}
								<Text
									numberOfLines={1}
									style={{ flex: 1, bold: true, fontSize: 30 }}>
									{product.name}
								</Text>

								{
									prodshop &&
									<>
										{
											showTopSpinner &&
											<Spinner size={30} style={{ marginRight: 22 }} />
										}

										<Switch
											value={prodshop.available}
											disabled={updating}
											onValueChanged={toggleAvailability}

											style={{
												width: 46,
												height: 24,
												padding: 1,
												marginRight: 22,
												borderRadius: 12
											}}
											onStyle={{ backgroundColor: styles.color.azure2 }}
											offStyle={{ backgroundColor: styles.color.background }}
											triggerStyle={{ borderRadius: 11 }}
											triggerOnStyle={{ backgroundColor: styles.color.mint }}
											triggerOffStyle={{ backgroundColor: styles.color.main }} />
										<View hoverable style={{
											marginRight: 22,
											height: 26,
											width: 26,
											borderRadius: 26 / 2,
											backgroundColor: styles.color.azure2,
											alignItems: styles.alignItems.center,
											justifyContent: styles.justifyContent.center,
										}}>
											<Portal to={!updating && screens.editProduct}
												params={{ id }}>
												<Icon
													name="edit"
													size={15}
													style={{ color: styles.color.white }} />
											</Portal>

											{({ hover }) =>
												hover &&
												<Text style={[
													styles.popHover,
													{
														bottom: "140%",
														right: 0,
														width: 158
													}
												]}>
													{strings.fragment.product.edit}
												</Text>
											}
										</View>
										{
											Boolean(vShop.shop.domain) &&
											<View hoverable
												onPress={() => showSocialModal(app, vShop.shop, vProdshop)}
												style={{
													marginRight: 22,
													alignItems: styles.alignItems.center,
													justifyContent: styles.justifyContent.center,
												}}>
												<Icon
													name={"MaterialIcons/ios-share"}
													size={30}
													style={{ color: styles.color.azure2 }} />


												{({ hover }) =>
													hover &&
													<Text style={[
														styles.popHover,
														{
															bottom: "140%",
															right: 0,
														}
													]}>
														{`Partager`}
													</Text>
												}
											</View>
										}

										<View hoverable>
											{({ hover }) => (
												<Icon
													name={hover ? "MaterialCommunityIcons/delete-empty" : "MaterialCommunityIcons/delete"}
													onPress={!updating && remove}
													size={30}
													style={{ color: styles.color.azure2 }} />
											)}


											{({ hover }) =>
												hover &&
												<Text style={[
													styles.popHover,
													{
														bottom: "140%",
														right: 0,
														width: 158
													}
												]}>
													{strings.fragment.product.remove}
												</Text>
											}
										</View>
									</>
								}

							</RNView>

							{/*Brand*/}
							<Text
								numberOfLines={1}
								style={{ fontSize: 30, paddingBottom: 9 }}>
								{product.brand}
							</Text>

							{/* ref, date & sections */}
							<RNView style={localStyles.secondaryInfos.layout}>
								{/*Code & date*/}
								<Text
									numberOfLines={1}
									style={{ fontSize: 16 }}>
									{
										Boolean(product.reference) &&
										<Text style={{ marginRight: 15 }}>
											{product.reference}
										</Text>
									}

									{
										strings.fragment.product[prodshop ? "addedOn" : "createdOn"]
										+ moment(prodshop?.creationDate || product.prodshop)
											.format("DD/MM/YY")
									}
								</Text>


								{
									(rate?.numberOfVotes > 0) &&
									<RNView style={{
										flexDirection: styles.flexDirection.row,
										alignItems: styles.alignItems.center
									}}>
										<StarsView
											size={22}
											icons="AntDesign/star"
											onStyle={{ color: styles.color.light }}
											offStyle={{ color: styles.color.background }}
											style={{ marginLeft: 26, width: 140, paddingBottom: 5 }}
											stars={rate.stars} />

										<Text style={{
											color: styles.color.light,
											marginLeft: 14,
											fontSize: 14,
											paddingBottom: 2,
											textDecorationLine: styles.textDecorationLine.underline
										}}>
											{rate.numberOfVotes}
											{strings.fragment.product.votes}
										</Text>
									</RNView>
								}

								<CatalogSectionDropdownSelection
									productId={id}
									alignRight
								/>
							</RNView>

							{/* Pricing */}
							{
								prodshop ?
									<Pricing
										prodshop={prodshop}
										persist={persist}
										disabled={updating}
										style={{ marginTop: 17 }} /> :

									// add button if public
									product.isPublic &&
									<Text style={{
										fontSize: 20,
										color: styles.color.aquaMarine,
										bold: true
									}}>
										{strings.fragment.product.add}
									</Text>
							}


							{/*gender*/}
							<Text style={{ marginTop: 20 }}>
								<Text style={{ bold: true, marginRight: 10 }}>
									{strings.fragment.product.gender}
								</Text>
								{strings.gender[product.gender]}
							</Text>

							{/*Age*/}
							<Text style={{ marginBottom: 30 }}>
								<Text style={{ bold: true, marginRight: 10 }}>
									{strings.fragment.product.ageClass}
								</Text>
								{strings.ageClass[product.ageClass]}
							</Text>

							{/*description*/}
							<Text style={{ bold: true }}>
								{strings.fragment.product.description}
							</Text>
							<Text>
								{product.description}
							</Text>

							{// sizes
								(product.sizes && product.sizes.length > 1) &&
								<RNView>
									{/*Title*/}
									<RNView style={{
										marginTop: 20,
										alignSelf: styles.alignSelf.flexStart,
										flexDirection: styles.flexDirection.row,
										paddingVertical: 4,
										paddingHorizontal: 19,
										minWidth: 126,
										borderRadius: 50,
										backgroundColor: styles.color.azure2,
									}}>
										<Icon
											name="sizes"
											size={22}
											style={{
												marginRight: 13,
												color: styles.color.white,
											}} />

										<Text style={{
											color: styles.color.white,
											fontSize: 16
										}}>
											{strings.fragment.product.sizes}
										</Text>
									</RNView>

									{/*list*/}
									<RNView style={{
										flexDirection: styles.flexDirection.row,
										flexWrap: styles.flexWrap.wrap,
										marginTop: 10,
									}}>
										{product.sizes.map((size, index) =>
											<Text key={index}
												style={{
													color: styles.color.azure2,
													fontSize: 16,
													marginRight: 10,
													marginTop: 10,
													padding: 5,
													borderColor: styles.color.azure2,
													borderWidth: 1,
													borderStyle: styles.borderStyle.dashed,
													borderRadius: 10,
												}}>
												{size}
											</Text>
										)}
									</RNView>
								</RNView>
							}

						</RNView>
					</RNView> :

					<RNView style={[styles.center, { paddingVertical: 70 }]}>
						{
							loading ?
								<Spinner size={50} /> :
								<ErrorView onLoadClicked={loadable.load} />
						}
					</RNView>

			}
		</FragmentLayout>
	);
}

export default React.memo(ProductUI);

const localStyles = {
	layout: {
		padding: 35,
	},

	header: {
		marginBottom: 25,
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.spaceBetween,
		zIndex: 1,
	},

	categories: {
		layout: {
			flexDirection: styles.flexDirection.row,
		},

		current: {
			color: styles.color.azure2,
			borderBottomWidth: 1,
			borderColor: styles.color.azure2,
			borderStyle: styles.borderStyle.solid,
		}
	},

	content: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.flexStart,
		flexWrap: styles.flexWrap.wrap,
	},

	gallery: {
		width: 412,
		height: 600,
		marginRight: 57,
		marginTop: 42,
	},

	secondaryInfos: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			justifyContent: styles.justifyContent.spaceBetween,
			zIndex: 1,
		},
	}
};
