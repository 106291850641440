import React from 'react';
import use from "library-react/hook";
import Text from "library-react/component/Text";

export default function Subtitle({...props}) {
	props.style = use.defaultStyle(props.style, localStyles.subtitle);
	return <Text {...props}/>;
}

const localStyles = {
	subtitle: {
		marginVertical: 10,
		bold: true,
	},
}
