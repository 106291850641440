import React from "react";
import use from "../../../hook";
import Icon from "../../Icon/v2";
import View from "../../View/v2";
import styles from "../../../res/styles"
/**
 * The input preview, that's what the user sees in the form.
 */
export default function TagsInputPreview({ value, placeholder, editable, ...props }) {
	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout)
	return (
		<View {...props}>
			<Text
				numberOfLines={1}
				style={localStyles.content}>
				{
					value?.join(', ') || (
						// apply different color for placeholder
						<Text style={{ color: "#5e6383" }}>
							{placeholder}
						</Text>
					)
				}
			</Text>
			{
				Boolean(editable) &&
				<Icon
					name="AntDesign/right"
					size={18}
					style={localStyles.arrow}
				/>
			}
		</View>
	);
}

const localStyles = {
	layout: styles.static.bool(
		{
			flexDirection: styles.flexDirection.row,
			paddingRight: 23,
			paddingLeft: 15,
			paddingVertical: 13,
			borderWidth: .5,
			borderRadius: 9,
			alignItems: styles.alignItems.center,
		},
		{
			borderColor: "#1dafee",
			backgroundColor: styles.color.white,
		},
		{
			borderColor: "#5e6383",
			backgroundColor: "#f7f7f7",
		}),

	content: {
		flex: 1,
		fontSize: 14,
		color: styles.color.black,
	},

	arrow: {
		color: "#5e6383",
		marginLeft: 10,
	},
};