import React from "react";
import { Pressable, PressableProps, StyleSheet, View, ViewProps } from "react-native";
import Icon from "../../../../library-react/component/Icon/v2";
import use from "../../../../library-react/hook";
import { styles } from "../../../../res";


export default function Tab({ icon, title, isSelected, badge, ...props }: TabProps) {
	const { Text } = use.theme();

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<Pressable {...props}>
			{
				Boolean(icon) &&
				<Icon
					name={icon}
					size={20}
					style={[localStyles.icon, isSelected && localStyles.selected]} />
			}
			<Text style={[localStyles.text, isSelected && localStyles.selected]}>
				{title}
			</Text>
			{
				Boolean(badge) &&
				<View style={localStyles.badge}>
					<Text style={localStyles.badgeText}>
						{badge}
					</Text>
				</View>
			}
		</Pressable>
	);
}

interface TabProps extends PressableProps {
	icon: string,
	title: string,
	isSelected: any,
	badge: string | undefined,
}

const localStyles = StyleSheet.create({
	layout: {
		minWidth: 100,
		paddingHorizontal: 25,
		paddingVertical: 10,
		flexDirection: "row",
		justifyContent: "center",
	},

	icon: {
		marginRight: 20,
		color: styles.color.main,
	},

	text: {
		color: styles.color.main,
	},

	selected: {
		color: styles.color.azure2,
		textDecorationLine: "underline",
	},

	badge: {
		marginLeft: 2,
		...styles.circle(20),
		...styles.center,
		fontSize: 15,
		lineHeight: 15,
		backgroundColor: "#ca293e",
	},

	badgeText: {
		color: "#ffffff",
		fontSize: 15,
		textAlign: "center",
	},
});