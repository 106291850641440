import { LegacyOrderState } from "../../../../library-react/library-js/app/graphql"

const FILTERS: Filter[] = [
	{
		label: `Nouvelles`,
		config: {
			includedStates: [LegacyOrderState.preparing]
		},
	},
	{
		label: `En cours`,
		config: {
			includedStates: [
				LegacyOrderState.ready,
				LegacyOrderState.processingPayment,
				LegacyOrderState.paymentFailed,
			]
		},
	},
	{
		label: `Finis`,
		config: {
			includedStates: [
				LegacyOrderState.reception,
				LegacyOrderState.finished,
				LegacyOrderState.canceled,
			]
		},
	},
];

export default FILTERS;

export interface Filter {
	label: string,
	config: {
		includedStates?: LegacyOrderState[],
	}
}