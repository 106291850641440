import React from "react"
import use from "library-react/hook"
import {styles} from "../../../../res"
import View from "library-react/component/View/v2";
import Icon from "library-react/component/Icon/v2";
import {TextInput} from "react-native-paper"
import {isNil} from "ramda";
import CatalogSection from "library-js/app/model/entity/CatalogSection";

function NewSubSectionButton({of: parentSection, whatsWrong, onSubmitted, siblings, ...props}) {
	const [lastName, setLastName] = use.state();

	const editedSection = new CatalogSection(parentSection.path.concat([lastName]));

	const invalid = lastName && (
		lastName.includes('/') ? `Le nom ne doit pas contenir de /.` :
			whatsWrong?.(editedSection.name)
	);

	function submit() {
		if (!invalid) {
			setLastName(undefined);
			onSubmitted?.(editedSection, parentSection);
		}
	}

	const opened = !isNil(lastName);
	if (!opened)
		props.onPress = () => setLastName("");

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			{
				opened ?
					<TextInput
						dense
						ref={autoFocus}
						// autoFocus
						label={
							invalid || (
								lastName ? `Tapez "Entrer" pour ajouter la section`
									: `Nom de la sous catégorie`
							)
						}
						onChangeText={setLastName}
						onSubmitEditing={submit}
						error={invalid}
						style={localStyles.input}
					/> :

					<>
						<Icon
							name="add"
							size={25}
							style={localStyles.icon}
						/>

						<Text style={localStyles.text}>
							{`Ajouter une sous catégorie`}
						</Text>
					</>
			}
		</View>
	);
}

export default React.memo(NewSubSectionButton);

function autoFocus(input) {
	if (input) {
		//TODO: Do a material text input that doesn't need to wait before focus
		setTimeout(() => input?.focus());
	}
}


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		maxHeight: 50,
		overflow: styles.overflow.hidden,
	},

	icon: {
		color: styles.color.azure2,
		marginRight: 11,
	},

	text: {
		fontSize: 13,
		color: styles.color.azure2,
	},

	input: {
		fontSize: 18,
		alignSelf: styles.alignSelf.flexEnd,
		width: 325,
		marginBottom: 5,
	}
};
