import React from "react";
import styles from "../../../res/styles"
import Icon from "library-react/component/Icon/v2";
import View from "library-react/component/View/v2";
import strings from "../../../res/strings";
import use from "library-react/hook";
import ComponentUtils from "library-react/ComponentUtils";

export default function EditCard({icon, title, subtitle, onDisplay, style, children, onSubmitIndicationClicked, ...props}) {
	const {Text} = use.theme();
	children = ComponentUtils.executeFunctionChildren(children, true, onDisplay);

	props.style = use.defaultStyle(style, localStyles.general);
	return (
		<View {...props}>

			{/* Header */}
			<View style={localStyles.header.layout}>
				<Icon
					name={icon}
					size={50}
					style={localStyles.header.icon}/>

				<View style={localStyles.header.infos}>
					<Text style={localStyles.header.title}>
						{title}
					</Text>

					<Text style={localStyles.header.subtitle}>
						{subtitle}
					</Text>
				</View>
			</View>

			{/* Form */}
			{children}

			{/* Bottom part */}
			<View style={localStyles.bottomPart}>

				<Text
					onPress={onSubmitIndicationClicked}
					style={localStyles.submitIndication}>
					{`Cliquez sur le bouton "Enregistrer" en bas à droite de l'écran afin de sauvegarder toutes vos modifications.`}
				</Text>

				<Text style={localStyles.cancel}
					  onPress={onDisplay}>
					{strings.component.Card.cancel}
				</Text>
			</View>
		</View>
	);
}

const localStyles = {
	general: {
		paddingVertical: 26,
	},

	header: {
		layout: {
			paddingHorizontal: 26,
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
		},

		infos: {
			marginHorizontal: 30,
			flexDirection: styles.flexDirection.column,
			justifyContent: styles.justifyContent.center,
		},

		icon: {color: styles.color.main},

		title: {
			color: styles.color.main,
			fontSize: 20,
			lineHeight: 38,
			bold: true,
		},

		subtitle: {
			color: styles.color.secondary,
			fontSize: 17,
			lineHeight: 26,
		},
	},

	form: {
		marginTop: 30
	},

	bottomPart: {
		flexDirection: 'row',
		alignItems: styles.alignItems.center,
		justifyContent: 'space-between',
		paddingHorizontal: 30,
	},

	submitIndication: {
		color: 'grey',
		italic: true,
		fontSize: 12,
		width: '50%',
		textAlign: styles.textAlign.justify,
	},

	cancel: {
		fontSize: 15,
		bold: true,
		color: 'grey',
		padding: 15,
		marginVertical: 5,
	}
};
