import use from "library-react/hook";
import {Animated} from "react-native";
import Portal from "library-react/component/Portal";
import screens from "@main/screens";
import {styles} from "@src/res";
import Icon from "library-react/component/Icon/v2";
import React from "react";

export default function AddReservationButton({id, show, ...props}) {
	const av = use.memo(() => new Animated.Value(0));
	use.onChange(() => {
			const animation = Animated.timing(av, {
				toValue: show ? 1 : 0,
				duration: 100,
				useNativeDriver: true,
			});

			animation.start();
			return () => animation.stop();
		},
		[show]
	);

	props.style = use.defaultStyle(props.style, localStyles.layout(av), [av]);
	return (
		<Portal to={screens.newReservation} params={{userId: id}}>
			<Icon
				name="MaterialIcons/add"
				size={30}
				{...props}
			/>
		</Portal>
	);
}
const localStyles = {
	layout: av => ({
			color: 'white',
			backgroundColor: '#ca293e',
			...styles.circle(70),
			...styles.absolute({bottom: 30, right: 50}),
			...styles.newShadow(0, 3, 6, .16),
			transform: [{scale: av}],

			opacity: av.interpolate({
				inputRange: [0, .2],
				outputRange: [0, 1],
				extrapolate: "clamp",
			})
		}
	),

}