import React, {useCallback} from "react"
import Card from "../../../component/Card"
import strings from "../../../../res/strings"
import styles from "../../../../res/styles"
import View from "library-react/component/View"
import Text from "library-react/component/Text"
import StaticComponent from "library-react/component/StaticComponent"
import OpenHoursInput from "library-react/component/input/OpenHoursInput"
import use from "library-react/hook";
import useMe from "library-react/hook/pro/useMe";


export default function OpenHoursCard({editedShop, willResetFields, ...props}) {
	const {shop} = useMe().shop;
	const setShop = use.callback(editFunction => editFunction(editedShop));

	props.onCanceled = willResetFields(["openHours"]);

	return (
		<Card
			title={strings.fragment.shop.openHours.title}
			subtitle={strings.fragment.shop.openHours.subtitle}
			icon="clock"
			{...props}>
			{editable =>
				<View style={localStyles.layout}>
					{
						!editable ?
							// Display
							strings.week.map((dayString, day) =>
								<View key={day} style={localStyles.day}>
									<Text style={{width: 100}}>{dayString}</Text>

									{/*Dotted line*/}
									<Line/>

									{/* hours */}
									<View style={localStyles.hours}>
										{
											shop.weekOpenHours[day].length ?
												shop.weekOpenHours[day]
													.map((openHour, index) =>
														<Text key={index}
															  style={localStyles.hour}>
															{openHour.formatted}
														</Text>
													)

												: <Closed/>
										}
									</View>
								</View>
							)// start monday
								.reorder(0, 1, 7) :

							// Edit
							<OpenHoursInput
								addButtonStyle={{color: styles.color.aquaBlue}}
								buttonColors={{
									primary: styles.color.main,
									secondary: styles.color.white,
								}}
								initialValue={shop.openHours}
								onValueChanged={openHours => setShop(shop => shop.openHours = openHours)}
							/>
					}
				</View>
			}
		</Card>
	);
}

const Line = new StaticComponent(
	<Text
		children={" "}
		style={{
			flex: 1,
			alignSelf: styles.alignSelf.baseline,
			borderBottomWidth: 1,
			fontSize: 14,
			borderStyle: styles.borderStyle.dotted
		}}/>
);

const localStyles = {
	layout: {
		paddingVertical: 30,
		paddingHorizontal: 15,
	},

	day: {
		flexDirection: styles.flexDirection.row,
		marginBottom: 20,
	},

	hours: {
		flexDirection: styles.flexDirection.row,
		flexWrap: styles.flexWrap.wrap,
		flexShrink: 1,
		justifyContent: styles.justifyContent.flexEnd,
	},

	hour: {
		marginLeft: 20
	}
};

const Closed = new StaticComponent(
	<Text style={localStyles.hour}>{strings.fragment.shop.openHours.closed}</Text>
);
