const version = require("./version");
console.log(`Version: ${version.complete}`);

// design scrollbar on windows
const styles = require("@src/res/styles").default;

// --- zoom ---
{
	const { style } = document.body;
	let setZoom;
	// if (Environment.browser.is.Firefox) {
	// style.position = "absolute";
	setZoom = zoom => {
		style.transform = `scale(${zoom})`;

		// original algorithm
		// const leftWidth = (window.innerWidth - window.innerWidth * zoom) / zoom;
		// const horizontalSideShift = leftWidth / 2;
		// const leftHeight = (window.innerWidth - window.innerWidth * zoom) / zoom;
		// const verticalSideShift = leftHeight / 2;

		// reduced to
		const fixRatio = (1 - zoom) / (zoom * 2);
		const horizontalSideShift = window.innerWidth * fixRatio;
		const verticalSideShift = window.innerHeight * fixRatio;

		style.right = style.left = `-${horizontalSideShift}px`;
		style.top = style.bottom = `-${verticalSideShift}px`;
	}
	// } else
	// 	setZoom = zoom => style.zoom = `${zoom * 100}%`;

	const resize = () => {
		// set zoom only if 1200 < width < 1920 otherwise don't change the zoom
		const width = Math.min(Math.max(window.innerWidth, 1200), 1920);
		const zoom = width / 1920;
		setZoom(zoom);
	};

	style.position = 'absolute';
	window.addEventListener("resize", resize);
	resize(); // run at start
}

//  --- scroll bar windows ---
{
	if (navigator.platform === "Win32") {
		const styleElement = document.createElement('style');
		styleElement.textContent = `
			*::-webkit-scrollbar {
				width: 0px;
			}
		
			*[data-class=thinScrollbar]::-webkit-scrollbar {
				width: 2px;
			}
			
			*[data-class=thinScrollbar]::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 5px lightgrey;
			}
			
			*[data-class=thinScrollbar]::-webkit-scrollbar-thumb {
				background-color: ${styles.color.azure2};
			}
		`;
		document.head.appendChild(styleElement);
	}
}
