import React, { useState } from 'react';
import { pipe, toLower, trim, propSatisfies, inc } from 'ramda';
import { Pressable, ScrollView } from "react-native";
import use from "../../../hook";
import { parallel } from "../../../library-js/utils/function";
import { styles } from "../../../res";
import AppBar from "../../AppBar/AppBar";
import StandardAppBar from "../../AppBar/StandardAppBar";
import KeyboardAvoidingView from "../../KeyboardAvoidingView";

export default function SingleTagEditor({ onSubmitted, autocomplete, cancel, keywords, strings, ...props }) {
	const [count, setCount] = useState(0);
	const [value, setValue] = use.state("");

	function submit(tag = value) {
		tag = tag?.trim().toLowerCase();
		if (tag) {
			onSubmitted(tag);
			setCount(inc);
		}
		setValue('');
	}

	const memory = use.memo({ currentValue: value });

	const wordAlreadyInKeywords = ((() => {
		const correct = pipe(trim, toLower);
		const correctValue = correct(value);
		return keywords?.map(correct).includes(correctValue)
	})());


	const [suggestions, setSuggestions] = use.state([]);
	const [loadingSuggestions, setLoadingSuggestionsTo] = use.state.bool();

	use.syncEffect(async () => {
		if (wordAlreadyInKeywords) return;
		if (!autocomplete || !value) return;
		setLoadingSuggestionsTo(true);
		setSuggestions([]); // clean while waiting

		await Promise.await(600);

		const valueHasChanged = () => value !== memory.currentValue;
		if (valueHasChanged()) return;

		Promise.resolve(autocomplete(value))
			.then((suggestions) => {
				if (!valueHasChanged())
					setSuggestions(
						[suggestions] // correct type
							.flat()
							.filter(Boolean)
							.map(String)
					);
			})
			.finally(setLoadingSuggestionsTo.false);
	}, [value]);


	const { Text, TextInput } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<KeyboardAvoidingView {...props}>
			<StandardAppBar
				statusBarPadding={false}
				style={localStyles.appBar}>

				<AppBar.ActionButton
					icon="AntDesign/arrowleft"
					onPress={cancel} />

				<Pressable
					onPress={() => value ? submit() : cancel()}
					style={[styles.center, { paddingRight: 10 }]}>
					<Text style={{ bold: false, fontSize: 14 }}>
						{value ? `Ajouter` : `Terminer`}
					</Text>
				</Pressable>
			</StandardAppBar>

			<TextInput
				value={value}
				onValueChanged={parallel(
					setValue,
					value => memory.currentValue = value,
				)}
				blurOnSubmit={false}
				onSubmitted={submit}
				placeholder={strings?.tagPlaceholder || `Mot clé...`}
				autoFocus
				style={localStyles.input}
			/>

			<ScrollView
				keyboardShouldPersistTaps="handled"
				contentContainerStyle={localStyles.container}
				style={localStyles.scroll}>
				<Text style={{ color: 'grey', italic: true }}>
					{
						wordAlreadyInKeywords ? <WordAlreadyInKeyword />
							: !value ? (
								strings?.inviteToTap
								|| (
									!count ?
										`Commencez à taper un nouveau mot clé.`
										: `Vous avez ajouté ${count} mot${count > 1 ? 's' : ''} clé. Continuez pour ajouter un autre.`
								)
							)
								: loadingSuggestions &&
								`Chargements de suggestions...`
					}
				</Text>
				{
					Boolean(value) &&
					suggestions?.map((suggestion, index) =>
						<Text
							key={index}
							onPress={() => submit(suggestion)}
							style={localStyles.suggestion(index)}>
							{suggestion}
						</Text>
					)
				}

			</ScrollView>
		</KeyboardAvoidingView>
	);
}

function WordAlreadyInKeyword() {
	const { Text } = use.theme();
	return (
		<Text style={{ paddingHorizontal: 20, marginBottom: 10, fontSize: 12, }}>
			{`Ce mot existe déjà dans la liste.`}
		</Text>
	);
}

const localStyles = {
	layout: {
		flex: 1,
	},

	appBar: {
		justifyContent: styles.justifyContent.spaceBetween,
	},

	scroll: {
		flex: 1
	},

	container: {
		padding: 25,
	},

	input: {
		margin: 25,
		marginBottom: 0,
	},

	suggestion: index => ({
		paddingVertical: 10,
		paddingLeft: 10,
		borderTopWidth: index ? .5 : 0,
		borderColor: 'lightgrey',
	}),
};