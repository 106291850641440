import React from "react";
import View from "library-react/component/View/v2";
import KycDocumentItem, {KycDocumentHeader} from "./KycDocumentItem";
import use from "library-react/hook";
import {styles} from "@src/res";
import SupportButton from "@main/ui/payment/OnlinePaymentConfiguration/SupportButton";
import Text from "library-react/component/Text/v2";
import Button from "@main/component/Button";
import {any, pick, pipe, values} from "ramda";


export default function KycDocumentsUI({value: kycsMap, next, stateLoadable, updateState, ...props}) {
	const keys = Object.keys(kycsMap);

	const state = stateLoadable.value;
	const willBeAutoEnabled = Boolean(
		state?.bankAccountId &&
		state.UBO_DECLARATION &&
		pipe(
			pick(['ASKED', 'VALIDATED']),
			values,
			any(Boolean),
		)(state.UBO_DECLARATION.is)
	);

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<SupportButton
				size={30}
				style={localStyles.support}/>

			<Text style={localStyles.header}>
				{`Pour chaque catégorie veuillez rajouter les documents nécessaires et soumettre.`}
				{
					willBeAutoEnabled &&
						`\n\nUne fois que tous les documents seront validés, le paiement en ligne s'activera automatiquement.`
				}
			</Text>

			<KycDocumentHeader/>
			{
				keys.map((type, index) =>
					<KycDocumentItem
						key={type}
						type={type}
						value={kycsMap[type]}
						updateState={updateState}
						style={localStyles.item(index)}/>
				)
			}

			<Button onPress={next}
					style={localStyles.submit}>
				{`Suivant ›`}
			</Button>
		</View>
	);
}

const localStyles = {
	layout: {
		padding: 20,
	},
	support: {
		alignSelf: styles.alignSelf.flexEnd,
	},
	header: {
		marginBottom: 40,
		fontSize: 18,
		lineHeight: 24,
	},
	item: styles.static.bool(
		{
			height: 100,
		},
		{
			borderTopWidth: .5,
			borderColor: 'lightgrey',
		},
	),
	submit: {
		marginTop: 40,
		alignSelf: styles.alignSelf.flexEnd,
	}
}
