import React from "react"
import {ScrollView, Switch, View as RNView} from "react-native";
import use from "library-react/hook";
import Button from "../../../../component/Button";
import {styles} from "../../../../../res"
import ImageInput from "./ImageInput";
import HomeInput from "./HomeInput";
import Preview from "./Preview";
import useWebsiteConfig from "../useWebsiteConfig";
import useUpdateConfig from "../useUpdateConfig";
import ErrorView from "library-react/component/ErrorView";
import {ShopWebsiteConfiguration} from "library-js/app";
import PinsConfigView from "./PinsConfigView"
import {parallel} from "library-js/utils/function";

function HomeConfigUI({...props}) {
	const {value: config, loading, load: reload} = useWebsiteConfig.loadable();

	const edition = use.memo(() => {
		if (config) {
			const copy = config?.copy({home: true});
			copy.home.bindSetters();
			return copy;
		}
	}, [config]);

	const home = edition?.home || new ShopWebsiteConfiguration.Home();
	if (!home.mode)
		home.mode = ShopWebsiteConfiguration.Home.Mode.wall;


	use.subscribe(home?.onPropertyChangedSync, [edition]);


	const updateConfig = useUpdateConfig();
	const [submitting, setSubmittingTo] = use.state.bool(false);
	const [submitVersion, incSubmitVersion, setSubmitVersion] = use.version(0);

	const wasEdited = edition && !edition.equalsTo(config);
	const submit = use.asyncCallback(shouldStop => wasEdited &&
		function persist() {
			incSubmitVersion();

			const isValid = home.mode.run({
				wall: () => !(home?.displayTitle && !home.title),
				pinList: () =>
						(home.pins.length > 0)
						&& home.pins.every((pin, index) => {
							const invalidFields = pin.getInvalidFields();
							if (invalidFields.length > 0)
								console.log(`Pin n°${index + 1} ${pin.constructor.type} invalid: `, invalidFields.join(', '));
							return invalidFields.length === 0;
						})
				,
			});

			if (isValid)
				return Promise.process(shouldStop)
					.then(parallel(setSubmittingTo.true, () => setSubmitVersion(0)))
					.then(() => updateConfig(edition))
					.catch(console.warn)
					.finally(setSubmittingTo.false);
		},
		[wasEdited, edition]
	);


	const {Text, Spinner} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>

			{
				config ?
					<>
						<RNView style={localStyles.mode}>
							<Text style={localStyles.modeTitle}>
								{`Mode d'affichage`}
							</Text>

							<RNView style={localStyles.modeForm}>
								<Text style={localStyles.modeName}>
									{`Simple`}
								</Text>

								<Switch
									value={home.mode.is.pinList}
									onValueChange={isPinList => {
										const {Mode} = ShopWebsiteConfiguration.Home;
										home.mode = isPinList ? Mode.pinList : Mode.wall;
									}}
									style={localStyles.modeSwitch}
								/>

								<Text style={localStyles.modeName}>
									{`Avancé`}
								</Text>
							</RNView>
						</RNView>

						<ScrollView {...localStyles.scrollStyles}>
							{
								home.mode.is.pinList ?
									<PinsConfigView
										home={home}
										submitted={submitVersion > 0}/> :
									<>
										<ImageInput onValueChanged={home.setBanner}/>
										<RNView style={localStyles.inputAndPreview.layout}>
											<HomeInput title={home.title}
													   subtitle={home.subtitle}
													   displayTitle={home.displayTitle}
													   link={home.link}
													   onTitleChanged={home.setTitle}
													   onSubtitleChanged={home.setSubtitle}
													   onDisplayTitleChanged={home.setDisplayTitle}
													   onLinkChanged={home.setLink}
													   submittedVersion={submitVersion}
											/>
											<Preview image={home.banner}
													 title={home.title}
													 subtitle={home.subtitle}
													 displayTitle={home.displayTitle}
													 style={{marginLeft: 105}}
											/>
										</RNView>
									</>
							}
						</ScrollView>

						{/* Submit */}
						{
							submit &&
							<Button
								onPress={submit}
								loading={submitting}
								raised={!submitting}
								style={localStyles.submit}>
								{`Sauvegarder`}
							</Button>
						}

					</> :

					loading ?
						<Spinner style={{marginVertical: 100}}/> :
						<ErrorView onLoadClicked={reload}/>
			}
		</RNView>
	);
}

export default React.memo(HomeConfigUI);

const localStyles = {
	layout: {
		flex: 1,
	},

	scrollStyles: {
		style: {
			flex: 1,
			paddingTop: 20,
		},

		contentContainerStyle: {
			padding: 20,
			paddingLeft: 30,
			paddingBottom: 100,
		}
	},

	mode: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
	},

	modeTitle: {
		fontSize: 22,
		padding: 20,
		paddingLeft: 30,
	},

	modeForm: {
		flex: 1,
		flexDirection: styles.flexDirection.row,
		...styles.center,
		paddingVertical: 20,
		marginHorizontal: 20,
		borderBottomWidth: 1,
		borderColor: styles.color.lightgrey,
	},

	modeName: {
		paddingHorizontal: 20,
		fontSize: 20,
		bold: true,
	},

	modeSwitch: {
		transform: [{scale: 1.2}],
	},

	banner: {
		height: 80,
		maxWidth: 100
	},

	inputAndPreview: {
		layout: {
			marginTop: 20,
			flexDirection: styles.flexDirection.row,
			justifyContent: styles.justifyContent.spaceBetween,
		}
	},

	submit: {
		...styles.absolute({bottom: 20, right: 20}),
	},
};
