import React from "react"
import {View as RNView} from "react-native"
import ModalFrame from "library-react/component/ModalFrame";
import Text from "library-react/component/Text";
import {strings, styles} from "../../res"
import Search from "library-js/Search";
import use from "library-react/hook";
import TextInput from "library-react/component/input/TextInput";
import View from "library-react/component/View";
import ScrollView from "library-react/component/ScrollView";
import ProductItem from "./ProductItem";
import Icon from "library-react/component/Icon";
import Loading from "./Loading";

export default React.memo(
	function SelectProductDialog({title, shopId, inShop, onCloseClicked, onSelected, ...props}) {
		const [query, setQuery] = use.state("");

		const iterator = use.infiniteIterator(
			() => Search.index.prodshop.getIterator({query, shopId, inShop, available: true}),
			[query, shopId, inShop],
		);

		// default layout style
		props.style = use.defaultStyle(props.style, localStyles.layout);

		return (
			<ModalFrame.Dialog {...props}>
				<View style={localStyles.top}>
					{
						Boolean(title) &&
						<Title>
							{title}
						</Title>
					}

					{
						onCloseClicked &&
						<Icon
							name="EvilIcons/close"
							onPress={onCloseClicked}
							style={{height: 40, width: 40, color: "lightgrey"}}/>
					}
				</View>

				{/* Search bar */}
				{use.memo(() => (
					<View style={{
						flexDirection: styles.flexDirection.row,
						marginHorizontal: 20,
					}}>
						<Icon
							name="MaterialIcons/search"
							style={{
								height: 21,
								width: 21,
								color: "lightgrey",
								marginRight: 7,
								marginTop: 3,
							}}/>

						<TextInput
							placeholder={strings.search}
							placeholderTextColor="lightgrey"
							onSubmitted={setQuery}
							style={{
								flex: 1,
								fontSize: 20,
								paddingBottom: 10,
								borderBottomWidth: .5,
								borderColor: "lightgrey",
							}}
						/>
					</View>
				))}

				<ScrollView
					contentContainerStyle={localStyles.content}
					onEndReachedThreshold={100}
					onEndReached={iterator.ready && iterator.load}>

					{ // render items
						iterator.items.map(vProdshop => (
								<ProductItem
									key={vProdshop.product.id}
									item={vProdshop}
									onPress={onSelected && (() => onSelected(vProdshop))}
									style={localStyles.productStyle}/>
							)
						)
					}

					{/* --- loading --- */}
					{!iterator.end && loading}

					{// fillers (up to 100)
						iterator.items.slice(0, 100)
							.map((_, index) =>
								<RNView
									key={`fill${index}`}
									style={localStyles.fillerStyle}
								/>
							)
					}
				</ScrollView>
			</ModalFrame.Dialog>
		);
	}
);

const Title = React.memo(({children}) => (
	<Text
		numberOfLines={1}
		style={{fontSize: 23, color: "#0bafee", flex: 1}}>
		{children}
	</Text>
));

const loading = (
	<Loading style={{width: 200, minHeight: 200, margin: 10}}/>
);

const localStyles = {
	layout: {
		padding: 0,
		borderRadius: 20,
		width: 1000,
		maxWidth: "100%",
	},

	top: {
		flexDirection: styles.flexDirection.row,
		paddingTop: 15,
		paddingBottom: 30,
		paddingHorizontal: 20,
		justifyContent: styles.justifyContent.flexEnd,
		alignItems: styles.alignItems.flexEnd,
	},

	content: {
		flexDirection: styles.flexDirection.row,
		flexWrap: styles.flexWrap.wrap,
		justifyContent: styles.justifyContent.spaceBetween,
		paddingHorizontal: 10,
	},

	...(width => ({
		productStyle: {
			width,
			margin: 10,
			...styles.newShadow(0, 3, 6, .16)
		},

		fillerStyle: {width},
	}))(200)
};
