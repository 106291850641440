import {styles} from "../../../../res";
import React, {useCallback, useState} from "react";
import View from "../../View";
import Icon from "../../Icon";
import Image from "../../Image";
import ComponentUtils from "../../../ComponentUtils";
import Selector from "../../Selector";
import ScrollView from "../../ScrollView";

export default function Gallery({items = [], resizeMode = 'contain', imageStyle, onFullScreenClicked, ...props}){
	let [index, setIndex] = useState(0);
	if (index > items.lastIndex)
		index = items.lastIndex;

	let next = useCallback(() => setIndex(index => index + 1), []);
	let previous = useCallback(() => setIndex(index => index - 1), []);

	imageStyle = ComponentUtils.defaultStyle(imageStyle, styles.fit);

	return (
		<View {...props}>
			<View style={{flex: 1}}
				  onPress={onFullScreenClicked && (() => onFullScreenClicked(index))}>
				{
					items.map((item, current) =>
						<Image
							key={current}
							source={item && {uri: item.uri}}
							style={imageStyle.repush(styles.if(current !== index, styles.invisible))}
							resizeMode={resizeMode}/>
					)
				}

				{
					index > 0 &&
					<Arrow
						left
						onPress={previous}/>
				}

				{
					index < items.lastIndex &&
					<Arrow
						right
						onPress={next}/>
				}

				<Pagination
					index={index}
					onIndexChanged={index => setIndex(index)}
					length={items.length} />
			</View>

			<Items
				items={items}
				onSelected={index => setIndex(index)}/>
		</View>
	)
}

const Arrow = (() => {
	let defaultStyle = [
		styles.center,
		{
			position: styles.position.absolute,
			top: 0,
			bottom: 0,
			padding: 15,
		}
	];

	return ({right, onPress}) => (
		<View onPress={onPress}
			  style={defaultStyle.repush(right ? {right: 0} : {left: 0})}>
			<Icon
				name={`MaterialIcons/chevron-${right ? "right" : "left"}`}
				style={{height: 28, width: 28, color: styles.color.white}}/>
		</View>
	);
})();

const Pagination = (() => {
	let style = {
		position: styles.position.absolute,
		bottom: 16,
		right: 24,
		flexDirection: styles.flexDirection.row,
	};

	let dotStyle = {
		width: 12,
		height: 12,
		borderRadius: 6,
		marginLeft: 10,
	};

	return ({index, length, onIndexChanged}) => (
		length > 1 &&
		<Selector
			index={index}
			onIndexChanged={onIndexChanged}
			style={style}>
			{Array.range(length)
				.map((current) =>
					<Selector.Item
						key={current}
						style={({selected}) => [dotStyle, {backgroundColor: selected ? styles.color.white : "#5e6383"}]}/>
				)
			}
		</Selector>
	);
})();

const Items = (() => {
	const style = {
		marginTop: 27,
		flexGrow: 0,
	};

	const itemStyle = {
		width: 72,
		height: 90,
		borderRadius: 5,
	};

	const gap = {marginRight: 33.8};

	return ({items, onSelected}) => (
		<ScrollView
			horizontal
			style={style}>
			{
				items.length > 1 &&
				items.map((item, index) => (
					<View
						key={index}
						style={items.lastIndex > index && gap}
						onPress={() => onSelected(index)}>
						<Image
							style={itemStyle}
							resizeMode="cover"
							source={{uri: item.uri}} />
					</View>
				))
			}
		</ScrollView>
	);
})();
