import React from "react"
import use from "library-react/hook"
import { styles } from "../../res"
import { ScrollView } from 'react-native'

export default function FragmentLayout({ Component = ScrollView, ...props }) {
	props.style = use.defaultStyle(props.style, FragmentLayout.style);
	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, FragmentLayout.contentContainerStyle);
	return <Component {...props} />;
}


FragmentLayout.style = {
	flexShrink: 1,
	flexGrow: 1,
	flexBasis: 0,
	// 3 of them => flex: 1
};

FragmentLayout.contentContainerStyle = {
	marginTop: 30,
	margin: 25,
	...styles.newShadow(0, 3, 6, .16),
	backgroundColor: styles.color.white,
	borderRadius: 20,
};
