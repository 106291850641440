import CryptoJS from "crypto-js";
import assignParamsToUrl from "../utils/URL/assignParamsToUrl";
import { Order } from "./model/entity";

export default function generateInvoiceURL(order) {
	const orderId = order.id;

	const dataToHash = order instanceof /*legacy*/ Order ?
		order.user.id + order.shop.id + order.creationDate + "🧂"
		: order.id + order.shopName + order.creationDate + "🧂";
	const hash = CryptoJS.MD5(dataToHash).toString();

	return assignParamsToUrl({ orderId, hash }, "https://us-central1-rcm55-bagshop.cloudfunctions.net/order/invoice");
}
