import React from "react";
import { UIManager, View as RNView } from "react-native";
import use from "../../hook";
import Environment from "../../library-js/Environment";
import { styles } from "../../res";
import animateNextRender from "../../utils/animateNextRender";
import View from "../View/v2";

/**
 * @param {{
 * initialValue?: any, 
 * onValueChanged?: (value: boolean) => void, 
 * disabled?: any, 
 * editable?: any, 
 * triggerStyle?: import("react-native").ViewProps["style"], 
 * triggerOnStyle?: import("react-native").ViewProps["style"], 
 * triggerOffStyle?: import("react-native").ViewProps["style"], 
 * onStyle?: import("react-native").ViewProps["style"], 
 * offStyle?: import("react-native").ViewProps["style"], 
 * children?: import("react-native").ViewProps["children"], 
 * animated?: any,
 * } & import("react-native").ViewProps}
 */
export default function Switch({ initialValue, onValueChanged, disabled, editable = true, triggerStyle, triggerOnStyle, triggerOffStyle, onStyle, offStyle, children, animated, ...props }) {
	let [value, setValue] = use.state(Boolean(initialValue));
	if ("value" in props) { // value imposed
		value = Boolean(props.value);
		delete props.value;
	}

	use.syncEffect(() => {
		if (animated)
			animateNextRender();
	}, [value]);

	props.feedback = false;
	props.onPress = use.callback(event => {
		event.preventDefault();
		setValue(!value);

		if (onValueChanged)
			onValueChanged(!value, value);

	}, [onValueChanged, value]);

	if (disabled) {
		editable = false;
		console.warn('use Switch.editable instead of disabled.');
	}

	if (!editable)
		delete props.onPress;

	props.style = [
		localStyles.layout,
		value ? onStyle : offStyle,
		props.style,
	].flatMap();

	return (
		<View {...props}>
			<RNView style={[
				localStyles.trigger,
				{ alignSelf: value ? styles.alignSelf.flexEnd : styles.alignSelf.flexStart },
				value ? triggerOnStyle : triggerOffStyle,
				triggerStyle,
			].flatMap()} />

			{children}
		</View>
	);
}

Environment.run({
	android() {
		if (UIManager.setLayoutAnimationEnabledExperimental)
			UIManager.setLayoutAnimationEnabledExperimental(true);
	}
});

const localStyles = {
	layout: {
		width: 40,
		height: 20,
		backgroundColor: "grey",
	},

	trigger: {
		flex: 1,
		width: "50%",
		backgroundColor: styles.color.white,
	}

};
