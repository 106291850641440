import React, {useCallback, useState} from "react"
import View from "../View";
import styles from "../../res/styles"
import Icon from "../Icon";
import Text from "../Text";
import {is} from "../../library-js/utils";
import ComponentUtils from "../../ComponentUtils";


export default function BooleanInput
	({
		 defaultValue: initialValue, label, disabled, onValueChanged,
		 squareStyle, squareOnStyle, squareOffStyle,
		 checkStyle, checkOnStyle, checkOffStyle,
		 textStyle, textOnStyle, textOffStyle,...props
	 }) {
	let [value, setValue] = useState(Boolean(initialValue));

	if ("value" in props) { // value imposed
		value = Boolean(props.value);
		delete props.value;
	}

	props.feedback = false;
	props.onPress = useCallback(() => {
		setValue(!value);

		if (onValueChanged)
			onValueChanged(!value, value);

	}, [onValueChanged, value]);

	if (disabled)
		delete props.onPress;

	props.style = [
		localStyles.layout,
		BooleanInput.default.layout,
		props.style,
	].flatMap();

	squareStyle = [
		localStyles.square,
		BooleanInput.default.square,
		value ? squareOnStyle : squareOffStyle,
		squareStyle,
	].flatMap();

	checkStyle = [
		localStyles.check,
		BooleanInput.default.check,
		value ? checkOnStyle : checkOffStyle,
		styles.phantom(value),
		checkStyle,
	].flatMap();

	textStyle = [
		localStyles.text,
		BooleanInput.default.text,
		value ? textOnStyle : textOffStyle,
		textStyle
	].flatMap();

	return (
		<View {...props}>
			<View style={squareStyle}>
				<Icon name={'MaterialIcons/check'}
					  style={checkStyle}
				/>
			</View>

			{
				ComponentUtils.isElement(label) || is(label, Function) ?
					label :
					<Text style={textStyle}>
						{label}
					</Text>
			}
		</View>
	);
}

BooleanInput.default = {};


const localStyles = {
	layout: {
		backgroundColor: styles.color.white,
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.spaceAround,
		paddingHorizontal: 10
	},
	square: {
		width: 30,
		height: 30,
		borderWidth: .2,
		borderColor: styles.color.black,
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.center,
	},

	check: {
		width: 30,
		height: 30,
		color: styles.color.black,
	},
	text: {
		marginLeft: 10,
		flex: 1,
		color: styles.color.black,
	}

};
