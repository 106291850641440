import React from "react"
import {FlatList, View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@src/res"
import FragmentLayout from "@main/component/FragmentLayout";
import Head from "@main/component/Head";
import ShopCategoryInput from "@main/ui/showcase/ShowcasesMarketUI/ShopCategoryInput";
import useShopCategories from "library-react/hook/useShopCategories";
import useMe from "library-react/hook/pro/useMe";
import useRouteParamState, {commonConfigs} from "library-react/hook/useRouteParamState";
import ShowcaseRow from "./ShowcaseRow"
import InfiniteIterator from "library-js/InfiniteIterator";
import VShowcase from "library-js/app/model/view/general/VShowcase";
import {Server} from "library-js/app";
import {remove} from "ramda";
import ErrorView from "library-react/component/ErrorView";
import Button from "@main/component/Button";
import {MAX_SHOP_SHOWCASES} from "library-js/app/Server";
import screens from "@main/screens";
import Portal from "library-react/component/Portal";
import Search from "library-js/Search";

function ShowcasesMarketUI({...props}) {
	const [tree] = useShopCategories();
	const {shop} = useMe().shop;
	const defaultCategory = tree?.get(shop.categoryId);
	let [shopCategory, setShopCategory] = use.state(defaultCategory);
	use.syncEffect(() => {
		if (!shopCategory && defaultCategory)
			setShopCategory(shopCategory = defaultCategory);
	}, [tree]);

	const [query, setQuery] = useRouteParamState('query', commonConfigs.query);
	const marketIterator = use.infiniteIterator(() =>
			Search.index.showcase.getIterator({
				query,
				shopCategories: shopCategory && [shopCategory.id]
			})
				.setDelay(800),
		[query, shopCategory],
	);

	const showcasesLoadable = use.loadable.server(() =>
			Server.retailer.showcase.getShowcase(shop.id),
		[shop.id],
	);

	const {value: shopShowcases} = showcasesLoadable;

	const updateShowcaseSelection = use.callback((isNowSelected, vShowcase) => {
		let newShopShowcases;

		if (isNowSelected) {
			const vShowcaseShop = VShowcase.Shop.from({
				itemsCount: 0,
				showcaseShop: {
					showcaseId: vShowcase.id,
					shopId: shop.id,
					creationDate: Date.now(),
				}
			});
			vShowcaseShop.vShowcase = vShowcase;
			newShopShowcases = [vShowcaseShop].concat(shopShowcases);
		} else if (!isNowSelected) {
			const indexInSelection = shopShowcases.findIndex(item => item.showcase.id === vShowcase.id);
			if (indexInSelection >= 0)
				newShopShowcases = remove(indexInSelection, 1, shopShowcases);
		}

		if (newShopShowcases)
			showcasesLoadable.setValue(newShopShowcases);
	}, [shopShowcases]);

	const {TextInput, Spinner, ErrorView, Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			<FlatList
				ListHeaderComponent={
					<>
						<RNView style={localStyles.header}>
							<Head style={localStyles.title}>
								{`Vitrines Shopinzon`}
							</Head>
						</RNView>

						<TextInput
							value={query}
							onValueChanged={setQuery}
							placeholder={`Recherche...`}
							style={localStyles.search}/>

						<ShopCategoryInput
							value={shopCategory}
							onValueChanged={setShopCategory}
							style={localStyles.categories}/>
					</>
				}

				data={marketIterator.items}
				keyExtractor={vShowcase => String(vShowcase.id)}
				renderItem={({item: vShowcase, index}) => {
					if (!shopShowcases) return null; // don't show market before shop showcases were loaded

					const vShowcaseShop = shopShowcases.find(vShowcaseShop => vShowcaseShop.showcase.id === vShowcase.id);

					return (
						<Portal to={screens.showcase} params={{id: vShowcase.id}}>
							<ShowcaseRow
								vShowcase={vShowcase}
								vShowcaseShop={vShowcaseShop}
								onSelectedChanged={updateShowcaseSelection}
								inMarket
								style={!index ? localStyles.firstShowcase : undefined}/>
						</Portal>
					);
				}}
				onEndReached={marketIterator.loadNextPage}
				onEndReachedThreshold={.01}
				ListFooterComponent={
					(showcasesLoadable.loading || !marketIterator.end) &&
					<Spinner style={{alignSelf: styles.alignSelf.center, margin: 20}}/>
				}

				style={localStyles.market.layout}
				contentContainerStyle={localStyles.market.content}
			/>

			<FragmentLayout
				contentContainerStyle={localStyles.myShowcases.content}
				style={localStyles.myShowcases.layout}>
				<RNView style={localStyles.header}>
					<Head>
						{`Mes vitrines`}
					</Head>

					{
						(shopShowcases?.length >= MAX_SHOP_SHOWCASES) ?
							<Text>
								{`Nombre maximum de vitrines atteint`}
							</Text> :

							<Portal to={screens.createShowcase}>
								<Button
									raised
									disabled={!shopShowcases}
									style={styles.phantom(shopShowcases, true)}>
									{`Créer une vitrine`}
								</Button>
							</Portal>
					}
				</RNView>
				{
					shopShowcases ? (
							(shopShowcases.length > 0) ?
								shopShowcases?.map(vShowcaseShop =>
									<Portal key={vShowcaseShop.showcase.id} to={screens.showcase}
											params={{id: vShowcaseShop.showcase.id}}>
										<ShowcaseRow
											vShowcaseShop={vShowcaseShop}
											onSelectedChanged={updateShowcaseSelection}
											style={localStyles.shopShowcase}/>
									</Portal>
								) :
								<Text style={localStyles.emptyShopShowcases}>
									{`Vous n'avez encore aucune vitrine. Ajoutez en une depuis notre market-place, ou créez la votre sur mesure en cliquant sur le bouton "Créer une vitrine" au dessus.`}
								</Text>
						) :
						<RNView style={{alignItems: styles.alignItems.center, padding: 20}}>
							{
								showcasesLoadable.loading ?
									<Spinner/> :
									<ErrorView onLoadClicked={showcasesLoadable.load}/>
							}
						</RNView>
				}
			</FragmentLayout>
		</RNView>
	);
}

export default React.memo(ShowcasesMarketUI);


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		flex: 1,
	},

	header: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		alignItems: styles.alignItems.center,
		padding: 30
	},

	market: {
		layout: {
			flex: 1,
			height: '100%',
		},

		content: {
			...FragmentLayout.contentContainerStyle,
			marginRight: FragmentLayout.contentContainerStyle.margin / 2,
			flexGrow: 1,
			overflow: styles.overflow.hidden,
		}
	},


	title: {
		alignSelf: styles.alignSelf.flexStart,
	},

	search: {
		paddingHorizontal: 20,
		paddingVertical: 10,
		borderRadius: 20,
		...styles.newBorder(1, 'lightgrey'),
		marginLeft: 35,
		alignSelf: styles.alignSelf.flexStart,
		width: 300,
		fontSize: 19,
	},

	categories: {
		marginLeft: 35,
		marginTop: 10,
	},

	firstShowcase: {
		marginTop: 40,
	},

	myShowcases: {
		layout: {
			flexBasis: 600,
			flexGrow: 0,
			flexShrink: 1,
			height: '100%',
		},

		content: {
			minHeight: 400,
			marginLeft: FragmentLayout.contentContainerStyle.margin / 2,
			overflow: styles.overflow.hidden,
		}
	},

	shopShowcase: {
		minHeight: 130,
	},

	emptyShopShowcases: {
		textAlign: styles.textAlign.center,
		margin: 50,
		lineHeight: 24,
	}
};
