import React, { useContext } from "react"
import useSubscribe from "../useSubscribe";

export const meContext = new React.createContext(null);

export function useMeLoadable() {
	const loadable = useContext(meContext);
	useSubscribe(loadable?.onStateChanged, [loadable]);
	return loadable;
}

export default function useMe() {
	return useMeLoadable()?.value;
}
