import React from "react";
import use from "../hook";
import useApp from "../app/useApp"

export default function ModalOpenable({ children: child, getModal, defaultOpened, propName = "onPress", disabled }) {
	const app = useApp();
	function open() {
		const modal = getModal?.();

		if (modal)
			app.openModal(modal);
	}


	use.effect(() => {
		if (defaultOpened)
			open();
	});

	return disabled ? child : React.cloneElement(child, { [propName]: open });
}