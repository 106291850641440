import React from "react";
import use from "library-react/hook";
import View from "library-react/component/View/v2";
import SimpleButton from "library-react/component/SimpleButton";
import {styles} from "@src/res";

export default function RemainingAmountView({onTransfer, ...props}) {
	const [version, upgrade] = use.version();
	const {value: remaining, loading: loadingAmount, load, error} = use.loadable.server(
		() => Server.retailer.payment.transaction.getRemainingAmount(),
		[version]
	);

	const [loadingTransfer, setLoading] = use.state.bool();
	const transfer = use.callback(async () => {
		setLoading.true();
		Server.retailer.payment.transaction.transferRemainingAmount()
			.then(response => {
				if (onTransfer && response.ok) {
					onTransfer(response.content)
					upgrade();
				} else
					response.log();
			})
			.finally(setLoading.false);
	});

	const loading = loadingAmount || loadingTransfer;

	const {Text, Spinner} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		error ?
			<>
				<SimpleButton
					filled
					colors={{
						primary: styles.color.azure2,
						secondary: styles.color.white,
					}}
					onPress={load}
					style={localStyles.button}>
					{`Recharger la somme en attente`}
				</SimpleButton>
			</> :
			<View {...props}>
				{
					loading ? <Spinner/> :
						<>
							<Text style={localStyles.text}>
								{`Somme en attente: ${remaining?.formatted}`}
							</Text>
							{
								Boolean(remaining?.value) &&
								<SimpleButton
									filled
									colors={{
										primary: styles.color.azure2,
										secondary: styles.color.white,
									}}
									onPress={transfer}
									style={localStyles.button}>
									{`Transférer`}
								</SimpleButton>
							}

						</>
				}

			</View>
	)
}

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		borderWidth: .5,
		borderRadius: 20,
		padding: 10,
		...styles.center,
	},
	text: {
		marginRight: 10,
	},
	button: {
		...styles.center,
		backgroundColor: styles.color.azure2,
		marginLeft: 10,
		color: 'white',
		fontSize: 13,
		borderRadius: 20,
	},
}
