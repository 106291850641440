import React from "react"

export const empty = ({Svg, Path, G}) => () => (
	<Svg viewBox="0 0 141 98" fill="none">
		<Path
			d="M116.812 13.207H118.534C119.446 13.2097 120.321 13.5733 120.966 14.2184C121.611 14.8635 121.974 15.7377 121.977 16.65V26.601C121.974 27.5134 121.611 28.3876 120.966 29.0327C120.321 29.6778 119.446 30.0414 118.534 30.044H21.744C20.8317 30.0414 19.9575 29.6778 19.3123 29.0327C18.6672 28.3876 18.3036 27.5134 18.301 26.601V16.65C18.3036 15.7377 18.6672 14.8635 19.3123 14.2184C19.9575 13.5733 20.8317 13.2097 21.744 13.207H24.12"
			stroke="#838383" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
		<Path d="M70.122 30.423V96.154" stroke="#838383" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"
			  strokeLinejoin="round"/>
		<Path d="M81.106 96.155H59.173" stroke="#838383" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"
			  strokeLinejoin="round"/>
		<G>
			<Path
				d="M46.295 58.899H1.946V48.569H42.852C43.7643 48.5716 44.6385 48.9352 45.2837 49.5803C45.9288 50.2254 46.2924 51.0996 46.295 52.012V58.899Z"
				stroke="#838383" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"
				strokeLinejoin="round"/>
			<Path d="M1.946 1.5V96.155" stroke="#838383" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"
				  strokeLinejoin="round"/>
			<Path d="M44.229 58.899V96.155" stroke="#838383" strokeWidth="3" strokeMiterlimit="10"
				  strokeLinecap="round" strokeLinejoin="round"/>
			<Path d="M1.946 58.899L44.229 75.599" stroke="#838383" strokeWidth="3" strokeMiterlimit="10"
				  strokeLinecap="round" strokeLinejoin="round"/>
			<Path d="M1.946 75.599L44.229 58.899" stroke="#838383" strokeWidth="3" strokeMiterlimit="10"
				  strokeLinecap="round" strokeLinejoin="round"/>
		</G>
		<G>
			<Path
				d="M94.569 58.899H138.918V48.569H98.047C97.1347 48.5716 96.2605 48.9352 95.6153 49.5803C94.9702 50.2254 94.6066 51.0996 94.604 52.012V58.898L94.569 58.899Z"
				stroke="#838383" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"
				strokeLinejoin="round"/>
			<Path d="M138.918 1.5V96.155" stroke="#838383" strokeWidth="3" strokeMiterlimit="10"
				  strokeLinecap="round" strokeLinejoin="round"/>
			<Path d="M96.669 58.899V96.155" stroke="#838383" strokeWidth="3" strokeMiterlimit="10"
				  strokeLinecap="round" strokeLinejoin="round"/>
			<Path d="M138.918 58.899L96.669 75.599" stroke="#838383" strokeWidth="3" strokeMiterlimit="10"
				  strokeLinecap="round" strokeLinejoin="round"/>
			<Path d="M138.918 75.599L96.669 58.899" stroke="#838383" strokeWidth="3" strokeMiterlimit="10"
				  strokeLinecap="round" strokeLinejoin="round"/>
		</G>
		<Path d="M28.493 13.207H111.751V22.366H28.493V13.207Z" stroke="#838383" strokeWidth="3" strokeMiterlimit="10"
			  strokeLinecap="round" strokeLinejoin="round"/>
	</Svg>
);
