import React from "react"
import {styles} from "../../res";
import ComponentUtils from "library-react/ComponentUtils";
import View from "library-react/component/View";

export default function Slot({...props}){
	props.style = ComponentUtils.defaultStyle(props.style, defaultStyle);
	return <View {...props}/>;
}

const defaultStyle = {
	backgroundColor: styles.color.blueBackground,
	borderRadius: 10,
	borderWidth: 1,
	borderColor: styles.color.main,
	borderStyle: styles.borderStyle.dashed,
};
