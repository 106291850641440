import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook";
import {styles} from "@src/res";
import Icon from "library-react/component/Icon/v2";
import TextInput from "library-react/component/input/TextInput"
import useRouteParamState, {commonConfigs} from "library-react/hook/useRouteParamState";

export default function SearchBar({...props}){
	const [query, setQuery] = useRouteParamState('query', commonConfigs.query);

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			<TextInput
				value={query}
				onValueChanged={setQuery}
				placeholder={`Nom, marque, mots clés..`}
				placeholderTextColor="lightgrey"
				style={localStyles.input}/>

			<Icon name="MaterialIcons/search" size={30} style={localStyles.icon} />
		</RNView>
	);
}


const localStyles = {
	layout: {
		backgroundColor: 'white',
		...styles.newShadow(0, 3, 8, .16),
		flexDirection: styles.flexDirection.row,
		borderRadius: 50,
		minHeight: 77,
		width: '80%',
		maxWidth: 600,
	},

	icon: {
		alignSelf: styles.alignSelf.center,
		marginRight: 20,
		color: 'grey',
	},

	input: {
		fontSize: 20,
		fontWeight: 'bold',
		color: 'black',
		paddingRight: 20,
		paddingLeft: 35,
		flex: 1,
	},
};
