import React, { useImperativeHandle } from "react"
import Icon from "./Icon/v2";
import use from "../hook";
import { is } from "../library-js/utils";
import { styles } from "../res"
import inputComponent from "./abstract/inputComponent";
import { not } from "ramda";

function CheckBox({ value, onValueChanged, onStyle, offStyle, renderIcon, editable = true, ...props }, refProp) {
	const toggle = () => onValueChanged(not);
	useImperativeHandle(refProp, () => ({ toggle }));

	if (editable)
		props.onPress = toggle;

	if (value)
		props.name = is(renderIcon, Function) ? renderIcon()
			: !is.null(renderIcon) ? renderIcon
				: DEFAULT_ICON;

	props.style = use.defaultStyle(props.style,
		[localStyles[value], value ? onStyle : offStyle],
		[onStyle, offStyle, value]
	);

	return <Icon {...props} />;
}

export default React.forwardRef(inputComponent(CheckBox));

const DEFAULT_ICON = "MaterialIcons/check";
const DEFAULT_COLOR = "#3aa4ff";

const localStyles = styles.static.bool(
	{
		borderWidth: 1,
		color: styles.color.white,
	},

	{
		borderColor: DEFAULT_COLOR,
		backgroundColor: DEFAULT_COLOR,
	},

	{
		borderColor: "lightgrey",
	}
);
