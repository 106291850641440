import React from "react"
import OriginalImageInput from "../../../../../component/ImageInput";
import use from "library-react/hook";
import View from "library-react/component/View";
import Icon from "library-react/component/Icon";
import {styles} from "../../../../../../res";


function ImageInput({...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	props.placeholder = props.multiple ? <MultiplePlaceholder/> : <SinglePlaceholder/>;
	return <OriginalImageInput {...props}/>;
}

export default React.memo(ImageInput);

const SinglePlaceholder = React.memo(
	function SinglePlaceholder({...props}) {
		props.style = use.defaultStyle(props.style, localStyles.placeholder.layout);
		return (
			<View {...props}>
				<Icon name="MaterialIcons/add"
					  style={localStyles.placeholder.add}
				/>
				<View style={{flexDirection: styles.flexDirection.row}}>
					<Icon name="picture"
						  style={localStyles.placeholder.picture}
					/>
					<Icon name="document"
						  style={localStyles.placeholder.document}
					/>
				</View>
			</View>
		)
	}
);

const MultiplePlaceholder = React.memo(
	function MultiplePlaceholder({...props}) {
		props.style = use.defaultStyle(props.style, localStyles.placeholder.layout);
		return (
			<View {...props}>
				<Icon name="MaterialIcons/add"
					  style={localStyles.placeholder.add}
				/>
				<View style={{flexDirection: styles.flexDirection.row}}>
					<View style={{flexDirection: styles.flexDirection.column,  alignItems: styles.alignItems.center, opacity:0.5}}>
						<Icon name="picture"
							  style={localStyles.placeholder.multiple.picture}
						/>
						<View style={{flexDirection: styles.flexDirection.row, marginTop: 4}}>
							<Icon name="picture"
								  style={[localStyles.placeholder.multiple.pictureItem]}/>
							<Icon name="picture"
								  style={[localStyles.placeholder.multiple.pictureItem]}/>
							<Icon name="picture"
								  style={localStyles.placeholder.multiple.pictureItem}
							/>
						</View>
					</View>
					<Icon name="document"
						  style={localStyles.placeholder.document}
					/>
				</View>
			</View>
		)
	}
);

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
	},
	placeholder: {
		layout: {
			flexDirection: styles.flexDirection.column,
			justifyContent: styles.justifyContent.center,
			alignItems: styles.alignItems.center,
			position: styles.position.absolute,
			top: '45%',
			right: 0,
			left: 0,
		},
		add: {
			width: 32,
			height: 32,
			color: styles.color.azure2,
			marginBottom: 32,
		},
		picture: {
			width: 30,
			height: 23,
			marginRight: 7,
		},
		document: {
			marginTop: 3,
			width: 27,
			height: 23
		},
		multiple: {
			picture: {
				width: 30,
				height: 23,
				color: styles.color.main,
			},
			pictureItem: {
				marginHorizontal: 3,
				width: 9,
				height: 7
			}
		},
	}
};
