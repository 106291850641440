const geocoder = new google.maps.Geocoder();

/**
 * @param textAddress {String}
 * @return {Promise<google.maps.GeocoderResult | undefined>}
 */
export default function geocode(textAddress){
	return new Promise((resolve, reject) => {
		geocoder.geocode(
			{address: textAddress},
			// {placeId: suggestionToPlaceId}, // Responds UNKNOW_ERROR. Looks like place id from geocoding and auto-complete are not the same.
			(result, status) => {
				if (status === google.maps.GeocoderStatus.OK)
					resolve(result);
				else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) // empty result
					resolve([]);
				else // error
					reject(status);
			}
		);
	});
}
