import React from "react"
import {is} from "../../../../../../library-js/utils";
import {
	OrderItemMessage,
	OrderItemNotification,
	OrderNotification,
	ProductMessage, ReservationNotification,
	TextMessage
} from "../../../../../../library-js/app/model/chat";
import TextMessageView from "./TextMessageView";
import ProductMessageView from "./ProductMessageView";
import OrderItemMessageView from "./OrderItemMessageView";
import OrderNotificationView from "./OrderNotificationView";
import OrderItemNotificationView from "./OrderItemNotificationView";
import ReservationNotificationView from "./ReservationNotificationView";

export default React.memo(
	function ChatEventView(props) {
		switch (is(props.event)) {
			// --- message ---
			case TextMessage:
				return <TextMessageView {...props} />;

			case ProductMessage:
				return <ProductMessageView {...props} />;

			case OrderItemMessage:
				return <OrderItemMessageView {...props} />;

			// --- notifications ---
			case OrderNotification:
				return <OrderNotificationView {...props} />;

			case OrderItemNotification:
				return <OrderItemNotificationView {...props} />;

			case ReservationNotification:
				return <ReservationNotificationView {...props} />;

			default:
				return null;
		}
	}
);
