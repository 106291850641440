import React, {useContext} from "react";
import useSubscribe from "library-react/hook/useSubscribe";

export default function useWebsiteConfig() {
	return useWebsiteConfigLoadable()?.value;
}

export const ConfigLoadableContext = React.createContext();

function useWebsiteConfigLoadable() {
	const loadable = useContext(ConfigLoadableContext);
	useSubscribe(loadable?.onStateChanged, [loadable]);
	return loadable;
}

useWebsiteConfig.loadable = useWebsiteConfigLoadable;
