import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {strings, styles} from "../../../../res"
import Text from "library-react/component/Text";
import Head from "../../../component/Head";
import Server from "library-js/app/Server"
import NewSectionForm from "./NewSectionForm";
import SectionList from "./SectionList";
import Button from "../../../component/Button";
import SimpleButton from "library-react/component/SimpleButton";
import useMe from "library-react/hook/pro/useMe";
import FragmentLayout from "../../../component/FragmentLayout";
import generateShopCupboardLoadable from "library-js/app/generateShopCupboardLoadable";
import strictAPI from "library-js/app/Server/strictAPI";
import useToast from "library-react/hook/useToast";
import CatalogSection from "library-js/app/model/entity/CatalogSection";
import override from "library-js/utils/function/override";
import {prop} from "ramda";

function CatalogSectionsUI({...props}) {
	const {shop} = useMe().shop;
	const loadable = use.createLoadable(
		() => {
			const loadable = generateShopCupboardLoadable(shop.id, {available: null, keepEmptySections: true});
			loadable.loader = override(loadable.loader, (params, loader) => loader(...params)
				.then(cupboard => {
					cupboard.getAll(true)
						.forEach((section, index) => section.index = index);

					return cupboard;
				})
			);

			return loadable;
		},
		[shop.id]
	);
	const {/** @type {CatalogCupboard} */ value: cupboard, loading, load: reloadSections} = loadable;

	const [editionVersion, reset] = use.version();
	const edition = use.memo(() => cupboard?.copy({sections: true}),
		[cupboard, editionVersion]
	);
	use.subscribe(edition?.onPropertyChangedSync, [edition]);

	const edited = edition && !cupboard?.equalsTo(edition);

	// --- validation ---

	function creationCheck(name) {
		name = name?.trim();

		if (name && (name.last !== '/') && edition?.getSection(name))
			return `Cette section éxiste déjâ.`;
	}

	function updateCheck(section, current) {
		if (current.name !== section.name)
			return creationCheck(section.name);
	}

	// ---- updates ---
	function add(name) {
		const section = new CatalogSection(name);
		const lastIndex = edition.getLastIndex() || -1;
		section.index = lastIndex + 1;
		edition.add([section]);
	}

	//---

	const [submitting, setSubmittingTo] = use.state.bool(false);
	const toast = useToast();

	function submit() {
		setSubmittingTo(true);

		strictAPI(Server.retailer.catalog.sections.persist)(edition)
			.then(cupboard => {
				loadable.setValue(cupboard);
				toast(`✅  Sauvegardé!`);
			})
			.catch(error => {
				console.error(error);
				toast(`❌  ` + error.message);
			})
			.finally(setSubmittingTo.false);
	}

	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.layout);

	return (
		<FragmentLayout {...props}>
			<Head style={localStyles.head}>
				{`Catégories`}
			</Head>

			<NewSectionForm
				whatsWrong={creationCheck}
				onSubmitted={add}
			/>


			{
				edition ?
					<>
						<SectionList
							cupboard={edition}
							creationCheck={creationCheck}
							updateCheck={updateCheck}
							style={localStyles.listSection}
						/>

						{
							edited &&
							<RNView style={localStyles.submit.layout}>
								<Button
									onPress={submit}
									loading={submitting}
									style={localStyles.submit.main}>
									{`Enregistrer`}
								</Button>

								{
									!submitting &&
									<SimpleButton onPress={reset}>
										{`Annuler`}
									</SimpleButton>
								}
							</RNView>
						}
					</> :
					<>
						<Text>
							{/* loading or error */}
							{
								loading ?
									`Chargment des sections...` :
									`${strings.onErrorWhileLoading}.`
							}

							{/* reload */}
							{
								!loading &&
								<Text
									onPress={reloadSections}
									style={{color: styles.color.azure2}}>
									{`   `}{strings.reload}
								</Text>
							}
						</Text>
					</>
			}

		</FragmentLayout>
	);
}

export default React.memo(CatalogSectionsUI);


const localStyles = {
	layout: {
		padding: 35,
	},

	head: {
		alignSelf: styles.alignSelf.flexStart,
		borderBottomWidth: 0,
	},

	listSection: {
		marginBottom: 60,
	},

	submit: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
		},

		main: {
			marginRight: 20,
		}
	}
};
