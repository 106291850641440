import moment from "moment";
import React from "react";
import { StyleSheet, View } from "react-native";
import Image from "../../../library-react/component/Image";
import StateView from "../../../library-react/component/OrderStateView";
import Portal from "../../../library-react/component/Portal";
import Row, { RowProps } from "../../../library-react/component/Row";
import use from "../../../library-react/hook";
import { gql, Order_V2 } from "../../../library-react/library-js/app/graphql";
import { images, styles } from "../../../res";
import screens from "../../screens";
import OrderRowHeader from "./Header";
import rowStyles, { defaultColumnsStyles, noUserColumnStyles } from "./styles";

function OrderRow_core({ item: order, noUser, ...props }: OrderRowProps) {
	const user = order.userByUserId;

	const columnsStyles = noUser ? noUserColumnStyles : defaultColumnsStyles;
	props.style = use.defaultStyle(props.style, localStyles.layout);
	const { Text } = use.theme();
	return (
		<Portal to={order && screens.order} params={{ id: order.id }}>
			<Row {...props} columnsStyles={columnsStyles}>
				{
					order &&
					<>
						{/*User*/}
						<View style={localStyles.user}>
							{
								!noUser &&
								<>
									<Image
										source={{ uri: user?.photo || undefined }}
										defaultSource={images.interlocutor}
										style={localStyles.picture}
									/>
									<View>
										<Text
											numberOfLines={1}
											style={localStyles.name}>
											{user?.name || order.customerName}
										</Text>
										<Text
											numberOfLines={1}
											style={localStyles.email}>
											{user?.email || order.customerEmail || order.customerPhone}
										</Text>
									</View>
								</>
							}
						</View>


						{/* ID */}
						<Text style={localStyles.text}>
							{order.id}
						</Text>

						{/* Date */}
						<Text style={localStyles.text}>
							{moment(order.creationDate).format("DD/MM/YYYY")}
						</Text>

						{/* Nb of items */}
						<Text style={localStyles.text}>
							{order.items_aggregate.aggregate?.count}
						</Text>

						{/* price */}
						<Text style={localStyles.text}>
							{order.items_aggregate.aggregate?.sum?.totalPrice}
						</Text>

						{/* state */}
						<StateView
							state={order.currentState?.state}
							displayText
							background
							innerStyles={{
								text: { fontSize: 15 }
							}}
							style={localStyles.state} />
					</>
				}
			</Row>
		</Portal>
	);
}

interface OrderRowProps extends Omit<RowProps, "columnsStyles"> {
	item: Order_V2,
	noUser?: any,
}

const OrderRow = React.memo(OrderRow_core);
export default OrderRow;

export { OrderRowHeader };

export const ORDER_ROW_FRAGMENT = gql`fragment proweb_OrderRow on order_v2 {
	id
	userByUserId { id name email photo }
	items_aggregate { aggregate { count sum { totalPrice } } }
	currentState { state }
	creationDate
}`;

const localStyles = StyleSheet.create({
	layout: {
		...rowStyles.row,
		borderTopWidth: .5,
		borderTopColor: styles.color.background,
	},

	hoverLayout: {
		...styles.shadow,
		backgroundColor: styles.color.lightBackground,
		borderBottomColor: styles.color.transparent,
		borderLeftColor: styles.color.aquaMarine,
		// replace margin with padding
		marginHorizontal: 0,
		paddingHorizontal: 3,
	},

	user: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
	},



	picture: {
		width: 60,
		height: 60,
		borderRadius: 30,
		marginRight: 20,
		backgroundColor: styles.color.background,
		...styles.newShadow(0, 3, 6, 0.16),
	},

	name: {
		color: styles.color.azure2,
		bold: true,
		fontSize: 17,
	},
	email: {
		fontSize: 16,
		color: styles.color.main,
	},

	text: {
		fontSize: 19,
		color: styles.color.main,
	},

	state: {
		paddingVertical: 9,
		width: 180,
	},
});