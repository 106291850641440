import React from "react";
import Environment from "../library-js/Environment";
import View from "./View";
import Image from "./Image";
import styles from "../res/styles";
import is from "../library-js/utils/is";
import use from "../hook";


export default Environment.select({
	web: Image,

	default: React.memo(
		function ImagePlaceholder({source, defaultSource, resizeMode, ...props}) {
			const [ready, setReady] = use.state(false);
			const itsReady = use.callback(() => setReady(true));

			use.effect(
				() => setReady(false),
				[retrieveUri(source)]
			);

			props.style = use.defaultStyle(props.style, localStyles.layout);

			return (
				<View {...props}>
					{
						Boolean(defaultSource) &&
						(!ready || !retrieveUri(source)) &&
						<Image source={defaultSource}
							   style={styles.fit}
							   resizeMode={resizeMode}
						/>
					}

					{/* Workaround for  static image in absolute position https://github.com/facebook/react-native/issues/21064 */}
					<Image
						source={source}
						resizeMode={resizeMode}
						onLoad={itsReady}
						style={localStyles.image(ready)}
					/>
				</View>
			);
		}
	),
})

const retrieveUri = source => isSourceOpaque(source) ? source : source.uri;

/**
 * Defined by react native, an opaque source is a source returned by the require function (used for static assets).
 */
const isSourceOpaque = Environment.select({
	web: source => is(source, String, true),
	mobile: source => is(source, Number, true),
});


const localStyles = {
	layout: {overflow: styles.overflow.hidden},

	image: styles.static.bool(
		styles.fit,
		null,
		styles.invisible,
	),
};
