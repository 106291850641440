import React from "react";
import View from "library-react/component/View/v2";
import {Address as PayAddress} from "library-js/app/model/pay/mango";
import use from "library-react/hook";
import AddressLocationForm from "library-react/component/AddressLocationForm";
import DateInput from "library-react/component/input/DateInput";
import CountryInput from "library-react/component/CountryInput";
import {styles} from "@src/res";
import LabeledTextInput from "@main/ui/payment/OnlinePaymentConfiguration/input/LabeledTextInput";
import Button from "@main/component/Button";
import Birthplace from "library-js/app/model/pay/mango/Birthplace";
import UBO from "library-js/app/model/pay/mango/UBO";
import LabeledWrapper from "@main/component/LabeledWrapper";
import SimpleButton from "library-react/component/SimpleButton";

export default function UBOItem({item, add, edit, ...props}) {
	const ubo = use.memo(() => (item || new UBO()).bindSetters(), [item]);
	const [submitted, setSubmitted] = use.state.bool();
	const [loading, setLoading] = use.state.bool();

	const addUbo = async () => {
		setSubmitted(true);
		const invalidFields = ubo.getInvalidFields();
		if (invalidFields.length > 0)
			console.log("Missing fields: ", ubo.getInvalidFields())

		else if (add) {
			setLoading(true);
			await add(ubo);
			setLoading(false);
		}
	};

	const editUbo = async () => {
		setSubmitted(true);
		const invalidFields = ubo.getInvalidFields();
		if (invalidFields.length > 0)
			console.log("Missing fields: ", ubo.getInvalidFields())

		else if (edit) {
			setLoading(true);
			await edit(ubo);
			setLoading(false);
		}
	};

	const deleteUbo = async () => {
		setLoading(true);
		ubo.IsActive = false;
		await edit(ubo);
		setLoading(false);
	}

	const editable = Boolean(edit) || !item;

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<LabeledTextInput label={`Nom`}
							  placeholder={`Nom`}
							  validate={submitted && UBO.generateValidateOf.FirstName()}
							  onValueChanged={ubo.setFirstName}
							  defaultValue={ubo.FirstName}
							  editable={editable}/>
			<LabeledTextInput label={`Prenom`}
							  placeholder={`Prenom`}
							  validate={submitted && UBO.generateValidateOf.LastName()}
							  onValueChanged={ubo.setLastName}
							  defaultValue={ubo.LastName}
							  editable={editable}/>


			<AddressLocationForm
				label={'Addresse'}
				validate={submitted && UBO.generateValidateOf.Address()}
				onAddressChanged={ubo.setAddress}
				defaultValue={ubo.Address}
				editable={editable}
				TextInputComponent={LabeledTextInput}/>


			<LabeledWrapper
				label={'Date de naissance'}
				style={localStyles.labeledWrapper}>
				<DateInput
					validate={submitted && UBO.generateValidateOf.Birthday()}
					onValueChanged={value => ubo.Birthday = value / 1000}
					defaultValue={ubo.Birthday * 1000}
					editable={editable}
					style={localStyles.input}/>
			</LabeledWrapper>

			<LabeledWrapper
				label={'Nationalité'}
				style={localStyles.labeledWrapper}>
				<CountryInput
					placeholder={'Pays'}
					validate={submitted && UBO.generateValidateOf.Nationality()}
					onValueChanged={ubo.setNationality}
					defaultValue={ubo.Nationality}
					editable={editable}
					style={localStyles.input}/>
			</LabeledWrapper>


			<LabeledWrapper
				label={'Pays de naissance'}
				style={localStyles.labeledWrapper}>
				<CountryInput
					placeholder={'Pays'}
					validate={submitted && Birthplace.generateValidateOf.Country()}
					onValueChanged={value => ubo.Birthplace.Country = value}
					defaultValue={ubo.Birthplace?.Country}
					editable={editable}
					style={localStyles.input}/>
			</LabeledWrapper>


			<LabeledTextInput label={'Lieu de naissance'}
							  placeholder={'Ville'}
							  validate={submitted && Birthplace.generateValidateOf.City()}
							  onValueChanged={value => ubo.Birthplace.City = value}
							  defaultValue={ubo.Birthplace?.City}
							  editable={editable}/>
			<View style={localStyles.footer}>

				{
					(edit && !loading) &&
					<SimpleButton onPress={deleteUbo}
								  colors={{
									  primary: 'red',
									  secondary: styles.color.white,
								  }}
							style={localStyles.delete}>
						{`Supprimer`}
					</SimpleButton>
				}
				{
					editable &&
					<Button loading={loading}
							onPress={edit ? editUbo : addUbo}>
						{edit ? `Mettre à jour` : `Ajouter un bénéficiaire`}
					</Button>
				}
			</View>
		</View>
	)
}

const localStyles = {

	layout: {
		backgroundColor: styles.color.white,
		padding: 20,
	},

	labeledWrapper: {
		margin: 20,
	},
	input: {
		fontSize: 20,
	},

	delete: {
	},
	footer: {
		flexDirection: styles.flexDirection.row,
		alignSelf: styles.alignSelf.flexEnd,
	},

	state: {
		alignItems: styles.alignItems.center,
		padding: 20,
		borderRadius: 20,
		borderWidth: .2,
	}
}
