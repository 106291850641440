import { View, ViewProps } from "react-native";
import Icon from "../../../../library-react/component/Icon/v2";
import OrderContactView from "../../../../library-react/component/order/OrderContactView";
import OrderInstructionsView from "../../../../library-react/component/order/OrderInstructionsView";
import use from "../../../../library-react/hook";
import generateInvoiceURL from "../../../../library-react/library-js/app/generateInvoiceURL";
import { Order_V2 } from "../../../../library-react/library-js/app/graphql";
import { formatPrice } from "../../../../library-react/library-js/app/model/utils/price";
import getResourcesForPaymentMean from "../../../../library-react/library-js/res/getResourcesForPaymentMean";
import getResourcesForReceptionMean from "../../../../library-react/library-js/res/receptionMeans";
import Color from "../../../../library-react/library-js/utils/Color";

export default function OrderUIFooter({ order, ...props }: OrderUIFooterProps) {
	const total = order.items_aggregate.aggregate?.sum?.totalPrice!;
	const currency = order.items[0].currency!;
	const hasPayment = order.payments.some(({ status, amount }) => amount! >= total! * 100);

	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>

			<OrderContactView
				order={order}
				phoneClickable
				addressClickable
				style={{ maxWidth: '30%' }} />
			{
				Boolean(order.instructions) &&
				<OrderInstructionsView
					style={{ flexBasis: '33%', flexShrink: 1 }}>
					{order.instructions}
				</OrderInstructionsView>
			}


			<View style={localStyles.bill.layout}>

				{
					total &&
					[
						// [`Sous total`, originalPrice],
						// [`TVA`, taxPrice],
						[`Total`, total],
					]
						.map(([title, price], index) => (
							<View
								key={index}
								style={localStyles.bill.item.layout}>
								<Text style={localStyles.bill.item.title}>
									{title}
								</Text>

								<Text style={localStyles.bill.item.value}>
									{formatPrice(price, currency)}
								</Text>
							</View>
						))
				}
				
				<View style={localStyles.mean.layout}>
					{
						Boolean(order.receptionMean) &&
						<Text style={localStyles.mean.title}>
							{`Retrait: ${getResourcesForReceptionMean(order.receptionMean).title()}`}
						</Text>
					}
					{
						Boolean(order.paymentMean) ?
							<Text style={localStyles.mean.title}>
								{`Paiement: ${getResourcesForPaymentMean(order.paymentMean)?.title()}`}
							</Text> : (
								Boolean(order.expectedPaymentMean) &&
								<Text style={localStyles.mean.title}>
									{`Paiement prévu: ${getResourcesForPaymentMean(order.expectedPaymentMean)?.title()}`}
								</Text>
							)
					}
					{
						Boolean(hasPayment && order.paymentAlias) &&
						<Text>
							{order.paymentAlias}
						</Text>
					}
				</View>

				<PDFLink url={generateInvoiceURL(order)} />

			</View>
		</View>
	);
};

interface OrderUIFooterProps extends ViewProps {
	order: Order_V2,
}

function PDFLink({ url }: { url: string }) {
	const { Text } = use.theme();
	return (
		<View
			accessibilityRole="link"
			// @ts-ignore
			hrefAttrs={{ target: "_blank" }}
			href={url}
			target="_blank"
			style={{
				height: 40,
				minWidth: 250,
				borderRadius: 50,
				justifyContent: "space-evenly",
				alignItems: "center",
				flexDirection: "row",
				borderWidth: 1,
				borderColor: '#eb1b2b',
			}}>
			<Text style={{
				textAlign: "center",
				color: '#eb1b2b',
			}}>
				{`Télécharger la facture`}
			</Text>

			<Icon name="pdf" size={19} />
		</View>
	);
}

const localStyles = {
	layout: {
		flexDirection: "row",
		justifyContent: "space-between",
		paddingHorizontal: 80,
	},

	mean: {
		layout: {
			marginVertical: 20,
		},
		title: {
			fontSize: 16,
			marginVertical: 2,
		},
		card: {
			marginTop: 5,
			alignItems: "flex-end",
		}
	},

	startSide: {
		layout: {
			alignItems: "flex-end",
			flexShrink: 1,
			marginBottom: 40,
		},

		card: {
			layout: {
				flexDirection: "row",
				flexWrap: "wrap",
				alignItems: "flex-start",
				flexShrink: 1,
				marginBottom: 27,
			},

			title: {
				fontSize: 16,
				bold: true,
				marginRight: 22,
			},

			infos: {
				fontSize: 18,
			},
		},
	},

	bill: {
		layout: {
		},

		item: {
			layout: {
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "space-between",
				padding: 10,
				minWidth: 200,
				backgroundColor: new Color("#6c63ff").alpha(.16).toString(),
			},

			title: {
				fontSize: 16,
				bold: true,
			},

			value: {
				fontSize: 23,
				bold: true,
			}
		}
	}
} as const;