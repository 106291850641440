import React from "react"
import use from "library-react/hook"
import {styles} from "../../../res"
import View from "library-react/component/View";
import Text from "library-react/component/Text";
import Icon from "library-react/component/Icon/v2";

export default React.memo(
	function SectionView({item: section, selected, toggle: toggleCallback, tasking: taskingProp, ...props}) {
		let [tasking, setTasking] = use.state(false);
		if (taskingProp)
			tasking = true;

		const isMounted = use.isMounted();
		const toggle = use.callback(
			toggleCallback && (
			() => {
				const promise = toggleCallback(section, selected);
				if (promise instanceof Promise){
					setTasking(true);
					promise.result(() => isMounted() && setTasking(false));
				}
			}),
			[section, selected, toggleCallback]
		);

		props.onPress = !tasking ? toggle : stayFocus;


		const isRoot = !(section.path.length > 1);
		props.style = use.defaultStyle(
			props.style,
			[localStyles.layout, !isRoot && localStyles.subSectionLayout],
			[isRoot]
		);
		return (
			<View {...props}>
				<Text
					numberOfLines={1}
					style={[localStyles.text(selected), tasking && localStyles.tasking]}>
					{section.path.last}
				</Text>

				{
					!tasking &&
					<Icon
						name={selected ? "Feather/check" : "Feather/square"}
						size={20}
						style={localStyles.selected(selected)}
					/>
				}
			</View>
		);
	}
);

const stayFocus = () => 0;


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		paddingHorizontal: 30,
		paddingVertical: 15,
	},

	subSectionLayout: {
		paddingLeft: 60,
	},

	text: styles.static.bool(
		{fontSize: 18, flexGrow: 1},
		{color: styles.color.aquaMarine}
	),

	selected: styles.static.bool(
		{
			marginLeft: 5,
		},
		{
			color: styles.color.aquaMarine
		},
	),

	tasking: {
		color: styles.color.secondary,
	}
};
