import init from "library-react/init"
import Text from "library-react/component/Text"
import Icon from "library-react/component/Icon"
import styles from "../res/styles"
import fonts from "../res/fonts"
import icons from "../res/icons"
import "library-react/component/ModalFrame";
import Platform from "library-js/Platform";
import { cache } from "library-js/app/Server"
import moment from "moment"
import "moment/locale/fr"
import BooleanInput from "library-react/component/input/BooleanInput";
import AuthManager from "library-js/AuthManager";
import { ChatManager } from "library-js/app/model/chat";
import globals from "./globals";
import firebase from "library-react/Firebase"

cache.enabled = false;

// firebase before library
firebase.init({
	apiKey: globals.apiKeys.google,
	appId: "1:607058134897:web:6d71ae6c44ce69252ed8d0"
});

init({
	mode: process.env.REACT_APP_DEBUG_MODE,
	platform: Platform.retailer,
	searchKey: globals.apiKeys.algolia,
});

Text.default.style.color = styles.color.main;
Text.default.style.font = fonts.poppins;
Text.default.style.fontSize = 19;

Icon.add(icons);

moment.locale("fr");

BooleanInput.default = {
	layout: {},
	square: {
		width: 29,
		height: 29,
		borderWidth: 1,
		borderRadius: 5,
		borderColor: styles.color.main,
	},
	check: {
		width: 35,
		height: 25.7,
		color: "#3acce1",
	},
	text: {
		fontSize: 22,
		color: styles.color.main,
	},
};

// define authenticate function
AuthManager.authenticate = function (customToken) {
	// AuthManager.signOut();
	return firebase.auth().signInWithCustomToken(customToken);
};

// auto init chat manager
AuthManager.onUserChanged(user => {
	if (user)
		Promise.process(() => /* user changed */ AuthManager.user?.uid !== user.uid)
			// get shop id
			.then(() => AuthManager.getClaims())
			// init chat manager
			.then(claims => ChatManager.init(firebase, claims.shopId))
			.catch(error => console.error("A problem happened while initiating chat: ", error));
});
