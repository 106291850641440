import { Server } from "library-js/app";
import Icon from "library-react/component/Icon";
import Image from "library-react/component/Image";
import View from "library-react/component/View";
import firebase from "library-react/Firebase";
import Component from "../../../library-react/Component";
import { images, styles } from "../../../res";
import AppName from "../../component/AppName";
import DecorationSide from "./DecorationSide";
import PasswordForm from "./PasswordForm";
import SigninForm from "./SigninForm";

export default class SigninUI extends Component {
	onResetState(state, props) {
		super.onResetState(state, props);
		state.forgotPassword = false;
	}

	async signin(email, password){
		const response = await Server.retailer.auth.authenticate(email.trim().toLowerCase(), password);
		if (response.ok){
			const token = response.content;
			await firebase.auth().signInWithCustomToken(token);
		}
		else
			response.throw();
	}

	async askPassword(email){
		const response = await Server.public.utils.connectByEmail(email);
		if (!response.ok)
			response.throw();
	}

	toggleForm(){
		this.setState(state => (state.forgotPassword = !state.forgotPassword));
	}

	onRender(props, {forgotPassword}) {
		props.style.unshift(localStyles.fragment);

		return (
			<View {...props}>
				<DecorationSide />

				{/*Main side*/}
				<View style={{flex: 1, maxWidth: 867}}>
					<View style={[styles.fit, {top: "20%", alignItems: styles.alignItems.center}]}>

						{/*Center form*/}
						<View style={{maxWidth: 476}}>
							{/*Title*/}
							<View style={{
								flexDirection: styles.flexDirection.row,
								alignItems: styles.alignItems.baseline,
								marginBottom: 100,
							}}>
								{/*Logo*/}
								<Image source={images.logo}
									   resizeMode={styles.resizeMode.contain}
									   style={{width: 61, height: 71, marginRight: 15}}/>

								{/*Name*/}
								<AppName style={{height: 86, fontSize: 68}}/>

								{/*Pro*/}
								<Icon name="pro"
									  style={{marginLeft: 6, width: 71, height: 37, alignSelf: styles.alignSelf.flexEnd}}/>
							</View>

							{// form
								!forgotPassword ?
									<SigninForm onToggleForm={this.toggleForm}
												onSubmitted={this.signin}/> :

									<PasswordForm onToggleForm={this.toggleForm}
												  onSubmitted={this.askPassword}/>
							}
						</View>
					</View>

				</View>
			</View>
		);
	}
}


const localStyles = {
	fragment: {
		flexDirection: styles.flexDirection.row
	},

	logo: {
		brackets: {width: 18, height: 37}
	},
};
