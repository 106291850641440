import React from "react"
import use from "library-react/hook"
import {styles} from "../../../res"
import Image from "library-react/component/Image";
import Slot from "../Slot";
import selectFile from "library-react/utils/selectFile/index.web";
import {Public as Server} from "library-js/app/Server"
import Loading from "../Loading";

export default React.memo(
	function SingleImageInput({defaultValue, onValueChanged, placeholder, validate, ...props}) {
		const [value, setValue] = use.state(defaultValue);
		const [uploading, setUploading] = use.state(false);

		const renderTime = use.renderTime();
		use.effect(() => {
			if (onValueChanged && renderTime > 1)
				onValueChanged(value);
			},
			[value]
		);

		props.onPress = use.asyncCallback(shouldStop =>
			() => Promise.process(shouldStop)
				.then(() => selectFile("image/*", false))
				.then(file => {
					if (!file) return;

					setUploading(true);
					return Promise.process(shouldStop)
						.then(() => Server.media.uploadFile(file))
						.then(response => {
							if (response.ok)
								setValue(response.content);
							else
								response.log();
						});
				})
				.result((_, error) => {
					setUploading(false);
					if (error)
						console.warn(error);
				})
		);

		const valid = !validate || (validate instanceof Function ? validate(value) : Boolean(value));
		props.style = use.defaultStyle(props.style, localStyles.layout(valid), [valid]);
		return (
			<Slot {...props}>
				{
					uploading ?
						<Loading/>

						: value ?
						<Image
							source={value}
							resizeMode="cover"
							style={styles.fit}
						/>

						: placeholder
				}
			</Slot>
		);
	}
);

const localStyles = {
	layout: styles.static.bool(
		{
			...styles.center,
			overflow: styles.overflow.hidden,
		},
		null,
		{borderColor: styles.color.error},
	),
};
