import Server from "../../../../library-react/library-js/app/Server";
import strictAPI from "../../../../library-react/library-js/app/Server/strictAPI";
import actionFactory from "../../../../library-react/utils/action/actionFactory";

const willDeleteReservationSpanFactory = actionFactory({
	run: ({ willParams: [span] }) => strictAPI(Server.retailer.reservation.persistSpan)(span.id, null),
	confirmationModal: true,
	message: {
		success: `Service supprimé!`,
	},
});

export default willDeleteReservationSpanFactory;