import { Linking } from 'react-native'
import Debug from "../library-js/Debug"
import { is, Objects } from "../library-js/utils"
import Environment from "../library-js/Environment"
import URL from "../library-js/utils/URL"

export default {
	call(number, prompt) {
		Debug.assert(!is(number, String), "You must provide a phone number to call.", () => { number = "" });
		// prepare scheme
		let scheme = (prompt && Environment.is.android) ? Scheme.PHONE.prompt : Scheme.PHONE.normal;
		// launch
		return launch(`${scheme}${number}`);
	},

	email(to, subject, body) {
		let recipients = {};
		// ---- FORMAT INPUTS ----

		// Format recipients to be like :
		// {
		// 	to : ["email1@domain.com", "email2@domain.com", ...],
		// 	cc : ["email1@domain.com", "email2@domain.com", ...],
		// 	bcc : ["email1@domain.com", "email2@domain.com", ...],
		// }

		// to = undefined
		if (!to)
			recipients = { to: [] };

		// to = "email@domain.com"
		else if (is.primitive(to))
			recipients = { to: [to] };

		// to = ["email1@domain.com", "email2@domain.com"]
		else if (is(to, Array))
			recipients = { to };

		// to is an object
		else
			recipients = to;

		["to", "cc", "bcc"].forEach(field => {
			if (recipients[field]) {
				if (is(recipients[field], Array))
					recipients[field] = recipients[field].map(value => `${value}`);
				else
					recipients[field] = [`${recipients[field]}`];
			}
		});


		// convert subject and body into string
		subject = is(subject) ? `${subject}` : subject;
		body = is(body) ? `${body}` : body;

		let params = Objects.map(recipients, (field, array) => array.join(','));

		if (subject)
			params.subject = subject;

		if (body)
			params.subject = body;

		launch(
			Scheme.EMAIL
			+ encodeURIComponent(recipients.to.join(','))
			+ '?' + URL.Params.encode(params)
		)
	},

	sms(number, body) {
		Debug.assert(!is(number, String), "You must provide a phone number to call.", () => { number = "" });

		let url = Scheme.SMS + number;
		if (body)
			URL.Params.encode({ body: `${body}` });

		launch(url);
	}
}

const Scheme = {
	EMAIL: "mailto:",
	PHONE: {
		normal: "tel:",
		prompt: "telprompt:",
	},

	SMS: "sms:"
};

async function launch(url) {
	try {
		return Linking.openURL(url)
	}
	catch (error) {
		if (url.includes('telprompt')) {
			// telprompt was cancelled and Linking openURL method sees this as an error
			// it is not a true error so ignore it to prevent apps crashing
			// see https://github.com/anarchicknight/react-native-communications/issues/39
		}
		else
			throw error;
	}
}
