import ResizeObserver from "resize-observer-polyfill"
import React from 'react';
import ReactDOM from 'react-dom';

window.ResizeObserver = ResizeObserver; // set the observer globally before importing app

const App = require('./pro-app/main/App').default;
require("./web.js");


ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('app')
);
