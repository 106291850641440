import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook";
import {strings, styles} from "../../../../../../../res";
import sectionFormOn from "./sectionFormOn";
import {TextInput} from "react-native-paper";
import View from "library-react/component/View";
import Text from "library-react/component/Text";
import Icon from "library-react/component/Icon/v2";
import {ScrollView} from "react-native-web";

export default sectionFormOn(
	({submit, cupboard}) => {
		const [parentSection, setParentSection] = use.state(null);

		const [hasValue, setHasValue] = use.state();
		const onTextChange = use.callback(value => setHasValue(Boolean(value)));

		const onSubmitted = use.callback(
			event => {
				const name = `${parentSection}/${event.target.value}`;
				submit(name);
			},
			[parentSection, submit],
		);

		return (
			<RNView style={localStyles.layout}>
				<Dropdown
					items={cupboard.getRootSections()}
					onSelectedChanged={setParentSection}
				/>

				{
					parentSection &&
					<>
						<TextInput
							dense
							label={`Nom de la nouvelle sous section`}
							onSubmitEditing={onSubmitted}
							onChangeText={onTextChange}
							style={localStyles.input}
						/>

						{
							hasValue &&
							<Text style={localStyles.indication}>
								{`Tapez "Entrer" pour ajouter la sous section.`}
							</Text>
						}
					</>
				}
			</RNView>
		);
	},
	true
);

function Dropdown({items, onSelectedChanged}) {
	const [opened, setOpened] = use.state(false);
	const [selected, setSelected] = use.state(null);
	use.onChange(onSelectedChanged, [selected]);

	use.effect(
		!items.includes(selected) &&
		(() => setSelected(null)),
		[selected, ...items]
	);


	return (
		<View
			onPress={() => setOpened(true)}
			onBlur={() => setTimeout(() => setOpened(false), 100)}
			style={localStyles.dropdown.layout}>
			<Text
				numberOfLines={1}
				style={localStyles.dropdown.selected}>
				{
					!selected ? `Sélectionnez une section parente` :
						selected.path.last
				}
			</Text>

			<Icon
				name="MaterialIcons/arrow-drop-down"
				size={18}
				style={localStyles.dropdown.icon}
			/>

			{
				opened &&
				<ScrollView style={localStyles.dropdown.window.layout}>
					{items.map(item => (
						<View
							key={item.name}
							onPress={() => setSelected(item)}
							style={localStyles.dropdown.window.item}>
							<Text>
								{item.name}
							</Text>
						</View>
					))}
				</ScrollView>
			}
		</View>
	);
}


const localStyles = {
	layout: {
		width: 280
	},

	input: {
		width: "100%",
	},

	indication: {
		fontSize: 12,
	},

	dropdown: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			paddingBottom: 5,
			borderBottomWidth: .5,
			borderColor: styles.color.secondary,
			marginBottom: 15,
			zIndex: 1,
		},

		selected: {
			flex: 1,
			marginRight: 13,
		},

		icon: {
			color: styles.color.secondary,
		},

		window: {
			layout: {
				position: styles.position.absolute,
				top: 0,
				left: 0,
				right: 0,

				maxHeight: 300,
				...styles.newShadow(0, 3, 8, .16),
				backgroundColor: styles.color.white,
			},

			item: {
				paddingVertical: 18,
				marginLeft: 15,
				marginRight: 10,
				borderBottomWidth: .5,
				borderColor: styles.color.lightgrey,
			},
		}
	}
};
