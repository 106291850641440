import TransactionState from "../app/model/entity/Transaction/State";

export default function getResourcesForTransactionState(status) {
	status = (status instanceof TransactionState) ? status : TransactionState.from(status);
	if (status) {
		const resources = status.select(RESOURCES);
		return RESOURCES_KEYS.reduce((copy, key) => {
			const resource = resources[key];
			copy[key] = (resource instanceof Function) ? resource : () => resource;
			return copy;
		}, {});
	}
}

const RESOURCES_KEYS = ['title', 'color'];

const RESOURCES = {
	created: {
		title: `En attente`,
		color: '#5e6383',
	},

	succeeded: {
		title: 'Réussie',
		color: '#6ac259',
	},

	failed: {
		title: `Echouée`,
		color: '#eb1b2b',
	},
};
