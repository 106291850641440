import use from "../../../library-react/hook";
import useMe from "../../../library-react/hook/pro/useMe";
import isEligibleForReservations from "../../../library-react/library-js/app/isEligibleForReservations";
import Server from "../../../library-react/library-js/app/Server";
import ReservationRow from "../reservation/ReservationsListUI/ReservationRow";
import { ClientUITab } from "./TabsSection";

export default function useReservationsTab(userId: string): ClientUITab<any> | undefined {
	const { shop } = useMe().shop;
	const iterator = use.infiniteIterator(() =>
		isEligibleForReservations(shop.categoryId) &&
		Server.retailer.reservation.getList.getIterator(shop.id, { userId, desc: true }),
		[userId],
	);

	if (!iterator) return;

	const numberOfRelevantReservations = iterator.items?.filter((reservation: any) => reservation.isCancellable).length;
	const badge = !(numberOfRelevantReservations > 0) ? undefined
		: numberOfRelevantReservations > 9 ? "9+"
			: String(numberOfRelevantReservations);

	return {
		icon: 'reservation-restaurant',
		title: 'RESERVATIONS',
		items: iterator.items,
		loading: iterator.loading,
		error: iterator.last?.error,
		renderItem(item, index) {
			return (
				<ReservationRow
					key={item.id}
					item={item}
					displayDate
					style={{ zIndex: length - index }} />
			);
		},
		loadMore: iterator.loadNextPage,
		badge,
	};
}