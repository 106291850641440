import React from "react"
import ModalFrame from "library-react/component/ModalFrame";
import Text from "library-react/component/Text"
import {strings, styles} from "../../../res";
import use from "library-react/hook";
import StarsView from "../../component/StarsView";
import TextInput from "library-react/component/input/TextInput";
import Loading from "../../component/Loading";
import ComponentUtils from "library-react/ComponentUtils";
import Evaluation from "library-js/app/model/view/retailer/Evaluation";
import {retailer as Server} from "library-js/app/Server";

export default React.memo(({userId, evaluation, onSubmitted, ...props}) => {
	const forceUpdate = use.forceUpdate();
	const [persisting, setPersisting] = use.state(false);
	const [error, setError] = use.state(false);


	evaluation = use.memo(() => evaluation ? evaluation.clone() : new Evaluation(), [evaluation]);

	const submit = use.callback(async () => {
		setPersisting(true);
		setError(false);

		let response = await Server.client.evaluate(userId, evaluation);

		if (response.ok)
			onSubmitted(evaluation);
		else
			setError(true);

		setPersisting(false);
	}, [evaluation]);

	props.style = use.defaultStyle(props.style, {padding: 40, width: 600, maxWidth: "100%"});

	return (
		<ModalFrame.Dialog {...props}>
			<Text style={localStyles.title}>
				{strings.fragment.client.user.EvaluateDialog.title}
			</Text>

			<StarsView
				editable={!persisting}
				stars={evaluation.comment.stars}
				onStarsChanged={use.callback(stars => {
					evaluation.comment.stars = stars;
					forceUpdate();
				})}
			/>

			<TextInput
				value={evaluation.comment.text}
				onValueChanged={use.callback(text => {
					evaluation.comment.text = text;
					forceUpdate();
				})}
				placeholder="Écrire une note personnelle..."
				multiline
				editable={!persisting}
				style={localStyles.textInput} />

			{
				error &&
				<Text style={{color: styles.color.error, marginVertical: 5}}>
					{strings.onError}
				</Text>
			}

			{
				persisting ? <Loading style={localStyles.submit[persisting]} />
					: ( // Submit
						<Text
							onPress={submit}
							style={localStyles.submit[persisting]}>
							{strings.validate}
						</Text>
					)
			}
		</ModalFrame.Dialog>
	);
});

const localStyles = {
	title: {
		fontSize: 18,
		bold: true,
		marginBottom: 20,
	},

	textInput: {
		marginTop: 60,
		height: 100,
		borderBottomWidth: 1,
		borderBottomColor: styles.color.background,
		fontSize: 16,
	},

	submit: ComponentUtils.staticStateStyles(
		{
			marginTop: 68,
			alignSelf: styles.alignSelf.flexEnd,
		},
		{
			true: {
				height: 30,
				width: 30,
			},
			false: {
				paddingHorizontal: 30,
				paddingVertical: 5,
				borderRadius: 50,
				backgroundColor: styles.color.azure2,
				color: styles.color.white,
			},
		}
	),
};
