import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "../../res"
import Switch from "library-react/component/Switch";

export default React.memo(
	function ({size = 46, ...props}) {
		props.onStyle = use.defaultStyle(props.onStyle, localStyles.onStyle);
		props.offStyle = use.defaultStyle(props.offStyle, localStyles.offStyle);
		props.triggerOnStyle = use.defaultStyle(props.triggerOnStyle, localStyles.triggerOnStyle);
		props.triggerOffStyle = use.defaultStyle(props.triggerOffStyle, localStyles.triggerOffStyle);

		props.triggerStyle = use.defaultStyle(props.triggerStyle, localStyles.triggerStyle(size), [size]);
		props.style = use.defaultStyle(props.style, localStyles.style(size), [size]);

		return <Switch {...props}/>;
	}
);

function getPadding(size){
	return 2;
}

function getHeight(size){
	return size / 2;
}


const localStyles = {
	onStyle: {backgroundColor: styles.color.azure2},
	offStyle: {backgroundColor: styles.color.background},
	triggerStyle: size => ({borderRadius: getHeight(size) - getPadding(size) * 2}),
	triggerOnStyle: {backgroundColor: styles.color.mint},
	triggerOffStyle: {backgroundColor: styles.color.main},
	style: size => ({
		justifyContent: styles.justifyContent.center,
		width: size,
		height: getHeight(size),
		padding: getPadding(size),
		borderRadius: getHeight(size) / 2,
	}),
};
