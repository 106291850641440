import React from "react"
import {ScrollView, View as RNView} from "react-native"
import use from "library-react/hook"
import PopupMenu, {PopupContext} from "library-react/component/PopupMenu";
import {styles} from "@src/res";
import ProductRow from "./ProductRow";
import {path, remove} from "ramda";
import useActionsContext from "./useActionsContext";
import PopupHeader from "./PopupHeader";

function Counter({...props}) {
	const {selection, setSelection} = useActionsContext();

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<PopupMenu
			{...props}
			alignLeft
			alignBottom
			persistAfterClick
			popupStyle={localStyles.dropdown}
			trigger={
				<Text style={localStyles.count}>
					{selection.length}
				</Text>
			}>

			<PopupHeader>
				{`Articles sélectionnés`}
			</PopupHeader>

			<ScrollView
				contentContainerStyle={{paddingBottom: 15}}
				style={{flex: 1}}>
				<Text
					onPress={() => setSelection([])}
					style={localStyles.removeAll}>
					{`Tout retirer`}
				</Text>


				{
					selection.map((vProdshop, index) =>
						<ProductRow
							key={idOf(vProdshop)}
							item={vProdshop}
							remove={() => setSelection(remove(index, 1))}
						/>
					)
				}
			</ScrollView>

		</PopupMenu>
	);
}

export default React.memo(Counter);

const idOf = path(['product', 'id']);

const localStyles = {
	layout: {
		...styles.circle(50),
		overflow: styles.overflow.overflow,
		backgroundColor: styles.color.azure,
		borderColor: "white",
		borderWidth: 3,
		...styles.center,
	},

	header: {
		flexDirection: styles.flexDirection.row,
		backgroundColor: styles.color.azure,
		height: 50
	},

	title: {
		alignSelf: styles.alignSelf.center,
		textAlign: styles.textAlign.center,
		flex: 1,
		color: 'white',
		bold: true
	},

	close: {
		color: 'white',
		paddingHorizontal: 15,
		...styles.absolute({top: 0, bottom: 0, right: 0}),
	},

	dropdown: {
		height: 500,
		width: 400,
	},

	removeAll: {
		textDecorationLine: styles.textDecorationLine.underline,
		color: 'grey',
		paddingHorizontal: 20,
		paddingVertical: 5,
		marginVertical: 5,
		textAlign: styles.textAlign.right,
		alignSelf: styles.alignSelf.flexEnd,
	},

	count: {
		bold: true,
		color: 'white',
		fontSize: 25,
	},
};
