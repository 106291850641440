import { is } from "ramda";
import React from "react";
import use from "../../hook";
import willCatchAndToast from "../willCatchAndToast";

export default function actionFactory({ run, confirmationModal, message }) {
	return function action({ toast, app, onRunning }) {
		return function willRun(...willParams) {
			return async function runAction(...eventParams) {
				const allParams = {
					app, toast,
					willParams,
					eventParams,
				};

				try {
					let ok = !confirmationModal;
					if (!ok) {
						if (!is(Function, confirmationModal))
							confirmationModal = DEFAULT_CONFIRM_MODAL;
						ok = await confirmationModal(allParams);
					}

					if (ok) {
						const promise = (async () => run(allParams))();
						promise.then(() => {
							if (message?.success)
								toast?.(message.success);
						});

						try {
							onRunning?.(promise, allParams);
						}
						catch (error) {
							console.error(error);
						}

						return promise;
					}
				}
				catch (error) {
					willCatchAndToast(toast)(error);
				}
			};
		}
	};
}

function DEFAULT_CONFIRM_MODAL({ app }) {
	return new Promise(resolve => {
		function Modal({ ...props }) {
			const { ConfirmModal } = use.theme();

			return (
				<ConfirmModal
					title={`Attention`}
					body={`Cette action est iréversible.`}
					yes={() => resolve(true)}
					no={() => resolve(false)}
					{...props}
				/>
			);
		}

		app.openModal(<Modal />)
	});
}