import Title from "@main/component/Head";
import screens from "@main/screens";
import { Server } from "library-js/app";
import ReservationState from "library-js/app/model/entity/Reservation/State";
import strictAPI from "library-js/app/Server/strictAPI";
import paragraph from "library-js/utils/function/paragraph";
import Icon from "library-react/component/Icon/v2";
import TextInput from "library-react/component/input/TextInput";
import Portal from "library-react/component/Portal";
import View from "library-react/component/View/v2";
import use from "library-react/hook";
import useMe, { useMeLoadable } from "library-react/hook/pro/useMe";
import useForceUpdate from "library-react/hook/useForceUpdate";
import useToast from "library-react/hook/useToast";
import { pathEq, remove, update } from "ramda";
import React from "react";
import { View as RNView } from "react-native";
import { styles } from "../../../../res";
import { FilterButton } from "../../order/OrdersListUI/FiltersBar";
import OrdersListFrame from "../../order/OrdersListUI/OrdersListFrame";
import DaySeparator from "./DaySeparator";
import * as icons from "./icons";
import ReservationRow from "./ReservationRow";

function ReservationsListUI({ ...props }) {
	const { shop } = useMe().shop;

	const [query, setQuery] = use.state('');
	const [showRefused, setShowRefusedTo] = use.state.bool();
	const [tab, setTab] = use.state(TABS.coming);

	const iterator = use.infiniteIterator(() => {
		const config = {
		};

		if (query)
			Object.assign(config, {
				query,
				desc: true,
			});
		else
			Object.assign(config, {
				...tab.config,
				cursor: new Date().setHours(0, 0, 0, 0).valueOf(),
				includeStates: showRefused ?
					undefined
					: [ReservationState.Type.accepted, ReservationState.Type.waiting],
			});

		return Server.retailer.reservation.getList.getIterator(shop.id, config)
			.setDelay(query ? 800 : 0);
	},
		[tab, showRefused, query],
	);
	const items = iterator.items;

	const forceUpdate = useForceUpdate();

	function handleReservationUpdate(vReservation) {
		const id = vReservation.reservation.id;
		const state = vReservation.currentState.type;

		const index = iterator.items.findIndex(pathEq(['reservation', 'id'], id));
		if (index >= 0) {
			if (state.is.refused && !showRefused) // remove the refused reservation
				iterator.items = remove(index, 1, iterator.items);
			else // replace reservation
				iterator.items = update(index, vReservation, iterator.items);
			forceUpdate();
		}
	}

	const [activating, setActivatingTo] = use.state.bool();
	const meLoadable = useMeLoadable();
	const toast = useToast();

	function activateReservations() {
		setActivatingTo(true);

		strictAPI(Server.retailer.me.getInfos)()
			.then(async me => {
				const { shop } = me.shop;
				if (!shop.acceptsReservations) {
					shop.acceptsReservations = true;
					await strictAPI(Server.retailer.me.editShop)(shop);
				}
				meLoadable.setValue(me);

				toast(`Réservations activées!`);
			})
			.finally(setActivatingTo.false);
	}


	const { Text, Spinner } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView
			{...props}>
			<OrdersListFrame
				onEndReached={iterator.loadNextPage}
				{...localStyles.scroll}>

				<>
					<RNView style={localStyles.header}>
						<RNView style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
							<Title style={localStyles.title}>
								{`Réservations`}
							</Title>

							<RNView style={localStyles.searchBar}>
								<Icon
									name="MaterialIcons/search"
									size={24}
									style={localStyles.searchIcon} />

								<TextInput
									placeholder={`Nom, prénom, téléphone, etc...`}
									value={query}
									onValueChanged={setQuery}
									style={localStyles.searchInput} />
							</RNView>
						</RNView>

						{
							activating ?
								<Text style={{ fontSize: 18 }}>
									{`Activation en cours...`}
								</Text> :

								!shop.acceptsReservations &&
								<RNView style={localStyles.warning.layout}>

									<RNView style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 5, }}>
										<Icon
											name="MaterialIcons/warning"
											size={25}
											style={localStyles.warning.icon}
										/>
										<Text style={localStyles.warning.title}>
											{`Réservations désactivées`}
										</Text>
									</RNView>

									<RNView style={localStyles.warning.body}>
										<Text style={localStyles.warning.txt}>
											{`Les réservations pour votre établissement ont été désactivées.`}
										</Text>

										<Text
											onPress={activateReservations}
											style={localStyles.warning.button}>
											{`Activer`}
										</Text>
									</RNView>
								</RNView>

						}
					</RNView>

					{
						!query &&
						<RNView style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
							<RNView style={localStyles.filters}>
								{
									[TABS.coming, TABS.past]
										.map(currentTab => (
											<FilterButton
												key={currentTab.title}
												text={currentTab.title}
												selected={tab === currentTab}
												onPress={() => setTab(currentTab)}
											/>
										))
								}

							</RNView>

							<View
								onPress={setShowRefusedTo.toggle}
								style={localStyles.refusedToggle(showRefused)}>

								{
									showRefused ?
										<Icon
											name="MaterialIcons/check"
											size={20}
											style={{ color: 'white' }}
										/> :
										<RNView
											style={[styles.circle(20), { borderWidth: 1, borderColor: 'lightgrey' }]} />
								}

								<Text style={{ marginLeft: 20, color: showRefused ? 'white' : 'black' }}>
									{`Inclure les réservations `}
									<Text style={{ color: showRefused ? 'white' : 'red' }}>
										{`refusées`}
									</Text>
								</Text>
							</View>
						</RNView>
					}
				</>

				{
					items?.map((item, index) => {
						const time = item?.reservation.time;
						const date = time && new Date(time);

						const previous = items[index - 1];

						const previousTime = previous?.reservation.time;
						const previousDate = previousTime && new Date(previousTime);

						const previousIsSameDay = date?.toLocaleDateString() ===
							(previousDate?.toLocaleDateString() || 'this string prevents undefined == undefined');

						const next = items[index + 1]
						const nextTime = next?.reservation.time;
						const nextDate = nextTime && new Date(nextTime);
						const nextIsSameDay = date?.toLocaleDateString() ===
							(nextDate?.toLocaleDateString() || 'this string prevents undefined == undefined');

						return (
							<React.Fragment key={KEY_EXTRACTOR(item, index)}>
								{
									!previousIsSameDay &&
									<DaySeparator time={time} />
								}

								<ReservationRow
									item={item}
									hasPrevious={previousIsSameDay}
									hasNext={nextIsSameDay}
									onUpdated={handleReservationUpdate}
									style={{ zIndex: 10 ** 6 - index }} />
							</React.Fragment>
						);
					})
				}

				{
					!iterator.end ?
						<Spinner size={60} style={{ alignSelf: 'center', marginVertical: 30 }} /> :
						!iterator.items.length &&
						<RNView style={{ alignItems: 'center', marginVertical: 100 }}>
							<Icon name={icons.empty} size={150} />

							<Text style={{ textAlign: 'center' }}>
								{paragraph`Vous n'avez pas de résérvations${tab === TABS.past && ` passées`}.`}
							</Text>
						</RNView>
				}
			</OrdersListFrame>

			{
				shop.acceptsReservations &&
				<Portal to={screens.newReservation}>
					<Icon
						name="MaterialIcons/add"
						size={30}
						style={{
							color: 'white',
							backgroundColor: '#ca293e',
							...styles.circle(70),
							...styles.absolute({ bottom: 30, right: 50 })
						}}
					/>
				</Portal>
			}
		</RNView>

	);
}

export default React.memo(ReservationsListUI);

const TABS = {
	coming: {
		title: `Planning`,
		config: {
			desc: false,
		},
	},

	past: {
		title: `Historique`,
		config: {
			desc: true,
		},
	},
};

const KEY_EXTRACTOR = (item, index) => String(item?.reservation.id || index);

const localStyles = {
	layout: {
		flex: 1,
	},

	scroll: {
		contentContainerStyle: {
			paddingVertical: 42,
			flexGrow: 1,
		}
	},

	header: {
		flexDirection: 'row',
		alignItems: 'flex-start',
		marginBottom: 40,
		marginHorizontal: 42,
		justifyContent: 'space-between',
	},

	title: {},

	searchBar: {
		width: 500,
		flexShrink: 1,
		marginLeft: 40,
		borderRadius: 25,
		borderWidth: 1,
		borderColor: 'lightgrey',
		flexDirection: 'row',
		overflow: 'hidden',
		height: 50,
	},

	searchIcon: {
		paddingLeft: 20,
		color: 'black',
	},

	searchInput: {
		flex: 1,
		paddingLeft: 20,
		fontSize: 18,
	},

	filters: {
		flexDirection: 'row',
		alignItems: 'center',
		marginLeft: 40,
	},

	refusedToggle: styles.static.bool({
		borderRadius: 50,
		minWidth: 130,
		overflow: styles.overflow.hidden,
		flexDirection: 'row',
		paddingHorizontal: 25,
		marginRight: 40,
		...styles.center,
	},
		{
			backgroundColor: '#de0101'
		}, {
		borderWidth: 1,
		borderColor: 'lightgrey',
	}
	),

	warning: {
		layout: {
			borderRadius: 9,
			backgroundColor: '#cd3a3a',
			padding: 10,
			width: 300,
		},

		title: {
			bold: true,
			fontSize: 16,
			color: 'white',
		},

		icon: {
			color: 'white',
			marginRight: 5,
		},

		body: {
			flexDirection: 'row',
			alignItems: 'flex-end',
			justifyContent: 'space-between',
		},

		txt: {
			color: 'white',
			fontSize: 13,
		},

		button: {
			backgroundColor: 'white',
			paddingHorizontal: 10,
			paddingVertical: 5,
			borderRadius: 5,
			color: 'black',
		},
	}
};
