import React from "react";
import styles from "../../../res/styles"
import View from "library-react/component/View/v2";
import EditButton from "../EditButton";
import use from "library-react/hook";
import ComponentUtils from "library-react/ComponentUtils";

export default function DisplayCard({ title, onEdit, children, ...props }) {
	const { Text } = use.theme();
	children = ComponentUtils.executeFunctionChildren(children, false, onEdit);

	return (
		<View {...props}>
			{/* Title and modify icon*/}
			<View style={localStyles.header.layout}>
				<Text style={localStyles.header.title}>
					{title}
				</Text>

				{/*Edit button*/}
				{
					onEdit &&
					<EditButton onPress={onEdit}
						style={localStyles.header.icon} />
				}
			</View>

			{children}
		</View>
	);
}


const localStyles = {
	header: {
		layout: {
			paddingVertical: 20,
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			justifyContent: styles.justifyContent.spaceBetween,
			borderBottomWidth: 1,
			borderColor: styles.color.separator,
		},

		title: {
			bold: true,
			marginLeft: 31,
			color: styles.color.main,
			fontSize: 20,
			lineHeight: 38,
		},

		icon: {
			marginRight: 22
		},
	},

	bottomPart: {
		flexDirection: styles.flexDirection.rowReverse,
		alignItems: styles.alignItems.center,
		marginTop: 55,
	},

	cancel: {
		fontSize: 15,
		textDecorationLine: styles.textDecorationLine.underline,
		marginRight: 60,
	}
};
