import React from "react"
import use from "library-react/hook"

function TextHolder({...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle({opacity: 0, color: 'transparent'}, props.style, [props.style]);
	return <Text {...props}/>;
}

export default React.memo(TextHolder);
