import strings from "../library-react/res/strings"

export default strings

strings.menu = {
	shop: "Ma boutique",
	product: "Catalogue",
	client: "Ma clientèle",
	order: "Mes commandes",
	website: "Mon site",
	payments: "Transferts",
	profile: "Profil",
};

// fragments

strings.fragment.signin = {
	infos: {
		copyrights: "© Shopinzon 2021",
		conditions: "Conditions générales",
		legals: "Mentions légales",
		contact: "Contact",
	},

	signinForm: {
		welcome: "Bienvenue sur l’espace pro Shopinzon,",
		error: "Identifiant ou mot de passe erroné. Merci de réessayer.",
		forgotPassword: "Mot de passe oublié ?",
		login: "Se connecter",
		name: {
			placeholder: "Identifiant",
		},
		password: {
			placeholder: "Mot de passe",
		},
	},

	passwordForm: {
		title: "Vous avez oublié votre mot de passe ?\nIl vous suffit d’indiquer l’adresse mail liée à votre compte Shopinzon",
		emailPlaceholder: "E-mail",
		submit: "Poursuivre",

		confirmation: "Vous allez recevoir un mail sous quelques minutes qui vous expliquera les instructions à suivre afin de réinitialiser votre mot de passe.",
		error: "Cette adresse email n'est pas enregistré.",
	},

	legals: {
		title: "Mentions légales",
		content: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of “de Finibus Bonorum et Malorum” (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, “Lorem ipsum dolor sit amet..”, comes from a line in section 1.10.32." + "\n"
			+ "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of “de Finibus Bonorum et Malorum” (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, “Lorem ipsum dolor sit amet..”, comes from a line in section 1.10.32." + '\n'
			+ "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of “de Finibus Bonorum et Malorum” (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, “Lorem ipsum dolor sit amet..”, comes from a line in section 1.10.32." + "\n"
			+ "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of “de Finibus Bonorum et Malorum” (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, “Lorem ipsum dolor sit amet..”, comes from a line in section 1.10.32."
	},
};

strings.fragment.chatList = {
	...strings.fragment.chatList,
	title: "Echanges clients",
};

strings.fragment.chat = {
	...strings.fragment.chat, // override
	newDeal: "Créer un nouveau deal",
	deal: {
		size: "Taille: "
	},
};

strings.fragment.shop = {
	tab: {
		infos: "Informations Boutique",
		imageManagement: "Gestion des images",
	},

	image: {
		title: "Gestionnaire d’images",
		subtitle: "Vos images serviront à alimenter toutes vos plateformes et services liés a Shopinzon",
	},

	generalInfos: {
		title: "A propos de votre boutique",
		subtitle: "Nom, description, email, telephone",
		name: "nom",
		description: "description",
		email: "email",
		phone: "telephone",
		requiredAge: "age requis",
	},

	paymentOnline: {
		LegalPersonType: "Type de personne légale",
		Name: "Nom de la société",
		HeadquartersAddress: "Adresse du siège social",
		Email: "Email de la socété",
		CompanyNumber: "Numero de la société",
		LegalRepresentativeLastName: "Nom",
		LegalRepresentativeFirstName: "Prenom",
		LegalRepresentativeCountryOfResidence: "Pays de residence",
		LegalRepresentativeNationality: "Nationalité",
		LegalRepresentativeBirthday: "Date de naissance",
		OwnerAddress: "Adresse du titulaire",
		OwnerName: "Nom complet du titulaire",
		IBAN: "IBAN du compte",
	},

	address: {
		title: "Adresse",
		subtitle: "de votre boutique"
	},

	openHours: {
		title: "Horaires",
		subtitle: "D’ouvertures",
		closed: "Fermé"
	},
	reception: {
		title: "Modalités d'achat",
		subtitle: "Moyens de retrait",
		checkoutNote: "Note pour le client lors de l'achat",
	},
	checkout: {
		title: "Encaissement",
		subtitle: "Moyens de paiement ",
	},

	social: {
		title: "Réseaux sociaux",
		subtitle: "Facebook, Twiter, Instagram …",
		noLink: "Non référencé"
	},

	medias: {
		gallery: {
			title: "Gallerie d’images",
			subtitle: "Vos images doivent être de qualité haute definition\nLes images insérées seront utilisées sur toute les plateformes gérées par Shopinzon",
			label: "Ajouter vos images"
		},
	}
};

strings.fragment.catalog = {
	title: "Mon catalogue",
	add: "Ajouter un article",
	search: "Recherche dans mon catalogue",
	loadMore: "Voir plus",
	empty: "Votre catalogue est vide.",
};

strings.fragment.clientList = {
	memberSince: "Membre depuis",
	lastBuy: "Dernier achat via l’application",
	yourRate: "Votre note",
	title: "Ma clientèle",
	search: "Entrez le nom ou l’email d’un client",
};

strings.fragment.product = {
	title: "Mon catalogue",
	remove: "Retirer du catalogue",
	edit: "Modifier le produit",
	createdOn: "Créé le ",
	addedOn: "Ajouté le ",
	add: "Ajouter",
	votes: " avis",
	gender: "Genre ",
	ageClass: "Catégorie d’age ",
	note: "Note de la boutique",
	addNote: "Ajouter une note",
	noNote: "Ecrire une note",
	description: "Description",
	sizes: "Tailles",
};

strings.fragment.addProduct = {
	generalInformation: {
		title: "Informations générales",
		subtitle: "Renseignez les informations principales de l’article a ajouter",
		mandatoryFields: "* Champs obligatoires",
	},
	additionalInformation: {
		title: "Informations supplémentaires",
		subtitle: "Informations facultatifs a completer",
	},
	measure: "Mesure",
	name: "Titre",
	principalImage: "Image principal",
	additionalImage: "Images suplementaires",
	brand: "Marque",
	price: "Prix",
	tag: "Mots clés",
	category: "Catégorie",
	reference: "Référence",
	color: "Couleur",
	gender: "Genre",
	size: "Taille",
	description: "Description",
	submit: "Valider",
};
strings.fragment.orderList = {
	billNumber: "Facture n°",
	numberOfItems: "nombre de produits",
	state: "statut",
	sum: "total",
	title: "Commandes",
	phone: "Tel.",
	noOrder: "Aucune commande",
};
strings.fragment.order = {
	title: "Facture",
	product: "Produit",
	status: "Statut",
	size: "Taille",
	paymentMethod: "Mode de paiement",
	price: "Prix",
	expire: "Exp",
	totalWithoutTaxes: "Total",
	VAT: "TVA",
	totalTaxesIncluded: "Total TTC",
	goodsReady: "Commande prête →",
	cancel: "Annuler",
	download: "Telechargez la facture",
};

strings.fragment.client = {
	user: {
		noComment: "Pas de commentaire sur la boutique.",
		writeComment: {
			true: "Changer",
			false: "Écrire une note"
		},
		evaluations: "Évaluations boutiques",
		evaluation: "Votre évaluation",
		evaluate: "Changer",
		order: "Nb de commandes",
		membership: {
			title: "Membre",
			prefix: "depuis ",
		},

		EvaluateDialog: {
			title: "Votre évaluation",
		}
	}
};


strings.fragment.social = {
	title: "Gestion des reseaux sociaux",
	nonexistent: "Votre boutique n'est pas enregistré pour ce service.\nContactez nous pour nous en faire la demande.",
};

strings.fragment.website = {
	title: "Mon site internet",
	nonexistent: "Votre boutique n'a pas de site pour le moment.\nContactez nous pour nous en faire la demande.",
};

// Component
strings.component.Card = {
	save: "Sauvegarder",
	cancel: "Annuler"
};
strings.component.AddressInput = {
	street: "Rue",
	number: "Numero de rue",
	city: "Ville",
	zipCode: "Code postal",
	country: "Pays",
};
strings.component.SizeInput = {
	size: "Taille",
	category: "Catégorie",
	addNewSize: "Ajouter ici une nouvelle taille",
};
strings.component.CategoryInput = {
	category: "Catégorie",
};
strings.component.AgeClassInput = {
	ageClass: "Catégorie d’age ",
};
strings.component.GenderInput = {
	gender: "Genre",
};
strings.component.ImageInput = {
	addPictures: "Ajouter des images",
};
strings.component.ChatButton = "Message";

strings.app = {
	error: "Une erreur s'est produite durant le chargement de la boutique."
};
