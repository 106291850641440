import React from "react"
import {Animated, View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@src/res";
import Counter from "./Counter";
import {curry} from "ramda";
import AvailableSwitch from "./AvailableSwitch";
import {Context} from "./useActionsContext";
import {Server} from "library-js/app";
import PriceButton from "./PriceButton";
import SectionsButton from "./SectionsButton";
import ProgressBar from "./ProgressBar";
import timeout from "library-js/utils/timeout";
import {parallel} from "library-js/utils/function";
import strictAPI from "library-js/app/Server/strictAPI";
import OtherOptions from "@main/ui/product/CatalogUI/ActionsBar/OtherOptions";
import paragraph from "library-js/utils/function/paragraph";

function ActionsBar({selection, setSelection, updateItems, cupboard, removeItems, updateSectionsQuantities, ...props}) {
	const [persisting, setPersisting] = use.state(/* { label: string, progress: 0->1 } */);

	const update = use.callback(curry(async (label, items) => {
		setPersisting({label});

		for (const [index, item] of items.entries()) {
			const {product, prodshop} = item;

			await persistProduct(product.id, product, prodshop)
				.catch(error => console.error({product, error}));

			try {
				setPersisting({label, progress: (index + 1) / items.length});
				update.callback?.([item]);
			} catch (error) {
				console.error(error);
			}
		}
	}));
	update.callback = updateItems;

	const context = use.memo({
		selection, setSelection,
		persisting, setPersisting,
		update,
	}, [selection, persisting]);

	const isProgress100 = persisting?.progress >= 1;
	const progressBarOpacity = use.memorise((_, av) =>
		(isProgress100 && av) || new Animated.Value(1),
		[isProgress100],
	);

	const [fadingOutProgressBar, setFadingOutProgressBar] = use.state.bool();
	use.effect(() => {
		if (isProgress100) {
			const fadeOutProgressBar = Animated.timing(progressBarOpacity, {
				toValue: .001,
				useNativeDriver: true,
				duration: 500,
			});

			const cancel = timeout(() => {
				setFadingOutProgressBar(true);

				fadeOutProgressBar.start(() => {
					setPersisting(undefined);
					setFadingOutProgressBar(false);
				});
			}, 600);

			return parallel(cancel, () => fadeOutProgressBar.stop(), setFadingOutProgressBar.false);
		}
	}, [isProgress100]);

	async function remove() {
		const label = paragraph`Retrait de ${selection.length} article${selection.length > 1 && 's'}`;
		setPersisting({label});

		for (const [index, item] of selection.entries()) {
			const {product} = item;

			await persistProduct(product.id)
				.catch(error => console.error({product, error}));

			try {
				setPersisting({label, progress: (index + 1) / selection.length});
			} catch (error) {
				console.error(error);
			}
		}

		setTimeout(removeItems, 500);
	}


	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<Context.Provider value={context}>
			<RNView {...props}>
				{
					(!persisting?.label || (isProgress100 && fadingOutProgressBar)) &&
					<RNView style={localStyles.control}>
						<Counter style={localStyles.counter}/>

						<RNView style={localStyles.actions}>
							<AvailableSwitch style={localStyles.switch}/>
							<PriceButton/>
							<SectionsButton cupboard={cupboard} updateSectionsQuantities={updateSectionsQuantities}/>
							<OtherOptions remove={remove}/>

						</RNView>
					</RNView>
				}

				<ProgressBar style={{opacity: progressBarOpacity}}/>
			</RNView>
		</Context.Provider>
	);
}

export default ActionsBar;

const persistProduct = strictAPI(Server.retailer.catalog.persist);


const localStyles = {
	layout: {
		height: 75,
		...styles.absolute({left: 0, right: 0, bottom: 0}),
		backdropFilter: `saturate(180%) blur(${50 * 0.2}px)`,
		backgroundColor: 'rgba(0,0,0,0.42)',
	},

	control: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		paddingLeft: 20,
		...styles.fit,
	},

	counter: {
		alignSelf: styles.alignSelf.center,
	},

	actions: {
		flexDirection: styles.flexDirection.row,
	},

	switch: {
		marginRight: 10,
	}
};
