import React from "react"
import use from "library-react/hook"
import {styles} from "../../../res"
import FragmentLayout from "../../component/FragmentLayout";
import PasswordForm from "@main/component/PasswordForm";

function ProfileFragment({...props}) {
	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.layout);

	return (
		<FragmentLayout {...props}>
			<PasswordForm style={localStyles.password}/>
		</FragmentLayout>
	);
}

export default React.memo(ProfileFragment);


const localStyles = {
	layout: {
		padding: 30,
		...styles.center,
	},

	password: {
		padding: 20,
		borderStyle: styles.borderStyle.dashed,
		borderWidth: 1,
		borderColor: styles.color.background,
		borderRadius: 20,

		width: 475,
		maxWidth: "100%"
	}
};
