import React from "react";
import Text from "library-react/component/Text";
import {styles, strings} from "../../res";
import use from "library-react/hook";

export default function ChatButton({...props}){
	if (!props.children)
		props.children = strings.component.ChatButton;

	props.style = use.defaultStyle(props.style, defaultStyle);

	return <Text {...props} />;
}

const defaultStyle = {
	color: styles.color.white,
	paddingHorizontal: 28,
	paddingVertical: 4,
	backgroundColor: styles.color.azure2,
	borderRadius: 50,
};
