import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "../../../../res"
import Image from "library-react/component/Image";
import View from "library-react/component/View";
import Icon from "library-react/component/Icon";

export default React.memo(
	function ImageItem({source, onDeleteClicked: deleteCallback, ...props}) {
		const onDeleteClicked = use.callback(
			() => deleteCallback && deleteCallback(source),
			[deleteCallback]
		);

		props.style = use.defaultStyle(props.style, localStyles.layout);

		return (
			<View {...props}>
				<Image
					source={source}
					resizeMode="cover"
					style={styles.fit}
				/>

				{
					({hover}) =>
						hover &&
						<RNView style={localStyles.filter.layout}>
							<Icon
								name="remove"
								onPress={onDeleteClicked}
								style={localStyles.filter.delete}
							/>
						</RNView>
				}
			</View>
		);
	}
);


const localStyles = {
	layout: {
		...styles.newShadow(0, 3, 6, .16),
	},

	filter: {
		layout: [
			styles.fit,
			styles.center,
			{backgroundColor: styles.color.darkTransparent},
		],

		delete: {
			height: 30,
			width: 30,
			color: styles.color.white,
		}
	}
};
