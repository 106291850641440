import { useNavigation, useRoute } from "@react-navigation/native";
import { Pressable, View } from "react-native";
import Icon from "../../../../library-react/component/Icon/v2";
import Portal from "../../../../library-react/component/Portal";
import use from "../../../../library-react/hook";
import { styles } from "../../../../res";
import screens from "../../../screens";

export default function OrdersOrReservationsTabs({ ...props }) {
	const currentScreen = useRoute().name;
	const { navigate } = useNavigation<any>();
	const { Text } = use.theme();
	return (
		<View {...props}>
			<View style={localStyles.switch}>
				{
					[
						{
							icon: 'Octicons/package',
							title: `Commandes`,
							screen: screens.orderList,
						},
						{
							icon: 'Octicons/calendar',
							title: `Réservations`,
							screen: screens.reservationsCalendar,
						},
					].map(({ icon, title, screen }, index) => {
						const selected = screen === currentScreen;
						return (
							<Pressable
								key={index}
								onPress={() => navigate(screen)}
								style={[localStyles.tab.layout(selected), !index && { marginRight: -20 }]}>
								<Icon
									name={icon}
									size={30}
									style={localStyles.tab.icon(selected)}
								/>

								<Text
									numberOfLines={1}
									style={localStyles.tab.title(selected)}>
									{title}
								</Text>
							</Pressable>
						);
					})
				}
			</View>

			{
				currentScreen === screens.reservationsList &&
				<Portal to={screens.reservationsCalendar}>
					<Text style={localStyles.calendarLink}>
						{`Afficher en mode calendrier ›`}
					</Text>
				</Portal>
			}
		</View>
	);
}

const localStyles = {
	switch: {
		flexDirection: "row",
		backgroundColor: "white",
		height: 80,
		width: 500,
		borderRadius: 40,
		alignSelf: 'center',
		marginVertical: 50,
	},

	tab: {
		layout: styles.static.bool({
			flexDirection: "row",
			...styles.center,
			paddingHorizontal: 20,
			borderRadius: 40,
			flex: 1,
		},
			{
				backgroundColor: styles.color.azure,
			}
		),

		icon: styles.static.bool({
			marginRight: 15,
		},
			{ color: 'white' },
			{ color: styles.color.azure },
		),

		title: styles.static.bool({
			bold: true,
			fontSize: 20,
		},
			{ color: 'white' },
			{ color: styles.color.azure },
		),
	},

	calendarLink: {
		alignSelf: 'flex-end',
		paddingHorizontal: 30,

		fontSize: 18,
		color: 'grey',
		bold: true,
	}
} as const;