import React from "react"
import use from "library-react/hook";
import sectionFormOn from "./sectionFormOn";
import {TextInput} from "react-native-paper";
import View from "library-react/component/View";
import Text from "library-react/component/Text";

export default sectionFormOn(
	function NewSectionForm({submit}) {
		const [hasValue, setHasValue] = use.state();
		const onTextChange = use.callback(value => setHasValue(Boolean(value)));

		const onSubmitted = use.callback(
			event => submit(event.target.value),
			[submit],
		);

		return (
			<View>
				<TextInput
					dense
					label={`Nom de la nouvelle section`}
					onSubmitEditing={onSubmitted}
					onChangeText={onTextChange}
					style={localStyles.layout}
				/>

				{
					hasValue &&
					<Text style={localStyles.indication}>
						{`Tapez "Entrer" pour ajouter la section.`}
					</Text>
				}

			</View>
		);
	}
);


const localStyles = {
	layout: {
		width: 250
	},

	indication: {
		fontSize: 12,
	},
};
