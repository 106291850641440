import abstractComponent from "./abstractComponent";
import use from "../../hook";
import selectFile from "../../utils/selectFile";
import Server from "../../library-js/app/Server";
import timeout from "library-js/utils/timeout";

export default abstractComponent(render => {
		return function Component({value: forcedValue, defaultValue, onValueChanged, validate, ...props}) {
			const [value, setValue] = use.state(forcedValue || defaultValue);

			use.onChange((onValueChanged && value !== forcedValue) &&
				function notify() {
					onValueChanged(value);
				},
				[value],
			);

			// set forced value
			/// Should be after onValueChanged callback
			use.syncEffect(() => {
					if (forcedValue)
						return timeout(() => setValue(forcedValue), 1000);
				},
				[value, forcedValue],
			);


			const [uploading, setUploading] = use.state(false);

			const selectImage = use.asyncCallback(shouldStop =>
				() => Promise.process(shouldStop)
					.then(() => selectFile("image/*", false))
					.then(file => {
						if (file){
							setUploading(true);
							return Promise.process(shouldStop)
								.then(() => Server.public.media.uploadFile(file))
								.then(response => {
									if (response.ok)
										setValue(response.content);
								})
								.result((_, error) => {
									setUploading(false);
									if (error)
										console.warn(error);
								})
						}
					})
			);

			let remove = use.callback(() => setValue(undefined));
			if (!value || props.notRemovable || uploading)
				remove = undefined;

			return render(
				props,
				{
					value,
					selectImage,
					uploading,
					remove,
					get valid(){
						return !validate || (validate instanceof Function ? validate(value) : Boolean(value));
					}
				},
			);
		}
	}
)
