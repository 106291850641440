import React from "react";
import { Pressable, PressableProps, StyleSheet, Text, View as RNView, ViewProps } from "react-native";
import LinearGradient from "react-native-linear-gradient";
import use from "../../../../library-react/hook";
import { styles } from "../../../../res";
import FILTERS, { Filter } from "./filters";


function FiltersBar({ filter: selectedFilter, setFilter, ...props }: FilterProps) {
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			{
				FILTERS.map((filter, index) =>
					<FilterButton
						key={index}
						text={filter.label}
						selected={filter === selectedFilter}
						onPress={() => setFilter(filter)}
					/>
				)
			}
		</RNView>
	);
}

export default React.memo(FiltersBar);

interface FilterProps extends ViewProps {
	filter: Filter,
	setFilter(filter: Filter): void,
}

export function FilterButton({ text, selected, ...props }: { text: string, selected: any } & PressableProps) {
	props.style = use.defaultStyle(props.style, localStyles.button);

	return (
		<Pressable {...props}>
			{
				selected && <LinearGradient
					style={[styles.fit, { borderRadius: BORDER_RADIUS, zIndex: -1 }]}
					colors={[styles.color.deepSkyBlue, styles.color.aquaBlue]} />
			}
			<Text style={[localStyles.text, Boolean(selected) && localStyles.selectedText]}>
				{text}
			</Text>

		</Pressable>
	);
}


const BORDER_RADIUS = 50;
const localStyles = StyleSheet.create({
	layout: {
		flexDirection: "row",
	},
	button: {
		borderRadius: BORDER_RADIUS,
		paddingHorizontal: 45,
		paddingVertical: 15,
		minWidth: 130,
		marginRight: 10,
		alignItems: "center",
		overflow: "hidden",
	},

	text: {
		fontSize: 17,
		textAlign: styles.textAlign.center,
		color: "rgba(94,99,131,0.52)",
	},

	selectedText: {
		color: styles.color.white,
	},
});