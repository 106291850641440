import View from "../../component/View/v2";
import DefaultProductItem from "@main/component/ProductItem"; // TODO FIX outside library import
import Icon from "../../component/Icon/v2";
import React from "react";
import useApp from "../../app/useApp";
import Text from "../../component/Text/v2";
import { styles } from "../../res";
import SelectProductDialog from "./SelectProductDialog"
import use from "../../hook";
import AuthManager from "../../library-js/AuthManager";
import { Objects } from "../../library-js/utils";

export default function LinkedProducts({ iterator, editedItems, onValueChanged, ...props }) {
	const app = useApp();
	use.subscribe(iterator.onStateChanged, [iterator]);

	const forceUpdate = use.forceUpdate();
	const removeProduct = use.callback(removedProduct => {
		editedItems[removedProduct.product.id] = false;
		if (onValueChanged)
			onValueChanged()
		forceUpdate();
	});

	const addProduct = use.callback(addedProduct => {
		editedItems[addedProduct.product.id] = addedProduct;
		if (onValueChanged)
			onValueChanged()
		forceUpdate();
	});

	const selectedProduct = Objects.valuesOf(editedItems).filter(Boolean);
	if (iterator?.items?.length) {
		const items = iterator.items
			.filter(({ vProdshop }) => !(vProdshop.product.id in editedItems))
			.map(({ vProdshop }) => vProdshop);
		selectedProduct.push(...items);
	}

	const selectProduct = () => app.openModal(
		<SelectProductDialog
			shopId={AuthManager.claims?.shopId}
			ProductItem={DefaultProductItem}
			hideSelectedProduct={true}
			hideProducts={selectedProduct}
			onProductSelected={addProduct}
		/>
	);

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>

			<Text style={localStyles.title}>
				{`Produits associés à la vitrine`}
			</Text>
			<View style={localStyles.items}>
				{
					selectedProduct.map(vProdshop =>
						<ProductItem key={vProdshop.product.id}
							vProdshop={vProdshop}
							isSelected={true}
							onPress={() => removeProduct(vProdshop)}
							style={localStyles.item} />
					)
				}
				<View style={[localStyles.item, localStyles.add]}
					onPress={selectProduct}>
					<Text>
						{`Ajouter des produits`}
					</Text>
					<Icon
						name={"Entypo/plus"}
						size={50}
					/>
				</View>
			</View>
		</View>
	);

}

function ProductItem({ vProdshop, isSelected, ...props }) {
	return (
		<View hoverable {...props}>
			<DefaultProductItem
				item={vProdshop}
				style={localStyles.item} />
			{
				({ hover }) => hover &&
					<View style={localStyles.imageFilter}>
						<Icon name={isSelected ? 'MaterialIcons/remove' : 'MaterialIcons/add'}
							size={50}
							style={localStyles.icon} />
					</View>
			}
		</View>
	);
}

const localStyles = {
	layout: {},
	title: {
		fontSize: 23,
		color: "#0bafee",
		flex: 1,
		marginVertical: 10,
	},
	items: {
		flexDirection: styles.flexDirection.row,
		flexWrap: styles.flexWrap.wrap,
	},
	item: {
		marginHorizontal: 5,
		width: 200,
		...styles.newShadow(0, 3, 6, .16)
	},
	add: {
		alignSelf: styles.alignSelf.center,
		minHeight: 200,
		...styles.center,
	},
	imageFilter: {
		...styles.fit,
		...styles.center,
		backgroundColor: '#0000009e',
	},
	icon: {
		color: styles.color.white,
	}
}
