import React from "react"
import View from "../View";
import use from "../../hook";
import SimpleButton from "../SimpleButton";
import ComponentUtils from "../../ComponentUtils";
import Text from "../Text";
import {strings, styles} from "../../res";

/**
 *
 * @param value Boolean[7]
 * @param onValueChanged (Boolean[7]) => void
 * @param buttonStyle Style for buttons.
 * @param textStyle Style for texts inside buttons
 * @param props
 * @returns {*}
 * @constructor
 */
export default function WeekDayInput({value, onValueChanged, colors,  buttonStyle, textStyle, startAt = 1, ...props}) {
	let [state, setState] = use.legacyState(() => ({value: defaultValue.copy()}));

	value = !value ? state.value
		: defaultValue.map((_, index) => value[index] || false);

	props.style = ComponentUtils.defaultStyle(props.style, localStyles.layout);
	colors = Object.assign({}, localStyles.colors, colors || {});
	buttonStyle = ComponentUtils.defaultStyle(buttonStyle, localStyles.buttonStyle);
	textStyle = ComponentUtils.defaultStyle(textStyle, localStyles.textStyle);

	return (
		<View {...props}>
			{
				value.map((enabled, day) => (
					<SimpleButton
						key={day}
						colors={colors}
						filled={enabled}
						// fill background with secondary color
						style={[styles.if(!enabled, {backgroundColor: colors.secondary}), buttonStyle].flatMap()}
						onPress={() => {
							let newValue = value.copy();
							newValue[day] = !enabled;

							if (onValueChanged)
								onValueChanged(newValue, value);

							setState({value: newValue});
						}}>
						<Text style={textStyle}>
							{strings.component.WeekDayInput.day[day]}
						</Text>
					</SimpleButton>
				)).reorder(0, startAt, value.length)
			}
		</View>
	);
}

const defaultValue = Array.range(7).map(() => false);

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		flexWrap: styles.flexWrap.wrap,
	},

	colors: {
		primary: styles.color.black,
		secondary: styles.color.white,
	},

	buttonStyle: [
		styles.center,
		{
			height: 52.8,
			width: 52.8,
			borderRadius: 52.8/2,
			borderWidth: .5,
			marginRight: 15,
		}
	],

	textStyle: {
		fontSize: 19
	}
};
