import React from "react"
import {images, strings, styles} from "../../../res";

import Text from "library-react/component/Text";
import View from "library-react/component/View/v2";
import ComponentUtils from "library-react/ComponentUtils";
import moment from "moment";
import Icon from "library-react/component/Icon";
import Image from "library-react/component/Image/Image";
import ChatButton from "../../component/ChatButton";
import use from "library-react/hook";
import rowStyles from "../../component/OrderRow/styles";
import StarsView from "../../component/StarsView";
import Portal from "../../../library-react/component/Portal";
import screens from "../../screens";
import useApp from "../../../library-react/app/useApp";

const ClientRow = React.memo(function ({client, ...props}) {
	let {vUser, lastPurchaseDate, membership} = client;
	let {user, rate} = vUser;

	const app = useApp();
	const onChatClicked = use.callback(user && (() => app.openChatWith(user?.id)), [user?.id])


	props.style = use.defaultStyle(props.style, [localStyles.layout, localStyles.item]);
	props.hoverStyle = use.defaultStyle(props.hoverStyle, localStyles.itemHover);

	return (
		<Portal to={user && screens.client} params={{id: user.id}}>
			<View {...props}>
				<View style={localStyles.membership.layout}>
					{membership && <Icon style={localStyles.membership.icon} name='membership'/>}
				</View>
				<View style={localStyles.picture.layout}>
					<Image source={user.picture}
						   defaultSource={images.interlocutor}
						   resizeMode={styles.resizeMode.cover}
						   style={localStyles.picture.item}/>
				</View>

				<View style={localStyles.infos.layout}>
					<Text style={localStyles.infos.name}>{user.name}</Text>
					<Text style={localStyles.infos.email}>{user.email}</Text>
					{
						membership &&
						<Text style={localStyles.infos.membership}>
							{strings.fragment.clientList.memberSince} {moment(membership).format("DD/MM/YYYY")}
						</Text>
					}
				</View>

				<View style={localStyles.lastBuy}>
					{lastPurchaseDate && <Text>{moment(lastPurchaseDate).calendar()}</Text>}
				</View>

				<View style={localStyles.stars}>
					<StarsView
						style={styles.phantom(rate.numberOfVotes > 0, true)}
						stars={rate.stars}/>
				</View>

				<View style={localStyles.message.layout}>
					<View onPress={event => {
						event.preventDefault(); //https://github.com/necolas/react-native-web/issues/951
						onChatClicked();
					}}>
						<ChatButton/>
					</View>
				</View>
			</View>
		</Portal>
	);
});

const HeaderRow = React.memo(function ({membership, picture, infos, lastBuy, rate, message, ...props}) {
	props.style = ComponentUtils.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<View style={localStyles.membership.layout}>
				<Text style={localStyles.title}>{membership}</Text>
			</View>

			<View style={localStyles.picture.layout}>
				<Text style={localStyles.title}>{picture}</Text>
			</View>

			<View style={localStyles.infos.layout}>
				<Text style={localStyles.title}>{infos}</Text>
			</View>

			<View style={localStyles.lastBuy}>
				<Text style={localStyles.title}>{lastBuy}</Text>
			</View>

			<View style={localStyles.stars}>
				<Text style={localStyles.title}>{rate}</Text>
			</View>
			<View style={localStyles.message.layout}>
				<Text style={localStyles.title}>{message}</Text>
			</View>
		</View>
	);
});


let localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
	},
	item: [
		rowStyles.row,
		{
			borderBottomWidth: .5,
			borderBottomColor: styles.color.background,
		},
	],
	itemHover: {
		...styles.shadow,
		backgroundColor: styles.color.lightBackground,
		borderLeftColor: styles.color.aquaMarine,
	},
	membership: {
		layout: {
			alignItems: styles.alignItems.center,
			maxWidth: 57,
			minWidth: 25,
			flex: 1,
		},
		icon: {
			width: 21,
			height: 21
		}
	},
	picture: {
		layout: {
			width: 58,
			height: 58,
			alignItems: styles.alignItems.center,
		},
		item: [
			{
				backgroundColor: styles.color.lightgrey,
				borderRadius: 58 / 2,
			},
			styles.fit,
		],
	},
	infos: {
		layout: {
			flexDirection: styles.flexDirection.column,
			justifyContent: styles.alignItems.center,
			maxWidth: 270,
			padding: 20,
			flex: 1,
		},
		name: {
			fontSize: 17,
			color: styles.color.azure2
		},
		email: {
			fontSize: 16,
			color: styles.color.main
		},
		membership: {
			fontSize: 12,
			color: '#fa5b97'
		},

	},
	lastBuy: {
		justifyContent: styles.justifyContent.center,
		alignItems: styles.alignItems.center,
		maxWidth: 245,
		minWidth: 103,
		flex: 1,
	},
	stars: {
		justifyContent: styles.alignItems.center,
		alignItems: styles.alignItems.center,
		maxWidth: 200,
		padding: 10,
		flex: 1,
	},
	title: {
		fontSize: 16,
		color: styles.color.main,
		textAlign: styles.textAlign.center,
		bold: true
	},
	message: {
		layout: {
			flex: 1,
			alignItems: styles.alignItems.flexEnd,
			marginRight: 20,
		},
		button: {}
	}
};

export default {Client: ClientRow, Header: HeaderRow};


