import React from "react"
import use from "library-react/hook"
import {styles} from "../../res"
import AppBar from "../../library-react/component/AppBar/AppBar";
import View from "../../library-react/component/View/v2";
import AuthManager from "../../library-react/library-js/AuthManager";
import Icon from "../../library-react/component/Icon/v2";
import Text from "../../library-react/component/Text/v2";
import {ChatManager} from "../../library-react/library-js/app/model/chat";
import Environment from "library-js/Environment";

function MainAppBar({shouldDisplayChat, onChatClicked, ...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<AppBar {...props}>

			{
				Environment.os === 'android' &&
				<View
					href="https://play.google.com/store/apps/details?id=il.shopinzon.retailer.android"
					accessibilityRole="link"
					target="_blank"
					style={localStyles.inApp.layout}>
						<Icon name="MaterialIcons/smartphone" size={20} style={localStyles.inApp.icon} />

					<Text style={localStyles.inApp.text}>
						{`Ouvrir l'application`}
					</Text>
				</View>
			}

			{/* disconnect button */}
			<View
				hoverable
				onPress={AuthManager.signOut}
				style={localStyles.action}>
				{
					({hover}) => (
						<>
							<Icon name="SimpleLineIcons/power"
								  size={33}
								  style={{color: hover ? styles.color.aquaMarine : styles.color.azure2}}/>

							{hover && DISCONNECT_TOOLTIP}
						</>
					)
				}
			</View>

			{/* chat button */}
			{
				shouldDisplayChat &&
					<Icon
						name="chat"
						size={37}
						onPress={onChatClicked}
						style={[localStyles.action, localStyles.chat]}>
						<ChatNotification/>
					</Icon>
			}
		</AppBar>
	);
}

export default React.memo(MainAppBar);

const ChatNotification = React.memo(() => {
	const [unread, setUnreads] = use.state(0);
	use.effect(() => ChatManager.listenApp(({unread}) => setUnreads(unread)));
	return (
		(unread > 0) &&
		<View style={localStyles.notification.layout}>
			<Text numberOfLines={1}
				  style={localStyles.notification.number}>
				{unread}
			</Text>
		</View>
	);
});

const DISCONNECT_TOOLTIP = (
	<Text style={[styles.popHover, {top: "70%", right: "50%", width: 140}]}>
		{`Se deconnecter`}
	</Text>
);


const localStyles = {
	layout: {
		...styles.newShadow(0, 1, 15, .16),
		height: 97,
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.flexEnd,
		paddingRight: 28,
		zIndex: 1
	},

	inApp: {
		layout: {
			borderWidth: 1,
			borderColor: styles.color.deepSkyBlue,
			borderRadius: 25,
			alignSelf: styles.alignSelf.center,
			marginRight: 15,
			paddingVertical: 10,
			paddingHorizontal: 15,
			height: 50,
			alignItems: styles.alignItems.center,
			flexDirection: styles.flexDirection.row,
		},

		text: {
			fontSize: 20,
			color: styles.color.deepSkyBlue,
		},

		icon: {
			color: styles.color.deepSkyBlue,
			marginRight: 10,
		}
	},

	action: {
		paddingHorizontal: 20,
		justifyContent: styles.justifyContent.center,
	},

	chat: {
		color: styles.color.azure2,
	},

	notification: {
		layout: [
			styles.center,
			{
				position: styles.position.absolute,
				right: 10,
				top: 15,
				backgroundColor: 'red',
				...styles.circle(22),
			}
		],

		number: {
			color: styles.color.white,
			fontSize: 11,
			lineHeight: 15,
			textAlign: styles.textAlign.center,
		},
	}
};

