import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@src/res"
import Text from "library-react/component/Text/v2";

function Tooltip({children, ...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			<Text style={{lineHeight: '150%'}}>
				{children}
			</Text>
		</RNView>
	);
}

export default React.memo(Tooltip);


const localStyles = {
	layout: {
		...styles.absolute({bottom: '110%', right: 0}),
		backgroundColor: 'white',
		padding: 20,
		borderRadius: 8,
		...styles.newShadow(0, 3, 5, .16),
		width: 200,
	},
};
