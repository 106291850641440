import Icon from "library-react/component/Icon";
import React from "react"
import ComponentUtils from "library-react/ComponentUtils";
import styles from "../../res/styles"

/**
 * @deprecated use theme Spinner component
 */
export default function Loading({...props}){
	props.style = ComponentUtils.defaultStyle(props.style, defaultStyle);

	return <Icon name="loading" {...props}/>;
}

const defaultStyle = {
	color: styles.color.aquaBlue,
	width: 24,
	height: 24,
};
