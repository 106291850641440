import React from "react"
import {View as RNView} from "react-native"
import {images, styles} from "@src/res";
import View from "library-react/component/View/v2";
import Portal from "library-react/component/Portal";
import screens from "@main/screens";
import LoadingPlaceholder from "library-react/component/LoadingPlaceholder";
import Image from "library-react/component/Image";
import use from "library-react/hook";
import TextHolder from "library-react/component/TextHolder";
import Icon from "library-react/component/Icon/v2";
import useApp from "library-react/app/useApp";
import SelectClientModal from './SelectClientModal'

export default function UserSection({loadable, edition, user, setUser, ...props}) {
	const vReservation = loadable?.value;
	const entity = edition || vReservation?.reservation;
	const loadedUser = vReservation?.vUser?.user;

	const app = useApp();
	const selectUser = loadedUser ? undefined :
		function selectUser() {
			app.openModal(<SelectClientModal onceSelected={setUser}/>);
		};

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			<Portal to={loadedUser && screens.client} params={{id: loadedUser?.id}}>
				<View
					onPress={selectUser}
					style={{flexDirection: 'row', alignItems: 'center'}}>
					<LoadingPlaceholder
						disabled={entity}
						style={localStyles.picture}>
						{
							entity &&
							<Image
								source={user?.picture}
								defaultSource={images.interlocutor}
								resizeMode="cover"
								style={[styles.fit, {backgroundColor: 'white'}]}
							/>
						}
					</LoadingPlaceholder>

					<RNView style={localStyles.infos}>
						<LoadingPlaceholder
							disabled={entity}
							style={localStyles.nameWrapper}>
							<Text style={localStyles.name}>
								{
									user ? user.name :
										entity ? `Associer un client` :
											<TextHolder>
												Yonathan Bibas
											</TextHolder>
								}
							</Text>
						</LoadingPlaceholder>

						{
							!(entity && !user) &&
							<LoadingPlaceholder
								disabled={entity}
								style={localStyles.emailWrapper}>
								<Text
									noLinks
									style={localStyles.email}>
									{
										user?.email ||
										<TextHolder>
											ceciestunemaillong@gmail.com
										</TextHolder>
									}
								</Text>
							</LoadingPlaceholder>
						}
					</RNView>
				</View>
			</Portal>

			{
				loadedUser ?
					<Icon
						name={"chat"}
						size={40}
						onPress={() => app.openChatWith(user?.id)}
						style={localStyles.chatIcon}
					/> :
					user &&
					<View
						onPress={() => setUser(undefined)}
						style={styles.center}>
						<Text style={{marginHorizontal: 20}}>
							{`Retirer`}
						</Text>
					</View>
			}
		</RNView>
	);
}


const localStyles = {
	layout: {
		backgroundColor: '#f7f7f7',
		borderRadius: 9,
		paddingVertical: 25,
		paddingHorizontal: 35,
		flexDirection: 'row',
		justifyContent: 'space-between'
	},

	picture: {
		...styles.circle(100),
		marginRight: 30,
	},

	infos: {
		alignItems: 'flex-start',
	},

	nameWrapper: {
		borderRadius: 15,
		marginBottom: 7,
	},

	name: {
		bold: true,
		fontSize: 25,
	},

	emailWrapper: {
		borderRadius: 10,
	},

	email: {
		fontSize: 20,
	},

	chatIcon: {
		...styles.circle(100),
		backgroundColor: 'white',
		color: styles.color.azure,
		alignSelf: 'center',
	}
};
