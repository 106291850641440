import {useIsFocused} from "@react-navigation/native";
import use from "../index";

export default function useFocusTime() {
	const [focusTime, refresh] = use.version();
	const focused = useIsFocused();
	use.onChange(focused && refresh, [focused]);

	return focusTime;
}
