import React from "react";
import LegalInfoUI from "@main/ui/payment/OnlinePaymentConfiguration/LegalInfoUI";
import UBODeclarationUI from "@main/ui/payment/OnlinePaymentConfiguration/UBODeclarationUI";
import IBANBankAccountUI from "@main/ui/payment/OnlinePaymentConfiguration/IBANBankAccountUI";
import FragmentLayout from "@main/component/FragmentLayout";
import Tabs from "@main/component/Tabs";
import {Objects} from "library-js/utils";
import {styles} from "@src/res";
import use from "library-react/hook";
import {Server} from "library-js/app";
import KycDocumentsUI from "@main/ui/payment/OnlinePaymentConfiguration/KycDocumentsUI";
import KYCDocumentType from "library-js/app/model/pay/mango/KYCDocumentType";
import {identity, inc, min, pipe} from "ramda";
import LegalInfosRequired from "@main/ui/payment/OnlinePaymentConfiguration/LegalInfosRequired";
import ErrorView from "library-react/component/ErrorView";


export default function OnlinePaymentConfigurationUI({...props}) {
	let [tab, setTab] = use.state(0);

	const stateLoadable = use.loadable.server(() => Server.retailer.payment.getConfigState());

	const {
		value: loadedLegalUser,
		loading: loadingLegalUser,
		load: loadLegalUser,
		error: errorLegalUser,
	} = use.loadable.server(
		() => Server.retailer.payment.legalUser.get(),
	);

	let [legalUser, setLegalUser] = use.state(null);
	legalUser = legalUser || loadedLegalUser;

	const kycLoadables = KYCDocumentType.domains.required.toObject(
		identity,
		(type) => use.loadable.server(() => Server.retailer.payment.kyc.get(type)),
	);

	const {value: uboDeclaration, loading: loadingUbos, load: loadUbos, error: errorUbos} = use.loadable.server(
		() => Server.retailer.payment.ubo.get(),
	);

	const {
		value: ibanBankAccount,
		loading: loadingIbanBankAccount,
		load: loadIbanBankAccount,
		error: errorIbanBankAccount
	} = use.loadable.server(() => Server.retailer.payment.bankAccount.get());

	const loading = loadingLegalUser || loadingUbos || loadingIbanBankAccount || Objects.some(kycLoadables, (_, loadable) => loadable.loading);

	const error = errorLegalUser || errorUbos || errorIbanBankAccount || Objects.some(kycLoadables, (_, loadable) => loadable.error);

	const load = () => {
		if (errorLegalUser)
			loadLegalUser();

		Objects.forEach(kycLoadables,
			(_, loadable) => loadable.error && loadable.load()
		);

		if (errorUbos)
			loadUbos();

		if (errorIbanBankAccount)
			loadIbanBankAccount();
	};

	if (loading)
		tab = 4;

	if (error)
		tab = 5;

	const kycs = Objects.map(kycLoadables, (_, loadable) => loadable.value);

	const {Spinner} = use.theme();

	const next = () => setTab(pipe(inc, min(3)));
	const updateState = update => {
		if (update && stateLoadable.value) {
			const copy = stateLoadable.value.copy();
			update(copy);
			stateLoadable.setValue(copy);
		}
	};
	const commonProps = {stateLoadable, next, updateState};

	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.content);
	return (
		<FragmentLayout {...props}>
			<Tabs
				tabs={TABS}
				selected={tab}
				style={localStyles.tabs}
				onSelectedChanged={!loading && setTab}/>

			{

				loading ?
					<Spinner style={localStyles.loading}/> :


					error ?
						<ErrorView
							onLoadClicked={load}
							doNotDisplayDisconnectOption
							style={localStyles.error}/> :

						<>
							<LegalInfoUI
								{...commonProps}
								value={legalUser}
								onValueChanged={pipe(setLegalUser, next)}
								style={styles.phantom(tab === 0)}/>

							{
								legalUser ?
									<>
										<KycDocumentsUI
											{...commonProps}
											value={kycs}
											style={styles.phantom(tab === 1)}/>

										<UBODeclarationUI
											{...commonProps}
											value={uboDeclaration}
											style={styles.phantom(tab === 2)}/>

										<IBANBankAccountUI
											{...commonProps}
											value={ibanBankAccount}
											style={styles.phantom(tab === 3)}/>
									</> :

									tab !== 0 &&
									<LegalInfosRequired
										{...commonProps}
										next={() => setTab(0)}/>
							}
						</>
			}
		</FragmentLayout>
	);
}

const TABS = [
	{
		icon: "FontAwesome/id-card-o",
		title: 'Informations légales',
	},
	{
		icon: "Ionicons/document-attach-outline",
		title: 'Documents légaux',
	},
	{
		icon: "FontAwesome/users",
		title: 'Déclaration UBO',
	},
	{
		icon: "FontAwesome/bank",
		title: 'Compte banquaire',
	},
];

const localStyles = {
	content: {
		overflow: styles.overflow.hidden,
	},

	loading: {
		flex: 1,
		alignSelf: styles.alignSelf.center,
		margin: 100,
	},

	error: {
		padding: 100,
	},

	tabs: {
		borderBottomWidth: 1,
		borderColor: styles.color.separator,
	},
};
