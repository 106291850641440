import moment from "moment";
import { map, mapObjIndexed, mergeDeepRight, pipe, prop, times } from "ramda";
import React from "react";
import { Pressable, View as RNView } from "react-native";
import { Calendar } from "react-native-calendars";
import use from "../../../../hook";
import useMe from "../../../../hook/pro/useMe";
import useReservationSpans from "../../../../hook/pro/useReservationSpans";
import Server from "../../../../library-js/app/Server";
import strictAPI from "../../../../library-js/app/Server/strictAPI";
import Environment from "../../../../library-js/Environment";
import { Loadable } from "../../../../library-js/utils";
import toIsoDate from "../../../../library-js/utils/toIsoDate";
import { styles } from "../../../../res";
import Icon from "../../../Icon/v2";
import Day from "./Day";
import Label from "./Label";
import ThemeContext from "../../../../hook/useTheme/ThemeContext"; // used



export default function ReservationsCalendar({ primaryColor, day, setDay, ...props }) {
	const me = useMe();
	const shop = me?.shop?.shop;

	let markedDates = {};
	function updateDates(days) {
		if (days) {
			mapObjIndexed((config, day) =>
				markedDates[day] = mergeDeepRight(markedDates[day], config),
				days
			);
		}
	}

	// --- month reports ---
	const [monthDate, setMonthDate] = use.state(day);
	const monthReportsLoadable = use.createLoadable(shop?.id &&
		function () {
			return new Loadable(() => strictAPI(Server.retailer.reservation.getMonthReports)(shop.id, monthDate))
				.setDelay(800);
		},
		[shop?.id, monthDate]
	);
	const monthReports = monthReportsLoadable.value;

	updateDates(
		/* date: {accepted: bool, waiting: bool} */
		monthReports?.reduce((days, { day, numberOfReservationsAccepted, numberOfReservationsWaiting }) => ({
			...days,
			[day]: {
				accepted: numberOfReservationsAccepted > 0,
				waiting: numberOfReservationsWaiting > 0,
			},
		}), {})
	);
	const insight = monthReports?.reduce((insight, { numberOfReservationsWaiting, numberOfReservationsAccepted, numberOfPlacesAccepted, numberOfPlacesWaiting }) => ({
		accepted: (insight.accepted || 0) + numberOfReservationsAccepted,
		waiting: (insight.waiting || 0) + numberOfReservationsWaiting,
		places: (insight.places || 0) + numberOfPlacesAccepted + numberOfPlacesWaiting,
	}), {})

	// --- closures ---
	const closures /* Closure[] */ = useReservationSpans()?.closures;
	const closedDays /* {date: boolean} */ = closures?.reduce((days, closure) => {
		const periodDays = times(i => {
			const dayMoment = closure.getStartMoment(shop?.timezone).add(i + 1, 'days');
			return toIsoDate(dayMoment);
		}, closure.days - 1);

		return {
			...days,
			[closure.start]: true,
			...periodDays.reduce((passed, day) => ({
				...passed,
				[day]: { closed: true },
			}), {})
		};
	}, {});

	if (closedDays)
		updateDates(
			/* date: {closed: {previous: bool, next: bool}} */
			mapObjIndexed((_, day) => {
				const yesterday = toIsoDate(moment(day).subtract(1, 'day'));
				const tomorrow = toIsoDate(moment(day).add(1, 'day'));
				return {
					closed: {
						previous: closedDays[yesterday],
						next: closedDays[tomorrow],
					}
				};
			}, closedDays)
		);

	// --- selected day ----

	updateDates({ [day]: { selected: true } });

	const [calendar, setCalendar] = use.state.bool(true);

	// --- common styles ---
	updateDates(
		map(() => ({ customContainerStyle: localStyles.calendarDayContainer }), markedDates)
	);

	const willChangeMonth = delta => () => setMonthDate(day => {
		const newDayMoment = moment(day);
		newDayMoment.date(1);
		if (delta > 0)
			newDayMoment.add(delta, 'month');
		else
			newDayMoment.add(delta, 'day');

		return toIsoDate(newDayMoment);
	});

	const nextMonth = willChangeMonth(1);
	const previousMonth = willChangeMonth(-1);

	const { Text } = use.theme();

	const colorsTheme = use.memo({ colors: { primary: primaryColor } }, [primaryColor]);

	return (
		<RNView {...props}>
			<Pressable
				onPress={setCalendar.toggle}
				style={localStyles.calendarHeader}>
				<RNView style={localStyles.calendarOpener}>
					<Text style={[localStyles.month, { color: primaryColor }]}>
						{moment(monthDate).format('MMMM YYYY').firstUp}
					</Text>

					<Icon
						name={`MaterialIcons/keyboard-arrow-${calendar ? 'up' : 'down'}`}
						size={20}
						style={{ color: primaryColor }}
					/>
				</RNView>

				<RNView style={localStyles.monthInsight}>
					{
						insight?.accepted > 0 &&
						<RNView style={localStyles.reportItem}>
							<Label state="accepted" />

							<Text style={localStyles.monthReportNumber}>
								{insight.accepted}
							</Text>
						</RNView>
					}

					{
						insight?.waiting > 0 &&
						<RNView style={localStyles.reportItem}>
							<Label state="waiting" />

							<Text style={localStyles.monthReportNumber}>
								{insight.waiting}
							</Text>
						</RNView>
					}


					{
						insight?.places > 0 &&
						<RNView style={localStyles.reportItem}>
							<Icon name="reservation-restaurant" size={12} />

							<Text style={localStyles.monthReportNumber}>
								{insight.places}
							</Text>
						</RNView>
					}

					{
						monthReportsLoadable.loading &&
						<Text style={localStyles.monthReportNumber}>
							{`Chargement...`}
						</Text>
					}
				</RNView>
			</Pressable>
			{
				calendar &&
				<>
					<ThemeContext.Provider value={colorsTheme}>
						<Calendar
							onDayPress={pipe(prop('dateString'), setDay)}
							hideExtraDays
							enableSwipeMonths={Environment.is.mobile}
							markingType="period"
							dayComponent={Day}
							markedDates={markedDates}
							renderHeader={() => null}
							hideArrows
							initialDate={monthDate}
							onMonthChange={pipe(prop('dateString'), setMonthDate)}
							style={{ backgroundColor: 'transparent', paddingRight: 0, paddingLeft: 0, minHeight: 335 }}
						/>
					</ThemeContext.Provider>

					{
						Environment.is.web &&
						<RNView style={localStyles.monthArrows}>
							<Icon
								name="MaterialIcons/keyboard-arrow-left"
								size={30}
								onPress={previousMonth}
								style={styles.square(40)}
							/>
							<Icon
								name="MaterialIcons/keyboard-arrow-right"
								size={30}
								onPress={nextMonth}
								style={styles.square(40)}
							/>
						</RNView>
					}
				</>
			}


		</RNView>
	);
}

const localStyles = {
	layout: {
	},

	calendarHeader: {
		flexDirection: 'row',
		alignItems: 'center',
		alignSelf: 'center',
		width: '88%',
		paddingVertical: 20,
	},

	calendarOpener: {
		flexDirection: 'row',
		alignItems: 'center',
	},

	month: {
		fontWeight: 'bold',
		fontSize: 15,
		marginRight: 10,
	},

	monthInsight: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},

	reportItem: {
		flexDirection: 'row',
		alignItems: 'center',
		marginHorizontal: 9,
	},

	monthReportNumber: {
		fontWeight: 'bold',
		fontSize: 12,
		marginLeft: 7,
	},



	closedPeriod: {
		color: '#e5e5e5',
		textColor: 'rgb(166,166,166)',
	},

	calendarDayContainer: {
		borderRadius: 5,
	},

	monthArrows: {
		flexDirection: 'row',
		justifyContent: 'center',
		marginBottom: 20,
	}
};