import { identity } from "ramda";
import { useState } from "react";
import timeout from "../../library-js/utils/timeout";
import useMemo from "../useMemo";
import useOnChange from "../useOnChange";
import useSyncEffect from "../useSyncEffect";

export default function useInputState({ value: forcedValue, defaultValue, onValueChanged, validate }, config) {
	if (!config)
		config = {};

	config.forceValueTimeout = Number(config.forceValueTimeout);
	if (!(config.forceValueTimeout >= 0))
		config.forceValueTimeout = 100;

	const isValueForced = forcedValue != undefined;

	let [value, setValue] = useState(
		isValueForced ? forcedValue :
			defaultValue != undefined ? defaultValue :
				config?.defaultValue
	);

	const correctedValue = (config?.correctValue || identity)(value);
	const isCorrect = correctedValue === value;

	if (!isCorrect)
		setValue(value = correctedValue);


	const valid = (validate instanceof Function) ? validate(value) :
		Boolean(!validate || value);

	const memory = useMemo({});
	memory.onValueChanged = onValueChanged;
	useOnChange(
		function notify() {
			const { onValueChanged } = memory;
			if (onValueChanged && value != forcedValue)
				onValueChanged(value);
		},
		[value],
	);

	// set forced value
	/// Should be after onValueChanged callback
	useSyncEffect(() => {
		if (isValueForced)
			return timeout(() => setValue(forcedValue), config.forceValueTimeout);
	},
		[value, forcedValue],
	);

	return [value, setValue, valid];
}
