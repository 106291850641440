import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {strings, styles} from "@src/res";
import Image from "library-react/component/Image";
import View from "library-react/component/View/v2";
import Icon from "library-react/component/Icon/v2";

function ProductRow({item: {product, prodshop}, remove, ...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<RNView style={localStyles.main}>

				<RNView style={localStyles.image}>
					<Image
						source={product.mainPicture}
						resizeMode="cover"
						style={styles.fit}/>
				</RNView>

				<RNView style={localStyles.content}>
					<Text numberOfLines={1}
						  style={localStyles.name}>
						{product.fullTitle}
					</Text>
					<Text numberOfLines={1}
						  style={localStyles.price}>
						{prodshop.finalPrice?.formatted}
						{
							prodshop.oldPrice &&
							<>
								{' '}
								<Text style={localStyles.oldPrice}>
									{prodshop.oldPrice.formatted}
								</Text>
							</>
						}
					</Text>

					<Text numberOfLines={1}
						  style={localStyles.unavailable}>
						{
							prodshop.available ? strings.invisibleChar :
								`Hors ligne`
						}
					</Text>
				</RNView>
			</RNView>

			<RNView style={localStyles.actions}>
				<Icon
					name="MaterialIcons/close"
					size={30}
					onPress={remove}
					style={localStyles.remove}/>
			</RNView>

		</View>
	);
}

export default React.memo(ProductRow);


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		padding: 10,
		paddingRight: 0,
		minWidth: 300,
	},

	main: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		flex: 1,
	},

	image: {
		...styles.square(50),
		marginRight: 10,
		borderRadius: 5,
		overflow: styles.overflow.hidden,
	},

	content: {
	},

	name: {
		fontSize: 16,
	},

	price: {
		color: styles.color.azure2,
		fontSize: 14,
	},

	oldPrice: {
		fontSize: 12,
		textDecorationLine: styles.textDecorationLine.lineThrough,
		color: 'grey',
	},

	unavailable: {
		fontSize: 12,
		italic: true,
		color: "grey",
	},

	actions: {
		flexDirection: styles.flexDirection.row,
	},

	remove: {
		paddingHorizontal: 15,
		color: 'lightgrey',
	}
};
