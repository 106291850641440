import Component from "../Component";
import View from "./View";
import React from "react";
import {Functions, is} from "../library-js/utils";
import ComponentUtils from "../ComponentUtils";
import PropTypes from "prop-types"

export default class Selector extends Component {
	static propTypes = {
		/**
		 * Callback when value changed.
		 * (value, index) => void
		 */
		onValueChanged : PropTypes.func,

		/**
		 * Value to set.
		 * If value doesn't exist in any items, no items is considered as selected.
		 */
		value : PropTypes.any,

		/**
		 * Index to set.
		 * If index is out of range, no items is considered as selected.
		 */
		index : PropTypes.number,

		/**
		 * Default value set if value is undefined and no item's correspond to an undefined value.
		 */
		defaultValue : PropTypes.any,

		/**
		 * Default index selected if index is undefined, value is undefined and no item's correspond to an undefined value.
		 */
		defaultIndex : PropTypes.number
	};

	static callbacks = ["onIndexChanged", "onValueChanged"];

	static criticalProps = ["value", "index"];

	isUpdateCritic(props, oldProps, state) {
		let criticalPropHasChanged = super.isUpdateCritic(props, oldProps, state);
		if (criticalPropHasChanged) return true;

		// check items length
		let items = props.children ? props.children.filter(child => ComponentUtils.is(child, Selector.Item)) : [];
		return state.selected >= 0
			&& items.length <= state.selected;
	}

	onResetState(state, props) {
		super.onResetState(state, props);
		state.selected = props.index;
	}

	get items(){
		return (
			this.props
			&& this.props.children
			&& this.props.children.filter(child => ComponentUtils.is(child, Selector.Item))
		) || [];
	}

	get value(){
		// if value is imposed by props, returns it
		if ("value" in this.props)
			return this.props.value;

		let index = this.index;
		if (index >= 0){
			let selected = this.items[index];
			return selected && selected.props.value;
		}

		return this.props.defaultValue;
	}

	get index(){
		// if index is imposed by props, returns it
		if (is(this.props.index))
			return this.props.index;

		// if value is defined by props, try to find the index of the value by searching the items
		if ("value" in this.props) {
			let index = this.items.findIndex(item => item.props.value === this.props.value);
			return (index >= 0) ? index : undefined;
		}

		// returns index selected by user
		if (this.state.selected >= 0)
			return this.state.selected;

		if ("defaultValue" in this.props) {
			let index = this.items.findIndex(item => item.props.value === this.props.defaultValue);
			return (index >= 0) ? index : undefined;
		}

		return this.props.defaultIndex;
	}

	onItemSelected(child, index){
		this.setState({selected: index});

		if (index !== this.state.selected){
			this.fire.onValueChanged(child.props.value, index);
			this.fire.onIndexChanged(index);
		}
	}

	onRender({onValueChanged, value, index, defaultValue, defaultIndex, noLayout, ...props}, {selected}){
		let numberOfItems = 0;
		props.children = props.children.map(child => {
			if (ComponentUtils.is(child, Selector.Item)){
				let index = numberOfItems++;
				child = React.cloneElement(child, {
					key : child.key || `item${index}`,
					selected : index === this.index,
					onPress : Functions.append(child.props.onPress, () => this.onItemSelected(child, index))
				});
			}

			return child;
		});

		if (noLayout)
			return <React.Fragment children={props.children} />;

		return <View {...props}/>;
	}

	static Item = class SelectorItem extends Component {
		onRender({selected, value, ...props}){
			return <View properties={{selected}} {...props}/>;
		}
	}
}
