import React from "react"
import {View as RNView} from "react-native"
import inputComponent from "./abstract/inputComponent"
import {styles} from "../res"
import use from "../hook";

function TreeNodeDropdownInput(
	{
		roots,
		Dropdown,
		Separator,
		getParentOf,
		getChildrenOf,
		placeholder,
		value: node,
		onValueChanged: setNode,
		...props
	}
) {
	if (!getParentOf)
		getParentOf = defaultGetParentOf;
	if (!getChildrenOf)
		getChildrenOf = defaultGetChildrenOf;
	if (!Separator)
		Separator = DefaultSeparator;


	const path = getPathTo(node, getParentOf);
	const children = node && getChildrenOf(node);

	const getSiblingsOf = node => {
		if (node) {
			const parent = getParentOf(node);
			if (parent)
				return getChildrenOf(parent);
			return roots;
		}
	};

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			<Dropdown
				options={roots}
				value={path[0] || null} // setting null keeps control over the input
				onValueChanged={setNode}
				path={path}
				index={0}/>

			{
				path?.slice(1)
					.map((node, index) => {
						const siblings = getSiblingsOf(node);
						return (
							<React.Fragment key={index + 1}>
								<Separator
									previous={path[index - 1]}
									next={node}
									indexPrevious={index - 1}
									indexNext={index}/>
								<Dropdown
									options={siblings}
									value={node}
									onValueChanged={setNode}
									path={path}
									index={index + 1}/>
							</React.Fragment>
						);
					})
			}

			{
				(children?.length > 0) &&
				<React.Fragment key={path?.length}>
					<Separator
						previous={path.last}
						next={undefined}
						indexPrevious={path.lastIndex}
						indexNext={path.length}/>
					<Dropdown
						options={children}
						onValueChanged={setNode}
						path={path}
						index={path.length}/>
				</React.Fragment>
			}

		</RNView>
	);
}

export default inputComponent(TreeNodeDropdownInput);

function getPathTo(node, getParentOf) {
	const path = [];

	let previousParent = undefined;
	let parent = node;
	while (parent && parent !== previousParent) {
		path.unshift(parent);
		previousParent = parent;
		parent = getParentOf(parent);
	}

	return path;
}

const defaultGetParentOf = node => node?.parent;
const defaultGetChildrenOf = node => node?.children;
const DefaultSeparator = () => null;


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		flexWrap: styles.flexWrap.wrap,
	},
};
