import React from "react"
import StarsView from "library-react/component/StarsView";
import use from "library-react/hook";
import {styles} from "../../res";

export default function ({...props}){
	if (!props.icons)
		props.icons = "star";

	if (!props.size)
		props.size = 20;

	props.style = use.defaultStyle(props.style, {width: 140});
	props.onStyle = use.defaultStyle(props.onStyle, {color: styles.color.light});
	props.offStyle = use.defaultStyle(props.offStyle, {color: styles.color.background});

	return <StarsView {...props} />;
}
