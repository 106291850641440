import React, { useState } from "react";
import { View } from "react-native"
import Search from "library-js/Search";
import ModalFrame from "../../component/ModalFrame";
import { strings, styles } from "../../res";
import Icon from "../../component/Icon/v2";
import DefaultProductItem from "../../component/ProductItem";
import useTheme from "../useTheme";
import useMemo from "../useMemo";
import useInfiniteIterator from "../infiniteIterator";
import useForceUpdate from "../useForceUpdate";
import useCallback from "../useCallback";
import useDefaultStyle from "../useDefaultStyle";
import ScrollView from "../../component/ScrollView"

export default function SelectProductDialog(
	{
		title,
		shopId,
		hideSelectedProduct,
		ProductItem,
		onProductSelected: onSelected,
		hideProducts: hideProductsProps,
		...props
	}
) {
	const hideProducts = useMemo(() => [...hideProductsProps] || [], [hideProductsProps]);
	const [query, setQuery] = useState("");

	const iterator = useInfiniteIterator(() =>
		Search.index.prodshop.getIterator({
			query,
			available: true,
			shopId,
			exclude: hideProducts.map(vProdshop => ({ 'vProduct.product.id': vProdshop.product.id })),
			number: 50,
		}),
		[query, shopId],
	);

	const forceUpdate = useForceUpdate();
	const onProductSelected = useCallback(selectedProduct => {
		if (onSelected)
			onSelected(selectedProduct);

		if (hideSelectedProduct) {
			iterator.items = iterator.items.filter(
				vProdshop => vProdshop.product.id !== selectedProduct.product.id
			);
			if (iterator.items.length < 16) //TODO Make ScrollView onEndReached called
				iterator.load()
			forceUpdate();
			hideProducts.push(selectedProduct);
		}

	},
		[hideSelectedProduct, onSelected, hideProducts]
	);


	if (!ProductItem)
		ProductItem = DefaultProductItem;

	const { Spinner, Text, TextInput } = useTheme()

	props.style = useDefaultStyle(props.style, localStyles.layout);
	return (
		<ModalFrame.Dialog {...props}>
			<View style={localStyles.header.layout}>
				{
					<Text style={localStyles.header.title}>
						{title || `Selectionez vos produits`}
					</Text>
				}
			</View>
			<View style={localStyles.search.layout}>
				<Icon
					name="MaterialIcons/search"
					size={21}
					style={localStyles.search.icon} />

				<TextInput
					placeholder={strings.search}
					placeholderTextColor="lightgrey"
					onValueChanged={setQuery}
					style={localStyles.search.input}
				/>
			</View>
			<ScrollView
				style={localStyles.scroll.layout}
				contentContainerStyle={localStyles.scroll.content}
				onEndReachedThreshold={100}
				onEndReached={iterator?.loadNextPage}>
				<View style={localStyles.items}>
					{
						iterator?.items.map(vProdshop =>
							<ProductItem
								key={vProdshop.product.id}
								vProdshop={vProdshop}
								onPress={() => onProductSelected(vProdshop)}
								style={localStyles.item} />
						)
					}
				</View>

				{
					!iterator.end &&
					<Spinner size={40} style={localStyles.spinner} />
				}
			</ScrollView>
		</ModalFrame.Dialog>
	)
}

const localStyles = {
	layout: {
		borderRadius: 20,
		width: 1000,

		height: "90%",
		maxWidth: "100%",
		paddingBottom: 0,
		paddingHorizontal: 0,
	},
	header: {
		layout: {
			marginBottom: 10,
			paddingHorizontal: 50,
		},
		title: {
			fontSize: 23, color: "#0bafee",
		},
	},
	search: {
		layout: {
			flexDirection: styles.flexDirection.row,
			paddingHorizontal: 50,
		},
		icon: {
			color: "lightgrey",
			marginRight: 7,
			marginTop: 3,
		},
		input: {
			fontSize: 20,
			paddingBottom: 10,
			borderBottomWidth: .5,
			borderColor: "lightgrey",
			flex: 1,
		}
	},
	scroll: {
		layout: {
			flex: 1,
		},
		content: {
		},
	},
	items: {
		flexDirection: styles.flexDirection.row,
		flexWrap: styles.flexWrap.wrap,
		marginBottom: 10,
		justifyContent: 'space-evenly',
		paddingHorizontal: 50
	},
	spinner: {
		marginVertical: 100,
	},
	item: {
		width: 200,
		margin: 5,
		...styles.newShadow(0, 3, 6, .16)
	},
}
