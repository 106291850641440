import React from "react"
import useShopCategories from "library-react/hook/useShopCategories";
import TreeNodeDropdownInput from "library-react/component/TreeNodeDropdownInput";
import WebDropdown from "library-react/component/web/Dropdown";
import {compose, map, pipe, prepend, prop, whereEq} from "ramda";

export default function ShopCategoryInput({...props}) {
	const [tree = null] = useShopCategories();

	return tree && (
		<TreeNodeDropdownInput
			Dropdown={Dropdown}
			roots={tree.getRoots()}
			{...props}
		/>
	);
}

function Dropdown({options, value, onValueChanged, path, index}) {

	return (
		<WebDropdown
			placeholder={index ? `Sous catégorie` : `Catégorie`}
			value={value?.name || NO_FILTER}
			options={
				pipe(
					map(prop('name')),
					prepend(NO_FILTER)
				)(options)
			}
			onValueChanged={compose(onValueChanged,
				name => name === NO_FILTER ? path[index - 1] :
					options.find(whereEq({name})),
			)}
			style={localStyles.dropdown}
		/>
	);
}

const NO_FILTER = `Tout`;

const localStyles = {
	dropdown: {
		fontSize: 18,
		marginRight: 10,
		borderWidth: 0,
		borderBottomWidth: 1,
		padding: 5,
	}
}
