import React from "react"
import use from "library-react/hook"
import {styles} from "../../../../res"
import View from "library-react/component/View/v2";
import Image from "library-react/component/Image";
import AbstractGalleryInput from "library-react/component/abstract/GalleryInput";
import {ScrollView} from "react-native-web";
import Text from "library-react/component/Text/v2";
import {is} from "library-js/utils";

function GalleryInput({placeholder, disabled, ...props}, {value, selectImage, uploading, remove, valid}) {
	props.style = use.defaultStyle(props.style, localStyles.layout, [valid]);
	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.scroll);

	const {Spinner} = use.theme();

	return (
		<ScrollView
			horizontal
			{...props}>

			{
				!disabled &&
				<View
					onPress={!uploading && selectImage}
					style={localStyles.add.layout}>

					{
						uploading ?
							<Spinner/> :
							<Text style={localStyles.add.content(valid)}>
								+
							</Text>
					}
				</View>
			}

			{
				value.map((image, index) =>
					<View
						key={image?.url || index}
						hoverable
						style={localStyles.item.layout}>
						<Image
							source={image}
							resizeMode="contain"
							style={styles.fit}
						/>

						{
							!disabled && (
								({hover}) => (
									hover &&
									<View
										onPress={() => remove(index)}
										style={localStyles.item.remove.layout}>
										<Text style={localStyles.item.remove.content}>
											{`Retirer`}
										</Text>
									</View>
								)
							)
						}
					</View>
				)
			}

			{
				!(value?.length > 0) && (
					is(placeholder, String) ?
						<Text
							style={{bold: true, color: 'lightgrey', fontSize: 30, alignSelf: styles.alignSelf.center}}>
							{placeholder}
						</Text> : placeholder
				)
			}
		</ScrollView>
	);
}

export default React.memo(AbstractGalleryInput(GalleryInput));


const localStyles = {
	layout: {},

	scroll: {
		padding: 20,
	},

	add: {
		layout: {
			height: 100,
			width: 100,
			marginRight: 20,
			...styles.newBorder(1, styles.color.grey),
			...styles.center,
		},

		content: styles.static.bool(
			{
				bold: true,
				fontSize: 30,
			},
			{color: styles.color.grey},
			{color: "red"}
		)
	},

	item: {
		layout: {
			height: 100,
			width: 100,
			marginRight: 20,
			...styles.center,
		},

		remove: {
			layout: {
				...styles.fit,
				...styles.center,
				backgroundColor: "rgba(0,0,0,0.39)"
			},

			content: {
				color: "red",
				fontSize: 18,
			}
		}
	},
};
