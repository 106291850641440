import React from "react";
import { View as RNView } from 'react-native'
import use from "../../../../hook";
import { images, styles } from "../../../../res";
import View from "../../../View/v2";
import Image from "../../../Image"
import getResourcesForReservationState from "../../../../library-js/res/reservationState";
import Icon from "../../../Icon/v2";
import Portal from "../../../Portal";

export default function ReservationRow({ item: vReservation, reservationToPortalParams, ...props }) {
	const { reservation, vUser, currentState } = vReservation;

	const res = getResourcesForReservationState(currentState?.type);
	const color = res?.color();
	const { Text } = use.theme();

	const portalParams = reservationToPortalParams?.(reservation) || {};

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<Portal {...portalParams}>
			<View {...props}>
				<RNView style={localStyles.body}>
					<RNView style={localStyles.user}>
						<Image
							source={vUser?.user.picture}
							defaultSource={images.interlocutor}
							resizeMode="cover"
							style={localStyles.picture(color)}
						/>

						<Text
							numberOfLines={1}
							style={localStyles.name(color)}>
							{reservation.fullName || vUser?.user.name || `Nom inconnue`}
						</Text>
					</RNView>

					{
						Boolean(reservation?.instructions) &&
						<Icon
							name="Ionicons/chatbox-ellipses"
							size={16}
							style={localStyles.instructions}
						/>
					}

					<Text style={localStyles.numberOfPlaces}>
						{reservation?.numberOfPlaces}
					</Text>
				</RNView>
			</View>
		</Portal>
	)
}

const localStyles = {
	layout: {
		paddingVertical: 6,
		alignItems: 'center',
	},

	body: {
		width: '88%',
		flexDirection: 'row',
		alignItems: 'center',
	},

	user: {
		flexDirection: 'row',
		alignItems: 'center',
		flex: 1,
		backgroundColor: 'white',
	},

	picture: borderColor => ({
		...styles.circle(35),
		borderWidth: 1,
		borderColor,
		zIndex: 1,
		backgroundColor: 'white',
	}),

	name: backgroundColor => ({
		minWidth: 165,
		borderRadius: 5,
		overflow: 'hidden',
		marginLeft: -8,
		textAlign: 'center',
		padding: 4,
		marginBottom: -2.5,
		bold: true,
		color: 'white',
		flexShrink: 1,
		backgroundColor,
		fontSize: 12,
	}),


	numberOfPlaces: {
		fontSize: 14,
		marginLeft: 3,
		textAlign: 'right',
		minWidth: 30,
	},

	instructions: {
		marginHorizontal: 7,
		color: '#5e6383',
	},
};