import React from "react"
import View from "library-react/component/View";
import Text from "library-react/component/Text";
import { images, strings, styles } from "../../../res"
import use from "library-react/hook";
import Image from "library-react/component/Image/Image";
import Loading from "../../component/Loading"
import Button from "../../component/Button";
import Icon from "library-react/component/Icon";
import StaticComponent from "library-react/component/StaticComponent";
import StarsView from "../../component/StarsView";
import ChatButton from "../../component/ChatButton";
import ComponentUtils from "library-react/ComponentUtils";
import moment from "moment";
import { Communication } from "library-react/utils";
import { onSwitch } from "library-js/utils";
import ScrollView from "library-react/component/ScrollView";
import EvaluateDialog from "./EvaluateDialog";
import useApp from "../../../library-react/app/useApp";


export default function UserView({ id, loadable }) {
	const app = useApp();
	const onChatClicked = use.callback(() => app.openChatWith(id), [id])

	const forceUpdate = use.forceUpdate();
	const evaluate = use.memo((client, evaluation) => app && (
		() => {
			let close = app.openModal(
				<EvaluateDialog
					userId={id}
					evaluation={evaluation}
					onSubmitted={evaluation => {
						if (client)
							client.evaluation = evaluation;

						forceUpdate();
						close();
					}}
				/>
			);
		}
	), [
		loadable.value,
		loadable.value && loadable.value.evaluation,
		id,
	]);


	return (
		<View style={localStyles.layout}>
			{// Loading
				loadable.loading ? <Loading style={{ alignSelf: styles.alignSelf.center, marginVertical: 100, width: 40, height: 40 }} /> :

					loadable.value ? // Display
						(({ comment, evaluation, vUser: { user, rate }, membership, numberOfOrders }) =>
							<React.Fragment>
								{/*User*/}
								<View style={localStyles.user.layout}>
									{/* Picture */}
									<Image
										source={user.picture}
										defaultSource={images.interlocutor}
										style={localStyles.picture} />

									{/*	Infos */}
									<View style={localStyles.user.infos.layout}>
										{/* Name */}
										<Text style={localStyles.user.name}>
											{user.name}
										</Text>

										{/* Contacts */}
										<View style={localStyles.user.infos.contact.layout}>
											{["email", "phone"].map(contact => (
												<View key={contact}
													style={localStyles.user.infos.contact.item.layout}>
													{
														Boolean(user[contact]) &&
														<React.Fragment>
															<Icon name={contact}
																style={localStyles.user.infos.contact.item.icon} />

															<Text
																onPress={
																	onSwitch(contact)
																		.select("email", () => Communication.email(user[contact]))
																		.select("phone", () => Communication.call(user[contact]))
																		.run()
																}
																style={localStyles.user.infos.contact.item.text}>
																{user[contact]}
															</Text>
														</React.Fragment>
													}
												</View>
											))}

											{/*	Chat */}
											<ChatButton onPress={onChatClicked} />
										</View>

										{/*	User comment */}
										<Text>
											{(comment && comment.text) || (
												<Text style={{ italic: true }}>
													{strings.fragment.client.user.noComment}
												</Text>
											)}
										</Text>

										<Line />

										<Text>
											{((commentText) => (
												<React.Fragment>
													{commentText}
													<Text
														onPress={evaluate}
														style={localStyles.editText}>
														{strings.fragment.client.user.writeComment[Boolean(commentText)]}
													</Text>
												</React.Fragment>
											))(
												evaluation &&
												evaluation.comment &&
												evaluation.comment.text
											)}
										</Text>
									</View>
								</View>

								{/*	Stars & membership */}
								<ScrollView horizontal contentContainerStyle={localStyles.bottom.layout}>

									{/*	World evaluations */}
									<View style={localStyles.bottom.item.layout}>
										<Icon name="evaluations"
											style={localStyles.bottom.item.icon.other} />

										<View>
											<Text style={localStyles.bottom.item.title}>
												{strings.fragment.client.user.evaluations}
											</Text>

											<StarsView
												stars={rate.stars}
												onStyle={localStyles.bottom.item.worldStars} />
										</View>
									</View>

									{/*	Shop evaluation */}
									<View style={localStyles.bottom.item.layout}>
										<Icon name="evaluations"
											style={localStyles.bottom.item.icon.other} />

										<View>
											<Text style={localStyles.bottom.item.title}>
												{strings.fragment.client.user.evaluation}
											</Text>

											<View style={{ flexDirection: styles.flexDirection.row }}>
												<StarsView
													stars={evaluation && evaluation.comment && evaluation.comment.stars} />

												<Text
													onPress={evaluate}
													style={localStyles.editText}>
													{strings.fragment.client.user.evaluate}
												</Text>
											</View>
										</View>
									</View>

									{/*	Membership */}
									<View style={[localStyles.bottom.item.layout, styles.phantom(membership, true)]}>
										<Icon name="membership"
											style={localStyles.bottom.item.icon.membership} />

										<View>
											<Text style={localStyles.bottom.item.title}>
												{strings.fragment.client.user.membership.title}
											</Text>

											{
												membership > 0 &&
												<Text style={localStyles.bottom.membership.text}>
													{strings.fragment.client.user.membership.prefix}
													{moment(membership).format("DD/MM/YYYY")}
												</Text>
											}
										</View>
									</View>


									{/* Orders number */}
									<View style={[localStyles.bottom.item.layout, { marginRight: 45 }]}>
										<Icon name="MaterialIcons/format-list-bulleted"
											style={localStyles.bottom.item.icon.other} />

										<View>
											<Text style={localStyles.bottom.item.title}>
												{strings.fragment.client.user.order}
											</Text>

											<Text>
												{numberOfOrders || 0}
											</Text>
										</View>
									</View>
								</ScrollView>

							</React.Fragment>
						)(loadable.value)

						: ( // Error
							<View style={{
								flex: 1,
								alignItems: styles.alignItems.center,
								alignSelf: styles.alignSelf.center
							}}>
								<Text style={{ textAlign: styles.textAlign.center, marginBottom: 10 }}>
									{strings.onError}
								</Text>

								<Button onPress={loadable.load}>
									{strings.reload}
								</Button>
							</View>
						)
			}
		</View>
	);
}

const Line = new StaticComponent(
	<View style={{ height: .5, width: 100, backgroundColor: styles.color.lightgrey, marginVertical: 16 }} />
);

const localStyles = {
	layout: {
		backgroundColor: styles.color.white,
		paddingTop: 35,
		borderRadius: 20,
		marginBottom: 21,
	},

	user: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.flexStart,
			paddingBottom: 20,
			borderBottomWidth: .5,
			borderColor: styles.color.separator,
			marginHorizontal: 22,
		},

		name: {
			fontSize: 21,
			color: styles.color.azure2,
		},

		infos: {
			layout: {
				flex: 1,
			},

			contact: {
				layout: {
					flexDirection: styles.flexDirection.row,
					justifyContent: styles.justifyContent.spaceBetween,
					flexWrap: styles.flexWrap.wrap,
					marginVertical: 15,
				},

				item: {
					layout: {
						flexDirection: styles.flexDirection.row,
						alignItems: styles.alignItems.center,
					},

					icon: {
						height: 20,
						width: 20,
						color: styles.color.black,
						marginRight: 10,
					},
				},


			},
		},

	},

	picture: {
		height: 100,
		width: 100,
		borderRadius: 50,
		marginRight: 20,
		backgroundColor: styles.color.background,
	},

	editText: {
		color: styles.color.azure2,
		paddingLeft: 18,
		textDecorationLine: styles.textDecorationLine.underline,
	},

	bottom: {
		layout: {
			alignItems: styles.alignItems.center,
			justifyContent: styles.justifyContent.spaceBetween,
			paddingVertical: 22,
			flexGrow: 1,
			borderBottomWidth: .5,
			borderColor: styles.color.separator,
		},

		item: {
			layout: {
				flexDirection: styles.flexDirection.row,
				alignItems: styles.alignItems.center,
				marginLeft: 35,
			},

			icon: ComponentUtils.staticStateStyles(
				{
					width: 50,
					height: 50,
					marginRight: 12,
				},
				{
					membership: {},
					other: { opacity: .5 },
				}
			),

			title: {
				bold: true,
				marginBottom: 9,
			},

			worldStars: {
				color: ["#42b4b7", "#32739c"]
			}
		},

		membership: {
			text: {
				bold: true,
			},
		}
	}
};
