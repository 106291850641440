import React from "react";
import { View as RNView } from "react-native";
import AppBar from "./AppBar";
import { styles } from "@src/res"
import use from "library-react/hook";
import Menu, { CLOSED_MENU_WIDTH } from "./Menu";
import ChatListUI from "@main/ui/chat/ChatListUI";
import ChatUI from "@main/ui/chat/ChatUI";
import PromiseQueue from "library-react/library-js/utils/PromiseQueue";
import { appContext } from "library-react/app/useApp";
import useFocusEffect from "library-react/hook/navigation/useFocusEffect";
import Text from "library-react/component/Text/v2";
import { ActivateButton } from "@main/component/MailDialogButton";
import useMe from "library-react/hook/pro/useMe";
import URL from "library-js/utils/URL"
import { retrieveChatID } from "@main/screens";

function Frame({ children, ...props }) {
	const [chatOpened, setChatOpened] = use.state.bool(true);
	const [interlocutorId, setInterlocutorId] = use.state(null);
	setInterlocutorId.last = interlocutorId;
	const backToChatList = use.callback(() => setInterlocutorId());

	const [passChatInstance, openChat] = use.memo(() => {
		const queue = new PromiseQueue();

		return [
			queue.resolveAll.bind(queue),

			function openChat(id) {
				setChatOpened(true);
				setInterlocutorId(id);

				if (instances.chat && setInterlocutorId.last === id)
					return Promise.resolve(instances.chat);

				return queue.newPromise();
			},
		];
	});

	const instances = use.instances({
		chat: chatFragment => {
			if (chatFragment)
				passChatInstance(chatFragment);
		},
	});

	const [shouldDisplayChat, setShouldDisplayChat] = use.state();
	props.onLayout = event => setShouldDisplayChat(event.nativeEvent.layout.width >= MIN_WIDTH_FOR_SIDE_CHAT);

	const [floating, setFloating] = use.state(undefined);

	const context = use.memo(
		() => {
			return function FrameControl({ floating: floatingProp, children }) {
				const setterEffect = set => value => {
					//Set the value if different of undefined and unset it on unmount
					if (value !== undefined) {
						set(value);
						return () => set(undefined);
					}
				};

				useFocusEffect(focus => focus && setterEffect(setFloating)(floatingProp), [floatingProp]);
				return children;
			}
		},
	);
	use.effect(() => {

		const pathName = new URL(window.location.toString()).pathname;
		const chatId = retrieveChatID(pathName);
		if (chatId)
			openChat(chatId);
	}, [openChat]);

	const shop = useMe().shop.shop;

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<appContext.Consumer>
			{
				app => {
					Object.assign(app, {
						openChatWith(id, attachment) {
							openChat(id).then(chat => chat.setAttachment(attachment));
						}
					});

					return (
						<RNView {...props}>
							{
								Boolean(shop && !shop.activated && !shop.dev) &&
								<RNView style={localStyles.activation.layout}>
									<Text style={localStyles.activation.title}>
										{`Votre boutique n'est pas activé`}
									</Text>
									<ActivateButton style={localStyles.activation.button} />
								</RNView>
							}


							<AppBar
								onChatClicked={setChatOpened.toggle}
								shouldDisplayChat={shouldDisplayChat} />

							<Menu />

							{/*	main */}
							{
								(shouldDisplayChat !== undefined) &&
								<RNView style={localStyles.main}>


									{/* fragment container */}
									<FrameContext.Provider value={context}>
										<RNView style={localStyles.container}>
											{children}
											{floating}
										</RNView>

									</FrameContext.Provider>


									{/* ---  chat ---  */}
									{
										shouldDisplayChat &&
										<>
											<ChatListUI
												onSelected={setInterlocutorId}
												style={[localStyles.chat, styles.phantom(chatOpened && !interlocutorId)]}
											/>

											{
												Boolean(interlocutorId) && chatOpened &&
												<ChatUI
													key={interlocutorId}
													ref={instances.set.chat}
													id={interlocutorId}
													onBackClicked={backToChatList}
													style={localStyles.chat}
												/>
											}
										</>
									}

								</RNView>
							}
						</RNView>
					);
				}
			}
		</appContext.Consumer>
	);
}

export default Frame;

export const FrameContext = React.createContext(undefined);


const MIN_WIDTH_FOR_SIDE_CHAT = 1300;


const localStyles = {
	layout: {
		backgroundColor: styles.color.background,
		flex: 1,
	},
	activation: {
		layout: {
			backgroundColor: '#ff9900',
			flexDirection: styles.flexDirection.row,
			...styles.center,
			padding: 15,
		},
		title: {
			textAlign: styles.textAlign.center,
			color: 'white',
			fontSize: 25
		},
		button: {
			...styles.center,
			backgroundColor: '#fdcb00',
			marginLeft: 10,
			color: 'white',
			fontSize: 23,
		},
	},

	main: {
		flexDirection: styles.flexDirection.row,
		flex: 1,
		paddingLeft: CLOSED_MENU_WIDTH,
	},

	container: {
		flex: 1,
	},
	floating: {
		...styles.absolute({ bottom: 20, right: 30 }),
	},

	chat: {
		backgroundColor: styles.color.white,
		width: 531,
		maxWidth: 531, // need both

		// ...styles.fragmentCard,
		// marginTop: 29,
		// marginBottom: 22.4,
		// marginRight: 25,
	},

};
