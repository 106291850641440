import React from 'react';
import View from "library-react/component/View/v2";
import Text from "library-react/component/Text";
import TextInput from "library-react/component/input/TextInput";
import {styles} from "@src/res";
import {is} from "library-js/utils";

export default function LabeledTextInput({label, ...props}) {
	props.placeholder = props.placeholder || label;
	props.editable = is(props.editable) ? props.editable : true;
	props.style = [localStyles.input.textInput(props.editable), props.style].flat();
	return (
		<View style={localStyles.input.layout}>
			<Text style={localStyles.input.label}>
				{label}
			</Text>
			<TextInput {...props}/>
		</View>
	);
}


const localStyles = {
	input: {
		layout: {
			padding: 20,
		},
		label: {
			color: styles.color.black,
			fontSize: 13,
		},
		textInput: styles.static.bool({
				fontSize: 20,
				borderBottomWidth: .5,
				paddingLeft: 3,
			},
			{},
			{
				backgroundColor: styles.color.grey,
			}
		),
		editableText: styles.static.bool(
			{
				color: "black",
				fontSize: 20,
				borderBottomWidth: .5,
			},
			{
				color: "red"
			}
		),
		text: {
			color: "black",
			fontSize: 20,
			borderBottomWidth: .5,
			backgroundColor: styles.color.grey,
		}

	},
}
