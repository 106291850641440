import { Pressable, View, ViewProps } from "react-native";
import LinearGradient from "react-native-linear-gradient";
import useApp from "../../../../library-react/app/useApp";
import Icon from "../../../../library-react/component/Icon/v2";
import Image from "../../../../library-react/component/Image";
import Portal from "../../../../library-react/component/Portal";
import use from "../../../../library-react/hook";
import { Order_V2 } from "../../../../library-react/library-js/app/graphql";
import formatUserAddress from "../../../../library-react/library-js/app/model/utils/user/formatUserAddress";
import { images, styles } from "../../../../res";
import screens from "../../../screens";

export default function ClientView({ order, ...props }: ClientViewProps) {
	const app = useApp();
	const user = order.userByUserId!;

	const onChatClicked = () => app.openChatWith(user.id_firebase);
	const address = formatUserAddress(user);

	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<Portal to={screens.client} params={{ id: user.id_firebase }}>
			<View {...props}>
				{borderLeft}

				<View style={localStyles.user.layout}>
					{/* Picture */}
					<View style={localStyles.user.picture}>
						<Image
							source={{ uri: user.photo || undefined }}
							defaultSource={images.interlocutor}
							resizeMode="cover"
							style={styles.fit}
						/>
					</View>

					{/*	Main infos */}
					<View style={localStyles.user.mainInfos.layout}>
						<Text
							numberOfLines={1}
							style={localStyles.user.mainInfos.name}>
							{user.name}
						</Text>

						<Text
							numberOfLines={1}
							style={localStyles.user.mainInfos.email}>
							{user.email}
						</Text>
					</View>
				</View>

				{/*	Address */}
				{
					Boolean(address) &&
					<View style={localStyles.address.layout}>
						<Icon
							name="MaterialIcons/place"
							size={20}
							style={localStyles.address.icon}
						/>

						<Text
							numberOfLines={1}
							style={localStyles.address.text}>
							{address}
						</Text>
					</View>
				}


				{/*	Phone */}
				<Text style={localStyles.phone.layout}>
					{user.phone}
				</Text>

				<Pressable onPress={event => {
					event.preventDefault(); //https://github.com/necolas/react-native-web/issues/951
					onChatClicked?.();
				}}
					style={localStyles.chat.layout}>
					<Icon
						name="shopChat"
						size={25}
						style={localStyles.chat.icon}
					/>

					<Text
						numberOfLines={1}
						style={localStyles.chat.text}>
						{`Message`}
					</Text>
				</Pressable>
			</View>
		</Portal>
	);
}

interface ClientViewProps extends ViewProps {
	order: Order_V2,
}

const borderLeft = (
	<LinearGradient
		colors={[styles.color.deepSkyBlue, styles.color.aquaBlue]}
		style={{
			position: styles.position.absolute,
			left: 0,
			top: 0,
			bottom: 0,
			width: 6,
		}} />
);

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		alignItems: styles.alignItems.center,
		borderColor: styles.color.background,
		paddingVertical: 20,
		paddingHorizontal: 60,
		backgroundColor: '#f8f9fa',
		...styles.newShadow(0, 3, 6, .16),
	},

	user: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			justifyContent: styles.justifyContent.spaceBetween,
		},

		picture: {
			...styles.circle(58),
			overflow: styles.overflow.hidden,
			marginRight: 31,
		},

		mainInfos: {
			layout: {
				flexShrink: 1
			},

			name: {
				fontSize: 17,
				color: styles.color.azure2,
			},

			email: {
				fontSize: 16,
			},
		},
	},

	address: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			flexShrink: 3,
			paddingHorizontal: 5,
		},

		icon: {
			marginRight: 9,
		},

		text: {
			fontSize: 18,
		}
	},

	phone: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			flexShrink: 2,
			paddingHorizontal: 5,
		},
	},

	chat: {
		layout: {
			alignSelf: styles.alignSelf.stretch,
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
		},

		icon: {
			marginRight: 17,
			color: styles.color.azure2,
		},

		text: {
			fontSize: 16,
			color: styles.color.azure2,
		}
	},

};
