import React from "react"
import {View as RNView} from "react-native"
import use from "../../hook"
import {images, styles} from "../../res"
import TextInput from "../input/TextInput";
import Text from "../Text";
import Image from "../Image";
import Location from "../../library-js/location/Location"
import {autoComplete, getAddressOf, getLocationOf} from "./implementations";

function AddressLocationForm(
	{
		initialLocation,
		initialAddress,
		defaultValue,
		onAddressChanged,
		onValueChanged,
		editable,
		onLocationChanged,
		validate,
		placeholder,
		noMap = false,
		innerStyles = {input: null, map: null},
		TextInputComponent = TextInput,
		label,
		...props
	}
) {
	if (!onAddressChanged)
		onAddressChanged = onValueChanged;
	if (!initialAddress)
		initialAddress = defaultValue;

	const [address, setAddress] = use.state(initialAddress);
	use.onChange(
		address => {
			setQuery(address?.formatted || "");

			if (onAddressChanged)
				onAddressChanged(address);
		},
		[address],
	);

	const [location, setLocation] = use.state(initialLocation);
	use.onChange(onLocationChanged, [location]);

	const [query, setQuery] = use.state(initialAddress?.formatted || "");
	const isQueryDifferent = query && (!address || address.formatted !== query);

	const {value: suggestions, loading} = use.loadable(
		Boolean(
			autoComplete
			&& isQueryDifferent
		) &&
		function () {
			return Promise.process(this.shouldStop)
				.then(() => Promise.await(500))
				.then(() => autoComplete(query));
		},
		[query],
	) || {};

	const valid = Boolean(!validate || validate(address, location));

	const selectPlace = use.asyncCallback(shouldStop =>
		textPlace => {
			Promise.process(shouldStop)
				.then(() => Promise.all([
					getAddressOf(textPlace),
					getLocationOf(textPlace),
				]))
				.then(([address, location]) => {
					setAddress(address);
					setLocation(location);
				})
				.result((_, error) => {
					if (error)
						console.warn(error);
				});
		}
	);

	const suggestionsOpened = isQueryDifferent && (suggestions?.length > 0 || loading);

	const selectFirst = use.asyncCallback(shouldStop =>
		query => {
			Promise.process(shouldStop)
				.then(() => autoComplete(query))
				.then(suggestions => {
					if (suggestions?.first)
						return selectPlace(suggestions.first);
				})
		}
	);

	const forceUpdate = use.forceUpdate();
	const instances = use.instances({
		mapWrapper: div => {
			if (div) {
				instances.map = new google.maps.Map(div, {
					center: location?.toLatLng() || {lat: 46.7649812, lng: 0.0721534},
					zoom: location ? 17 : 5,
					zoomControl: false,
					mapTypeControl: false,
					scaleControl: false,
					streetViewControl: false,
					rotateControl: false,
					fullscreenControl: false,
					gestureHandling: 'cooperative',
				});
				forceUpdate(); // run effect place initial marker
			} else
				instances.map = null;
		}
	});

	use.onChange(
		(location && instances.map) &&
		((location, map) => {
			if (!instances.marker) {
				const marker = instances.marker = new google.maps.Marker({map, draggable: true});
				marker.addListener("dragend", () => {
					const latLong = marker.getPosition();
					const location = new Location(latLong.lat(), latLong.lng());
					setLocation(location);
				})
			}

			const latLng = location.toLatLng();
			instances.marker.setPosition(latLng);
			instances.map.setCenter(latLng);
			instances.map.setZoom(17);
		}),
		[location, instances.map],
	);

	props.style = use.defaultStyle(props.style, localStyles.layout(suggestionsOpened), [suggestionsOpened]);
	const inputStyle = use.defaultStyle(
		innerStyles?.input,
		[
			localStyles.input.self,
			!valid && {borderColor: "red", color: "red"}
		],
		[valid],
	);

	return (
		<RNView {...props}>

			{/* search bar */}
			<RNView style={localStyles.input.layout}>
				<TextInputComponent
					value={query}
					label={label}
					placeholder={placeholder || `Rechercher une adresse`}
					placeholderTextColor={!valid ? "red" : undefined}
					error={!valid}
					onValueChanged={setQuery}
					onSubmitted={selectFirst}
					editable={editable}
					style={inputStyle}
				/>

				{/* suggestions */}
				{
					Boolean(suggestionsOpened) &&
					<RNView style={localStyles.input.suggestions.layout}>
						{
							loading ?
								<Text style={localStyles.input.suggestions.item}>
									{`Recherche de l'addresse en cours ...`}
								</Text> :
								suggestions.map(place =>
									<Text
										key={place}
										onPress={() => selectPlace(place)}
										style={localStyles.input.suggestions.item}>
										{place}
									</Text>
								)
						}

						{googleAttribution}
					</RNView>
				}
			</RNView>

			{/* map */}
			{
				!noMap &&
				<div
					ref={instances.set.mapWrapper}
					style={localStyles.map}
				/>
			}
		</RNView>
	);
}

export default React.memo(AddressLocationForm);


const googleAttribution = (
	<Image
		source={images.googleAttribution}
		resizeMode="contain"
		style={{height: 20 * .7, width: 145 * .7, margin: 10}}
	/>
);

const localStyles = {
	layout: styles.static.bool(
		{},
		{zIndex: 1},
	),

	input: {
		layout: {
			zIndex: 1,
		},

		self: {
			paddingVertical: 9,
			paddingLeft: 10,
		},

		suggestions: {
			layout: {
				position: styles.position.absolute,
				top: "100%",
				left: 0,
				right: 0,

				backgroundColor: styles.color.white,
				...styles.newShadow(0, 7, 9, .16),
				zIndex: 1,
			},

			item: {
				marginHorizontal: 6,
				paddingVertical: 6,
				borderBottomWidth: .5,
				fontSize: 12,
				borderColor: styles.color.lightgrey,
			}
		},
	},

	map: {
		flex: 1,
		zIndex: 0,
	}
};
