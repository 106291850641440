import Entity from "./Entity"
import Image from "../media/Image"
import Badges from "../Badges"

export default class User extends Entity { }
User.addProperties({
	name: String,
	email: String,
	phone: String,
	badges: Badges,
	picture: Image,
	photo: {
		get() {
			return this.picture.uri;
		}
	},
});
