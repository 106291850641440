import React from "react";
import View from "../View/v2";
import {styles} from "../../res";
import Text from "../Text/v2";
import use from "../../hook";

export default function AddArticle({...props}){
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<Text style={localStyles.text}>
				{`Ajouter un article`}
			</Text>
		</View>
	);
}
const localStyles = {
	layout: {
		alignSelf: styles.alignSelf.center,
		paddingHorizontal: 20,
		height: 60,
		minWidth: 60,
		borderRadius: 30,
		backgroundColor: styles.color.white,
		...styles.newShadow(0, 2, 5, .2),
		...styles.center,
	},
	text: {
		textAlign: styles.textAlign.center,
	}
}
