import React from "react"
import Objects from "library-js/utils/Objects";
import {INPUT_PROPS, retrieveNextValueFromProps} from "./input/TextInput";
import use from "../hook";
import {Server} from "library-js/app";
import {View as RNView} from "react-native";
import View from "./View/v2";
import Image from "./Image";
import {styles} from "../res";
import {parallel} from "../library-js/utils/function";

export default function BrandAutocompletion({children: input, onSuggestionClicked}) {
	const inputProps = Objects.retrieve(input.props, INPUT_PROPS);

	let [text, setText] = use.state(() => retrieveNextValueFromProps(inputProps));
	use.onChange(inputProps.onValueChanged, [text]);
	inputProps.onValueChanged = setText;
	text = retrieveNextValueFromProps(inputProps, text);
	inputProps.value = text;

	const [isFocused, setIsFocusedTo] = use.state.bool(inputProps.autoFocus);
	inputProps.onFocus = setIsFocusedTo.true;
	inputProps.onBlur = () => setTimeout(setIsFocusedTo.false, 500);

	const [selectedBrand, setSelectedBrand] = use.state();
	use.onChange(onSuggestionClicked, [selectedBrand]);

	const brandsIterator = use.infiniteIterator(() =>
		text && text !== selectedBrand &&
		Server.public.brand.getList.getIterator({query: text, created: true})
			.setDelay(700),
		[text],
	);

	const saveLayout = use.callback(event => saveLayout.current = event.nativeEvent.layout);
	inputProps.onLayout = use.memo((superOnLayout) => parallel(saveLayout, superOnLayout), [inputProps.onLayout]);

	const updatedInput = React.cloneElement(input, inputProps);

	const opened = Boolean(isFocused && brandsIterator && selectedBrand !== text);

	const {Text} = use.theme();
	return (
		<>
			{updatedInput}

			{
				opened &&
				<RNView style={localStyles.autocomplete(saveLayout.current)}>
					{
						brandsIterator.state.is.ok ?
							(() => {
								const itemsToShow = brandsIterator.items.slice(0, 3);
								return (
									<>
										{
											itemsToShow.map(brand =>
												<BrandRow
													key={brand.name}
													name={brand.name}
													logo={brand.logo}
													onPress={() => {
														setSelectedBrand(brand.name);
														setText(brand.name);
													}}/>
											)
										}

										{
											!itemsToShow?.some(brand => brand.name === text) &&
											<BrandRow
												name={text}
												onPress={() => setSelectedBrand(text)}/>
										}
									</>
								);
							})() :

							brandsIterator.state.is.loading &&
							<Text style={localStyles.loading}>
								{`Chargement...`}
							</Text>
					}
				</RNView>
			}
		</>
	);
}


function BrandRow({name, logo, ...props}) {
	const {Text} = use.theme();
	return (
		<View {...props} style={localStyles.row}>
			{
				logo &&
				<Image
					source={{uri: logo.url, width: 50}}
					resizeMode="contain"
					style={{marginLeft: 10, width: 50, height: 30}}/>
			}

			<Text style={{fontSize: 11, marginLeft: 10}}>
				{name}
			</Text>
		</View>
	);
};

const localStyles = {
	input: {},

	autocomplete: layout => ({
		...styles.absolute({top: layout.height + layout.y, left: layout.x}),
		width: 300,
		backgroundColor: styles.color.white,
		zIndex: 1,
		elevation: 1,
		...styles.newBorder(1, styles.color.lightgrey),
		borderTop: 0,
	}),

	row: {
		padding: 5,
		borderTopWidth: 1,
		borderColor: styles.color.lightgrey,
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		minHeight: 40,
	},

	loading: {
		padding: 10,
	}
};
