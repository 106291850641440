import {styles} from "../../res";
import use from "../../hook";
import is from "../../library-js/utils/is";
import Server from "../../library-js/app/Server";
import Showcase from "../../library-js/app/model/entity/Showcase";
import Image from "../../component/Image/Image";
import {View as RNView} from "react-native";
import React from "react";
import {Trigger} from "library-js/utils";
import Platform from "library-js/Platform";
import AuthManager from "library-js/AuthManager";
import ArticlesInput from "../../component/ArticlesInput";

const showcasePersistAPI = Platform.select({
	marketer: Server.marketer.showcase.persist,
	retailer: (id, edition) => Server.retailer.showcase.persist(AuthManager.claims?.shopId, id, edition),
});


export default function useShowcaseEditor(id, config) {
	const loadable = use.loadable.server(
		is.defined(id) && (
			() => Server.public.showcase.getByID([id])
				.updateContent(map => {
					const vShowcase = map[id];
					vShowcase.showcase.articles = vShowcase.showcase.articles || [];
					return vShowcase;
				})
		),
		[id],
	);

	const edition = use.memo(showcase => (
			loadable ? showcase?.copy()
				: new Showcase()
		)?.bindSetters(),
		[loadable?.value?.showcase],
	);

	const Editor = use.memo(editorFactory);

	Editor.edited = edition && !edition.equalsTo(loadable?.value?.showcase);
	use.subscribe(edition?.onPropertyChanged, [edition]);
	use.subscribe(edition?.onArticlesChanged.bind(edition), [edition]);

	const [submitted, setSubmittedTo] = use.state.bool();
	const [persisting, setPersistingTo] = use.state.bool();
	Editor.persisting = persisting;
	Editor.persist = use.callback(() => {
		setSubmittedTo(true);
		const invalidFields = edition.getInvalidFields();
		if (invalidFields.length)
			throw new Error("The showcase has some invalid fields: " + invalidFields.join(', '));

		setPersistingTo(true);
		const promise = showcasePersistAPI(id, edition);
		promise.then(response => {
			if (response.ok) {
				if (loadable)
					loadable.setValue(vShowcase => vShowcase.setShowcase(response.content));
				else if (config?.onceCreated instanceof Function) {
					config.onceCreated(response.content);
				}
			} else
				response.log();
		})
			.finally(setPersistingTo.false);
		return promise;
	}, [edition, loadable]);

	const [deleting, setDeletingTo] = use.state.bool();
	Editor.deleting = deleting;
	Editor.delete = use.callback(() => {
		setDeletingTo(true);
		const promise = showcasePersistAPI(id, null)
			.then(response => {
				if (!response.ok)
					response.throw();
				return response;
			});
		promise.finally(setDeletingTo.false);
		return promise;
	}, [edition, loadable]);

	Editor.assign({edition, loadable, persisting, deleting, submitted});
	return Editor;
}

const editorFactory = () => {
	function Editor(
		{
			TitleInput,
			Text: TextProps,
			BannerInput,
			PortraitInput,
			ArticleInput: ArticleInputProps,
			Image: ImageProps,
			DescriptionInput,
			GalleryInput,
			ImageInput: ImageInputProps,
			editable = true,
			...props
		}) {
		use.subscribe(Editor.subscribe);
		const {loadable, edition, persisting, deleting, submitted} = Editor;
		use.subscribe(loadable?.onStateChanged, [loadable]);
		use.subscribe(edition?.onPropertyChanged, [edition]);

		const {Text, TextInput, Spinner, ErrorView, ImageInput: ImageInputContext} = use.theme();
		if (!TitleInput)
			TitleInput = TextInput;
		if (!BannerInput)
			BannerInput = Image;
		if (!PortraitInput)
			PortraitInput = Image;
		if (!DescriptionInput)
			DescriptionInput = TextInput;

		if (!TextProps)
			TextProps = Text;

		const ImageInput = ImageInputProps || ImageInputContext;

		const tasking = persisting || deleting;

		return (
			<RNView {...props}>
				{
					edition ?
						editable ? <>
							<TitleInput
								placeholder={`Titre (40 car. max)`}
								maxLength={40}
								value={edition.title}
								onValueChanged={edition.setTitle}
								disabled={tasking}
								validate={submitted && Showcase.generateValidateOf.title()}
								style={localStyles.title}/>

							<BannerInput
								placeholder={`Bannière principale`}
								source={edition.banner}
								value={edition.banner}
								onValueChanged={edition.setBanner}
								disabled={tasking}
								validate={submitted && Showcase.generateValidateOf.banner()}
								notRemovable
								style={localStyles.banner}/>

							<RNView style={localStyles.body}>
								<PortraitInput
									placeholder={`Bannière portrait`}
									source={edition.portrait}
									value={edition.portrait}
									onValueChanged={edition.setPortrait}
									disabled={tasking}
									validate={submitted && Showcase.generateValidateOf.portrait()}
									notRemovable
									style={localStyles.portrait}/>

								<DescriptionInput
									placeholder={`Description de la vitrine (100 car. min)`}
									value={edition.description}
									onValueChanged={edition.setDescription}
									disabled={tasking}
									multiline
									validate={submitted && Showcase.generateValidateOf.description()}
									style={localStyles.description}/>
							</RNView>
							<ArticlesInput ImageInput={ImageInput}
										   showcase={edition}
										   validate={submitted && Showcase.generateValidateOf.articles()}
										   submitted={submitted}/>
						</> : <>
							<TextProps style={localStyles.title}>
								{edition.title}
							</TextProps>

							<Image source={edition.banner}
								   style={localStyles.banner}/>

							<RNView style={localStyles.body}>
								<Image
									source={edition.portrait}
									style={localStyles.portrait}/>
								<TextProps style={localStyles.description}>
									{edition.description}
								</TextProps>
							</RNView>
							<ArticlesInput disabled={true} showcase={edition}/>

						</>

						:

						<RNView style={[{flex: 1}, styles.center]}>
							{
								loadable?.loading ?
									<Spinner/> :
									<ErrorView onLoadClicked={loadable?.load}/>
							}
						</RNView>
				}
			</RNView>
		);
	}

	const trigger = new Trigger();
	Editor.subscribe = trigger.add;
	Editor.assign = data => {
		Object.entries(data)
			.forEach(([key, value]) => {
				if (Editor[key] !== value) {
					Editor[key] = value;
					trigger.fireAsyncOnce();
				}
			});
	};

	return Editor;
};

const localStyles = {
	title: {
		textAlign: styles.textAlign.center,
		fontWeight: 'bold',
		padding: 10,
		fontSize: 35,
		marginBottom: 20,
		width: 500,
		alignSelf: styles.alignSelf.center,
	},

	banner: {
		height: 300,
		marginBottom: 30,
	},

	body: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.flexStart,
		justifyContent: styles.justifyContent.spaceBetween,
		marginBottom: 30
	},

	portrait: {
		width: 300,
		height: 450,
		marginRight: 20,
	},

	description: {
		height: 450,
		flex: 1,
	},
};
