import React from "react"
import use from "../../../../../../hook"
import Text from "../../../../../../component/Text";
import {fonts} from "../../../../../../res";

export default React.memo(
	function ChatTextMessageView({event: message, ...props}) {
		props.style = use.defaultStyle(props.style, defaultStyle);

		return (
			<Text
				numberOfLines={1}
				{...props}>
				{
					message.safeText
				}
			</Text>
		);
	}
);


const defaultStyle = {
	color: '#939191',
	fontSize: 13,
	font: fonts.Poppins
};
