import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@src/res"
import ThemeContext from "library-react/hook/useTheme/ThemeContext";
import BrandAutocompletion from "library-react/component/BrandAutocompletion";
import inputComponent from "library-react/component/abstract/inputComponent";
import {eqBy, F, objOf, pipe, remove, toLower} from "ramda";
import {Server} from "library-js/app";
import Image from "library-react/component/Image";
import View from "library-react/component/View/v2";
import Icon from "library-react/component/Icon/v2";
import {BrandListPin} from "library-js/app/ShopWebsiteConfiguration/Home/PINS";
import useApp from "library-react/app/useApp";
import SelectBrandModal from "@main/component/SelectBrandModal";

function BrandListPinView({pin, update, submitted, ...props}) {
	if (!pin.names)
		pin.names = [];

	const forceUpdate = use.forceUpdate();
	const brands = use.memorise((names, brands = {}) => {
			const missingBrands = names.filter(name => {
				if (brands[name] === undefined) {
					brands[name] = BRAND_IS_LOADING;
					return true;
				}
			});

			if (missingBrands.length > 0)
				Server.public.brand.get(...missingBrands)
					.then(response => {
						const replaceLoadingBrandsWith = value =>
							missingBrands.forEach(name => {
								if (brands[name] === BRAND_IS_LOADING)
									brands[name] = value;
							});

						if (response.ok) {
							Object.assign(brands, response.content);
							replaceLoadingBrandsWith(null);
							forceUpdate();
						} else {
							response.log();
							replaceLoadingBrandsWith(undefined);
						}
					});

			return brands;
		},
		pin.names,
	);
	const removeBrandAt = index => update(pin => pin.names = remove(index, 1, pin.names));

	const app = useApp();

	const {TextInput, Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			<TextInput
				placeholder={`Titre du bloc *`}
				value={pin.title || ''}
				autoFocus={!pin.title}
				onValueChanged={pipe(objOf('title'), update)}
				validate={submitted && BrandListPin.validator.title()}
				style={localStyles.title}
			/>

			<RNView style={localStyles.brands}>

				{
					Boolean(pin.names.length > 0 || pin.title) &&
					<View
						onPress={() => app.openModal(
							<SelectBrandModal
								initialValue={pin.names}
								multiple
								onceConfirmed={names => update({names})}
							/>
						)}
						style={localStyles.add}>
						<Icon
							name='MaterialIcons/add'
							size={30}
							style={localStyles.addIcon}/>

						<Text numberOfLines={1}
							  style={localStyles.addText(submitted && !pin.names.length)}>
							{`Ajouter une marque`}
						</Text>
					</View>
				}

				{
					pin.names.map((name, index) =>
						<View key={name} hoverable style={localStyles.brand}>
							{
								brands[name]?.logo ?
									<Image
										source={brands[name].logo}
										resizeMode="contain"
										style={localStyles.brandLogo}/> :
									<Text
										numberOfLines={2}
										style={localStyles.brandName}>
										{name}
									</Text>
							}

							{
								({hover}) =>
									hover &&
									<Icon
										name="MaterialIcons/remove"
										size={20}
										onPress={() => removeBrandAt(index)}
										style={localStyles.remove}/>
							}
						</View>
					)
				}
			</RNView>


		</RNView>
	);
}

export default inputComponent(BrandListPinView);

const BRAND_IS_LOADING = false;

const localStyles = {
	layout: {
		minHeight: 100
	},

	title: {
		paddingBottom: 15,
		paddingTop: 5,
		fontSize: 25,
		bold: true,
		paddingLeft: 7,
	},

	brands: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		flexWrap: styles.flexWrap.wrap,
	},

	brand: {
		...styles.newBorder(1, styles.color.lightgrey),
		padding: 3,
		width: 150,
		height: 60,
		marginVertical: 5,
		marginHorizontal: 7,
		...styles.center,
	},

	brandLogo: {
		flex: 1,
		alignSelf: styles.alignSelf.stretch,
	},

	brandName: {
		bold: true,
		fontSize: 20,
		textAlign: styles.textAlign.center,
	},

	add: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		height: 60,
		borderRadius: 7,
		borderWidth: 1,
		borderColor: styles.color.azure,
		paddingHorizontal: 20,
	},

	addIcon: {
		color: styles.color.azure,
		marginRight: 8,
	},

	addText: styles.static.bool(
		{},
		{
			color: 'red'
		},
		{
			color: 'grey'
		}
	),

	remove: {
		...styles.absolute({top: -10, right: -10}),
		backgroundColor: 'red',
		color: styles.color.white,
		...styles.circle(30),
	}
};
