import social from "./social"
import images from "../../library-react/res/image"
export default images;

images.logo = require("./logo.png");
images.picture = require("./picture.png");
images.social = social;
images.signin = {
	customer: require("./signin/customer.png"),
	locationMarker: require("./signin/location-marker.png"),
	shop: require("./signin/shop.png"),
};