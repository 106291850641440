import React from "react"
import {View as RNView} from "react-native"
import use from "../../../../../../hook"
import {styles} from "../../../../../../res";
import ProductMessageView from "./ProductMessageView";
import OrderStateView from "../../../../../../component/OrderStateView";

export default React.memo(
	function OrderItemMessageView({event: message, ...props}) {
		props.style = use.defaultStyle(props.style, localStyles.layout);

		return (
			<RNView {...props}>
				{
					message.vOrderItem.state &&
					<OrderStateView
						state={message.vOrderItem.state}
						displayText={false}
						styles={localStyles.icon}
					/>
				}
				<ProductMessageView
					event={message}
					style={localStyles.productView}
				/>
			</RNView>
		);
	}
);

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
	},

	icon: {
		marginRight: 5,
	},

	productView: {
		flex: 1,
	}
};
