import Objects from "../utils/Objects";

const productSizes = {
	sections: [
		`Homme`,
		`Femme`,
		`Enfant`,
		`Bébé`,
	],

	getCategoriesOf(section) {
		return Objects.valuesOf(sections[section]).flat();
	},



	getSizesOf(section, category) {
		const key = Objects.findKey(
			sections[section],
			(key, categories) => categories.includes(category),
		);

		return sizes[key]?.copy();
	}
};

export default productSizes;


const sections = {
	[productSizes.sections[0]]: {
		h1: [`Pull`, `Gilet`, `Sweat`],
		h2: [`Costume`],
		h3: [`Veste`, `Manteau`],
		h4: [`Chemise`],
		h5: [`T-shirt`, `Polo`],
		h6: [`Sous-vetements`, `Maillot`],
		h7: [`Jean (FR/US)`],
		h8: [`Pantalon`, `Short`],
		h9: [`Chaussures`],
		h10: [`Chaussettes`],
	},

	[productSizes.sections[1]]: {
		f1: [`Haut`, `Maille`, `Robe`, `Veste`, `Manteau`],
		f2: [`Pantalon`, `Short`, `Jean`, `Jupe`],
		f3: [`Lingerie haut`, `Maillots haut`],
		f4: [`Lingerie bas`, `Maillots bas`],
		f5: [`Chaussures`],
		f6: [`Chaussettes`],
	},

	[productSizes.sections[2]]: {
		e1: [`2-16 ans`],
		e2: [`Chaussures`]
	},

	[productSizes.sections[3]]: {
		b1: [`0-18 mois`],
		b2: [`Chaussures`],
	},
};

const sizes = {
	// man
	h1: ['42(XS)', '44(S)', '46(M)', '48(L)', '50(XL)', '52(XXL)', '54(3XL)', '56(4XL)'],
	h2: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '62', '64'],
	h3: ['40/42(XS)', '42(XS)', '42/44(S)', '44(S)', '46(S)', '46/48(M)', '48(M)', '50(M)', '50/52(L)', '52(L)', '54(L)', '54/56(XL)', '56(XL)', '58(XL)', '58/60(XXL)', '60(XXL)', '60/62(3XL)'],
	h4: ['35/36(XS)', '37/38(S)', '39/40(M)', '41/42(L)', '43/44(XL)', '45/46(XXL)', '44/48(3XL)', '48/50(4XL)'],
	h5: ['36(XS)', '38(S)', '40(M)', '42(L)', '44(XL)', '46(XXL)', '48(3XL)', '50(4XL)'],
	h6: ['S(40)', 'M(42)', 'L(44)', 'XL(46)', 'XXL(48)', '3XL(50)', '4XL(52)'],
	h7: ['38(28)', '38/40(29)', '40(30)', '40/42(31)', '42(32)', '42/44(33)', '44(34)', '46(36)', '48(38)'],
	h8: ['XXS(34)', 'XS(36)', 'S(38)', 'M(40)', 'L(42)', 'XL(44)', 'XXL(46)', '3XL(48)'],
	h9: ['38.5', '39', '39.5', '40', '40.5', '41', '41.5', '42', '42.5', '43', '43.5', '44', '44.5', '45', '45.5', '46'],
	h10: ['39/42', '43/46'],

	// woman
	f1: ['XS(T0)', 'S(T1)', 'M(T2)', 'L(T3)', 'XL(T4)', 'XXL(T5)', '3XL(T6)'],
	f2: ['34', '36', '38', '40', '42', '44', '46'],
	f3: ['85-B', '85-C', '90-B', '90-C', '90-D', '90-E', '95-B', '95-C', '95-D', '95-E', '95-F', '100-B', '100-C', '100-D', '100-E', '100-F', '105-B', '105-C', '105-D', '105-E', '105-F'],
	f4: ['1/2', '3/4'],
	f5: ['35', '35.5', '36', '36.5', '37', '37.5', '38', '38.5', '39', '39.5'],
	f6: ['35/38', '39/42'],

	// child
	e1: ['1,5/2 ANS (92cm)', '2/3 ANS (98cm)', ' 3/4 ANS (104cm)', '4/5 ANS (110cm)', '5/6 ANS (116cm)', '6/7 ANS (122cm)', '7/8 ANS (128cm)', '8/9 ANS (134cm)', '9/10 ANS (140cm)', '10/11 ANS (146cm)', '11/12 ANS (152cm)', ' 12/13 ANS (158cm)', '13/14 ANS (164cm)', '14/15 ANS (170cm)', '15/16 ANS (176cm)'],
	e2: ['24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38'],

	// baby
	b1: ['0/1 MOIS (50cm)', '1/2 MOIS (56cm)', '2/4 MOIS (62cm)', '4/6 MOIS (68cm)', '6/9 MOIS (74cm)', '9/12 MOIS (80cm)', '12/18 MOIS (86cm)'],
	b2: ['19', '20', '21', '22', '23'],
};


export const SIZES_COLLECTIONS = productSizes.sections.map(title => ({
	title,
	children: Object.entries(sections[title])
		.map(([key, categories]) => ({
			title: categories.join(', '),
			children: sizes[key],
		}))
}));