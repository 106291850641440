import React from "react"
import use from "library-react/hook"
import {styles} from "../../../../res"
import View from "library-react/component/View/v2";
import Image from "library-react/component/Image";
import AbstractImageInput from "library-react/component/abstract/ImageInput";
import {is} from "library-js/utils";
import Text from "library-react/component/Text/v2";
import Icon from "library-react/component/Icon/v2";


function ImageInput({placeholder, disabled, resizeMode, ...props}, {value, selectImage, uploading, valid, remove}) {
	props.onPress = selectImage;
	props.style = use.defaultStyle(props.style, localStyles.layout(valid), [valid]);

	props.hoverable = remove;

	const {Spinner} = use.theme();

	return (
		<View {...props}>
			<Image
				source={value}
				shouldNotResize
				resizeMode={resizeMode}
				style={styles.fit}
			/>

			{
				uploading ? <Spinner/> :
					!value && (
						is(placeholder, String) ?
							<Text style={localStyles.placeholder}>
								{placeholder}
							</Text> :
							placeholder
					)
			}

			{
				remove &&
				(({hover}) => hover &&
						<Icon
							name="MaterialIcons/delete-outline"
							size={30}
							onPress={remove}
							style={localStyles.delete}/>
				)
			}
		</View>
	);
}


export default React.memo(AbstractImageInput(ImageInput));


const localStyles = {
	layout: styles.static.bool(
		{
			...styles.center,
			borderWidth: 1,
		},
		{borderColor: styles.color.lightgrey},
		{borderColor: "red"},
	),

	placeholder: {
		bold: true,
		color: 'lightgrey',
		fontSize: 30,
	},

	delete: {
		color: 'red',
		...styles.absolute({top: 0, right: 0}),
		padding: 15,
		backgroundColor: "rgba(0, 0, 0, .3)",
	},
};
