import React from "react"
import {View as RNView} from "react-native"
import abstractComponent from "library-react/component/abstract/abstractComponent";
import use from "library-react/hook";
import timeout from "library-js/utils/timeout";
import Icon from "library-react/component/Icon/v2";
import Text from "library-react/component/Text";
import {styles} from "../../../../../../../res";
import View from "library-react/component/View";

export default abstractComponent(
	function sectionFormOn(Form, isSubSection) {
		return function ({onSubmitted, cupboard, ...props}) {
			const [state, setState] = use.state(State.closed);

			const open = use.callback(() => setState(State.opened));
			if (state === State.closed)
				props.onPress = open;

			const submit = use.callback(
				section => {
					if (section) {
						setState(State.ok);
						if (onSubmitted)
							onSubmitted(section);
					}
				},
				[onSubmitted],
			);

			use.effect(
				(state === State.ok) && (
					() => timeout(
						() => setState(State.closed),
						1000,
					)
				),
				[state]
			);

			return (
				<View {...props}>
					{
						(state === State.opened) ?
							<Form submit={submit} cupboard={cupboard} /> :
							<RNView style={localStyles.message.layout}>
								<Icon
									name={({
										closed: "add",
										ok: "MaterialIcons/check-circle",
									})[state]}
									size={25}
									style={localStyles.message.icon(state)}
								/>

								<Text
									numberOfLines={1}
									style={localStyles.message.text(state)}>
									{{
										closed: (
											<>
												{`Ajouter une nouvelle `}
												<Text style={{bold: true}}>
													{isSubSection && "sous "}
													{`catégorie`}
												</Text>
											</>
										),
										ok: `${isSubSection ? "Sous section" : "Section"} ajouté !`,
									}[state]}
								</Text>
							</RNView>
					}
				</View>
			);
		}
	}
);

const State = {
	closed: "closed",
	opened: "opened",
	ok: "ok",
};

const localStyles = {
	message: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
		},

		icon: styles.static(
			{marginRight: 16},
			{
				closed: {color: styles.color.azure2},
				ok: {color: styles.color.good},
			},
		),

		text: styles.static(
			{fontSize: 15},
			{
				closed: {color: styles.color.azure2},
				ok: {color: styles.color.good},
			}
		)
	}
};
