import React from "react"
import use from "library-react/hook"
import useMe from "library-react/hook/pro/useMe";
import toMoment from "library-js/utils/toMoment";
import {View} from "react-native";

function DaySeparator({time, summaryByDays, ...props}) {
	const {shop} = useMe().shop;
	const {Text} = use.theme();
	const timeMoment = toMoment(shop.timeZone, time)
	const summary = summaryByDays ? summaryByDays[timeMoment.format('YYYY-MM-DD')] : null;

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<Text style={localStyles.date}>
				{
					timeMoment.calendar({
						sameDay: "[Aujourd'hui]",
						nextDay: '[Demain]',
						nextWeek: 'dddd',
						sameElse: 'DD/MM',
						lastDay: '[Hier]',
						lastWeek: 'dddd [dernier]',
					})
				}
			</Text>
			{
				Boolean(summary) &&
				<View>
					<Text>
						{`Places acceptées: ${summary.numberOfPlacesAccepted}`}
					</Text>
					<Text>
						{`Reservations en attente: ${summary.numberOfReservationWaiting}`}
					</Text>
					<Text>
						{`Reservations acceptées: ${summary.numberOfReservationAccepted}`}
					</Text>
				</View>
			}
		</View>
	);
}

export default React.memo(DaySeparator);


const localStyles = {
	layout: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		flex: 1,
	},
	date: {
		marginLeft: 42,
		backgroundColor: 'black',
		color: 'white',
		paddingHorizontal: 10,
		borderRadius: 5,
		paddingVertical: 5,
		marginTop: 50,
		marginBottom: 10,
		alignSelf: 'flex-start',
		fontSize: 20,
	},
};
