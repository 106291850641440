import React from "react";
import AppBarView from "./AppBar";
import Icon from "../Icon/v2";
import { styles } from "../../res"
import ComponentUtils from "../../ComponentUtils";
import use from "../../hook";
import { is } from "../../library-js/utils";
import { TextStyleContext } from "../Text/v2";

/**
 * @param {{
	* children?: any, 
	* onBack?: Function | boolean, 
	* backIcon?: any, 
	* backIconSize?: number, 
	* backIconStyle?: any,
 * } extends import('react-native').ViewProps} 
 */
function StandardAppBar({ children, onBack, backIcon, backIconSize, backIconStyle, ...props } = {}) {
	const { Text } = use.theme();
	children = use.memo(
		() => ComponentUtils.childrenToArray(children)
			.map(child => {
				if (is(child, String))
					return (
						<Text
							key={child}
							numberOfLines={1}
							style={{ flex: 1 }}>
							{child}
						</Text>
					);

				return child;
			}),
		[children]
	);

	props.style = use.defaultStyle(props.style, localStyles.layout, [onBack]);

	return (
		<AppBarView {...props}>
			{
				onBack &&
				<Icon
					name={backIcon || "AntDesign/arrowleft"}
					size={backIconSize || 18}
					onPress={onBack}
					style={[localStyles.back, backIconStyle].flat()}
				/>
			}

			<TextStyleContext.Provider value={localStyles.title}>
				{children}
			</TextStyleContext.Provider>
		</AppBarView>
	)
}

export default React.memo(StandardAppBar);

const localStyles = {
	layout: hasBack => ({
		flexDirection: styles.flexDirection.row,
		paddingLeft: hasBack ? 8 : 16,
	}),

	back: {
		color: styles.color.black,
		paddingHorizontal: 8,
	},

	title: {
		fontSize: 19,
		color: styles.color.black,
		bold: true,
		alignSelf: styles.alignSelf.center,
		paddingTop: 6,
	}
};
