import { is, curry } from "ramda"

function bindActionToBoolState(setState, action) {
	return function (...params) {
		setState(true);

		let result, isPromise;
		try {
			result = action(...params);
			isPromise = is(Promise, result);
			if (isPromise)
				result.finally(() => setState(false));
		}
		finally {
			if (!isPromise)
				setState(false);
		}

		return result;
	};
}

export default curry(bindActionToBoolState);