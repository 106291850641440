import React from "react"
import use from "library-react/hook";
import View from "library-react/component/View";
import {strings, styles} from "../../../../res";
import GeneralInformation from "./StepView/GeneralInformation";
import AdditionalInformation from "./StepView/AdditionalInformation";
import Button from "../../../component/Button";
import Server from "library-js/app/Server";
import globals from "../../../globals";
import {VProdshop} from "library-js/app/model/view";
import {Prodshop} from "library-js/app/model";
import StaticComponent from "library-react/component/StaticComponent";
import CatalogSection from "library-js/app/model/entity/CatalogSection";
import {pipe} from "library-js/utils/function";
import {filter, map} from "library-js/utils/array";
import screens from "../../../screens";
import FragmentLayout from "../../../component/FragmentLayout";

function EditProductUI({route, navigation, ...props}) {
	const productId = route.params?.id;

	const [submitted, setSubmitted] = use.state(false);
	const [waitingSubmit, setWaitingSubmit] = use.state(false);
	const {value: vProdshop, loading, load} = use.loadable(
		() => {
			if (productId) {
				const shopId = globals.me.shop.id;
				return Server.public.prodshop.get({[shopId]: [productId]})
					.then(response => {
						if (!response.ok || !response.content[shopId][productId])
							throw response;
						return response.content[shopId][productId];
					});
			}
			const vProdshop = new VProdshop();
			vProdshop.prodshop = new Prodshop();
			return vProdshop;
		},
		[productId]
	);

	const submit = use.asyncCallback(shouldStop =>
			() => {
				setSubmitted(true);

				//Check mandatory fields
				if (!vProdshop.product.name || !vProdshop.prodshop.price.value || !vProdshop.product.gallery.length)
					return;

				setWaitingSubmit(true);

				let productId = vProdshop.product.id;
				const shopId = globals.me.shop.shop.id;
				let sectionsToLink = memory.sections || [];

				const process = Promise.process(shouldStop);

				// persist product
				process.then(() => Server.retailer.catalog.persist(productId, vProdshop.product, vProdshop.prodshop))
					.then(response => {
						if (response.ok) {
							const product = vProdshop.product;
							productId = product.id = response.content;
						} else
							response.throw();
					})
					// remove sections not selected (first get product's current sections)
					.then(() => Server.public.prodshop.getSectionsOf(shopId, productId))
					.then(response => {
						if (response.ok) {
							const procedure = pipe([
								// get sections to remove
								filter(section => !containsSection(sectionsToLink, section)),
								// convert to items
								map(section => new CatalogSection.Item(productId, section.id)),
								// send a request for each
								map(item => Server.retailer.catalog.sections.setProduct(item, false)),
								// wait for responses
								Promise.resultAll
							]);

							// apply
							return procedure(response.content);
						}
					})
					// sections to create on the fly
					.then(() => {
						const newSections = sectionsToLink.filter(section => !section.id);

						// check if sections needs to be created
						if (newSections.length)
							// get shop's current sections
							return process.then(() => Server.public.shop.getSections(shopId))
								// add new sections to them and save
								.then(response => {
									if (response.ok) {
										const cupboard = response.content;
										cupboard.add(newSections);
										return Server.retailer.catalog.sections.persist(cupboard);
									} else
										response.log();
								})
								// replace sections to link array
								.then(response => {
									if (response?.ok)
										sectionsToLink = response.content.getAll()
											.filter(newSection => containsSection(sectionsToLink, newSection));
									else
										response?.log();
								})
					})
					// bind sections to product
					.then(
						pipe([
							() => sectionsToLink,
							// remove non existing sections
							filter(section => section.id),
							// convert to items
							map(section => new CatalogSection.Item(productId, section.id)),
							// bind
							map(item => Server.retailer.catalog.sections.setProduct(item, true)),
							// wait for responses
							Promise.resultAll,
						])
					)
					.then(() => navigation.push(screens.product, {id: productId}))
					.anyway(() => setWaitingSubmit(false));
			},
		[vProdshop],
	);

	const memory = use.memo({sections: null}); // no need a state and refresh for this
	const saveSections = use.callback(sections => memory.sections = sections);


	const {Text, Spinner} = use.theme();
	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.layout);
	return (
		<FragmentLayout {...props}>
			{
				loading ?
					<Spinner size={50} /> :
					vProdshop ?
						<>
							<GeneralInformation
								submitted={submitted}
								vProdshop={vProdshop}
								onSectionsChanged={saveSections}
								inputStyle={localStyles.input}
								style={localStyles.generalForm}/>

							<Separator/>

							<AdditionalInformation
								submitted={submitted}
								vProdshop={vProdshop}
								inputStyle={localStyles.input}/>

							{/* Submit */}
							<View style={localStyles.button.layout}>
								<Button
									loading={waitingSubmit}
									style={localStyles.button.next}
									onPress={submit}>
									<Text style={localStyles.button.text}>
										{strings.fragment.addProduct.submit}
									</Text>
								</Button>
							</View>
						</> :
						<View style={[styles.center, {paddingVertical: 70}]}>
							<Text style={{marginBottom: 20}}>
								{strings.onError.firstUp}
							</Text>

							<Button onPress={load}>
								{strings.reload.firstUp}
							</Button>
						</View>
			}
		</FragmentLayout>
	);
}

export default React.memo(EditProductUI);

const Separator = new StaticComponent(
	<View style={{
		marginTop: 55,
		marginBottom: 35,
		height: 5,
		width: "100%",
		backgroundColor: styles.color.background
	}}/>
);

function containsSection(array, section) {
	return array.some(inSection => inSection.name === section.name);
}

const localStyles = {
	layout: {
		paddingHorizontal: 35,
		paddingVertical: 45,
		flexDirection: styles.flexDirection.column,
		overflow: styles.overflow.visible,
	},

	generalForm: {
		zIndex: 1, // for dropdown
	},

	input: {
		minWidth: 150,
		marginTop: 30,
		paddingTop: 8,
		paddingBottom: 15,
		fontSize: 20,
		borderBottomWidth: 0.5,
		borderColor: styles.color.secondary,
	},

	button: {
		layout: {
			flexDirection: styles.flexDirection.row,
			justifyContent: styles.justifyContent.flexEnd,
			marginBottom: 108,
			paddingEnd: 10,
			width: "100%",
			maxWidth: 980,
			zIndex: -1,
			marginTop: 20,
		},
		next: {
			width: 265,
			marginLeft: 62,
		},
		text: {
			bold: false,
			flex: 1,
		}
	},
};
