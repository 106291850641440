import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "../../../../res"
import View from "library-react/component/View/v2";
import Icon from "library-react/component/Icon/v2";
import GradientView from "library-react/component/GradientView";
import Text from "library-react/component/Text";
import Portal from "library-react/component/Portal";
import screens from "../../../screens";

function AddButton({...props}) {
	const [opened, setOpened] = use.state.bool(false);
	props.onPress = setOpened.toggle;
	props.onBlur = () => setTimeout(setOpened.false, 200);

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<Icon
				name="add"
				size={50}
				Layout={RNView} // workaround issue: https://github.com/necolas/react-native-web/issues/1781
				style={localStyles.icon(opened)}
			/>


			{/* window */}
			{
				opened &&
				<RNView style={localStyles.window}>
					{head}

					<RNView style={localStyles.choices}>
						<Portal to={screens.editProduct}>
							<View
								hoverable
								style={localStyles.button.layout}>
								{({hover}) => (
									<>
										<Icon
											name="album"
											size={40}
											style={localStyles.button.icon(hover)}
										/>

										<Text style={localStyles.button.text(hover)}>
											{`un article`}
										</Text>
									</>
								)}
							</View>
						</Portal>

						<Portal to={screens.sections}>
							<View
								hoverable
								style={localStyles.button.layout}>
								{({hover}) => (
									<>
										<Icon
											name="shareScreen"
											size={40}
											style={localStyles.button.icon(hover)}
										/>

										<Text style={localStyles.button.text(hover)}>
											{`une catégorie`}
										</Text>
									</>
								)}
							</View>
						</Portal>
					</RNView>
				</RNView>
			}
		</View>
	);
}

export default React.memo(AddButton);

const head = (
	<GradientView
		colors={[styles.color.deepSkyBlue, styles.color.aquaBlue]}
		style={{paddingVertical: 18}}>
		<Text style={{color: styles.color.white, textAlign: styles.textAlign.center}}>
			{`Ajouter`}
		</Text>
	</GradientView>
);

const localStyles = {
	layout: {
		paddingRight: 45,
		zIndex: 1,
		overflow: styles.overflow.visible,
	},

	icon: styles.static.bool(
		{color: [styles.color.aquaBlue, styles.color.deepSkyBlue]},
		{transform: [{rotate: "45deg"}]} // becomes a cross (close) icon
	),

	window: {
		...styles.absolute({top: '105%', right: 10}),
		borderRadius: 14,
		...styles.newShadow(0, 3, 16, .16),
		backgroundColor: styles.color.white,
		overflow: styles.overflow.hidden,
	},

	choices: {
		flexDirection: styles.flexDirection.row
	},

	button: {
		layout: [
			{
				minWidth: 190,
				paddingTop: 50,
				paddingBottom: 30,
				alignItems: styles.alignItems.center,
			},
			// ({hover}) => ({backgroundColor: hover ? '#f8f9fa' : styles.color.white})
		],

		icon: styles.static.bool(
			{paddingBottom: 30},
			{color: styles.color.azure2}
		),

		text: styles.static.bool(
			{
				fontSize: 17,
				textAlign: styles.textAlign.center,
			},
			{color: styles.color.azure2}
		)
	}
};
