import { is } from "ramda";
import { StyleSheet, View, ViewProps } from "react-native";
import use from "../../../../library-react/hook";
import { AppTheme } from "../../../theme";
import Tab from "./Tab";

export default function TabsSection({ tabs, tabIndex, setTabIndex, ...props }: TabsSectionProps) {
	const { items, renderItem, loadMore, loading, error } = tabs[tabIndex];

	// allows to hide the badge in case the tab had already been read
	const readTabsMemory = use.memo<{ [tabIndex: number]: boolean }>({});
	readTabsMemory[tabIndex] = true;

	const { Spinner, MainButton, Text } = use.theme<AppTheme>();
	return (
		<View {...props}>
			{
				tabs.length > 1 &&
				<View style={localStyles.tabs}>
					{
						tabs.map((tab, index) =>
							<Tab
								key={tab.title}
								title={tab.title}
								icon={tab.icon}
								badge={!readTabsMemory[index] ? tab.badge : undefined}
								isSelected={tabIndex === index}
								onPress={() => setTabIndex(index)}
								style={localStyles.tab}
							/>
						)
					}
				</View>
			}

			<View style={localStyles.items}>
				{items.map(renderItem)}

				<View style={localStyles.footer}>
					{
						loading ? <Spinner /> :
							<>
								{
									items?.length === 0 &&
									<Text style={localStyles.footerText}>
										{`La liste est vide.`}
									</Text>
								}
								{
									Boolean(error) &&
									<Text style={[localStyles.footerText, localStyles.error]}>
										{is(String, error) ? error : error!.message}
									</Text>
								}

								{
									loadMore &&
									<MainButton onPress={loadMore}>
										{error ? `Recharger` : `Charger plus`}
									</MainButton>
								}
							</>
					}
				</View>
			</View>
		</View>
	)
}

interface TabsSectionProps {
	tabs: ClientUITab<any>[],
	tabIndex: number,
	setTabIndex(index: number): void,
}

export interface ClientUITab<T> {
	icon: string,
	title: string,
	badge?: string | undefined,
	renderItem(item: T, index: number): JSX.Element,
	items: T[],
	loading: boolean,
	loadMore: (() => Promise<any>) | undefined,
	error?: undefined | string | Error,
}

const localStyles = StyleSheet.create({
	tabs: {
		flexDirection: "row",
	},

	tab: {
		flex: 1,
	},

	items: {
		backgroundColor: "white",
		paddingTop: 15,
		borderRadius: 20,
	},

	footer: {
		marginVertical: 50,
		alignItems: "center"
	},

	footerText: {
		textAlign: "center",
		marginBottom: 20,
	},

	error: {
		color: "red",
	},
});