import React from "react"
import {FlatList, View as RNView} from "react-native"
import use from "../../../../hook"
import {ChatManager} from "../../../../library-js/app/model/chat";
import ChatItem from "./ChatItem"
import {styles} from "../../../../res";

function ChatList({loadInterlocutors, onSelected, ...props}) {
	const iterator = use.infiniteIterator(() => ChatManager.getChatIterator());
	const {items} = iterator;

	const onItemPress = use.memo(() =>
			items.toObject(
				keyExtractor,
				chat => () => onSelected(chat.interlocutorId),
			),
		[onSelected, ...items]
	);

	const [interlocutors, setInterlocutors] = use.legacyState({});
	const loadMissingInterlocutors = use.callback(async () => {
			const missing = [];

			items.forEach(({interlocutorId: id}) => {
				if (!interlocutors[id])
					missing.push(id);
			});

			if (!missing.length) return; // nothing to load

			const promise = loadInterlocutors(...missing);
			setInterlocutors(
				missing.toObject(
					id => id,
					() => promise
				)
			);

			const response = await promise;
			if (response.ok)
				setInterlocutors(response.content);
			else
				setInterlocutors(
					missing.toObject(
						id => id,
						() => null
					)
				);
		},
		[interlocutors],
	);

	// sync
	use.subscribe(render =>
		ChatManager.listenChats(newChat => {
			const chats = iterator.items;
			const index = chats.findIndex(chat => chat.id === newChat.id);
			const oldChat = index >= 0 ? iterator.items[index] : undefined;
			const shouldBeFirst = oldChat?.time !== newChat.time;
			const replacement = shouldBeFirst ? [] : [newChat];

			if (oldChat)
				chats.splice(index, 1, ...replacement);

			if (shouldBeFirst)
				chats.unshift(newChat);

			render();
		})
	);

	// load interlocutors
	use.effect(loadMissingInterlocutors, [items.length]);

	const {Spinner, Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<FlatList
			data={items.copy()}
			keyExtractor={keyExtractor}
			renderItem={({item, index}) => (
				<ChatItem
					item={item}
					interlocutor={interlocutors[item.interlocutorId]}
					isLast={index === items.lastIndex}
					onPress={onItemPress[keyExtractor(item)]}
				/>
			)}
			contentContainerStyle={localStyles.content}
			onEndReached={iterator.ready ? iterator.load : undefined}
			ListFooterComponentStyle={localStyles.footer}
			ListFooterComponent={
				<RNView style={styles.center}>
					{
						!iterator.end ?
							<Spinner/> :
							(
								!iterator.items.length &&
								<Text style={{textAlign: styles.textAlign.center, margin: 5}}>
									{`Vous n'avez aucune discussion.`}
								</Text>
							)
					}
				</RNView>
			}
			{...props}
		/>
	);
}

export default React.memo(ChatList);

const keyExtractor = chat => chat.id;

const localStyles = {
	layout: {},

	content: {flexGrow: 1},

	footer: {
		paddingVertical: 20,
		backgroundColor: styles.color.white,
		flexGrow: 1,
		...styles.center,
	}
};
