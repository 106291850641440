import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "../../../../res"
import Slot from "../../Slot";
import selectFile from "library-react/utils/selectFile/index.web";
import {Public as Server} from "library-js/app/Server"
import Loading from "../../Loading";
import AddButton from "./AddButton";
import ImageItem from "./ImageItem";

export default React.memo(
	function GalleryImageInput({defaultValue, onValueChanged, placeholder, validate, ...props}) {
		const [value, setValue] = use.legacyState(
			() => !defaultValue ? []
				: (defaultValue instanceof Array ? defaultValue : [defaultValue])
		);

		const [uploading, setUploading] = use.state(false);

		const renderTime = use.renderTime();
		use.effect(
			() => {
				if (onValueChanged && renderTime > 1)
					onValueChanged(value);
			},
			value.copy(),
		);

		const add = use.asyncCallback(shouldStop =>
			() => Promise.process(shouldStop)
				.then(() => selectFile("image/*", true))
				.then(async files => {
					if (!files) return;

					const responses = [];
					setUploading(true);
					for (const file of files) {
						if (shouldStop()) break;

						const response = await Server.media.uploadFile(file);
						responses.push(response);
					}

					return responses;
				})
				.then(responses => responses.mapFiltering(
					response => response.content,
					response => response.ok,
				))
				.then(newMedias => setValue(medias => medias.push(...newMedias)))
				.result((_, error) => {
					setUploading(false);
					if (error)
						console.warn(error);
				})
		);

		const remove = use.callback(image =>
			setValue(gallery => {
				const index = gallery.indexOf(image);
				if (index >= 0)
					gallery.splice(index, 1);
			})
		);

		const valid = !validate
			|| (
				validate instanceof Function ?
					validate(value)
					: (value.length > 0)
			);

		if (!value.length > 0)
			props.onPress = add;

		props.style = use.defaultStyle(
			props.style,
			localStyles.layout(valid),
			[valid, value?.length > 0]
		);
		return (
			<Slot {...props}>
				{value?.map(image => (
					image &&
					<ImageItem
						key={image.url}
						source={image}
						onDeleteClicked={remove}
						style={localStyles.item.image}
					/>
				))}

				{
					uploading ?
						<Loading style={localStyles.item.icon}/> :
						!value?.length ? placeholder
							: (
								<AddButton
									onPress={add}
									style={localStyles.item.icon}
								/>
							)
				}

				{value?.map((_, index) => <Filler key={index}/>)}
			</Slot>
		);
	}
)

const localStyles = {
	layout: styles.static.bool(
		{
			...styles.center,
			justifyContent: styles.justifyContent.spaceEvenly,
			flexDirection: styles.flexDirection.row,
			overflow: styles.overflow.hidden,
			flexWrap: styles.flexWrap.wrap,
			paddingLeft: 10,
			paddingTop: 10,
		},
		null,
		{borderColor: styles.color.error},
	),

	item: styles.static(
		{
			height: 75,
			width: 75,
			marginRight: 10,
			marginBottom: 10,
		},
		{
			image: {},
			icon: {
				padding: 20,
			},
		}
	),
};

function createFiller() {
	const content = <RNView style={{
		...localStyles.item.image,
		height: 0,
	}}/>;

	return () => content;
}

const Filler = createFiller();
