import geocode from "../../library-js/location/geocode";
import {Address} from "../../library-js/app/model";
import Location from "../../library-js/location/Location";

const autoCompleteService = new google.maps.places.AutocompleteService();

export function autoComplete(input) {
	return new Promise((resolve, reject) =>
		autoCompleteService.getPlacePredictions(
			{input},
			(result, status) => {
				if (status === google.maps.places.PlacesServiceStatus.OK)
					resolve(result);
				else if ([
					google.maps.places.PlacesServiceStatus.ZERO_RESULTS,
					google.maps.places.PlacesServiceStatus.NOT_FOUND,
				].includes(status))
					resolve([]);
				else
					reject(status);
			}
		)
	)
		.then(results => results.map(suggestion => suggestion.description));
}

function getInfosOn(autoCompletedPlace) {
	return geocode(autoCompletedPlace)
		.then(results => results.first);
}

export function getAddressOf(autoCompletedPlace) {
	return getInfosOn(autoCompletedPlace)
		.then(place => Address.fromGooglePlace(place));
}

export function getLocationOf(autoCompletedPlace) {
	return getInfosOn(autoCompletedPlace)
		.then(place => {
			if (place.geometry) {
				const latLong = place.geometry.location;
				return new Location(latLong.lat(), latLong.lng());
			}
		});
}
