import use from "..";
import { useMeLoadable } from "./useMe";
import Server from "../../library-js/app/Server";

export default function useReservationSpans() {
	return useReservationSpansLoadable()?.value;
}

export function useReservationSpansLoadable(version) {
	const meLoadable = useMeLoadable();

	return use.loadable.server(meLoadable &&
		async function () {
			if (meLoadable.failed)
				meLoadable.load();
			const me = await Promise.resolve(meLoadable.promise || meLoadable.value);
			const shopId = me?.shop?.shop?.id;
			return Server.Public.reservation.getReservationSpans(shopId);
		},
		[meLoadable, version],
	);
}