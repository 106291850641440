import React from 'react'
import { View as RNView } from "react-native"
import useToast from "library-react/hook/useToast";
import use from "library-react/hook";
import strictAPI from "library-js/app/Server/strictAPI";
import Server from "../../../../library-react/library-js/app/Server";
import willCatchAndToast from "library-react/utils/willCatchAndToast";
import { styles } from "@src/res";
import getResourcesForReservationState from "library-js/res/reservationState";
import LoadingPlaceholder from "library-react/component/LoadingPlaceholder";
import Icon from "library-react/component/Icon/v2";
import TextHolder from "library-react/component/TextHolder";
import paragraph from "library-js/utils/function/paragraph";
import ReservationState from "library-js/app/model/entity/Reservation/State";
import useMe from "library-react/hook/pro/useMe";
import DateInput from "library-react/component/input/DateInput";
import TimeInput from "library-react/component/input/TimeInput";
import Dropdown from "library-react/component/web/Dropdown";
import { always, anyPass, either, F, ifElse, inc, isNil, nth, pipe, split, times } from "ramda";
import TextInput from "library-react/component/input/TextInput";
import ComponentUtils from "library-react/ComponentUtils";
import Reservation from "library-js/app/model/entity/Reservation";
import moment from "moment";
import getObjectIdOf from "library-js/utils/getObjectIdOf";
import PopupMenu from "library-react/component/PopupMenu";
import { useNavigation } from "@react-navigation/native";
import screens from "@main/screens";
import logCalls from "library-js/utils/function/logCalls";

export default function ReservationSection({ edition, loadable, reset, ...props }) {
	const { reservation, currentState } = loadable?.value || {};
	const state = currentState?.type;

	const toast = useToast();

	const [stateUpdating, setStateUpdating] = use.state(); // disable all actions when true
	const notPast = Date.now() < reservation?.time;
	const canUpdateState = notPast && !state.is.refused;

	function updateState(state) {
		setStateUpdating(state);

		strictAPI(Server.retailer.reservation.persist)({ reservation, state })
			.then(vReservation => {
				loadable.setValue(vReservation);
				toast(`Réservation ${state.select({
					accepted: `acceptée`,
					refused: `refusée`,
				})
					}!`);
			})
			.catch(willCatchAndToast(toast))
			.finally(() => setStateUpdating(undefined));
	}

	const { shop } = useMe().shop;
	const [submitted, setSubmitted] = use.state.bool();

	const [persisting, setPersisting] = use.state.bool();
	const navigation = useNavigation();

	function persistEdition() {
		setSubmitted(true);
		// check
		const invalidFields = edition.getInvalidFields(shop);
		if (!invalidFields.length) {
			setPersisting(true);

			strictAPI(Server.retailer.reservation.persist)({ reservation: edition })
				.then(vReservation => {
					if (loadable) {
						loadable.setValue(vReservation);
						toast(`Mise a jour!`);
						setSubmitted(false);
					} else
						navigation.replace(screens.reservation, { id: vReservation.reservation.id });
				})
				.catch(willCatchAndToast(toast))
				.finally(() => setPersisting(false));
		} else {
			toast(`Certains champs sont invalids: ${invalidFields.join(', ')}`);
			console.error({ invalidFields });
		}
	}

	const stateRes = getResourcesForReservationState(state);
	const color = stateRes?.color(reservation?.time);

	const entity = edition || reservation;
	const editable = Boolean(edition && !persisting);


	const edited = edition && !edition.equalsTo(reservation);

	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout, [color || 'lightgrey']);

	return (
		<RNView {...props}>

			{
				loadable &&
				<RNView style={localStyles.state}>
					{
						stateUpdating ? (
							<Text style={localStyles.stateText(getResourcesForReservationState(stateUpdating).color())}>
								{`Envoi en cours...`}
							</Text>
						) :
							state &&
							<>
								<LoadingPlaceholder
									disabled={entity}
									style={localStyles.stateTextWrapper}>
									<PopupMenu
										disabled={!canUpdateState}
										trigger={
											<RNView style={{ flexDirection: 'row', alignItems: 'center' }}>
												{
													canUpdateState &&
													<Icon
														name="MaterialIcons/arrow-drop-down"
														size={24}
														style={{ color, marginRight: 10 }}
													/>
												}
												<Text style={localStyles.stateText(color)}>
													{
														stateRes?.title(reservation?.time) ||
														<TextHolder>
															En attente
														</TextHolder>
													}
												</Text>
											</RNView>
										}>
										{
											[
												state?.is.waiting &&
												ReservationState.Type.accepted,
												ReservationState.Type.refused,
											]
												.filter(Boolean)
												.map(state => {
													const {
														color,
														action,
														icon
													} = getResourcesForReservationState(state);
													return (
														<PopupMenu.Item
															key={state.key}
															style={{ flexDirection: 'row', justifyContent: 'flex-start' }}
															onPress={() => updateState(state)}>
															<Icon
																name={icon()}
																size={26}
																style={{
																	color: color(),
																	marginRight: 15,
																}}
															/>

															<Text style={{
																color: color(),
																bold: true
															}}>
																{action()}
															</Text>
														</PopupMenu.Item>
													);
												})
										}
									</PopupMenu>
								</LoadingPlaceholder>

								<LoadingPlaceholder
									disabled={entity}
									style={localStyles.stateIconWrapper(color)}>
									<Icon
										name={stateRes?.icon()}
										size={30}
										style={localStyles.stateIcon}
									/>
								</LoadingPlaceholder>
							</>
					}
				</RNView>
			}


			<RNView style={localStyles.body}>
				{
					[
						{
							title: `Date`,
							icon: 'MaterialIcons/calendar-today',
							invalid: !edition?.date ? `Requis` :
								!edition?.getMoment().isSameOrAfter(Date.now(), 'day') ? `Passée` :
									!Reservation.validator.date(shop)(edition.date) &&
									`Fermé ce jour`,

							field: entity &&
								<DateInput
									key={getObjectIdOf(edition)}
									value={entity.getDateTimestamp(shop.timezone)}
									editable={editable}
									min={edition && moment().format('YYYY-MM-DD')}
									timezone={shop.timezone}
									onValueChanged={
										edition &&
										function (timestamp) {
											edition.setDateTimestamp(shop.timezone, timestamp);

											if (!shop.isOpenedAt(edition.time)) {
												const day = edition.getDay();
												const firstOpening = shop.weekOpenHours[day][0];
												const time = (firstOpening?.open * Date.MINUTE) || 0;
												edition.setDayTimestamp(timezone, time);
											}
										}
									}
									style={[localStyles.input, { alignSelf: 'flex-start' }]}
								/>
						},
						{
							title: `Heure`,
							icon: 'MaterialIcons/access-time',
							invalid: !edition?.getMoment()?.isAfter(Date.now(), 'minute') ? `Passée` :
								!logCalls(Reservation.validator.dayTime(shop, edition?.getDay()))(edition?.dayTime) &&
								`Fermé à cette heure`,

							field: entity &&
								<TimeInput
									key={getObjectIdOf(edition)}
									value={entity.getDayTimestamp(shop.timezone)}
									editable={editable && !!edition.time}
									onValueChanged={dayTimestamp => entity.setDayTimestamp(shop.timezone, dayTimestamp)}
									style={[localStyles.input, { alignSelf: 'flex-start' }]}
								/>
						},
						{
							title: `Pour`,
							icon: 'MaterialIcons/people',
							invalid: !Reservation.validator.numberOfPlaces()(edition?.numberOfPlaces) &&
								`Requis`,

							field: entity &&
								<Dropdown
									key={getObjectIdOf(edition)}
									placeholder={`Personnes`}
									options={times(pipe(inc, numberOfPlacesIO.write), 25)}
									editable={editable}
									value={numberOfPlacesIO.write(entity?.numberOfPlaces)}
									onValueChanged={
										edition &&
										pipe(numberOfPlacesIO.read, edition.setNumberOfPlaces)
									}
									style={[localStyles.input, { alignSelf: 'flex-start' }]}
								/>,
						},
						{
							title: `Nom`,
							icon: 'MaterialIcons/person',
							invalid: !Reservation.validator.fullName()(edition?.fullName) &&
								`Requis`,

							field: (
								entity &&
								<InputWrapper>
									<TextInput
										key={getObjectIdOf(edition)}
										value={entity?.fullName}
										editable={editable}
										onValueChanged={edition?.setFullName}
										placeholder={`Nom complet`}
										autoComplete="noop"
										style={localStyles.input}
									/>
								</InputWrapper>
							),
						},
						{
							title: `Téléphone`,
							icon: 'MaterialIcons/phone',
							invalid: !Reservation.validator.phone()(edition?.phone) &&
								`Requis`,

							field: (
								entity &&
								<InputWrapper>
									<TextInput
										key={getObjectIdOf(edition)}
										value={entity?.phone}
										editable={editable}
										onValueChanged={edition?.setPhone}
										placeholder={`Numéro de téléphone`}
										style={localStyles.input}
									/>
								</InputWrapper>
							),
						},
						{
							title: `Email`,
							icon: 'MaterialIcons/mail-outline',
							invalid: !Reservation.validator.email()(edition?.email) &&
								`Incorrect`,

							field: (
								entity &&
								<InputWrapper>
									<TextInput
										key={getObjectIdOf(edition)}
										value={entity?.email}
										editable={editable}
										placeholder={`Email (facultatif)`}
										onValueChanged={edition?.setEmail}
										style={localStyles.input}
									/>
								</InputWrapper>
							),
						},
					].map(({ title, icon, invalid, field }, index) =>
						<RNView
							key={index}
							style={localStyles.field}>
							<RNView style={localStyles.fieldHeader(entity)}>
								<Icon
									name={icon}
									size={18}
									style={localStyles.fieldIcon}
								/>

								<Text style={localStyles.fieldTitle}>
									{title}
								</Text>

								<Text style={{ fontSize: 16, color: 'red', marginLeft: 10 }}>
									{
										Boolean(edition && submitted) &&
										invalid
									}
								</Text>
							</RNView>

							<LoadingPlaceholder
								disabled={entity}
								style={[localStyles.fieldWrapper, !field && { alignSelf: 'flex-start' }]}>
								{
									field ||
									<TextHolder style={localStyles.input}>
										00.00.0000
									</TextHolder>
								}
							</LoadingPlaceholder>
						</RNView>
					)
				}
			</RNView>

			{
				(edition || Boolean(entity?.instructions)) &&
				<RNView style={localStyles.instructionsBox}>
					<Text style={{ bold: true, marginBottom: 5, fontSize: 18 }}>
						Note
					</Text>

					<InputWrapper>
						<TextInput
							key={getObjectIdOf(edition)}
							value={entity?.instructions}
							editable={editable}
							placeholder={`Ajouter une note...`}
							onValueChanged={edition?.setInstructions}
							multiline
							style={localStyles.instructions} />
					</InputWrapper>
				</RNView>
			}

			{
				(edition && reservation) &&
				<Text style={{ italic: true, color: 'grey', fontSize: 18, marginBottom: 20, textAlign: 'center' }}>
					{
						edited ? `Enregistrer vos modification ?`
							: `Cliquez sur un champ afin de le modifier.`
					}
				</Text>
			}


			{
				(edited || persisting) &&
				<RNView style={localStyles.actions}>
					{
						persisting ?
							<Text
								style={localStyles.actionText}>
								{`Envoi en cours...`}
							</Text> :
							<>
								{
									reset &&
									<Text
										onPress={reset}
										style={localStyles.actionText}>
										{`Annuler`}
									</Text>
								}

								<Text
									onPress={persistEdition}
									style={[localStyles.actionText, { color: styles.color.azure }]}>
									{`Enregistrer`}
								</Text>
							</>
					}
				</RNView>
			}
		</RNView>
	);
}

function InputWrapper({ children: inputElement }) {
	const [focus, setFocus] = use.state.bool();

	if (inputElement.props.editable) {
		inputElement = React.cloneElement(inputElement, props => {
			props.style = ComponentUtils.style.concat(
				!focus && { cursor: 'pointer' },
				props.style,
			);

			props.onFocus = setFocus.true;
			props.onBlur = setFocus.false;
		});
	}


	return inputElement;
}

const numberOfPlacesIO = {
	write: ifElse(Number.isFinite,
		i => paragraph`${i} personne${i > 1 && 's'}`,
		always(undefined),
	),

	read: either(
		pipe(split(' '), nth(0), Number),
		always(undefined),
	),
}

const localStyles = {
	layout: borderColor => ({
		padding: 20,
		backgroundColor: 'white',
		borderRadius: 9,
		borderWidth: 2,
		borderColor,
	}),

	state: {
		flexDirection: 'row',
		alignSelf: 'flex-end',
		alignItems: 'center',
		zIndex: 1,
	},

	stateTextWrapper: {
		borderRadius: 6,
	},

	stateText: color => ({
		color,
		bold: true,
		fontSize: 25,
		letterSpacing: 1.2,
	}),

	stateIconWrapper: backgroundColor => [
		{
			marginLeft: 20,
			...styles.circle(47),
			...styles.center,
		},
		backgroundColor && { backgroundColor },
	],

	stateIcon: {
		color: 'white',
	},

	body: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-evenly',
		alignSelf: 'center',
		width: '80%',
		maxWidth: 900,
		marginTop: 15,
	},

	field: {
		width: '33%',
		minWidth: 300,
		marginBottom: 30,
	},

	fieldHeader: styles.static.bool({
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 8,
	},
		{},
		{ opacity: 0 },
	),

	fieldIcon: {
		color: 'grey',
		marginRight: 8,
	},

	fieldTitle: {
		fontSize: 16,
		color: 'grey',
	},

	fieldWrapper: {
		borderRadius: 5,
	},

	fieldValue: {
		fontSize: 21,
		// color: 'black',
		bold: true,
		marginLeft: 1,
	},

	quotes: {
		color: 'lightgrey',
		marginHorizontal: 20,
	},

	instructionsBox: {
		alignSelf: 'center',
		marginVertical: 40,
	},

	instructions: {
		fontSize: 18,
		fontStyle: 'italic',
		height: 150,
		maxWidth: '100%',
		width: 600,
		borderRadius: 10,
		padding: 10,
		backgroundColor: '#f6f6f6',
		// fontWeight: 'bold',
		// textStyle: 'italic',
	},

	actions: {
		height: 50,
		// marginBottom: -(20 + 25 + 2),
		flexDirection: 'row',
		justifyContent: 'space-evenly',
	},

	actionText: {
		bold: true,
		fontSize: 20,
	},

	input: {
		borderWidth: 0,
		backgroundColor: 'transparent',
		fontSize: 21,
		color: 'black',
		marginLeft: 1,
	},
};
