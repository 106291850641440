import paragraph from "library-js/utils/function/paragraph";
import Icon from "library-react/component/Icon/v2";
import Portal from "library-react/component/Portal";
import View from "library-react/component/View/v2";
import use from "library-react/hook";
import React from "react";
import { Switch, View as RNView } from 'react-native';
import useReservationSpans from "../../../../library-react/hook/pro/useReservationSpans";
import { styles } from "../../../../res";
import Card from "../../../component/Card";
import screens from "../../../screens";


export default function ReservationsCard({ editedShop, willResetFields, ...props }) {
	const { spans, closures } = useReservationSpans() || {};

	const { Text } = use.theme();
	props.onCanceled = willResetFields(['acceptsReservations']);

	return (
		<Card
			icon='Octicons/calendar'
			title={`Réservations`}
			subtitle={`Recevez des réservations à votre établissement`}
			{...props}>
			{(editable, toggleMode) =>
				<RNView style={localStyles.display}>
					<View
						onPress={() => {
							if (!editable)
								toggleMode();
							editedShop.acceptsReservations = !editedShop.acceptsReservations;
						}}
						style={localStyles.option}>
						<RNView style={{ flex: 1 }}>
							<Text style={localStyles.title}>
								{`Accepter les réservations`}
							</Text>

							<Text style={localStyles.subtitle}>
								{paragraph`Votre établissement ${!editedShop.acceptsReservations && `n'`}accepte${!editable && ` actuellement`} pas les réservations`}
							</Text>
						</RNView>

						<Switch
							value={editedShop.acceptsReservations}
							style={localStyles.switch}
						/>
					</View>

					{
						[
							{
								title: `services`,
								description: `Horraires d'ouvertures des réservations`,
								length: spans?.length,
							},
							{
								title: `périodes de fermeture`,
								description: `Périodes de fermeture des réservations`,
								length: closures?.length,
							},
						].map(({ title, description, length }) =>
							<Portal
								key={title}
								to={screens.reservationSpansList}>
								<View style={localStyles.option}>
									<RNView style={{ flex: 1 }}>
										<Text style={localStyles.title}>
											{paragraph`Gérer les ${title}${length > 0 && ` (${length})`}`}
										</Text>

										<Text style={localStyles.subtitle}>
											{description}
										</Text>
									</RNView>

									<Icon
										name="MaterialIcons/chevron-right"
										size={30}
									/>
								</View>
							</Portal>
						)
					}

				</RNView>
			}
		</Card>
	);
}

const localStyles = {
	display: {
		paddingHorizontal: 30,
		flexGrow: 1,
	},

	option: {
		flexDirection: 'row',
		alignItems: 'center',
		paddingVertical: 20,
	},

	title: {
		bold: true,
		fontSize: 18,
		marginBottom: 2,
	},

	subtitle: {
		color: 'grey',
	},

	switch: {

	},

	header: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	title: {
		bold: true,
	},

	action: {
		color: styles.color.azure,
	},

	row: {
		paddingVertical: 10,
		flexDirection: 'row',
		// alignItems: 'center'
	},
};
