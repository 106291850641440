import React from "react";
import use from "library-react/hook";
import ImageInput from "../../../../component/ImageInput";
import View from "library-react/component/View";
import Icon from "library-react/component/Icon";
import {styles} from "../../../../../res";
import Text from "react-native-web/dist/exports/Text";
import Label from "./Label";

export default React.memo(
	function ({defaultValue, onValueChanged, ...props}) {
		props.style = use.defaultStyle(props.style, localStyles.layout);
		return (
			<View {...props}>
				<Label title={'Ajouter l’image principal de la page d’accueil de votre site'}
					   subtitle={'Optez pour une image de haute qualité (min 3000px/2000px)\n' +
					   'Mettre une phrase expliquant l’impact de cette image'}
				/>
				<ImageInput onValueChanged={onValueChanged}
							placeholder={<SinglePlaceholder/>}
							style={localStyles.image}/>
			</View>
		);
	}
)
const SinglePlaceholder = React.memo(
	function SinglePlaceholder({...props}) {
		props.style = use.defaultStyle(props.style, localStyles.placeholder.layout);
		return (
			<View {...props}>
				<Icon name="picture"
					  style={localStyles.placeholder.picture}
				/>
				<Text style={localStyles.placeholder.text}>
					Ajouter l’image Master
				</Text>
			</View>
		)
	}
);

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.column,
	},
	image: {
		marginVertical: 45,
		width: 570,
		height: 170,
	},
	placeholder: {
		layout: {
			flexDirection: styles.flexDirection.column,
			justifyContent: styles.justifyContent.center,
			alignItems: styles.alignItems.center,
			position: styles.position.absolute,
			top: 50,
			right: 0,
			left: 0,
		},
		picture: {
			width: 55,
			height: 44,
			marginBottom: 13,
		},
		text: {
			italic: true,
			fontSize: 18,
			color: styles.color.azure2,
		},
	}
};
