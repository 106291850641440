import Icon from "library-react/component/Icon/v2";
import use from "library-react/hook";
import { styles } from "../../res";

export default function EditButton({...props}) {
	const [hovered, setHovered] = use.state.bool();
	props.style = use.defaultStyle(props.style, defaultStyle(hovered), [hovered]);

	return (
		<Icon
			name="edit"
			onHoverChanged={setHovered}
			size={26}
			{...props}/>
	);
}

const defaultStyle = (hover) => ({
	...styles.center,
	backgroundColor: hover ? styles.color.white : styles.color.main,
	color: hover ? styles.color.main : styles.color.white,
	width: 54,
	height: 54,
	borderWidth: .5,
	borderRadius: 54 / 2,
	borderColor: styles.color.main,
});
