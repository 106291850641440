import { linking as websiteLinking } from "./ui/WebsiteUI/screens";
import { getStateFromPath, LinkingOptions } from "@react-navigation/native"
import parsePathname from "../library-react/library-js/utils/URL/parsePathname";

const screens = {
	welcome: 'welcome',

	shop: 'shop',
	catalog: 'catalog',
	product: 'product',
	editProduct: 'editProduct',

	showcasesMarket: 'showcasesMarket',
	createShowcase: 'createShowcase',
	showcase: 'showcase',

	ordersAndReservations: 'ordersAndReservations',

	orderList: 'orderList',
	order: 'order',

	reservationsList: 'reservationsList',
	reservationsCalendar: 'reservationsCalendar',
	reservation: 'reservation',
	newReservation: 'newReservation',
	reservationSpansList: 'reservationSpansList',
	editReservationSpan: 'editReservationSpan',
	editReservationClosure: 'editReservationClosure',

	clientList: 'clientList',
	client: 'client',

	paymentSettings: 'paymentSettings',
	paymentTransaction: 'paymentTransaction',

	profile: 'profile',

	sections: 'sections',
	website: 'website',
} as const;

export default screens;

export type RootStackParamsList = {
	shop: undefined,

	catalog: undefined,
	sections: undefined,
	product: { id: number },
	editProduct: { id: number },

	ordersAndReservations: undefined,
	order: { id: number },

	reservationsCalendar: undefined,
	reservationSpansList: undefined,
	editReservationSpan: { id?: number },
	editReservationClosure: { id?: number },
	newReservation: undefined,
	reservation: { id: number },

	clientList: undefined,
	client: { id: string },

	paymentSettings: undefined,
	paymentTransaction: undefined,

	welcome: undefined,
	profile: undefined,
	website: undefined,

	showcasesMarket: undefined,
	createShowcase: undefined,
	showcase: { id: number },

};

export const linking: LinkingOptions<RootStackParamsList> = {
	prefixes: ["https://pro.shopinzon.com", "https://beta-shopinzon-pro.web.app"],

	config: {
		screens: {
			shop: '',
			catalog: 'product',
			product: 'product/:id',
			editProduct: 'product/edit/:id?',

			showcasesMarket: 'showcase',
			createShowcase: 'showcase/create',
			showcase: 'showcase/:id',

			sections: 'catalog/sections', // can't use product/sections while native app doesn't handle it

			ordersAndReservations: {
				screens: {
					orderList: {
						path: 'order',
						exact: true,
					},
					reservationsList: {
						path: 'reservation',
						exact: true,
					},
				}
			},

			reservationsCalendar: 'reservations-calendar',

			order: 'order/:id',
			reservationSpansList: 'reservation-span',
			editReservationSpan: 'reservation-span/edit',
			editReservationClosure: 'reservation-closure/edit',
			newReservation: 'reservation/edit',
			reservation: 'reservation/:id',


			clientList: 'client',
			client: 'client/:id',

			paymentSettings: 'payment/settings',
			paymentTransaction: 'payment/transaction',

			welcome: 'welcome',
			profile: 'profile',
			website: {
				path: 'website',
				screens: websiteLinking,
			},
		},
	},

	getStateFromPath(path, options) {
		try {
			const userId = retrieveChatID(path);

			if (userId)
				return {
					routes: [
						{
							name: screens.client,
							params: { id: userId },
						}
					]
				};
		} catch (error) {
			console.error(error);
		}

		return getStateFromPath(path, options);
	}
};

export function retrieveChatID(pathname: string) {
	const [screenName, id] = parsePathname(pathname);
	if (screenName === 'chat')
		return id;
}
