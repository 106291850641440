import React from "react";
import {View as RNView} from "react-native"
import View from "library-react/component/View/v2";
import Text from "library-react/component/Text/v2";
import getResourcesForKYCDocumentType from "library-js/res/getResourcesForKYCDocumentType";
import use from "library-react/hook";
import {styles} from "@src/res"
import Row from "library-react/component/Row";
import Button from "@main/component/Button";
import Loading from "@main/component/Loading";
import selectFile from "library-react/utils/selectFile";
import getResourcesForDocumentStatus from "library-js/res/getResourcesForDocumentStatus";
import PaymentConfigurationStatus from "library-js/app/model/entity/Shop/PaymentConfigurationStatus";

export default function KycDocumentItem({type, value, updateState, ...props}) {
	const [kycDocument, setKycDocument] = use.state(value);

	const resource = getResourcesForKYCDocumentType(type);
	const status = getResourcesForDocumentStatus(kycDocument?.Status);

	const refused = kycDocument?.Status.is.REFUSED || kycDocument?.Status.is.OUT_OF_DATE;
	const editable = !kycDocument || kycDocument?.Status.is.CREATED || refused;
	const submittable = kycDocument?.Status.is.CREATED;

	const [addingFile, setAddingFile] = use.state(false);
	const [error, setError] = use.state();

	const addFile = async () => {
		const file = await selectFile(".pdf, .jpeg, .jpg, .png");

		if (file) {
			setAddingFile(true);
			const encodedFile = await file.toBase64();

			if (byteSize(encodedFile) > 10000000) // check encoded file size less than 10MB
				setError(true);
			else {
				const response = await Server.retailer.payment.kyc.add(type, encodedFile);
				if (response.ok) {
					setKycDocument(response.content);
					updateState(state => state[type] = PaymentConfigurationStatus.DocumentStatus.CREATED);
				}
			}

			setAddingFile(false);
		}
	};

	const [submitting, setSubmitting] = use.state(false);
	const submitKyc = async () => {
		setSubmitting(true);

		const response = await Server.retailer.payment.kyc.submit(kycDocument.Id);
		if (response.ok) {
			setKycDocument(response.content);
			updateState(state => state[type] = PaymentConfigurationStatus.DocumentStatus.ASKED);
		}

		setSubmitting(false);
	};


	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<Row {...props} columnsStyles={columnsStyles}>
			<View>
				<Text style={localStyles.title}>
					{resource.title()}
				</Text>
				<Text style={localStyles.document}>
					{resource.document()}
				</Text>
			</View>
			{
				editable && (
					addingFile ? <Loading/>
						: <Text style={localStyles.add(error)}
								onPress={addFile}>
							{`Ajouter un fichier${error ? ". Taille max 10MB" : ""}`}
						</Text>
				)
			}
			{
				submittable ?
					(
						!addingFile &&
						<Button loading={submitting}
								onPress={submitKyc}
								disabled={addingFile || submitting}
								style={localStyles.submit}>
							{`Soumettre`}
						</Button>
					) :
					(
						<View>
							<Text style={localStyles.title}>
								{status?.title()}
							</Text>
							{
								refused &&
								<Text style={localStyles.document}>
									{`${kycDocument?.RefusedReasonMessage || kycDocument?.RefusedReasonType || ''}`}
								</Text>
							}
						</View>

					)
			}

		</Row>
	)
}

export function KycDocumentHeader({...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<Row {...props} columnsStyles={columnsStyles}>
			<Text style={{bold: true}}>
				Document
			</Text>
			<RNView/>
			<Text style={{bold: true}}>
				Status
			</Text>
		</Row>
	);
}

function byteSize(str) {
	return new Blob([str]).size;
}

const columnsStyles = [
	{
		justifyContent: styles.justifyContent.center,
		width: 300,
		flexShrink: 1,
	},
	{
		justifyContent: styles.justifyContent.center,
		alignItems: styles.alignItems.center,
		width: 100,
		flexShrink: 1,
	},

	{
		justifyContent: styles.justifyContent.center,
		width: 100,
		flexShrink: 1,
	},
];
const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
	},
	title: {
		fontSize: 20,
	},
	document: {
		fontSize: 12,
	},
	add: styles.static.bool(
		{
			padding: 5,
			borderRadius: 5,
			textDecorationLine: styles.textDecorationLine.underline,
			borderWidth: .1
		},
		{
			borderColor: 'red',
			color: 'red',
		},
		{}),
	submit: {
		alignSelf: styles.alignSelf.flexEnd,
		flexDirection: styles.flexDirection.center,
	}
}
