import React from "react"
import {View as RNView} from "react-native"
import use from "../../../../../hook"
import Text from "../../../../../component/Text";
import View from "../../../../../component/View";
import LoadingPlaceholder from "../../../../../component/LoadingPlaceholder";
import Interlocutor from "../../../Interlocutor";
import moment from "moment";
import ChatEventView from "./ChatEventView";
import {fonts, images, strings, styles} from "../../../../../res";
import ImagePlaceholder from "../../../../../component/ImagePlaceholder";

export default React.memo(
	function ChatItem({item: chat, interlocutor, isLast, ...props}) {

		props.style = use.defaultStyle(props.style, localStyles.layout);

		return (
			<View {...props}>
				<View style={localStyles.picture.layout}>
					<View style={localStyles.picture.wrapper}>
						{
							(interlocutor instanceof Interlocutor) ?
								<ImagePlaceholder
									defaultSource={images.interlocutor}
									source={interlocutor.picture}
									resizeMode="cover"
									style={localStyles.picture.self}
								/> :
								(interlocutor instanceof Promise) &&
								<LoadingPlaceholder style={styles.fit}/>
						}
					</View>

					{
						(chat.unread > 0) &&
						<View style={localStyles.unread.layout}>
							<Text style={localStyles.unread.text}>
								{chat.unread}
							</Text>
						</View>
					}
				</View>

				{/*infos*/}
				<View style={localStyles.infos.layout(isLast)}>
					<View style={localStyles.infos.head.layout}>
						{
							(interlocutor instanceof Interlocutor) ?
								<Text
									style={localStyles.infos.head.name}
									numberOfLines={1}>
									{interlocutor.name}
								</Text> :
								(interlocutor instanceof Promise) ?
									<LoadingPlaceholder style={{width: 90, height: 15, borderRadius: 20}}/> :
									<RNView/>
						}

						<Text
							numberOfLines={1}
							style={localStyles.infos.head.time}>
							{
								chat.event &&
								moment(chat.event.time).calendar(null, strings.time.calendar)
							}
						</Text>
					</View>

					{
						chat.event?.ok &&
						<ChatEventView
							event={chat.event}/>
					}
				</View>
			</View>
		);
	}
);


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		paddingHorizontal: 16,
		paddingTop: 20,
		backgroundColor: styles.color.white,
	},

	picture: {
		layout: {
			marginRight: 6,
			marginBottom: 7,
			alignSelf: styles.alignSelf.flexStart,
		},

		wrapper: {
			height: 56,
			width: 56,
			borderRadius: 28,
			overflow: styles.overflow.hidden,
			...styles.newBorder(.5, '#dad9e1'),
		},

		self: {
			...styles.fit,
			backgroundColor: "#eee",
		}
	},

	infos: {
		layout: styles.static.bool(
			{
				paddingLeft: 11,
				paddingRight: 8,
				paddingBottom: 10,
				flex: 1,
				overflow: styles.overflow.hidden,
			},
			{},
			{
				borderBottomColor: '#dad9e1',
				borderBottomWidth: 1,
			}
		),

		head: {
			layout: {
				flexDirection: styles.flexDirection.row,
				alignItems: styles.alignItems.center,
				justifyContent: styles.justifyContent.spaceBetween,
				minHeight: 24,
				marginBottom: 4,
			},

			name: {
				fontSize: 16,
				bold: true,
				flex: 1,
				font: fonts.Poppins,
			},

			time: {
				fontSize: 11,
				color: '#a6a5a6',
				marginLeft: 5,
			}
		},
	},

	unread: {
		layout: {
			position: styles.position.absolute,
			top: -4,
			right: -4,
			backgroundColor: "red",

			...styles.center,
			height: 20,
			minWidth: 20,
			borderRadius: 20 / 2,
			paddingHorizontal: 1,
		},

		text: {
			color: styles.color.white,
			fontSize: 13,
			lineHeight: 13,
			textAlign: styles.textAlign.center,
		}
	},
};
