import React from "react"
import {ScrollView, View as RNView, Switch} from "react-native"
import use from "library-react/hook"
import {styles} from "../../../../../res"
import Text from "library-react/component/Text/v2";
import Image from "library-react/component/Image";
import useWebsiteConfig from "../useWebsiteConfig";
import useUpdateConfig from "../useUpdateConfig";
import TextInput from "library-react/component/input/TextInput";
import Button from "../../../../component/Button";

function MenuConfigUI({...props}) {
	const config = useWebsiteConfig();

	const originalName = config?.catalog.name;
	let [name, setName] = use.state(originalName);
	// apply persisted name as default once loaded
	use.syncEffect((persistedName) => {
			if (config && name !== persistedName)
				setName(name = persistedName);
		},
		[originalName]
	);

	const updateConfig = useUpdateConfig();
	const [savingName, setSavingNameTo] = use.state.bool();
	const [savingBrands, setSavingBrandsTo] = use.state.bool();
	const [savingShowcases, setSavingShowcasesTo] = use.state.bool();
	const saving = savingName || savingBrands || savingShowcases;

	const submitName = !saving &&
		(name !== originalName) &&
		async function persistName() {
			setSavingNameTo(true);

			try {
				const edition = config.copy({catalog: true});
				edition.catalog.name = name;
				await updateConfig(edition);
			} finally {
				setSavingNameTo(false);
			}
		};

	const toggleBrands = !saving &&
		async function persistBrandsToggle() {
			setSavingBrandsTo(true);

			try {
				const edition = config.copy({catalog: true});
				edition.catalog.shouldShowBrands = !config.catalog.shouldShowBrands;
				await updateConfig(edition);
			} finally {
				setSavingBrandsTo(false);
			}
		};

	const toggleShowcases = !saving &&
		async function persistShowcasesToggle() {
			setSavingShowcasesTo(true);

			try {
				const edition = config.copy({catalog: true});
				edition.catalog.shouldShowShowcases = !config.catalog.shouldShowShowcases;
				await updateConfig(edition);
			} finally {
				setSavingShowcasesTo(false);
			}
		};


	props.style = use.defaultStyle(props.style, localStyles.layout);
	props.contentContainerStyle = localStyles.content;

	return (
		(config?.catalog || null) &&
		<ScrollView {...props}>
			{/* Catalog title */}
			<RNView style={localStyles.section}>
				<RNView style={localStyles.header}>
					<Text style={localStyles.title}>
						{`Modifiez le titre de votre catalogue`}
					</Text>
				</RNView>

				<Text style={localStyles.description}>
					{`Vous pouvez ci-dessous modifier le titre de votre catalogue affiché dans le menu du site. `}
					<Text style={{bold: true}}>
						{`Par défaut, le titre est Catalogue. `}
					</Text>
					{`Vous trouverez ci-desous des exemples pour différents commerces.`}
				</Text>

				<RNView style={localStyles.catalogNameForm}>
					<TextInput
						defaultValue={config.catalog.name}
						onValueChanged={setName}
						disabled={saving}
						placeholder={`"Catalogue" sera affiché si le champ est vide`}
						style={localStyles.catalogNameInput}
					/>

					<Button
						onPress={submitName}
						loading={savingName}
						raised={submitName}
						style={localStyles.submitName}>
						{`Enregistrer`}
					</Button>
				</RNView>

				<RNView style={localStyles.catalogNameDemos}>
					<RNView>
						<Image
							source={require("./catalog-demo.png")}
							resizeMode="contain"
							style={localStyles.catalogDemo}
						/>
						<Text style={localStyles.demoIndicator}>
							{`Titre par défaut`}
						</Text>
					</RNView>

					<RNView>
						<Image
							source={require("./menu-demo.png")}
							resizeMode="contain"
							style={localStyles.menuDemo}
						/>
						<Text style={localStyles.demoIndicator}>
							{`Exemple pour un restaurant`}
						</Text>
					</RNView>

					<RNView>
						<Image
							source={require("./services-demo.png")}
							resizeMode="contain"
							style={localStyles.menuDemo}
						/>
						<Text style={localStyles.demoIndicator}>
							{`Exemple pour un coiffeur`}
						</Text>
					</RNView>

				</RNView>
			</RNView>

			{/* Brands */}
			<RNView style={localStyles.section}>
				<RNView style={localStyles.header}>
					<Text style={localStyles.title}>
						{`Afficher les marques dans le menu du site`}
					</Text>

					<Switch
						value={
							savingBrands ? !config.catalog.shouldShowBrands
								: config.catalog.shouldShowBrands
						}
						disabled={saving}
						onClick={toggleBrands}
						style={localStyles.switch}/>
				</RNView>

				<Text style={localStyles.description}>
					{`Lorsque cette option est activé, une ligne `}
					<Text style={{bold: true}}>
						{`NOS MARQUES`}
					</Text>
					{` est ajouté dans le menu de navigation de votre site qui permetra d'afficher sur une page la liste des marques référencé dans votre catalogue.`}
				</Text>

				<Image
					source={require("./brands-demo.png")}
					resizeMode="contain"
					style={localStyles.brandsDemo}
				/>

				<Text style={localStyles.demoIndicator}>
					{`L'image ci-dessus est à titre d'exemple`}
				</Text>
			</RNView>

			{/*	Showcases */}
			<RNView style={localStyles.section}>
				<RNView style={localStyles.header}>
					<Text style={localStyles.title}>
						{`Afficher un racourcie vers vos vitrines`}
					</Text>

					<Switch
						value={
							savingShowcases ? !config.catalog.shouldShowShowcases
								: config.catalog.shouldShowShowcases
						}
						disabled={saving}
						onClick={toggleShowcases}
						style={localStyles.switch}/>
				</RNView>

				<Text style={localStyles.description}>
					{`Lorsque cette option est activé, une ligne `}
					<Text style={{bold: true}}>
						{`NOS VITRINES`}
					</Text>
					{` est ajouté dans le menu de navigation de votre site qui permetra d'ourvir une page avec la liste de vos vitrines en ligne.`}
				</Text>

				<Image
					source={require("./showcases-demo.png")}
					resizeMode="contain"
					style={localStyles.brandsDemo}
				/>

				<Text style={localStyles.demoIndicator}>
					{`L'image ci-dessus est à titre d'exemple`}
				</Text>
			</RNView>

		</ScrollView>
	);
}

export default React.memo(MenuConfigUI);


const localStyles = {
	layout: {
		flex: 1,
	},

	content: {
	},

	section: {
		paddingVertical: 50,
		paddingLeft: 30,
	},

	header: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		marginBottom: 30,
	},

	title: {
		fontSize: 22,
		marginRight: 30,
		flexShrink: 1,
		bold: true,
	},

	catalogNameForm: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
	},

	catalogNameInput: {
		padding: 15,
		borderBottomWidth: 1,
		borderColor: styles.color.azure2,
		width: 500,
		marginRight: 20,
		fontSize: 18,
		fontWeight: 'bold',
	},

	submitName: {},

	catalogNameDemos: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceEvenly,
		flexWrap: styles.flexWrap.wrap,
		paddingTop: 50,
	},

	catalogDemo: {
		height: 500,
		width: 500 * 538 / 1232,
		borderWidth: 1,
		borderColor: styles.color.lightgrey,
	},
	menuDemo: {
		height: 500,
		width: 500 * 538 / 1232,
		borderWidth: 1,
		borderColor: styles.color.lightgrey,
	},

	switch: {
		transform: [{scale: 1.5}]
	},

	description: {
		marginBottom: 30,
		lineHeight: 22,
	},


	brandsDemo: {
		height: 600,
	},

	demoIndicator: {
		italic: true,
		color: 'grey',
	},
};
