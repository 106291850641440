import { mergeLeft } from "ramda";
import React from "react";
import { StyleSheet, View, ViewProps } from "react-native";
import { useStateWithDeps } from "use-state-with-deps";
import use from "../../../../library-react/hook";
import { gql, useQuery } from "../../../../library-react/library-js/app/graphql";
import getFragmentName from "../../../../library-react/library-js/app/graphql/fragment/getFragmentName";
import OrderRow, { OrderRowHeader, ORDER_ROW_FRAGMENT } from "../../../component/OrderRow";
import { AppTheme } from "../../../theme";
import { Filter } from "./filters";

function OrderList({ filter, ...props }: OrderListProps) {
	const { includedStates } = filter.config;
	const [{ end, variables }, setIterator] = useStateWithDeps({
		end: false,
		variables: { includedStates },
	}, [includedStates]);
	const { data, loading, fetchMore } = useQuery(ORDERS_LIST_QUERY, { variables, notifyOnNetworkStatusChange: true });
	const orders = data?.order_v2;

	const loadMore = (!data || end || loading) ? undefined :
		function loadMore() {
			const offset = orders?.length;
			fetchMore({ variables: { offset } })
				.then(({ data }) => {
					if (data?.order_v2?.length < LIMIT)
						setIterator(mergeLeft({ end: true }));
				});
		};


	const { Spinner, MainButton, Text } = use.theme<AppTheme>();
	return (
		<View {...props}>
			{
				orders?.length! > 0 &&
				<OrderRowHeader />
			}

			{/*Order List*/}
			{
				orders?.map(item =>
					<OrderRow
						key={item.id}
						item={item}
					/>
				)
			}

			{
				loading ?
					<Spinner size={50} style={localStyles.spinner} /> :
					loadMore ?
						<MainButton
							onPress={loadMore}
							style={localStyles.more}>
							{`Voir plus`}
						</MainButton> :

						orders?.length === 0 &&
						<Text style={localStyles.empty}>
							{`Vous n'avez encore aucune commande.`}
						</Text>

			}
		</View>
	);
}

export default React.memo(OrderList);

interface OrderListProps extends ViewProps {
	filter: Filter
}

const LIMIT = 50;
const ORDERS_LIST_QUERY = gql`query proweb_orderUI($includedStates: [Int!]!, $offset: Int = 0){
	order_v2(
		where: {currentState: {state: {_in: $includedStates}}},
		order_by: {creationDate: desc},
		offset: $offset,
		limit: ${LIMIT},
	) {
		id
		...${getFragmentName(ORDER_ROW_FRAGMENT)}
	}
} ${ORDER_ROW_FRAGMENT}`;

const localStyles = StyleSheet.create({
	more: {
		marginTop: 40,
		alignSelf: "center",
	},

	spinner: { marginTop: 40 },

	empty: {
		textAlign: 'center',
		margin: 40
	},
});
