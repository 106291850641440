import { gql, useApolloClient } from "@apollo/client";
import { FrameContext } from "@main/App/Frame";
import { Server } from "library-js/app";
import isEligibleForReservations from "library-js/app/isEligibleForReservations";
import PaymentMean from "library-js/app/model/entity/Order/PaymentMean";
import GalleryModal from "library-react/component/GalleryModal";
import Icon from "library-react/component/Icon/v2";
import Image from "library-react/component/Image";
import Text from "library-react/component/Text";
import View from "library-react/component/View/v2";
import { useMeLoadable } from "library-react/hook/pro/useMe";
import { applyTo, complement, dec, equals, ifElse, inc, is } from "ramda";
import { Animated, View as RNView } from "react-native";
import useApp from "../../../../library-react/app/useApp";
import { persistShopTags, useShopTags } from "../../../../library-react/component/input/ShopTagsInput";
import use from "../../../../library-react/hook";
import useLegacyRetailer from "../../../../library-react/hook/pro/useLegacyRetailer";
import strictAPI from "../../../../library-react/library-js/app/Server/strictAPI";
import { styles } from "../../../../res";
import strings from "../../../../res/strings";
import Button from "../../../component/Button";
import persistShop from "../persistShop";
import AddressCard from "./AddressCard";
import InfosCard from "./InfosCard";
import OpenHoursCard from "./OpenHoursCard";
import PaymentCard from "./PaymentCard";
import ReceptionCard from "./ReceptionCard";
import ReservationsCard from "./ReservationsCard";
import SocialCard from "./SocialCard";

export default function InfosPage() {
	const meLoadable = useMeLoadable();
	const me = meLoadable.value;
	const { shop } = me.shop;

	const loader = useLegacyRetailer(gql`
		fragment ShopInfosPage on retailer {
			shop {
				paymentMeans
				paymentMeansList @client
				stripeAccount {
					id
					accountId
					capable
					cardPaymentCapability
				}
			}
		}
	`);

	const paymentConfigLoadable = use.loadable.server(() => Server.retailer.payment.getConfigState());

	const editedShop = use.memo(() => {
		const editedShop = shop.copy().bindSetters();
		editedShop.paymentMeans = shop.paymentMeans?.slice();
		editedShop.receptionMeans = shop.receptionMeans?.slice();
		return editedShop;
	},
		[shop],
	);

	use.subscribe(editedShop.onPropertyChanged, [editedShop]);

	const [shopTags] = useShopTags(shop?.id);
	let [editedShopTags, setEditedShopTags] = use.state(shopTags);
	use.effect(() => {
		if (shopTags)
			setEditedShopTags(shopTags);
	}, [shopTags]);



	const [submittedTime, incrementSubmittedTime] = use.version();
	const [persisting, setPersistingTo] = use.state.bool(false);

	const [cardsVersion, refreshCards] = use.version();
	const apollo = useApolloClient();
	const submit = () => {
		incrementSubmittedTime();

		const invalidFields = editedShop.getInvalidFields();
		if (invalidFields.length !== 0)
			return

		if ( // remove payment online if not configured
			editedShop.paymentMeans?.includes(PaymentMean.online)
			&& !paymentConfigLoadable.value?.ok
		)
			editedShop.paymentMeans = editedShop.paymentMeans.filter(complement(equals(PaymentMean.online)));


		setPersistingTo(true);

		const shopPromise = strictAPI(persistShop)(meLoadable, editedShop);

		const shouldPersistTags = shopTags && !equals(shopTags, editedShopTags);
		const tagsPromise = shouldPersistTags ? persistShopTags(apollo, shop.id, editedShopTags) : Promise.resolve();

		return Promise.all([shopPromise, tagsPromise])
			.then(() => {
				setOpenedCards(0);
				refreshCards();
				loader.refetch();
			})
			.catch(console.error)
			.finally(setPersistingTo.false);
	};

	const app = useApp();
	const openFullscreenGallery = (startIndex) => app.openModal(
		<GalleryModal
			items={[shop.logo].concat(shop.gallery)}
			startIndex={startIndex} />
	);

	const [openedCards, setOpenedCards] = use.state(0);
	const updateNumberOfOpenedCard = use.callback(editing => setOpenedCards(editing ? inc : dec))

	const bannerUrl = shop.gallery?.head?.url;

	const FrameControl = use.context(FrameContext);

	const willResetFields = fields => () =>
		fields?.forEach(
			ifElse(is(Function),
				applyTo(undefined),
				field =>
					editedShop[field] = shop[field]?.copy?.() || shop[field]
			)
		);

	const [submitAv, shakeSubmitButton] = use.memo(() => {
		const av = new Animated.Value(1);
		let shaking = undefined;
		function shake() {
			if (shaking)
				shaking.stop();

			shaking = Animated.sequence([
				Animated.timing(av, {
					toValue: .9,
					duration: 100,
					useNativeDriver: true,
				}),
				Animated.spring(av, {
					toValue: 1,
					duration: 500,
					friction: 2,
					useNativeDriver: true,
				}),
			]);

			shaking.start(() => shaking = undefined);
		}

		return [av, shake];
	});

	const cardProps = {
		onToggle: updateNumberOfOpenedCard,
		editedShop,
		willResetFields,
		submittedTime,
		onSubmitIndicationClicked: shakeSubmitButton,
		loader,
		style: localStyles.card,
	};

	return (
		<FrameControl floating={
			(openedCards > 0) &&
			<Button
				loading={persisting}
				onPress={submit}
				raised={!persisting}
				style={localStyles.floatingButton(submitAv)}>
				{`Enregistrer`}
			</Button>
		}>
			{/*Image*/}
			<RNView style={[localStyles.image.layout, styles.if(!bannerUrl, { borderWidth: 1 })]}>

				{
					bannerUrl ?
						// Banner
						<View
							onPress={() => openFullscreenGallery(1)}
							style={localStyles.image.banner}>
							<Image
								source={{ uri: bannerUrl }}
								resizeMode={styles.resizeMode.cover}
								style={styles.fit}
							/>
						</View> :

						// Default
						<>
							<Icon name="picture"
								size={92}
								style={localStyles.image.icon}
							/>

							<Text style={localStyles.image.title}>
								{strings.fragment.shop.image.title}
							</Text>

							<Text style={localStyles.image.subtitle}>
								{strings.fragment.shop.image.subtitle}
							</Text>
						</>

				}

				{
					shop.logo &&
					<View onVoidPress={openFullscreenGallery} style={localStyles.image.logo}>
						<Image source={{ uri: shop.logo.url }}
							resizeMode={styles.resizeMode.contain}
							style={styles.fit}
						/>
					</View>
				}
			</RNView>

			{/*Cards*/}
			<RNView
				key={cardsVersion}
				style={localStyles.content}>
				{/*General Infos*/}
				<InfosCard
					shopTags={shopTags}
					editedShopTags={editedShopTags}
					setEditedShopTags={setEditedShopTags}
					{...cardProps} />

				{/*Address and localisation*/}
				<AddressCard
					editable={false}
					style={localStyles.card} />

				{/*	Open hours */}
				<OpenHoursCard
					{...cardProps} />

				<PaymentCard
					{...cardProps}
					paymentConfigLoadable={paymentConfigLoadable} />

				<ReceptionCard
					{...cardProps} />

				<SocialCard
					{...cardProps} />

				{
					isEligibleForReservations(shop.categoryId) &&
					<ReservationsCard
						{...cardProps} />
				}

			</RNView>
		</FrameControl>
	);
}

const localStyles = {
	content: {
		flexDirection: styles.flexDirection.row,
		flexWrap: styles.flexWrap.wrap,
		justifyContent: styles.justifyContent.spaceEvenly,
		alignItems: styles.alignItems.flexStart,
	},

	image: {
		layout: {
			marginVertical: 38,
			marginHorizontal: 40,
			borderColor: styles.color.main,
			borderStyle: styles.borderStyle.dashed,
			borderRadius: 10,
			backgroundColor: styles.color.blueBackground,
			alignItems: styles.alignItems.center,
		},

		banner: {
			alignSelf: styles.alignSelf.stretch,
			height: 255,
			borderRadius: 10,
			overflow: styles.overflow.hidden,
		},

		logo: [
			styles.newShadow(0, 3, 6, .16),
			{
				width: 140,
				height: 140,
				borderRadius: 20,
				backgroundColor: styles.color.white,
				overflow: styles.overflow.hidden,

				position: styles.position.absolute,
				left: 26,
				top: -27,
			}
		],

		icon: {
			marginTop: 36,
			marginBottom: 20,
			color: styles.color.azure2,
		},

		title: {
			color: styles.color.azure2,
			textDecorationLine: styles.textDecorationLine.underline,
			fontSize: 28,
			marginBottom: 12,
			textAlign: styles.textAlign.center,
		},

		subtitle: {
			fontSize: 18,
			italic: true,
			textAlign: styles.textAlign.center,
			marginBottom: 30,
		},
	},

	card: {
		marginBottom: 30,
		minHeight: 525,
	},

	floatingButton: scale => ({
		transform: [{ scale }],
		...styles.absolute({ bottom: 20, right: 40 }),
	})
};
