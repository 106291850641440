import { StackScreenProps } from "@react-navigation/stack";
import React from "react";
import use from "../../../library-react/hook";
import useMe from "../../../library-react/hook/pro/useMe";
import Server from "../../../library-react/library-js/app/Server";
import { isTruthy } from "../../../library-react/library-js/types";
import { styles } from "../../../res";
import FragmentLayout from "../../component/FragmentLayout";
import { RootStackParamsList } from "../../screens";
import AddReservationButton from "./AddReservationButton";
import TabsSection from "./TabsSection";
import useOrdersTab from "./useOrdersTab";
import useReservationsTab from "./useReservationsTab";
import UserView from "./UserView";

function ClientUI({ route }: ClientUIProps) {
	const id = route.params?.id;
	const orderTab = useOrdersTab(id);
	const reservationsTab = useReservationsTab(id);
	const tabs = [orderTab, reservationsTab].filter(Boolean as any as isTruthy);
	const [tabIndex, setTabIndex] = use.state(0);
	const selectedTab = tabs[tabIndex];

	const { shop } = useMe().shop;
	const loadable = (use.loadable as any).server(() => Server.retailer.client.get(id), [id]);

	return (
		<>
			<FragmentLayout>
				<UserView id={id} loadable={loadable} />

				{
					loadable.value &&
					<TabsSection
						tabs={tabs}
						tabIndex={tabIndex}
						setTabIndex={setTabIndex} />
				}
			</FragmentLayout>

			{
				shop.acceptsReservations &&
				<AddReservationButton
					id={id}
					show={selectedTab === reservationsTab} />
			}
		</>
	);
}

export default React.memo(ClientUI);

type ClientUIProps = StackScreenProps<RootStackParamsList, "client">;

const localStyles = {
	layout: {
		backgroundColor: undefined,
		...styles.newShadow(0, 0, null, null, null),
	}
};
