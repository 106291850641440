import moment from "moment";
import { andThen, pipe, propEq } from "ramda";
import React from "react";
import { View as RNView } from "react-native";
import useApp from "../../../library-react/app/useApp";
import DateInput from "../../../library-react/component/input/DateInput";
import use from "../../../library-react/hook";
import useMe from "../../../library-react/hook/pro/useMe";
import { useReservationSpansLoadable } from "../../../library-react/hook/pro/useReservationSpans";
import useToast from "../../../library-react/hook/useToast";
import ReservationClosure from "../../../library-react/library-js/app/model/entity/ReservationClosure";
import Server from "../../../library-react/library-js/app/Server";
import strictAPI from "../../../library-react/library-js/app/Server/strictAPI";
import { parallel } from "../../../library-react/library-js/utils/function";
import paragraph from "../../../library-react/library-js/utils/function/paragraph";
import toMoment from "../../../library-react/library-js/utils/toMoment";
import bindPromiseToBoolState from "../../../library-react/utils/action/bindPromiseToBoolState";
import willCatchAndToast from "../../../library-react/utils/willCatchAndToast";
import { fonts } from "../../../res";
import Button from "../../component/Button";
import FragmentLayout from "../../component/FragmentLayout";
import screens from "../../screens";
import willDeleteReservationClosureFactory from "./utils/willDeleteReservationClosureFactory";

function EditReservationClosureUI({ route, navigation }) {
	let id = route.params?.id;
	if (id)
		id = Number(id);

	const loadable = useReservationSpansLoadable();
	const me = useMe();
	const shop = me?.shop?.shop;

	const closure = use.memorise(([id], lastClosure, [lastId]) => {
		if (lastClosure && id == lastId)
			return lastClosure;

		let editable;
		if (!id)
			editable = new ReservationClosure();
		else {
			const { closures } = loadable.value || {};
			editable = closures?.find(propEq('id', id))?.copy();
		}

		editable?.bindSetters();
		return editable;
	}, [id, loadable.value]);
	use.subscribe(closure?.onPropertyChanged, [closure]);


	const asMoment = timestamp => toMoment(shop?.timezone, timestamp);
	const asIsoDate = timestamp => asMoment(timestamp).format('YYYY-MM-DD');


	const startMoment = asMoment(closure?.start);
	let [end, setEnd] = use.state();
	use.syncEffect(() => { // set default end date
		const endMoment = closure?.getLastMoment(shop.timezone);
		if (endMoment)
			setEnd(end = endMoment.valueOf());
	}, [closure]);
	const endMoment = asMoment(end);


	const validate = (() => {
		const today = asMoment(asMoment(Date.now()).format('YYYY-MM-DD'));
		return {
			start: iso => asMoment(iso)?.isSameOrAfter(today, 'day'),
			end: ts => (startMoment || today)?.isSameOrBefore(ts),
		};
	})();


	const numberOfDays = (() => {
		if (endMoment && startMoment) {
			const delta = moment.duration(endMoment?.diff(startMoment)).asDays();
			return Math.abs(Math.trunc(delta)) + 1;
		}
	})();


	const toast = useToast();
	const [submitted, setSubmitted] = use.state.bool();
	const [submitting, setSubmittingTo] = use.state.bool();


	function submit() {
		setSubmitted(true);

		const valid = (
			closure?.title
			&& validate.start(closure.start)
			&& numberOfDays >= 1
		);

		console.log({
			title: closure?.title,
			start: closure?.start,
			numberOfDays,
		})

		if (valid) {
			closure.days = numberOfDays;
			persist(closure);
		}
	}

	function remove() {
		if (id)
			persist(null);
		else
			quit();
	}

	/* intern */ function persist(closure) {
		setSubmittingTo(true);
		strictAPI(Server.retailer.reservation.persistClosure)(id, closure)
			.then(() => {
				toast(`Fermeture ${closure ? `enregistrée` : `supprimée`}!`);
				quit();
			})
			.catch(willCatchAndToast(toast))
			.finally(setSubmittingTo.false);
	}

	/* intern */ function quit() {
		if (navigation.canGoBack())
			navigation.goBack();
		else
			navigation.replace(screens.reservationSpansList);
	}


	const [deleting, setDeleting] = use.state.bool();
	const app = useApp();
	const willDelete = willDeleteReservationClosureFactory({
		app, toast,
		onRunning: parallel(
			bindPromiseToBoolState(setDeleting),
			andThen(quit)
		),
	});

	const deleteIt = closure?.id ? willDelete(closure) : quit;

	const editable = !deleting && !submitting;



	const { Text, Spinner, ErrorView, TextInput } = use.theme();

	return (
		<FragmentLayout contentContainerStyle={localStyles.scroll}>
			{
				closure ?
					<>
						<RNView style={localStyles.head}>
							<Text style={localStyles.title}>
								{!id && `NOUVELLE `}
								{`PERIODE DE FERMETURE`}
							</Text>

							<Text
								onPress={deleteIt}
								style={localStyles.delete}>
								{
									deleting ? `Suppréssion en cours...` :
										id ? `Supprimer` : `Annuler`
								}
							</Text>
						</RNView>

						<TextInput
							placeholder={`Nom de la periode`}
							autoFocus={!id}
							value={closure.title}
							onValueChanged={closure.setTitle}
							validate={submitted}
							editable={editable}
							style={localStyles.titleInput}
						/>

						<RNView style={localStyles.period}>
							<DateInput
								placeholder={`Du`}
								value={asMoment(closure.start)?.valueOf()}
								onValueChanged={pipe(asIsoDate, closure.setStart)}
								validate={submitted && validate.start}
								editable={editable}
								timezone={shop.timezone}
								style={localStyles.date} />

							<DateInput
								placeholder={`Au`}
								value={end}
								onValueChanged={setEnd}
								validate={(submitted || Boolean(end && closure.start)) && validate.end}
								editable={editable}
								timezone={shop.timezone}
								style={localStyles.date} />
						</RNView>

						{
							Boolean(numberOfDays) &&
							<>
								<Text style={{ fontSize: 16 }}>
									{paragraph`La fermeture des réservations durera ${numberOfDays} jour${numberOfDays > 1 && 's'}.`}
								</Text>

								<Text style={{ fontSize: 16 }}>
									{paragraph`Les réservations réouvrirons le ${moment(end).add(1, 'day').format('DD/MM')}.`}
								</Text>
							</>
						}

						{
							!deleting && (
								submitting ? <Spinner style={localStyles.submit} /> :
									<Button
										onPress={submit}
										style={localStyles.submit}>
										<Text>
											{`Valider`}
										</Text>
									</Button>
							)
						}
					</> :

					// ---
					loadable.loading ?
						<Spinner style={{ marginVertical: 40 }} /> :

						Boolean(id && loadable.value && !closure) ?
							<Text style={{ marginVertical: 40, textAlign: 'center' }}>
								{`Cette période de fermeture n'existe pas.`}
							</Text> :

							<ErrorView onLoadClicked={loadable.load} />
			}
		</FragmentLayout>
	);
}

export default React.memo(EditReservationClosureUI);

const localStyles = {
	scroll: {
		padding: 40,
		alignSelf: 'center',
		width: "90%",
		maxWidth: 700,
	},

	head: {
		flexDirection: "row",
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	title: {
		fontFamily: fonts.Poppins,
		fontSize: 18,
	},

	delete: {
		color: '#eb1b2b',
		fontSize: 16,
		fontFamily: fonts.Poppins,
		paddingVertical: 14,
	},

	titleInput: {
		marginVertical: 16,
		fontSize: 18,
		borderWidth: 1,
		borderColor: 'lightgrey',
		borderRadius: 10,
		padding: 20,
	},

	period: {
		flexDirection: "row",
		justifyContent: 'space-between',
		marginVertical: 15,
	},

	date: {
		width: '45%',
		fontSize: 18,
		padding: 5,
		borderRadius: 5,
	},

	submit: {
		alignSelf: 'center',
		marginVertical: 30,
	}
};