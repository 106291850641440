export default function logCalls(fn, tag) {
	function logger(...params) {
		const result = fn(...params);
		console.log(
			tag ? `${tag}: ` : '', {
				params,
				result,
			}
		);
		return result;
	}

	Object.defineProperties(logger, {
		length: {
			configurable: true,
			value: fn.length,
		}
	});

	return logger;
}
