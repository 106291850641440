import React from "react"
import {FlatList, View as RNView} from 'react-native'
import {styles} from "../../../../res"
import Search from "library-js/Search";
import ProductItem from "../../../component/ProductItem";
import use from "library-react/hook";
import Portal from "library-react/component/Portal";
import screens from "../../../screens";
import useMe from "library-react/hook/pro/useMe";
import useRouteParamState, {commonConfigs} from "library-react/hook/useRouteParamState";
import {
	__,
	append,
	complement,
	compose,
	concat,
	eqBy,
	F,
	filter,
	findLastIndex,
	forEachObjIndexed,
	includes,
	last,
	not,
	pipe,
	tryCatch,
	uniqWith,
	when
} from "ramda";
import SearchBar from "./SearchBar";
import ActionsBar from "./ActionsBar";
import CatalogSectionSideBar from "@main/ui/product/CatalogUI/CatalogSectionSideBar";
import generateShopCupboardLoadable from "library-js/app/generateShopCupboardLoadable";
import override from "library-js/utils/function/override";
import AddProductFloatingButton from "./AddProductFloatingButton"
import {TextStyleContext} from "library-react/component/Text/v2";

function CatalogUI({...props}) {
	const [query] = useRouteParamState('query', commonConfigs.query);

	const [sections] = useRouteParamState('sections', {
		read: tryCatch(JSON.parse, () => []),
	});

	const {shop} = useMe().shop;
	const iterator = use.infiniteIterator(() => {
			const it = Search.index.prodshop.getIterator({
				query,
				shopId: shop.id,
				sections,
			})
				.setDelay(800);

			it.addToItems = override(it.addToItems, ([items], add) => add(items.filter(compose(not, removed.has, getId))));

			return it;
		},
		[query, ...sections],
	);

	const cupboardLoadable = use.createLoadable(
		() => generateShopCupboardLoadable(shop.id, {available: null, keepEmptySections: true}),
		[shop.id]
	);


	const [selection, setSelection] = use.state([]);

	function updateItems(items) {
		const replaceInArray = (copy) => items.forEach(item => {
			const index = copy.findIndex(areSameProduct(item));
			if (index >= 0)
				copy.splice(index, 1, item);
		});

		const allItems = iterator.items.slice();
		replaceInArray(allItems);
		iterator.items = allItems;

		// use the set state after updating the items above, so it will render its updates too
		setSelection(selected => {
			const copy = selected.slice();
			replaceInArray(copy);
			return copy;
		});
	}

	const removed = use.memo(() => {
		const set = new Set();
		// bind add & has
		['add', 'has'].forEach(fn => set[fn] = set[fn].bind(set));
		return set;
	});

	function removeItems() {
		const ids = selection.map(getId);
		iterator.items = iterator.items.filter(pipe(
			getId,
			when(includes(__, ids), pipe(removed.add, F)),
		));

		setSelection([]);

		const sectionsCountsUpdates = selection.reduce((counts, {sections}) =>
			sections.reduce((counts, section) => {
					if (!counts[section]) counts[section] = 0;
					counts[section]--;
					return counts;
				}, counts
			), {}
		);

		updateSectionsQuantities(sectionsCountsUpdates);
	}

	function updateSectionsQuantities(updates) {
		const cupboardCopy = cupboardLoadable.value.copy();
		forEachObjIndexed((deletedCount, sectionName) => {
			const section = cupboardCopy.getSection(sectionName);
			if (section && deletedCount) {
				const editedSection = section.updateACopy({
					quantity: (section.quantity || 0) + deletedCount
				});

				cupboardCopy.update(editedSection, section);
			}
		}, updates);
		cupboardLoadable.setValue(cupboardCopy);
	}

	const columns = 3;

	const {Spinner, Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<CatalogSectionSideBar
				cupboardLoadable={cupboardLoadable}
				style={localStyles.sections}
			/>

			<RNView style={{flex: 1}}>
				<FlatList
					key={!iterator.items.length}
					data={iterator.items}
					keyExtractor={getId}
					renderItem={({item, index}) => {
						const {id} = item.product;
						const isTheProduct = areSameProduct(item);
						const isSelected = selection.some(isTheProduct);
						return (
							<ProductItem
								override={{
									renderImage: render => () => (
										<Portal
											to={screens.product}
											params={{id}}>
											{render()}
										</Portal>
									),
								}}
								item={item}
								selected={isSelected}
								toggleSelection={({shiftKey}) => {
									if (shiftKey) {
										const lastSelected = last(selection);
										let fromIndex = lastSelected ? iterator.items.indexOf(lastSelected) : 0;
										if (fromIndex < 0)
											fromIndex = findLastIndex(
												item => selection.some(areSameProduct(item)),
												iterator.items.slice(0, index),
											);

										if (fromIndex < 0)
											fromIndex = 0;

										setSelection(pipe(
											concat(iterator.items.slice(fromIndex, index + 1)),
											uniqWith(areSameProduct),
										));
									} else
										setSelection(
											isSelected ?
												filter(complement(isTheProduct)) :
												append(item)
										)
								}}
								style={localStyles.item}/>

						);
					}}

					numColumns={columns}

					onEndReachedThreshold={.1}

					ListFooterComponentStyle={{alignItems: styles.alignItems.center, paddingTop: 50}}
					ListFooterComponent={
						!iterator.end ?
						<Spinner size={50}/> :
							!iterator.items.length &&
							((emptyCatalog) => (
								<RNView>
									<TextStyleContext.Provider value={{fontSize: 16, textAlign: styles.textAlign.center}}>
										<>
											<Text>
												{emptyCatalog ? `Votre catalogue est vide.` : `Aucun article n'a été trouvé.`}
											</Text>
											{
												emptyCatalog &&
												<Portal to={screens.editProduct}>
													<Text style={{bold: true, color: styles.color.azure2, paddingVertical: 15, fontSize: 17}}>
														{`Ajouter un nouvelle article?`}
													</Text>
												</Portal>
											}
										</>
									</TextStyleContext.Provider>
								</RNView>
							))(!query && !sections?.length)
					}

					onEndReached={iterator.loadNextPage}
					{...localStyles.catalog}
				/>

				<RNView style={[styles.absolute({left: 0, right: 0, top: 40}), {alignItems: styles.alignItems.center}]}>
					<SearchBar/>
				</RNView>

				{
					Boolean(selection?.length) ?
					<ActionsBar
						cupboard={cupboardLoadable.value}
						{...{
							selection, setSelection,
							updateItems, removeItems,
							updateSectionsQuantities,
						}} /> :

						<AddProductFloatingButton/>
				}
			</RNView>
		</RNView>
	);
}

export default React.memo(CatalogUI);

const getId = vProdshop => vProdshop.product.id;
const areSameProduct = eqBy(getId);

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		flex: 1,
	},

	sections: {
		flexBasis: 330,
		flexGrow: 0,
		backgroundColor: 'white',
		borderRightWidth: 1,
		borderColor: 'lightgrey',
	},

	catalog: {
		style: {
			flex: 1,
		},

		contentContainerStyle: {

			alignItems: styles.alignItems.center,
			flexGrow: 1,
			paddingTop: 150,
			paddingBottom: 50,
		}
	},

	search: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.flexStart,
			marginBottom: 35,
			borderBottomWidth: 1,
			borderBottomColor: styles.color.main,
			marginTop: 25,
			maxWidth: 950,
			marginRight: 50,
		},

		icon: {
			height: 24,
			width: 24,
			color: styles.color.main + "82",
		},

		input: {
			flex: 1,
			fontSize: 19,
			paddingLeft: 10,
			paddingBottom: 10,
		}
	},

	item: {
		margin: 20,
	},

	spinner: {
		alignSelf: styles.alignSelf.center,
		marginVertical: 50,
	}
};
