import React from "react"
import {ScrollView} from 'react-native'
import {styles} from "@src/res";
import {__, assoc, call, concat, flip, pipe, prop, uniq, without, zipWith, identity} from "ramda";
import Icon from "library-react/component/Icon/v2";
import useActionsContext from "./useActionsContext";
import PopupMenu from "library-react/component/PopupMenu";
import use from "library-react/hook";
import Button from "@main/component/Button";
import PopupHeader from "./PopupHeader";
import CatalogSectionRow from "@main/component/CatalogSectionRow";
import {Server} from "library-js/app";
import strictAPI from "library-js/app/Server/strictAPI";
import Tooltip from "@main/component/Tooltip";

function SectionsButton({cupboard, updateSectionsQuantities, ...props}) {
	const {selection, persisting, setPersisting} = useActionsContext();

	const sectionsQuantities = selection.reduce((map, vProdshop) => {
		vProdshop.sections?.forEach(sectionName => {
			if (!map[sectionName]) map[sectionName] = 0;
			map[sectionName]++;
		});

		return map;
	}, {});

	const [editions, setEditions] = use.state({/* section name -> bool */});

	const isToBeSaved = selection.some(({sections}) =>
		sections.some(section => editions[section] === false)
		|| Object.entries(editions)
			.some(([section, selected]) => selected && !sections.includes(section))
	);

	async function submit() {
		const label = `Déplacement de ${selection.length} article${selection.length > 1 ? 's' : ''}`;


		const editionsList = Object.entries(editions)
			.map(zipWith(call, [cupboard.getSection, identity]));

		const length = selection.length * editionsList.length;

		let step = 0;
		const incProgress = () => {
			step++;
			setPersisting({label, progress: step / length});
		};

		setPersisting({label});

		const api = strictAPI(Server.retailer.catalog.sections.setProduct);

		for (const vProdshop of selection) {
			const {product, sections: productSections} = vProdshop;

			for (const [section, inside] of editionsList) {
				try {
					const included = productSections.includes(section.name);
					if (included !== inside) {
						await api({productId: product.id, sectionId: section.id}, inside);

						const dependents = section.getDependents(inside);
						const sections = [section, ...dependents].map(prop('name'));
						const mutate = inside ? pipe(concat(sections), uniq) : without(sections);
						vProdshop.sections = mutate(vProdshop.sections);

						const quantitiesUpdate = sections.reduce(flip(assoc(__, inside ? 1 : -1)), {});
						updateSectionsQuantities(quantitiesUpdate);
					}
				} catch (error) {
					console.error({
						product,
						section, inside,
						error,
					});
				} finally {
					incProgress();
				}
			}
		}

	}

	const [hovered, setHovered] = use.state.bool();

	const {Text} = use.theme();
	props.disabled = persisting;

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<PopupMenu
			trigger={
				<>
				<Icon
					name="MaterialCommunityIcons/label-multiple"
					size={50}
					style={localStyles.icon}/>


					{
						hovered &&
						<Tooltip>
							{`Déplacer parmis les sections`}
						</Tooltip>
					}
				</>
			}
			onHoverChanged={setHovered}
			popupStyle={localStyles.popup}
			persistAfterClick
			alignBottom
			{...props}>
			<PopupHeader>
				{`Déplacer dans les sections`}
			</PopupHeader>

			<ScrollView {...localStyles.scroll}>

				<Text
					onPress={() => setEditions({})}
					style={localStyles.reset}>
					{`Remettre en place`}
				</Text>

				{
					cupboard?.getAll(true)
						.map(section => {
							const edited = editions[section.name] !== undefined ? section
								: (
									cupboard.getAscendantsOf(section)
										.find(({name}) => editions[name] == false)
									|| cupboard.getDescendantsOf(section)
										.find(({name}) => editions[name])
								);

							const inside = edited ? (editions[edited.name] ? 1 : 0)
								: (sectionsQuantities[section.name] || 0) / selection.length;


							return (
								<CatalogSectionRow
									key={section.name}
									item={section}
									count={sectionsQuantities[section.name] || 0}
									total={selection.length}
									inside={inside}
									indentationOffset={20}
									onPress={() =>
										setEditions((editions) => {
											const nextState = !(inside >= 1);
											const edited = assoc(section.name, nextState)(editions);
											section.getDependents(nextState)
												.forEach(({name}) => {
													edited[name] = nextState;
												});

											return edited;
										})
									}
								/>
							);
						})
				}
			</ScrollView>


			{
				isToBeSaved &&
				<Button
					onPress={submit}
					raised
					style={localStyles.submit}>
					{`Savegarder`}
				</Button>
			}

		</PopupMenu>
	);
}

export default React.memo(SectionsButton);

const localStyles = {
	layout: {
		paddingHorizontal: 15,
		...styles.center,
	},

	popup: {
		bottom: '90%',
		width: 400,
		height: 500,
		right: 20,
	},

	icon: {
		color: 'white',
	},

	scroll: {
		style: {
			height: 300,
		},

		contentContainerStyle: {
			paddingBottom: 100,
		},
	},

	reset: {
		textDecorationLine: styles.textDecorationLine.underline,
		color: 'grey',
		paddingHorizontal: 20,
		paddingVertical: 5,
		marginVertical: 5,
		textAlign: styles.textAlign.right,
		alignSelf: styles.alignSelf.flexEnd,
	},

	submit: {
		...styles.absolute({
			bottom: 15,
			right: 15,
		})
	}
};
