import moment from "moment";
import { propEq } from "ramda";
import { useState } from "react";
import { Pressable, StyleSheet, View as RNView, View, ViewProps } from "react-native";
import useApp from "../../../../library-react/app/useApp";
import Icon from "../../../../library-react/component/Icon/v2";
import SimpleButton from "../../../../library-react/component/SimpleButton";
import use from "../../../../library-react/hook";
import useToast from "../../../../library-react/hook/useToast";
import { LegacyOrderState, Order_V2 } from "../../../../library-react/library-js/app/graphql";
import getOrderStateTargets from "../../../../library-react/library-js/app/model/utils/order/state/getOrderStateTargets";
import { formatPrice } from "../../../../library-react/library-js/app/model/utils/price";
import Server from "../../../../library-react/library-js/app/Server";
import { callMicroservice } from "../../../../library-react/library-js/app/Server/microservices";
import getResourcesForOrderState, { OrderStateDisplay } from "../../../../library-react/library-js/res/getResourcesForOrderState";
import getResourcesForReceptionMean from "../../../../library-react/library-js/res/receptionMeans";
import bindPromiseToBoolState from "../../../../library-react/utils/action/bindPromiseToBoolState";
import willCatchAndToast from "../../../../library-react/utils/willCatchAndToast";
import { styles } from "../../../../res";
import { AppTheme } from "../../../theme";
import CashInDialog from "./CashInDialog";

export default function Head({ order, onStateUpdated, ...props }: OrderUIHeadProps) {
	const [updating, setUpdating] = useState(false);

	const toast = useToast();
	function updateStateTo(state: LegacyOrderState) {
		const promise = Server.retailer.order.updateState(order.id, state);

		promise.then(onStateUpdated)
			.catch(willCatchAndToast(toast));

		bindPromiseToBoolState(setUpdating, promise);
	}

	const state = order.currentState?.state!;
	const statesTargets = getOrderStateTargets("retailer", state!);
	const actions: Action[] =
		statesTargets.map(state => {
			const display = getResourcesForOrderState(state);
			return {
				label: display.action(),
				color: display.color(),
				execute: () => updateStateTo(state),
			};
		});

	const app = useApp();
	const total = order.items_aggregate.aggregate?.sum?.totalPrice!;

	{// add cashin to option when order is ready
		const cashin = () => app.openModal(
			<CashInDialog
				order={order}
				onSelected={paymentMean => {
					const promise = callMicroservice("order/cashin", { orderId: order.id, paymentMean });
					promise.then(onStateUpdated)
						.catch(willCatchAndToast(toast));
					bindPromiseToBoolState(setUpdating, promise);
				}} />
		);

		if (state === LegacyOrderState.ready && total > 0)
			actions.push({
				label: `Encaisser`,
				color: styles.color.azure,
				execute: cashin,
			});
	}


	const { ConfirmModal } = use.theme<AppTheme>();
	// edit cancel action (with dialog confirmation) 
	// & set cancel as last option
	{
		const index = actions.findIndex?.(propEq("label", getResourcesForOrderState(LegacyOrderState.canceled).action()));
		if (index >= 0) {
			const [cancelAction] = actions.splice(index, 1);
			actions.push(cancelAction);

			const { execute } = cancelAction;
			cancelAction.execute = () => app.openModal(
				<ConfirmModal
					body={`Êtes-vous sûr de vouloir annuler la commande? Cette action est irréversible.`}
					yes={execute}
					primaryColor="red"
				/>
			);
		}
	}

	// ----

	const currency = order.items[0].currency;
	const stateDisplay = getResourcesForOrderState(state);

	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<View style={localStyles.infos.layout}>
				<Text
					numberOfLines={1}
					style={localStyles.infos.title}>
					{`Commande du ${moment(order.creationDate).format("DD/MM/YYYY")}`}
				</Text>

				<Text
					numberOfLines={1}
					style={localStyles.infos.orderId}>
					{`N° ${order.id}`}
				</Text>

				<Text
					numberOfLines={1}
					style={localStyles.infos.total}>
					{formatPrice(total, currency)}
				</Text>

				<Text>
					{getResourcesForReceptionMean(order.receptionMean)?.title()}
				</Text>
			</View>


			<RNView>
				<RNView style={localStyles.state.layout(stateDisplay)}>
					<Icon
						name={stateDisplay.icon()}
						size={20}
						style={localStyles.state.icon(stateDisplay)} />

					<Text style={localStyles.state.title(stateDisplay)}>
						{stateDisplay.title(order.receptionMean)}
					</Text>
				</RNView>
				<Text style={localStyles.stateDescription}>
					{stateDisplay.description(order.receptionMean)}
				</Text>
			</RNView>

			<RNView style={{ flex: 1 }} />

			{
				updating ?
					<Text>
						{`Mise à jour...`}
					</Text> :
					<>
						{
							actions[0] &&
							<SimpleButton
								filled
								onPress={actions[0].execute}
								colors={{ primary: actions[0].color, secondary: styles.color.white }}
								style={localStyles.primaryAction}>
								<Text style={localStyles.primaryActionText}>
									{actions[0].label} ›
								</Text>
							</SimpleButton>
						}

						{
							(actions.length > 1) &&
							<MoreActionsButton actions={actions.slice(1)} />
						}
					</>
			}
		</View>
	);
}

interface OrderUIHeadProps extends ViewProps {
	order: Order_V2,
	onStateUpdated(): void,
}

interface Action {
	label: string,
	color: string,
	execute(): void,
}

function MoreActionsButton({ actions }: { actions: Action[] }) {
	const [opened, setOpened] = useState(false);
	const onPress = () => setOpened(true);
	const onBlur = () => setTimeout(() => setOpened(false), 300);

	const { Text } = use.theme();
	return (
		<RNView>
			<Icon
				name="MaterialCommunityIcons/dots-vertical"
				size={30}
				onPress={onPress}
				onBlur={onBlur}
				style={{ marginLeft: 10, paddingLeft: 30, paddingVertical: 10 }}
			/>

			{
				opened &&
				<RNView style={localStyles.moreOptionsWindow}>
					{
						actions.map((action, index) =>
							<Pressable
								key={String(index)}
								onPress={action.execute}
								style={{ padding: 20 }}>
								<Text>
									{action.label}
								</Text>
							</Pressable>
						)
					}
				</RNView>
			}
		</RNView>
	);
}

const localStyles = {

	state: {
		layout(display: OrderStateDisplay) {
			return {
				flexDirection: styles.flexDirection.row,
				alignItems: styles.alignItems.center,
				paddingHorizontal: 7,
				paddingVertical: 3,
				borderRadius: 5,
				borderColor: display.color(),
				borderWidth: 1,
				marginTop: 10,
				alignSelf: styles.alignSelf.flexStart
			};
		},

		icon(display: OrderStateDisplay) {
			return {
				color: display.color(),
				marginRight: 10,
			};
		},

		title(display: OrderStateDisplay) {
			return {
				fontSize: 18,
				color: display.color(),
			};
		}
	},

	infos: StyleSheet.create({
		layout: {
			marginRight: 20,
		},

		title: {
			fontSize: 25,
			color: styles.color.azure2,
		},

		orderId: {
			fontSize: 20,
		},

		total: {
			fontSize: 23,
			marginRight: 10,
		}
	}),

	...StyleSheet.create({
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.flexStart,
			...styles.newPaddings(40, 45, 16, 35),
		},


		stateDescription: {
			width: 220,
			paddingVertical: 10,
			fontSize: 14,
		},

		primaryAction: {
			borderRadius: 25,
			paddingHorizontal: 20,
		},

		primaryActionText: {
			fontWeight: "bold",
		},

		moreOptionsWindow: {
			...styles.absolute({ top: 0, right: 0 }),
			backgroundColor: styles.color.white,
			...styles.newShadow(0, 1, 5, .16),
			borderRadius: 5,
			width: 300,
		},
	})
};