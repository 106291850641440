import React from "react";
import use from "library-react/hook";
import FragmentLayout from "@main/component/FragmentLayout";
import {styles} from "@src/res";
import {Price} from "library-js/app/model";
import Transaction from "../../../../library-react/library-js/app/model/entity/Transaction";
import TransactionItem from "@main/ui/payment/TransactionList/TransactionItem";
import Button from "@main/component/Button";
import View from "library-react/component/View/v2";
import Text from "library-react/component/Text";
import Head from "@main/component/Head";
import Icon from "library-react/component/Icon/v2";
import Portal from "library-react/component/Portal";
import screens from "@main/screens";
import RemainingAmountView from "@main/ui/payment/TransactionList/RemainingAmountView";


export default function PaymentTransactionList({...props}) {

	const iterator = use.infiniteIterator(
		() => Server.retailer.payment.transaction.getList.getIterator()
	);

	const forceUpdate = use.forceUpdate();
	const onTransfer = transaction => {
		iterator.items.unshift(transaction);
		forceUpdate();
	}

	const {Text, Spinner} = use.theme();

	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.content);
	return (
		<FragmentLayout {...props}>
			<View style={localStyles.header.layout}>
				<Head style={localStyles.header.title}>
					{`Vos transactions`}
				</Head>
				<View style={localStyles.header.settings}>

					<Portal to={screens.paymentSettings}>
						<View style={localStyles.paymentSettings.layout}>
							<Icon name='Ionicons/settings' size={20} style={localStyles.paymentSettings.icon}/>
							<Text>
								{`Réglages`}
							</Text>
						</View>
					</Portal>

					<RemainingAmountView onTransfer={onTransfer}/>

				</View>

			</View>
			{
				Boolean(iterator?.items?.length) && <TransactionItem.Header style={localStyles.tableHeader}/>
			}
			{
				iterator?.items?.map(item => <TransactionItem key={item?.id} item={item} style={localStyles.item}/>)
			}
			{
				iterator.failed &&
					<View style={[styles.center, {marginVertical: 50}]}>
						<Text style={{marginBottom: 10}}>
							{`Une erreur est survenue`}
						</Text>
					</View>
			}
			{
				iterator.ready ?
					<Button
						onPress={iterator.load}
						style={localStyles.loadMore}>
						{iterator.failed ? `Recharger` : `Voir plus`}
					</Button> :

					iterator.loading ?
						<Spinner style={localStyles.loadMore}/> :

							// empty
							iterator.end &&
							!iterator.items.length &&
							<View style={[styles.center, {marginVertical: 50}]}>
								<Text style={{marginBottom: 10}}>
									{`Aucune transaction`}
								</Text>
							</View>
			}

		</FragmentLayout>
	);
}
const localStyles = {
	content: {
		overflow: styles.overflow.hidden,
		paddingVertical: 20,
		paddingHorizontal: 5,
	},
	header: {
		layout: {
			flexDirection: styles.flexDirection.row,
			justifyContent: styles.justifyContent.spaceBetween,
			marginBottom: 30,
		},
		title: {
			alignSelf: styles.alignSelf.flexStart,
			marginBottom: 25,
			marginHorizontal: 42,
		},
		settings: {
			flexDirection: styles.flexDirection.column,
			alignItems: styles.alignItems.flexEnd,
		}
	},
	paymentSettings: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			justifyContent: styles.justifyContent.center,
			padding: 10,
			marginBottom: 10,
		},
		icon: {
			marginRight: 5,
		},
		text: {}
	},
	tableHeader: {
		marginBottom: 15,
	},
	item: {
		borderTopWidth: .5,
		paddingVertical: 8,
	},
	loadMore: {
		alignSelf: styles.alignSelf.center,
		marginVertical: 50,
	}
}