import React from "react"
import { View as RNView, FlatList } from "react-native"
import use from "library-react/hook"
import { images, styles } from "../../../../res"
import ModalFrame from "library-react/component/ModalFrame";
import { Server } from "library-js/app";
import { path } from "ramda";
import View from "library-react/component/View/v2";
import Image from "library-react/component/Image";
import TextInput from "library-react/component/input/TextInput";

function SelectClientModal({ selectedUser, onceSelected, close, ...props }) {
	const [query, setQuery] = use.state(selectedUser?.name);
	const iterator = use.infiniteIterator(() => Server.retailer.client.getList.getIterator(query)
		.setDelay(800),
		[query]);

	const willSelect = (client) => () => {
		close();
		onceSelected(client.user);
	};

	const { Text, Spinner, DefaultModal } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<DefaultModal {...props}>
			<RNView style={{ alignSelf: 'center', width: "90%", maxWidth: 700, flex: 1 }}>

				<TextInput
					placeholder={`Nom, email...`}
					autoFocus
					defaultValue={selectedUser?.name}
					onValueChanged={setQuery}
					style={{
						borderBottomWidth: 1,
						borderColor: 'lightgrey',
						marginHorizontal: 40,
						marginTop: 20,
						fontSize: 25,
						paddingVertical: 15,
					}}
				/>

				<FlatList
					data={iterator.items}
					keyExtractor={path(['user', 'id'])}
					renderItem={({ item }) => (
						<View
							onPress={willSelect(item)}
							style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 20 }}>
							<Image
								source={item.user.picture}
								defaultSource={images.interlocutor}
								style={{
									...styles.circle(50),
									marginRight: 20,
								}}
							/>

							<RNView>

								<Text style={{ bold: true, fontSize: 20 }}>
									{item.user.name}
								</Text>

								<Text
									noLinks
									style={{ fontSize: 16, marginTop: 10 }}>
									{item.user.email}
								</Text>
							</RNView>
						</View>
					)}

					onEndReached={iterator.loadNextPage}
					onEndReachedThreshold={.1}
					ListFooterComponent={
						!iterator.end &&
						<Text style={{ italic: true, fontSize: 18, textAlign: 'center', marginVertical: 20 }}>
							Chargement...
						</Text>
					}

					contentContainerStyle={{ marginVertical: 20, marginHorizontal: 40 }}
					style={{ flex: 1 }}
				/>
			</RNView>
		</DefaultModal>

	);
}

export default React.memo(SelectClientModal);


const localStyles = {
	layout: {
		height: 900,
		maxHeight: '100%',
		borderTopLeftRadius: 30,
		borderTopRightRadius: 20,
	},
};
