import React from "react"
import {View as RNView} from "react-native"
import TextMessageView from "./TextMessageView";
import {ProductImage} from "../../../../component/OrderView";
import use from "../../../../../../hook"
import {styles} from "../../../../../../res";

export default React.memo(
	function ProductMessageView({event: message, ...props}) {
		props.style = use.defaultStyle(props.style, localStyles.layout);

		return (
			<RNView {...props}>
				<ProductImage
					source={message.product?.gallery?.first}
					style={localStyles.image}
				/>

				<TextMessageView
					event={message}
					style={localStyles.text}
				/>
			</RNView>
		);
	}
);

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
	},

	image: {
		marginRight: 13,
	},

	text: {
		flex: 1,
	}
};
