import React from "react"
import {styles} from "@src/res";
import {invoker, prop} from "ramda";
import Switch from "library-react/component/Switch";
import useActionsContext from "./useActionsContext";
import use from "library-react/hook";
import Tooltip from "@main/component/Tooltip";

function AvailableSwitch({...props}) {
	const {selection, update, persisting} = useActionsContext();
	const areAvailable = selection.every(prop('available'));

	const [hovered, setHovered] = use.state.bool();

	props.style = use.defaultStyle(props.style, localStyles.style);

	return (
		<Switch
			key={areAvailable}
			initialValue={areAvailable}
			onValueChanged={willBeAvailable => {
				const modified = selection.filter(({available}) => available !== willBeAvailable)
					.map(updateACopy({prodshop: {available: willBeAvailable}}));

				update(`Mise ${willBeAvailable ? 'en' : 'hors'} ligne de ${modified.length} article${modified.length > 1 ? 's' :''}`, modified);
			}}
			onHoverChanged={setHovered}
			disabled={persisting}
			{...localStyles}
			{...props}>
			{
				hovered &&
					<Tooltip>
						{`Mettre ${areAvailable ? 'hors' : 'en'} ligne`}
					</Tooltip>
			}
		</Switch>
	);
}

export default React.memo(AvailableSwitch);

const updateACopy = invoker(1, 'updateACopy');

const localStyles = (() => {
	const padding = 4;
	const size = 30;
	const borderWidth = 2;
	const height = size + padding * 2 + borderWidth * 2;
	return {
		style: {
			borderWidth,
			height,
			padding,
			borderRadius: height / 2,
			width: size * 2 + padding * 2 + borderWidth * 2,
			alignSelf: styles.alignSelf.center,
			borderColor: 'white',
			marginRight: 5,
			backgroundColor: 'transparent'
		},

		triggerStyle: {
			...styles.circle(size),
		},

		triggerOnStyle: {
			backgroundColor: "#00dc66",
		},

		triggerOffStyle: {
			backgroundColor: "white"
		},
	};
})();
