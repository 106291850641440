import Server from "../../../../library-react/library-js/app/Server";
import strictAPI from "../../../../library-react/library-js/app/Server/strictAPI";
import actionFactory from "../../../../library-react/utils/action/actionFactory";

const willDeleteReservationClosureFactory = actionFactory({
	run: ({ willParams: [closure] }) => strictAPI(Server.retailer.reservation.persistClosure)(closure.id, null),
	confirmationModal: true,
	message: {
		success: `Période de fermeture supprimé!`,
	},
});

export default willDeleteReservationClosureFactory;