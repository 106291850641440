const websiteScreens = {
	home: 'home',
	menu: 'menu',
};

export default websiteScreens;

export const linking = {
	home: '',
	menu: 'menu',
};
