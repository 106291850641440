import {retailer as Server} from "library-js/app/Server";
import {curry} from "ramda";

export default curry(async (meLoadable, editedShop)  => {
	let response = await Server.me.editShop(editedShop);
	if (response.ok) {
		const {receptionMeans, paymentMeans, checkoutNote, unavailableNote} = editedShop;
		response = await Server.me.checkoutConfiguration.persist(receptionMeans, paymentMeans, checkoutNote, unavailableNote);
	}

	if (meLoadable?.end && response.ok){
		const me = meLoadable.value;
		const newMe = me.updateACopy({shop: {shop: editedShop}});
		meLoadable.setValue(newMe);
	}

	return response;
});
