import React, {useRef} from "react";
import DefaultCheckBox from "library-react/component/CheckBox";
import View from "library-react/component/View/v2";
import Text from "library-react/component/Text/v2";
import use from "library-react/hook";
import {styles} from "../../../../res";

function CheckBox({label, value, defaultValue, onValueChanged, editable = true, ...props}) {
	const ref = useRef();

	if (!props.onPress && editable)
		props.onPress = () => ref.current?.toggle();

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<DefaultCheckBox
				ref={ref}
				{...{value, defaultValue, onValueChanged, editable}}
				style={localStyles.checkBox.layout}
				onStyle={localStyles.checkBox.onStyle}
				offStyle={localStyles.checkBox.offStyle}
			/>

			<Text style={localStyles.label}>
				{label}
			</Text>
		</View>
	);
}

export default CheckBox;

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center
	},
	label: {
		fontSize: 15,
		marginLeft: 10
	},
	checkBox: {
		layout: {
			...styles.square(20),
			borderRadius: 5,
			borderWidth: 1,
		},
		onStyle: {
			borderColor: styles.color.main,
			backgroundColor: styles.color.main,
		},
		offStyle: {
			borderColor: styles.color.black,
			borderWidth: 0,
		}
	}
}
