import abstractComponent from "./abstractComponent";
import use from "../../hook";
import selectFile from "../../utils/selectFile";
import Server from "../../library-js/app/Server";
import {is} from "library-js/utils";
import timeout from "library-js/utils/timeout";

export default abstractComponent(render => {
		return function GalleryInput({...props}) {
			const {value: forcedValue, defaultValue, onValueChanged, validate} = props;

			const isValueForced = forcedValue instanceof Array;
			let [value, setValue] = use.state(
				isValueForced ? forcedValue :
					defaultValue instanceof Array ? defaultValue :
						[]
			);

			use.onChange((onValueChanged && value !== forcedValue) &&
				function notify() {
					onValueChanged(value);
				},
				[value],
			);

			// set forced value
			/// Should be after onValueChanged callback
			use.syncEffect(() => {
					if (isValueForced)
						return timeout(() => setValue(forcedValue), 1000);
				},
				[value, forcedValue],
			);

			const [uploading, setUploading] = use.state(false);

			const selectImage = use.asyncCallback(shouldStop =>
				() => Promise.process(shouldStop)
					.then(() => selectFile("image/*", true))
					.then(files => {
						if (files?.length > 0) {
							setUploading(true);
							return Promise.process(shouldStop)
								.then(async () => {
									while (files.length > 0 && !shouldStop()) {
										const file = files.shift();
										const response = await Server.public.media.uploadFile(file);

										if (response.ok) {
											if (!shouldStop())
												setValue(value => value.concat([response.content]));
										} else {
											response.log();
											break;
										}
									}
								})
								.result((_, error) => {
									setUploading(false);
									if (error)
										console.warn(error);
								})
						}
					})
			);

			const remove = use.callback(index =>
				setValue(items => items.filter((_, i) => i !== index))
			);

			return render(
				props,
				{
					value,
					selectImage,
					uploading,
					remove,
					get valid(){
						return !validate || (validate instanceof Function ? validate(value) : (value?.length > 0));
					}
				},
			);
		}
	}
)
