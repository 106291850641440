import React from "react"
import use from "library-react/hook"
import inputComponent from "library-react/component/abstract/inputComponent";
import Image from "library-react/component/Image";
import {Server} from "library-js/app";
import View from "library-react/component/View/v2";
import {styles} from "@src/res"
import useApp from "library-react/app/useApp";
import SelectBrandModal from "@main/component/SelectBrandModal"
import Text from "library-react/component/Text/v2";

function BrandPinView({pin, update, submitted, ...props}) {
	const {Text} = use.theme();
	const loadable = use.loadable.server(
		pin.name &&
		(() => Server.public.brand.get(pin.name).updateContent(map => map[pin.name])),
		[pin.name]
	);
	const brand = loadable?.value;

	const app = useApp();
	const startSelection = () => app.openModal(
		<SelectBrandModal
			initialValue={pin.name}
			onceConfirmed={name => update({name})}/>
	)
	props.onPress = startSelection;
	use.effect(() => {
		if (!pin.name)
			startSelection();
	});

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			{
				brand?.logo ?
					<Image
						source={brand.logo}
						resizeMode="contain"
						style={localStyles.logo}/> :

					pin.name ?
						<Text
							numberOfLines={2}
							style={localStyles.name}>
							{pin.name}
						</Text> :

						// placeholder
						<Text style={{
							bold: true, color: submitted ? 'red' : 'lightgrey', fontSize: 25,
							textAlign: styles.textAlign.center
						}}>
							{`Sélectionner une marque`}
						</Text>
			}

		</View>
	);
}

export default inputComponent(BrandPinView);

const localStyles = {
	layout: {
		paddingRight: 50,
		height: 120,
		justifyContent: styles.justifyContent.center,
	},

	logo: {
		flex: 1,
	},

	name: {
		fontSize: 30,
		bold: true,
		textAlign: styles.textAlign.center,
	}
};
