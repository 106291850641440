import React from "react";
import useTagsInputState from "../../../hook/input/useTagsInputState";
import FullScreenDialog from "../../FullScreenDialog";
import ModalOpenable from "../../ModalOpenable";
import TagsInputEditor from "./TagsInputEditor";
import TagsInputPreview from "./TagsInputPreview";

/**
 * A default use of useTagsInputState, TagsInputEditor and TagsInputPreview.
 */
export default function TagsInput(
	{
		defaultOpened,
		collections,
		autocomplete,
		reservedKeywords,
		editable = true,
		editorHeader,
		tagsEditable,
		strings,
		...props
	}
) {
	const { value, setValue } = useTagsInputState(props);

	return (
		<ModalOpenable
			defaultOpened={defaultOpened}
			disabled={!editable}
			getModal={() =>
				<EditorModal
					defaultValue={value}
					submit={setValue}
					collections={collections}
					autocomplete={autocomplete}
					header={editorHeader}
					reservedKeywords={reservedKeywords}
					tagsEditable={tagsEditable}
					strings={strings}
				/>
			}>
			<TagsInputPreview
				value={value}
				editable={editable}
				strings={strings}
				{...props} />
		</ModalOpenable>
	);
}

function EditorModal({ close, defaultValue, submit, collections, autocomplete, reservedKeywords, header, tagsEditable, strings, ...props }) {
	return (
		<FullScreenDialog {...props}>
			<TagsInputEditor
				collections={collections}
				defaultValue={defaultValue}
				close={close}
				autocomplete={autocomplete}
				reservedKeywords={reservedKeywords}
				header={header}
				tagsEditable={tagsEditable}
				strings={strings}
				submit={value => {
					close();
					submit(value);
				}} />
		</FullScreenDialog>
	);
}