import React from "react";
import { ScrollView, ScrollViewProps, View, ViewProps } from "react-native";
import useMe from "../../../../library-react/hook/pro/useMe";
import isEligibleForReservations from "../../../../library-react/library-js/app/isEligibleForReservations";
import FragmentLayout from "../../../component/FragmentLayout";
import OrdersOrReservationsTabs from "./OrdersOrReservationsTabs";


export default function OrdersListFrame({ children, containerStyle, ...props }: OrdersListFrameProps) {
	const categoryId = useMe().shop.shop.categoryId;
	const withReservation = isEligibleForReservations(categoryId);

	return (
		<ScrollView {...props}>
			{
				withReservation &&
				<OrdersOrReservationsTabs />
			}

			<View style={[localStyles.container, containerStyle].flat()}>
				{children}
			</View>
		</ScrollView>
	);
}

interface OrdersListFrameProps extends ScrollViewProps {
	containerStyle?: ViewProps["style"],
}

const localStyles = {
	container: {
		...FragmentLayout.contentContainerStyle,
		paddingVertical: 50
	},
} as const;