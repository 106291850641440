import React from "react"
import {ScrollView} from "react-native"
import use from "library-react/hook";
import UserSection from './UserSection'
import ReservationSection from './ReservationSection'
import {styles} from "@src/res";
import useReservationEditor from "library-react/hook/pro/useReservationEditor";

function ReservationUI({route, navigation, ...props}) {
	const {id, userId} = route.params || {};
	const context = useReservationEditor({id, userId});
	const {loadable, edition} = context;
	const {vUser} = loadable?.value || {};

	// ------

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.contentContainerStyle, localStyles.layout);
	props.contentContainerStyle = localStyles.container;
	return (
		<ScrollView {...props}>
			{
				!(loadable?.value && !edition && !vUser) &&
				<>
					<Text style={localStyles.title(vUser)}>
						{`L'utilisateur`}
					</Text>
					<UserSection {...context}/>
				</>
			}


			<Text style={localStyles.title(edition || loadable?.value)}>
				{
					vUser ? `a passé la réservation` :
						id ? `Reservation n°${id}` :
							`Nouvelle réservation`
				}
			</Text>

			<ReservationSection {...context}/>
		</ScrollView>
	);
}

export default React.memo(ReservationUI);

const localStyles = {
	layout: {
		flex: 1,
	},

	container: {
		marginHorizontal: 30,
		marginBottom: 30,
	},

	title: styles.static.bool({
			marginLeft: 10,
			bold: true,
			fontSize: 30,
			color: 'grey',
			marginTop: 30,
			marginBottom: 20,
		},
		{},
		{opacity: 0},
	),
};
