import React from "react";
import {View as RNView} from "react-native"
import {strings, styles} from "@src/res";
import use from "library-react/hook";
import DateInput from "library-react/component/input/DateInput";
import CountryInput from "library-react/component/CountryInput";
import AddressLocationForm from "library-react/component/AddressLocationForm";
import {LegalUser} from "library-js/app/model/pay/mango";
import Button from "@main/component/Button";
import ScrollView from "library-react/component/ScrollView";
import LabeledTextInput from "@main/ui/payment/OnlinePaymentConfiguration/input/LabeledTextInput";
import Subtitle from "@main/ui/payment/OnlinePaymentConfiguration/input/Subtitle";
import SupportButton from "@main/ui/payment/OnlinePaymentConfiguration/SupportButton";
import Text from "library-react/component/Text/v2";
import LabeledWrapper from "@main/component/LabeledWrapper";


export default function LegalInfoUI({value: legalUserProps, onValueChanged, next, ...props}) {
	const memo = use.memo({savedLegalUser: legalUserProps}, [legalUserProps]);
	const [legalUser, setLegalUser] = use.state(() => (memo.savedLegalUser?.copy() || new LegalUser()).bindSetters());
	const [submitted, setSubmitted] = use.state.bool();
	const [loading, setLoading] = use.state.bool();

	const persist = async () => {
		setSubmitted(true);

		const invalidFields = legalUser.getPayOutInvalidFields();
		if (invalidFields.length > 0) {
			console.log("Missing fields: ", legalUser.getPayOutInvalidFields())
			return
		}

		setLoading(true);
		const response = await Server.retailer.payment.legalUser.persist(legalUser);
		if (response.ok) {
			memo.savedLegalUser = response.content;
			setLegalUser(memo.savedLegalUser.copy().bindSetters());
			onValueChanged(memo.savedLegalUser);
		}

		setLoading(false);
	};
	const edited = !memo.savedLegalUser || !legalUser.equalsTo(memo.savedLegalUser);
	use.subscribe(legalUser?.onPropertyChanged, [legalUser]);


	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<ScrollView {...props}>
			<SupportButton size={30} style={localStyles.support}/>

			<Text style={localStyles.header}>
				{`Après vérification des 4 étapes du processus par notre partenaire bancaire, le paiement en ligne sera activé sur votre site web (délai moyen 48H).`}
			</Text>

			<Subtitle>
				{`Infos sur la société`}
			</Subtitle>

			<LabeledTextInput label={strings.fragment.shop.paymentOnline.Name.firstUp}
							  placeholder={'Nom'}
							  validate={submitted && LegalUser.generateValidatePayOutOf.Name()}
							  onValueChanged={legalUser.setName}
							  defaultValue={legalUser.Name}/>


			<LabeledTextInput label={strings.fragment.shop.paymentOnline.Email.firstUp}
							  placeholder={'Email'}
							  validate={submitted && LegalUser.generateValidatePayOutOf.Email()}
							  onValueChanged={legalUser.setEmail}
							  defaultValue={legalUser.Email}/>

			<AddressLocationForm
				label={strings.fragment.shop.paymentOnline.HeadquartersAddress.firstUp}
				validate={submitted && LegalUser.generateValidatePayOutOf.HeadquartersAddress()}
				onAddressChanged={legalUser.setHeadquartersAddress}
				defaultValue={legalUser.HeadquartersAddress}
				TextInputComponent={LabeledTextInput}/>

			<LabeledTextInput
				label={strings.fragment.shop.paymentOnline.CompanyNumber.firstUp}
				placeholder={`SIRET ou SIREN`}
				validate={submitted && LegalUser.generateValidatePayOutOf.CompanyNumber()}
				onValueChanged={legalUser.setCompanyNumber}
				defaultValue={legalUser.CompanyNumber}/>


			<Subtitle>Infos sur le/la représentant/e légal/e </Subtitle>

			<RNView style={[localStyles.grid, {justifyContent: 'space-evenly'}]}>
				<LabeledTextInput
					label={strings.fragment.shop.paymentOnline.LegalRepresentativeFirstName.firstUp}
					validate={submitted && LegalUser.generateValidatePayOutOf.LegalRepresentativeFirstName()}
					onValueChanged={legalUser.setLegalRepresentativeFirstName}
					defaultValue={legalUser.LegalRepresentativeFirstName}
					style={localStyles.textInput}/>


				<LabeledTextInput
					label={strings.fragment.shop.paymentOnline.LegalRepresentativeLastName.firstUp}
					validate={submitted && LegalUser.generateValidatePayOutOf.LegalRepresentativeLastName()}
					onValueChanged={legalUser.setLegalRepresentativeLastName}
					defaultValue={legalUser.LegalRepresentativeLastName}
					style={localStyles.textInput}/>
			</RNView>

			<RNView style={localStyles.grid}>
				<LabeledWrapper label={strings.fragment.shop.paymentOnline.LegalRepresentativeBirthday.firstUp}>
					<DateInput
						validate={submitted && LegalUser.generateValidatePayOutOf.legalRepresentativeBirthday()}
						onValueChanged={legalUser.setLegalRepresentativeBirthday}
						defaultValue={legalUser.legalRepresentativeBirthday}
						style={localStyles.date}/>
				</LabeledWrapper>

				<LabeledWrapper label={strings.fragment.shop.paymentOnline.LegalRepresentativeNationality.firstUp}>
					<CountryInput
						onValueChanged={legalUser.setLegalRepresentativeNationality}
						defaultValue={legalUser.LegalRepresentativeNationality}
						validate={submitted && LegalUser.generateValidatePayOutOf.LegalRepresentativeNationality()}
						style={localStyles.country}/>
				</LabeledWrapper>

				<LabeledWrapper
					label={strings.fragment.shop.paymentOnline.LegalRepresentativeCountryOfResidence.firstUp}>
					<CountryInput
						validate={submitted && LegalUser.generateValidatePayOutOf.LegalRepresentativeCountryOfResidence()}
						onValueChanged={legalUser.setLegalRepresentativeCountryOfResidence}
						defaultValue={legalUser.LegalRepresentativeCountryOfResidence}
						style={localStyles.country}/>
				</LabeledWrapper>
			</RNView>


			{
				edited ?
					<Button loading={loading}
							onPress={persist}
							style={localStyles.submit}>
						{`Enregistrer`}
					</Button> :
					(
						legalUser &&
						<Button onPress={next}
								style={localStyles.submit}>
							{`Suivant ›`}
						</Button>
					)
			}

		</ScrollView>
	)
}

const localStyles = {
	layout: {
		backgroundColor: styles.color.white,
		padding: 20,
	},
	support: {
		alignSelf: styles.alignSelf.flexEnd,
	},
	header: {
		// fontSize: 20,
		margin: 8,
		marginTop: -5
	},

	addressInput: {
		layout: {},
		input: {
			fontSize: 20,
			padding: 20,
			paddingVertical: 20,
			paddingLeft: 20,
		},
	},
	input: {
		layout: {
			padding: 20,
		},
		label: {
			color: styles.color.black,
			fontSize: 13,
		},
		textInput: styles.static.bool({
				fontSize: 20,
				borderBottomWidth: .5,
			},
			{},
			{
				backgroundColor: styles.color.grey,
			}
		),
		editableText: styles.static.bool(
			{
				color: "black",
				fontSize: 20,
				borderBottomWidth: .5,
			},
			{
				color: "red"
			}
		),
		text: {
			color: "black",
			fontSize: 20,
			borderBottomWidth: .5,
			backgroundColor: styles.color.grey,
		}

	},
	submit: {
		alignSelf: styles.alignSelf.flexEnd,
	},

	grid: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		alignItems: styles.alignItems.center,
		flexWrap: styles.flexWrap.wrap,
		margin: 20,
	},

	textInput: {
		maxWidth: '100%',
		width: 400,
	},

	country: {
		fontSize: 20,
		width: 300,
		padding: 5,
	},

	date: {
		fontSize: 20,
		width: 200,
		padding: 5,
	}
}
