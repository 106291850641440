import React from "react";
import EditCard from "./EditCard";
import DisplayCard from "./DisplayCard";
import use from "library-react/hook";

export default function Card({ editable = true, title, onToggle, onCanceled, ...props }) {
	const [editMode, { toggle }] = use.state.bool();
	use.onChange(!editMode && onCanceled, [editMode]);
	use.onChange(onToggle, [editMode]);

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		editMode ?
			<EditCard {...props}
				title={title?.edit || title}
				onDisplay={toggle}
			/> :
			<DisplayCard {...props}
				title={title?.display || title}
				onEdit={editable && toggle} />
	);
}

const localStyles = {
	layout: {
		backgroundColor: "#f8f9fa",
		borderRadius: 20,
		width: 545,
	}
};
