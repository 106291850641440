import React from "react"
import { View as RNView } from "react-native"
import View from "library-react/component/View/v2";
import Image from "library-react/component/Image";
import { strings, styles } from "../../res"
import StaticComponent from "library-react/component/StaticComponent";
import use from "library-react/hook";
import Icon from "library-react/component/Icon/v2";

function ProductItem(
	{
		item,
		vProdshop,
		children,
		override,
		selected,
		toggleSelection,
		...props
	}
) {
	vProdshop = item || vProdshop;
	const { product, finalPrice, oldPrice } = vProdshop || {};

	let renderImage = () => (
		<Image
			source={{ uri: product?.mainPicture.url, width: localStyles.layout.width }}
			resizeMode="cover"
			style={localStyles.image} />
	);

	if (override?.renderImage)
		renderImage = override.renderImage(renderImage);

	const [infosHovered, setInfosHover] = use.state();

	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout, [selected]);
	return (
		<View {...props}>
			{renderImage(vProdshop)}

			<View
				onHoverChanged={toggleSelection && setInfosHover}
				onPress={toggleSelection}
				style={localStyles.infos}>
				<RNView style={localStyles.main}>
					<RNView style={localStyles.mainLeft}>
						<Text
							numberOfLines={1}
							style={localStyles.name}>
							{product?.name}
						</Text>

						<Text
							numberOfLines={1}
							style={localStyles.brand}>
							{product?.brand || strings.invisibleChar}
						</Text>
					</RNView>

					<RNView>
						<Text
							numberOfLines={1}
							style={localStyles.price}>
							{finalPrice?.formatted}
						</Text>

						{
							oldPrice &&
							<Text
								numberOfLines={1}
								style={localStyles.oldPrice}>
								{oldPrice.formatted}
							</Text>
						}
					</RNView>
				</RNView>

				{
					(selected || infosHovered) &&
					<Icon
						name="MaterialIcons/check"
						size={30}
						style={[
							styles.fit, {
								backgroundColor: selected ? styles.color.azure : "rgba(11,138,243,0.53)",
								color: 'white'
							}
						]} />
				}
			</View>


			<RNView style={localStyles.available(vProdshop.available)} />
		</View>
	);
}

export default React.memo(ProductItem);

const localStyles = {
	layout: selected => [
		{
			width: 200,
			borderRadius: 7,
			overflow: styles.overflow.hidden,
			backgroundColor: 'white',
			borderWidth: 1,
			borderColor: '#c8c8c8',
		},
		selected &&
		styles.newShadow(0, 0, 5, .1),
	],

	image: {
		height: 170,
		backgroundColor: '#f5f5f5'
	},

	available: styles.static.bool({
		...styles.absolute({ top: 170 - (14 / 2), right: 7 }),
		...styles.circle(14),
		backgroundColor: 'lightgrey',
		borderColor: 'white',
		borderWidth: 2,
	},
		{ backgroundColor: "#00dc66" }
	),

	infos: {},

	main: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		padding: 10,
		paddingVertical: 20,
	},

	mainLeft: {
		flex: 1,
		paddingRight: 10,
	},

	title: {
		fontSize: 14,
		color: styles.color.black,
		padding: 10,
	},

	brand: {
		color: styles.color.secondary,
		fontSize: 12,
	},

	price: {
		bold: true,
		fontSize: 15,
		color: styles.color.aquaMarine,
		textAlign: styles.textAlign.right,
	},

	oldPrice: {
		fontSize: 12,
		textDecorationLine: styles.textDecorationLine.lineThrough,
		color: 'grey',
		textAlign: styles.textAlign.right,
	},

	footer: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		alignItems: styles.alignItems.center,
		height: 30,
	},


};
