import View from "./View"
import Image from "./Image"
import {Text} from "react-native"
import styles from "../res/styles"
import React from "react"
import Icon from "./Icon"

export default ({vProdshop, saleTheme, distanceTo, infoStyle, labelView, displayName = true, children, ...props}) => {
	let {saleProduct, sale, vProduct, prodshop} = vProdshop;
	let productImageSrc = vProduct
		&& vProduct.product.gallery
		&& vProduct.product.gallery.first
		&& vProduct.product.gallery.first.url;

	let isOnSale = saleProduct
		&& sale
		&& sale.active;

	let accentColor = saleTheme ? styles.color.cherry : styles.color.tealish;

	return (
		<View {...props}>
			<View style={localStyles.image.layout}>
				<Image style={localStyles.image.self}
					   source={{uri: productImageSrc}}
					   resizeMode="cover"/>
				{/*Label*/}
				{
					isOnSale &&
					<View style={[localStyles.image.label.layout, {backgroundColor: accentColor}]}>
						<Text style={localStyles.image.label.text}>
							-{saleProduct.discount}%
						</Text>
					</View>
				}

			</View>


			{/*Name & brand*/}
			<View>
				<View style={localStyles.infos.labelView}>
					{labelView}
				</View>
				{/*Empty text to fill the height*/}
				<Text style={localStyles.invisible}>
					-
				</Text>

				{
					displayName &&
					<Text style={[localStyles.invisible, localStyles.name]}>
						-
					</Text>
				}

				{/*Displayed text using the filled height by invisible texts above, and thus not imposing their width*/}
				<View style={styles.fit}>
					<Text numberOfLines={1}
						  style={[localStyles.brand, infoStyle]}>
						{vProduct.product.title}
					</Text>

					{
						displayName &&
						<Text numberOfLines={1}
							  style={[localStyles.name, infoStyle]}>
							{vProduct.product.title !== vProduct.product.name ? vProduct.product.name : ""}
						</Text>
					}
				</View>
			</View>

			<View style={[localStyles.infos.layout, infoStyle]}>
				{/*price*/}
				<View style={localStyles.infos.price.layout}>
					{
						isOnSale ?
							[
								<Text key="priceOnSale"
									  style={[localStyles.infos.price.self, {color: accentColor}]}>
									{saleProduct.price.formatted}
								</Text>,
								< Text key="oldPriceOnSale" style={localStyles.infos.price.old}>
									{saleProduct.oldPrice.formatted}
								</Text>
							]
							:
							prodshop ?
								<Text style={localStyles.infos.price.self}>
									{prodshop.finalPrice?.formatted}
								</Text> : noText
					}
				</View>


				{ // Location
					Boolean(vProdshop.shop && vProdshop.shop.location && distanceTo) &&
					<View style={localStyles.infos.location.layout}>
						<Text
							style={localStyles.infos.location.text}>{vProdshop.shop.location.to(distanceTo).formatted}</Text>

						<Icon name="MaterialIcons/location-on" style={{color: accentColor, height: 12, width: 12}}/>
					</View>
				}
			</View>

			{/*	Foreground */}
			{children}
		</View>
	);
}

const localStyles = {
	invisible: {opacity: 0},
	name: {color: styles.color.brownishGrey, fontSize: 10},
	brand: {color: styles.color.black},
	image: {
		layout: {flex: 1, alignSelf: styles.alignSelf.stretch},
		self: {marginBottom: 2, flex: 1, aspectRatio: 1},
		label: {
			layout: {
				paddingHorizontal: 10,
				position: styles.position.absolute,
				bottom: 0,
				right: 0,
			},
			text: {fontWeight: "bold", color: styles.color.white}
		}
	},
	infos: {
		labelView:{
			position: styles.position.absolute,
			bottom:"100%",
			left:0,
			right: 0
		},
		layout: {
			flexDirection: styles.flexDirection.row,
			justifyContent: styles.justifyContent.spaceBetween
		},
		price: {
			layout: {flexDirection: styles.flexDirection.row},
			self: {fontWeight: styles.fontWeight.bold, color: styles.color.black},
			old: {
				marginLeft: 10,
				color: styles.color.black,
				textDecorationLine: styles.textDecorationLine.lineThrough,
			},
		},
		location: {
			layout: {
				alignSelf: styles.alignSelf.flexEnd,
				flexDirection: styles.flexDirection.row,
				marginLeft: 2,
				alignItems: styles.alignItems.center
			},
			text: {fontSize: 12},
		}
	},

};

const noText = (
	<View style={localStyles.invisible}>
		<Text style={{fontWeight: styles.fontWeight.bold}}>
			NoText
		</Text>
	</View>
);
