import React, { useCallback } from "react"
import View from "library-react/component/View"
import ComponentUtils from "library-react/ComponentUtils"
import { strings, styles } from "../../../res"
import Text from "library-react/component/Text"
import Icon from "library-react/component/Icon"
import Slot from "../../component/Slot"
import ImageView from "library-react/component/Image"
import EditButton from "../../component/EditButton"
import selectFile from "library-react/utils/selectFile"
import { Image } from "library-js/app/model/media"
import { Public as Server } from "library-js/app/Server"
import persistShop from "./persistShop";
import use from "library-react/hook";
import useMe, { useMeLoadable } from "../../../library-react/hook/pro/useMe";
import useToast from "../../../library-react/hook/useToast";
import PopupMenu from "library-react/component/PopupMenu";
import ThemeContext from "library-react/hook/useTheme/ThemeContext";

/**
 * Can only be used on web because it selects and uploads medias.
 */
export default function MediasPage({ ...props }) {
	let { shop } = useMe().shop;
	let { logo = {}, gallery = [] } = shop;
	gallery = gallery.copy();
	let banner = gallery.shift();

	const [tasking, setTasking] = use.legacyState(() => ({
		// elements which are tasking display a spinner
		logo: false,
		banner: false,
		adding: false,
		/*gallery mapped by url*/
	}));


	const toast = useToast();
	const meLoadable = useMeLoadable();
	const persist = persistShop(meLoadable);
	const editLogo = useCallback(async () => {
		let file = await selectFile("image/*");
		if (!file) return;

		setTasking({ logo: true });

		let image = new Image;
		image.url = file.url;

		let response = await Server.media.upload(image, file);
		if (response.ok) {
			let update = shop.copy();
			update.logo = image;
			response = await persist(update);
		}

		if (!response.ok) {
			toast(response.error.message)
			response.log();
		}

		setTasking({ logo: false });
	}, [shop]);

	const editBanner = useCallback(async () => {
		let file = await selectFile("image/*");
		if (!file) return;

		setTasking({ banner: true });

		let image = new Image;
		image.url = file.url;

		let response = await Server.media.upload(image, file);
		if (response.ok) {
			let update = shop.copy();
			update.gallery = update.gallery ? update.gallery.copy() : [];
			update.gallery.head = image;
			response = await persist(update);
		}

		if (!response.ok) {
			toast(response.error.message)
			response.log();
		}

		setTasking({ banner: false });
	}, [shop]);

	const addImage = useCallback(async () => {
		let file = await selectFile("image/*");
		if (!file) return;

		setTasking({ adding: true });

		let image = new Image;
		image.url = file.url;
		let response = await Server.media.upload(image, file);
		if (response.ok) {
			let update = shop.copy();
			update.gallery = update.gallery ? update.gallery.copy() : [];
			update.gallery.push(image);
			response = await persist(update);
		}

		if (!response.ok)
			response.log();

		setTasking({ adding: false });
	}, [shop]);

	const removeImage = useCallback(async image => {
		if (!shop.gallery || !shop.gallery.length)
			return;

		setTasking(tasking => (tasking[image && image.url] = true));

		let update = shop.copy();
		update.gallery = shop.gallery.copy();
		let index = update.gallery.indexOf(image);
		if (index >= 0) {
			update.gallery.splice(index, 1);
			let response = await persist(update);

			if (!response.ok)
				response.log();
		}

		setTasking(tasking => (tasking[image && image.url] = false));
	}, [shop]);

	const { Text } = use.theme();
	props.style = ComponentUtils.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<Slot style={localStyles.logo.layout}>
				<View style={localStyles.logo.image}>
					<ImageView source={{ uri: logo.url }}
						style={styles.fit} />

					{tasking.logo && <Tasking />}
				</View>

				{
					!tasking.logo &&
					<Edit onPress={editLogo} />
				}
			</Slot>

			<Slot style={localStyles.banner.layout}>
				<ImageView
					source={{ uri: banner && banner.url }}
					resizeMode={styles.resizeMode.cover}
					style={localStyles.banner.image}
				/>

				{
					tasking.banner ?
						<Tasking /> :
						<Edit onPress={editBanner} />
				}

				<PopupMenu
					style={styles.absolute({ bottom: 10, right: 63 })}
					alignBottom
					trigger={
						<EditButton name="MaterialCommunityIcons/information-variant" />
					}>
					<PopupMenu.Item>
						{`Cette image sera utilisé comme image principale pour votre site lorsque ce dernier est partagé sur les réseaux sociaux.`}
					</PopupMenu.Item>
				</PopupMenu>
			</Slot>

			<Slot onPress={!gallery.length && addImage}
				style={localStyles.gallery.layout}>
				{({ hover }) =>
					gallery.length ?
						<React.Fragment>
							<View style={localStyles.gallery.grid}>
								{gallery.map(image =>
									<View key={image.url}
										style={localStyles.gallery.item}>
										<ImageView source={{ uri: image.url }}
											style={localStyles.gallery.image}
										/>

										{// tasking
											tasking[image.url] ? <Tasking /> :

												// delete button on hover
												({ hover }) =>
													hover &&
													<View style={localStyles.gallery.remove.layout}>
														<Icon
															name="remove"
															onPress={() => removeImage(image)}
															style={localStyles.gallery.remove.icon} />
													</View>
										}
									</View>
								)}
							</View>

							<Icon name={tasking.adding ? "loading" : "add"}
								onPress={!tasking.adding && addImage}
								style={[localStyles.gallery.add, tasking.adding && { color: styles.color.azure2 }]} />
						</React.Fragment> :

						// Instructions
						<React.Fragment>
							<View style={{ flex: 1 }}>
								<Text style={localStyles.instructions.title(hover)}>
									{strings.fragment.shop.medias.gallery.title}
								</Text>

								<Text style={localStyles.instructions.subtitle(hover)}>
									{strings.fragment.shop.medias.gallery.subtitle}
								</Text>
							</View>

							<View style={localStyles.instructions.add.layout}>
								<Icon name={tasking.adding ? "loading" : "add"}
									style={localStyles.instructions.add.icon(hover)} />

								<Text style={localStyles.instructions.add.label(hover)}>
									{strings.fragment.shop.medias.gallery.label}
								</Text>
							</View>
						</React.Fragment>
				}
			</Slot>
		</View>
	);
}

const Edit = ({ onPress }) => (
	<View style={localStyles.edit.layout}>
		<EditButton onPress={onPress} />
	</View>
);

const Tasking = () => (
	<View style={[styles.fit, styles.center, { backgroundColor: styles.color.darkTransparent }]}>
		<ThemeContext.Consumer>
			{({ Spinner }) => <Spinner size={24} />}
		</ThemeContext.Consumer>
	</View>
);


const localStyles = {
	layout: {
		paddingTop: 53,
		paddingHorizontal: 42,
	},

	logo: {
		layout: [
			styles.center,
			{
				padding: 26,
				marginBottom: 35,
			},
		],

		image: [
			styles.newShadow(0, 3, 6, .16),
			{
				height: 146,
				width: 146,
			}
		],
	},

	banner: {
		layout: {
			overflow: styles.overflow.hidden,
			marginBottom: 48,
			height: 255,
		},

		image: styles.fit,
	},

	edit: {
		layout: {
			position: styles.position.absolute,
			right: 0,
			top: 0,
			bottom: 0,
			paddingRight: 63,
			justifyContent: styles.justifyContent.center,
		}
	},

	gallery: {
		layout: {
			flexDirection: styles.flexDirection.row,
			flexWrap: styles.flexWrap.wrap,
			alignItems: styles.alignItems.center,
			paddingVertical: 25,
			paddingRight: 40,
			paddingLeft: 26,
			marginBottom: 76,
		},

		grid: {
			flexDirection: styles.flexDirection.row,
			flexWrap: styles.flexWrap.wrap,
			flex: 1,
		},

		item: [
			styles.center,
			{
				width: 286,
				height: 191,
				marginRight: 33,
				marginVertical: 20,
			},
		],

		image: [
			styles.fit,
			{ zIndex: -1 }
		],

		remove: {
			layout: [
				styles.fit,
				styles.center,
				{ backgroundColor: styles.color.darkTransparent },
			],

			icon: {
				color: styles.color.white,
				width: 54,
				height: 54,
			}
		},

		add: {
			width: 54,
			height: 54,
			color: styles.color.main,
			margin: 17,
		}
	},

	instructions: {
		title: hover => ({
			fontSize: 37,
			color: hover ? styles.color.azure2 : styles.color.sleeping,
			bold: true,
		}),

		subtitle: hover => ({
			color: hover ? styles.color.sea : styles.color.sleeping,
			italic: true,
			marginTop: 6,
		}),

		add: {
			layout: {
				alignItems: styles.alignItems.center,
				width: 200,
			},

			icon: hover => ({
				height: 72,
				width: 72,
				marginBottom: 10,
				color: hover ? [styles.color.deepSkyBlue, styles.color.aquaBlue] : styles.color.sleeping
			}),

			label: hover => [
				styles.if(!hover, { color: styles.color.sleeping }),
				{
					fontSize: 17
				}
			],
		}
	},
};
