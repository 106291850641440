import React from 'react'
import {Animated} from 'react-native'
import LinearGradient from "react-native-linear-gradient";
import styles from "../../res/styles";
import View from "library-react/component/View/v2";
import ComponentUtils from "library-react/ComponentUtils";
import {is} from "library-js/utils";
import use from "library-react/hook";
import {TextStyleContext} from "library-react/component/Text/v2";

/**
 * @param {any}
 */
export default function Button({raised, loading, children, ...props}) {
	const {Text, Spinner} = use.theme();

	children = ComponentUtils.childrenToArray(children);
	props.style = ComponentUtils.style.merge(localStyles.layout, props.style);
	let borderRadius = props.style.borderRadius;

	const [hover, setHover] = use.state.bool();
	if (raised)
		props.style = [localStyles.raised({hover}), props.style];

	if (loading)
		delete props.onPress;

	return (
		<TextStyleContext.Provider value={localStyles.text}>
			<View onHoverChanged={setHover} {...props}>
				<LinearGradient
					style={[styles.fit, {borderRadius, zIndex: -1}]}
					colors={[styles.color.deepSkyBlue, styles.color.aquaBlue]}/>

				{
					ComponentUtils.childrenToArray(children)
						.map((child, index) => {
							if (is(child,[String, Number]))
								child = <Text key={index}>{child}</Text>;
							return child;
						})
				}

				{// spinner
					loading &&
					<Spinner
						color={styles.color.white}
						size={15}
						style={styles.absolute({right: 16, top: 17})}/>
				}
			</View>
		</TextStyleContext.Provider>
	);
}

const localStyles = {
	raised: ({hover}) => hover ? styles.newShadow(0, 2, 7, .16)
		: styles.newShadow(0, 15, 19, .16),

	layout: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.center,
		paddingHorizontal: 57,
		paddingVertical: 15,
		borderRadius: 50,
	},

	text: {
		bold: true,
		color: styles.color.white,
		fontSize: 17,
		textAlign: styles.textAlign.center,
	},
};
