import React from "react"
import {View as RNView, Animated} from "react-native"
import use from "library-react/hook"
import {styles} from "@src/res"
import useActionsContext from "./useActionsContext";
import ProgressBar from "library-react/component/ProgressBar";

function ProgressBar_catalog_actions({...props}) {
	const {persisting} = useActionsContext();

	const {label, progress} = persisting || {};

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return label ? (
		<Animated.View {...props}>

			<ProgressBar
				progress={progress || .01}
				color={styles.color.azure}
				style={localStyles.bar}/>

			<RNView
				numberOfLines={1}
				style={localStyles.content}>
				<Text style={localStyles.label}>
					{label}
				</Text>
			</RNView>
		</Animated.View>
	) : null;
}

export default React.memo(ProgressBar_catalog_actions);


const localStyles = {
	layout: {
		...styles.fit,
	},

	content: {
		...styles.center,
		...styles.fit,
	},

	label: {
		textAlign: styles.textAlign.center,
		color: 'white',
		bold: true,
		fontSize: 20,
	},

	bar: {
		...styles.fit,
	}
};
