import React, {useCallback} from "react"
import Card from "../../../component/Card";
import {strings, styles, images} from "../../../../res"
import View from "library-react/component/View";
import {Shop} from "library-js/app/model/entity";
import Image from "library-react/component/Image";
import Text from "library-react/component/Text";
import {Linking} from "react-native-web";
import {TextInput} from "react-native-paper";
import use from "library-react/hook";
import useMe from "library-react/hook/pro/useMe";


export default function SocialCard({editedShop, willResetFields, ...props}) {
	const {shop} = useMe().shop;
	const setShop = use.callback(editFunction => editFunction(editedShop));

	props.onCanceled = willResetFields(Shop.socialNetworks);

	return (
		<Card
			icon="share"
			title={strings.fragment.shop.social.title}
			subtitle={strings.fragment.shop.social.subtitle}
			{...props}>
			{(editable) =>
				<View style={localStyles.display.layout}>
					{
						!editable ?
							// Display
							Shop.socialNetworks
								.map(network => (
									<View
										key={network}
										style={localStyles.display.item}>

										<Logo network={network}/>

										{
											shop[network] ?
												<Text style={localStyles.display.link}
													  numberOfLines={1}
													  onPress={() => Linking.openURL(shop[network])}>
													{shop[network]}
												</Text> :

												// "no link" text
												<Text>
													{strings.fragment.shop.social.noLink}
												</Text>
										}
									</View>
								)) :

							// Edit
							<>
								{Shop.socialNetworks.map(network =>
									<View key={network} style={localStyles.edit.item}>
										<Logo network={network}/>

										<TextInput
											label={shop[network] ? "" : network}
											defaultValue={shop[network]}
											onChangeText={link => setShop(shop => shop[network] = link)}
											style={localStyles.edit.input}
										/>
									</View>
								)}
							</>
					}
				</View>
			}
		</Card>
	);
}

const Logo = ({network}) => (
	<Image
		source={images.social[network]}
		style={localStyles.display.logo}
		resizeMode={styles.resizeMode.contain}
	/>
);

const localStyles = {
	display: {
		layout: {
			paddingHorizontal: 30,
			paddingTop: 20,
			minHeight: 385,
		},

		item: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			paddingBottom: 20,
		},

		logo: {
			width: 45,
			height: 45,
			marginRight: 30,
		},

		link: {
			color: styles.color.azure2,
		}
	},

	edit: {
		item: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			paddingBottom: 20,
		},

		input: {
			width: 300
		}
	}
};
