import React from "react";
import View from "library-react/component/View/v2";
import Text from "library-react/component/Text/v2";
import {styles} from "@src/res";
import use from "library-react/hook";
import Button from "@main/component/Button";

export default function LegalInfosRequired({next, ...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<Text style={localStyles.header}>
				{`Pour pouvoir passez aux étapes suivante, vous devez avant tout soumettre vos informations légales.`}
			</Text>
			<Button onPress={next}
					style={localStyles.submit}>
				{`Informations légales`}
			</Button>
		</View>
	)
}
const localStyles = {
	layout: {
		backgroundColor: styles.color.white,
		padding: 20,
	},
	header: {
		marginBottom: 15,
		marginTop: -5
	},
	submit: {
		alignSelf: styles.alignSelf.center,
	}
}
