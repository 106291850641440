import React from "react"
import {ScrollView} from "react-native"
import use from "library-react/hook"
import {styles} from "../../../res"
import View from "library-react/component/View/v2";
import Text from "library-react/component/Text";
import Icon from "library-react/component/Icon/v2";
import Server from "library-js/app/Server"
import SectionView from "./SectionView";
import CatalogSection from "library-js/app/model/entity/CatalogSection";
import useMe from "../../../library-react/hook/pro/useMe";

function CatalogSectionDropdownSelection({productId, placeholder, loadable, productLoadable, productSections, toggle: toggleCallback, onRemoveClicked, alignRight, ...props}) {
	const shop = useMe().shop.shop;
	const {value: cupboard} = use.loadable.server(!loadable && (() => Server.public.shop.getSections(shop.id))) || loadable;

	const forceUpdate = use.forceUpdate();
	use.effect(loadable && (() => loadable.onStateChanged(forceUpdate)), [loadable]);

	const forcedProductSections = Boolean(productSections);
	const needsToLoadProductSections = Boolean(!forcedProductSections && productId && !productLoadable);

	const productSectionsLoadable = use.loadable.server((
			needsToLoadProductSections &&
			(() => Server.public.prodshop.getSectionsOf(shop.id, productId)) // sections to load
		),
		[needsToLoadProductSections, productId],
	) || productLoadable;

	if (productSectionsLoadable) // sections are to load, set variable to loaded value
		productSections = productSectionsLoadable.value;

	const selected = productSections?.map(section => [section.path.slice(0, 1), section.path]) // set path & parents as selected
		.flat()
		.distinct()
		.toObject(path => path.join('/'), () => true) || {};

	const [tasking, updateTasking] = use.legacyState({});
	const toggleSection = use.asyncCallback(shouldStop =>
			toggleCallback ?
				(section, isSelected) => toggleCallback(section, isSelected, cupboard)
				: (
					(needsToLoadProductSections && productSections) && (
						(section, isSelected) => {
							const nextState = !isSelected;
							const sectionItems = cupboard.getAll()
								.filter(aSection => (
									// if enabling, enable all ancestors
									nextState ? section.path.startsWith(aSection.path)
										// if disabling, disable all descendants
										: aSection.path.startsWith(section.path)
								))
								.map(section => new CatalogSection.Item(productId, section.id));

							const setItemsTasking = (areTasking) =>
								updateTasking(tasking =>
									sectionItems.forEach(item =>
										tasking[item.sectionId] = areTasking
									)
								);


							setItemsTasking(true);

							return Promise.process(shouldStop)
								.then(() => Promise.resultAll(
									sectionItems.map(item =>
										Server.retailer.catalog.sections.setProduct(item, nextState)
									)
								))
								.then(results => {
									const errors = []; // collect not ok responses

									results.forEach(([response, error], index) => {
										if (error)
											errors.push(error);
										else if (!response.ok)
											errors.push(response.toJSON());
										else { // OK
											const item = sectionItems[index];
											const sectionId = item.sectionId;

											if (nextState) {
												const section = cupboard.getAll().find(section => section.id === item.sectionId);
												if (section)
													productSectionsLoadable.value = productSectionsLoadable.value.concat(section);
												else
													console.warn("Cannot find the section to toggle in the cupboard.\n Section: ", item.sectionId, "\n Cupboard: ", cupboard.getAll(true));
											} else
												productSectionsLoadable.value = productSectionsLoadable.value.filter(section => section.id !== sectionId);
										}
									});

									if (errors.length > 0)
										console.warn("Errors while updating sections: ", errors);
								})
								.result((_, error) => {
									if (error)
										console.warn(error);

									setItemsTasking(false);
								})
						}
					)
				),
		[cupboard, toggleCallback]
	);

	const [opened, setOpened] = use.state(false);
	const instances = use.instances({
		root: element => {
			return element &&
			[
				element.on("focusin", () => setOpened(true)),
				element.on("focusout", () => setTimeout(() => {
					if (!element.contains(document.activeElement))
						setOpened(false);
				}, 50)),
			]
		}
	});

	props.ref = instances.set.root;
	props.focusable = true

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		(cupboard?.getAll(false).length > 0) &&
		(!productSectionsLoadable || productSections) &&
		<View {...props}>
			<Text style={localStyles.label}>
				{
					placeholder || (
						productSections?.length === 1 ?
							productSections.first.name :
							productSections?.length > 0 ? `Classifié (${productSections.length})` :
								`Classifier`
					)
				}
			</Text>

			<Icon
				name="FontAwesome/caret-down"
				size={20}
				style={localStyles.icon}
			/>

			{
				opened &&
				<ScrollView style={[localStyles.dropdown.layout, alignRight ? localStyles.dropdown.alignRight : localStyles.dropdown.alignLeft]}>
					{
						cupboard.getAll(true)
							.map(section => (
								<SectionView
									key={section.name}
									item={section}
									toggle={toggleSection}
									tasking={tasking[section.id]}
									selected={selected[section.name]}
								/>
							))
					}
				</ScrollView>
			}
		</View>
	);
}

export default React.memo(CatalogSectionDropdownSelection);


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		paddingVertical: 5,
		borderBottomWidth: .5,
		borderColor: styles.color.main,
	},

	label: {
		fontSize: 19,
		minWidth: 200,
		flexShrink: 1,
		flexGrow: 1,
	},

	icon: {
		marginLeft: 5,
		color: styles.color.main,
	},

	dropdown: {
		layout: {
			backgroundColor: styles.color.white,
			...styles.newShadow(0, 5, 10, .11),
			borderRadius: 8,
			paddingVertical: 20,

			position: styles.position.absolute,
			top: 0,
			maxHeight: 340,
			width: "100%",
			minWidth: 300,
		},

		alignLeft: {
			left: 0,
		},

		alignRight: {
			right: 0,
		},
	}
};
