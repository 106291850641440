import Button from "./component/Button";
import ConfirmModal from "./component/ConfirmModal";
import Spinner from "./component/Spinner";
import Switch from "./component/Switch";

export const appTheme = {
	Spinner,
	Switch,
	MainButton: Button,
	ConfirmModal: ConfirmModal,
} as const;

export type AppTheme = typeof appTheme;