import React from "react"
import StaticComponent from "library-react/component/StaticComponent";
import View from "library-react/component/View";
import {strings, styles, images} from "../../../res";
import Text from "library-react/component/Text";
import {Linking} from "react-native-web";
import Image from "library-react/component/Image";

export default new StaticComponent(
		<View style={[styles.fill, {
			backgroundColor: "#d8f1f563",
			borderTopRightRadius: 100,
			borderBottomRightRadius: 100
		}]}>
			{/*localisation*/}
			<Image source={images.signin.locationMarker}
				   resizeMode={styles.resizeMode.contain}
				   style={{
					   position: styles.position.absolute,
					   height: 1154,
					   width: 800,
					   left: -225,
					   bottom: -190,
				   }}/>

			{/*customer*/}
			<Image source={images.signin.customer}
				   resizeMode={styles.resizeMode.contain}
				   style={{width: "24.1%", height: "57.5%", position: styles.position.absolute, top: "19.7%", left: "18.4%"}}/>

			{/*shop*/}
			<Image source={images.signin.shop}
				   resizeMode={styles.resizeMode.contain}
				   style={{width: "40.8%", height: "27.3%", position: styles.position.absolute, top: "39%", left: "44.8%"}}/>

			<View style={{flexDirection: styles.flexDirection.row, position: styles.position.absolute, bottom: 60, left: 38, right: 93}}>
				<Text style={{fontSize: 15}}>
					{strings.fragment.signin.infos.copyrights}
				</Text>

				<View style={{flexDirection: styles.flexDirection.row, flex: 1, justifyContent: styles.justifyContent.flexEnd}}>
					<Text
						onPress={() => window.open('https://shopinzon.com/legal-agreement', '_blank')}
						style={{textDecorationLine: styles.textDecorationLine.underline, fontSize: 15, marginRight: 28}}>
						{strings.fragment.signin.infos.conditions}
					</Text>

					<Text
						onPress={() => window.open('https://shopinzon.com/private-policy', '_blank')}
						style={{textDecorationLine: styles.textDecorationLine.underline, fontSize: 15, marginRight: 28}}>
						{`Politique de confidentialité`}
					</Text>

					<Text style={{textDecorationLine: styles.textDecorationLine.underline, fontSize: 15}}
						  {...Linking.linkPropsFor("mailto:marc@shopinzon.com")}>
						{strings.fragment.signin.infos.contact}
					</Text>
				</View>
			</View>
		</View>
);
