import React from "react"
import { View as RNView } from "react-native"
import use from "library-react/hook"
import { images, styles } from "@src/res"
import Image from "library-react/component/Image/Image";
import getResourcesForReservationState from "library-js/res/reservationState";
import ReservationState from "library-js/app/model/entity/Reservation/State";
import ReservationContactButton from "./ReservationContactButton";
import SimpleButton from "library-react/component/SimpleButton";
import Icon from "library-react/component/Icon/v2";
import PopupMenu from "library-react/component/PopupMenu";
import useToast from "library-react/hook/useToast";
import { Server } from "library-js/app";
import strictAPI from "library-js/app/Server/strictAPI";
import View from "library-react/component/View/v2";
import Portal from "library-react/component/Portal";
import screens from "@main/screens";
import toMoment from "library-js/utils/toMoment";
import useMe from "library-react/hook/pro/useMe";

/**
 * @param {any}
 */
function ReservationRow({ item: vReservation, hasPrevious, hasNext, onUpdated, displayDate, ...props }) {
	const { shop } = useMe().shop;
	const { reservation, vUser, currentState } = vReservation || {};
	const state = currentState?.type;

	const stateRes = getResourcesForReservationState(state);
	const user = vUser?.user;
	const time = reservation?.time;
	const color = stateRes?.color(time);

	const isPast = new Date(time) < new Date();

	const [stateUpdating, setStateUpdating] = use.state();
	const toast = useToast();

	function updateStateTo(state) {
		setStateUpdating(state);

		strictAPI(Server.retailer.reservation.persist)({ id: reservation.id, reservation, state })
			.then(vReservation => {
				// --- adaptation code for mock data (remove once mock data is not use)
				// vReservation.reservation.id = reservation.id;
				// vReservation.reservation.time = reservation.time;
				// vReservation.currentState.type = state;
				// --

				toast(getResourcesForReservationState(state).title());
				onUpdated?.(vReservation);
			})
			.catch(error => {
				console.error(error);
				toast(error.message);
			})
			.finally(() => setStateUpdating(undefined));
	}

	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			<Portal to={reservation && screens.reservation} params={{ id: reservation?.id }}>
				<View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
					<RNView style={localStyles.leftSide}>
						<RNView style={localStyles.timeline(hasPrevious ? color : 'transparent')} />

						<Image
							source={user?.picture}
							defaultSource={images.interlocutor}
							style={localStyles.picture(color)} />

						<RNView style={localStyles.timeline(hasNext ? color : 'transparent')} />
					</RNView>

					<RNView style={{ width: 100 }}>
						<Text
							numberOfLines={1}
							style={localStyles.numberOfPlaces(color)}>
							{reservation?.numberOfPlaces}{`p`}
						</Text>

						<Text style={localStyles.time('black')}>
							{toMoment(shop.timeZone, time).format('HH:mm')}
						</Text>
					</RNView>

					<RNView>
						<Text
							numberOfLines={1}
							style={localStyles.name}>
							{reservation?.fullName}
						</Text>
						<Text
							numberOfLines={1}
							style={localStyles.phone}>
							{reservation?.phone}
						</Text>
					</RNView>
				</View>
			</Portal>
			{
				Boolean(displayDate) &&
				<View style={{ flexDirection: 'row', alignItems: 'center' }}>
					<Icon name="MaterialCommunityIcons/calendar" size={20} />
					<Text
						numberOfLines={1}
						style={localStyles.date}>
						{vReservation?.getMoment().format('DD/MM/YY')}
					</Text>
				</View>
			}
			<RNView style={{ flex: 1, flexDirection: 'row' }}>
				{
					stateUpdating ?
						<Text style={{
							marginLeft: 20,
							bold: true,
							fontSize: 18,
							color: getResourcesForReservationState(stateUpdating).color()
						}}>
							{`Envoi en cours...`}
						</Text> :
						state?.is.waiting && !isPast ?
							<PopupMenu
								alignLeft
								trigger={
									<SimpleButton
										filled
										style={{ borderRadius: 20, paddingHorizontal: 20 }}
										colors={{
											primary: color,
											secondary: 'white',
										}}>
										<Text style={{ bold: true, fontSize: 18 }}>
											{stateRes?.title(time)}
										</Text>

										<Icon name="MaterialIcons/arrow-drop-down" size={16} style={{ marginLeft: 7 }} />
									</SimpleButton>
								}>

								{
									[
										ReservationState.Type.accepted,
										ReservationState.Type.refused,
									].map(state => {
										const { color, action } = getResourcesForReservationState(state);
										return (
											<PopupMenu.Item
												key={state.key}
												onPress={() => updateStateTo(state)}>
												<Text style={{
													color: color(),
													bold: true
												}}>
													{action()}
												</Text>
											</PopupMenu.Item>
										);
									})
								}
							</PopupMenu>
							: <Text
								style={localStyles.state(color)}>
								{stateRes?.title(time)}
							</Text>
				}
			</RNView>

			<ReservationContactButton vReservation={vReservation} />
		</RNView>
	);
}

export default React.memo(ReservationRow);


const localStyles = {
	layout: {
		flexDirection: 'row',
		alignItems: 'center',
		paddingHorizontal: 42,
	},

	leftSide: {
		alignItems: 'center',
		marginRight: 20,
	},

	timeline: (color) => ({
		flex: 1,
		marginTop: -1,
		marginBottom: -1,
		width: 2,
		backgroundColor: color,
		minHeight: 20,
	}),

	picture: (color) => ({
		...styles.circle(60),
		borderWidth: 2,
		borderColor: color || 'black',
		zIndex: 1,
	}),

	column: {
		flex: 1,
	},

	numberOfPlaces: color => ({
		bold: true,
		fontSize: 20,
		color,
		marginBottom: 3,
	}),

	name: {
		fontSize: 20,
		bold: true,
		marginBottom: 3,
	},

	time: (color) => ({
		fontSize: 20,
		color: color,
	}),

	phone: {
		fontSize: 16,
	},

	date: {
		flex: 1,
		marginLeft: 5,
		fontSize: 16,
	},

	state: color => ({
		bold: true,
		fontSize: 20,
		color,
		paddingLeft: 20,
	}),

	confirmModal: {
		layout: {
			width: '99%',
			maxWidth: 700,
		},

		card: {
			marginTop: 20,
			marginBottom: 30,
			borderColor: 'lightgrey',
			padding: 20,
			borderWidth: 1,
			borderRadius: 9,
		},

		row: {
			marginVertical: 20,
			flexDirection: 'row',
		},

		field: {
			flex: 1,
			textAlign: 'center',
		},

		button: {
			paddingVertical: 20,
		},

		buttonTxt: {
			fontSize: 18,
			bold: true,
		}
	}
};
