export default {};

// import fonts from "../library-react/res/fonts"
// export default fonts;
//
// fonts.add("poppins", {
// 	regular: require("./font/Poppins/light.otf"),
// 	bold: require("./font/Poppins/medium.otf"),
// 	italic: require("./font/Poppins/light-italic.otf"),
// 	boldItalic: require("./font/Poppins/medium-italic.otf"),
// 	default : require("./font/Poppins/light.otf"),
// });
//
// fonts.add("sourceSansPro", {
// 	regular: require("./font/SourceSansPro/regular.ttf"),
// 	bold: require("./font/SourceSansPro/black.ttf"),
// 	italic: require("./font/SourceSansPro/italic.ttf"),
// 	boldItalic: require("./font/SourceSansPro/black-italic.ttf"),
// 	default: require("./font/SourceSansPro/regular.ttf"),
// });
