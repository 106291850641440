import React from "react"
import {styles} from "../../res"
import {ScrollView} from "react-native"
import Icon from "../Icon"
import View from "../View"
import ComponentUtils from "../../ComponentUtils"
import Text from "../Text";
import Component from "../../Component";
import {Functions, is} from "../../library-js/utils";


export default class DropdownSelector extends Component {
	static callbacks = ["onOpenedChanged", "onValueChanged"];

	onConvertProps(props) {
		let propsForRender = super.onConvertProps(props);
		let {popup: factory} = props;

		if (factory && (!this.props || this.props.popup !== factory)) {
			if (is(factory, Function))
				propsForRender.popup = factory();

			else
				propsForRender.popup = factory;
		} else
			propsForRender.popup = null;

		if (propsForRender.popup)
			propsForRender.popup = ComponentUtils.childrenToArray(propsForRender.popup)
				.map((child, index) => {
					if (ComponentUtils.is(child, DropdownSelector.Item)) {

						child = React.cloneElement(child, {
							key: child.key || `item${index}`,
							onPress: Functions.append(child.props.onPress, () => this.onItemSelected(child, index))
						});
					}

					return child;
				});

		return propsForRender;
	}

	onResetState(state, props) {
		super.onResetState(state, props);
		state.selectedTitle = undefined;
		this.visible = false;
	}

	onItemSelected(item) {

		this.setState({selectedTitle: item.props.title});

		this.fire.onValueChanged(is(item.props.value) ? item.props.value : item.props.title);
		this.setVisible(false);
	}

	setVisible(visible) {
		visible = Boolean(visible);

		if (visible !== this.visible) {
			this.visible = visible;
			this.forceUpdate();
			this.fire.onOpenedChanged(visible);
		}
	}

	onRender({placeholder, alignBottom, alignRight, textStyle, popup, popupStyle, defaultValue = "", validate, ...props}, {selectedTitle}) {
		let {visible} = this;

		props.style = ComponentUtils.defaultStyle(styles.if(validate && !selectedTitle, localStyles.errorLayout), props.style, localStyles.layout);
		popupStyle = ComponentUtils.defaultStyle(popupStyle, localStyles.menu(visible, alignBottom, alignRight));
		textStyle = ComponentUtils.defaultStyle(styles.if(validate && !selectedTitle, localStyles.errorText), textStyle, localStyles.text);
		props.onPress = Functions.prepend(props.onPress, () => this.setVisible(!visible));
		props.forwardedRef = this.onFocusOut;

		return (
			<View {...props}>
				<Text
					style={textStyle}>
					{selectedTitle || defaultValue || placeholder}
				</Text>
				<Icon onPress={() => this.setVisible(!visible)}
					  name="MaterialIcons/arrow-drop-down"
					  style={localStyles.icon}/>
				<View onPress={doNothing} // To prevent  click event to bubble to the parent and close the dropdown
					  feedback={false}
					  style={popupStyle}>
					<ScrollView>
						{popup}
					</ScrollView>
				</View>
			</View>
		);

	}

	onFocusOut(element) {
		if (element) {
			element.on("focusout", () => setTimeout(() => {
				if (!element.contains(document.activeElement)) {
					this.setVisible(false);
				}
			}, 50))
		}
	}

	static Item({title, ...props}) {
		props.style = ComponentUtils.defaultStyle(props.style, localStyles.text);

		return (
			props.children ?
				<View {...props} /> :
				<Text {...props}>
					{title}
				</Text>
		);
	}
}


function doNothing() {
}

let localStyles = {
	errorLayout: {
		borderColor: styles.color.error,
	},
	layout: {
		alignItems: styles.alignItems.center,
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		borderBottomWidth: 0.5,
		borderColor: '#dad9e1',
	},
	errorText: {
		color: styles.color.error
	},
	text: {
		flex: 1,
	},
	icon: {
		width: 20,
		height: 20,
	},
	menu: (visible, alignBottom, alignRight) => [
		styles.newShadow(0, 2, 3, .3),
		styles.phantom(visible),
		{
			position: styles.position.absolute,
			backgroundColor: styles.color.white,
			zIndex: 1,
			marginBottom: 10,
			maxHeight: 350,
		},
		alignBottom ? {bottom: "100%"} : {top: "100%"},
		alignRight ? {right: 0} : {left: 0},
	],
};
