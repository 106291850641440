import Generator from "library-js/utils/Generator";
import { append, dropLast, filter, map, path, prop, remove, trim, uniq, uniqBy } from "ramda";
import React from "react";
import { BackHandler, Pressable, ScrollView, View as RNView } from "react-native";
import DraggableFlatList from 'react-native-draggable-flatlist';
import use from "../../../hook";
import useToast from "../../../hook/useToast";
import { pipe } from "../../../library-js/utils/function";
import { styles } from "../../../res";
import AppBar from "../../AppBar/AppBar";
import StandardAppBar from "../../AppBar/StandardAppBar";
import KeyboardSpacer from "../../KeybordSpacer";
import View from "../../View/v2";
import SingleTagEditor from "./SingleTagEditor";
import TagRow from "./TagRow.js";

/**
 * @param {{collections: Collection[]}} props 
 */
function TagsInputEditor({ defaultValue, collections, submit, close, autocomplete, reservedKeywords, header, tagsEditable, strings, ...props }) {
	// Each entry ({id, value}) represent an editable size (entry.value) by a text input which is recognized by its entry.id
	const [entries, setEntries] = use.state(() =>
		defaultValue?.map(createEntry) || []
	);
	// display predefined sizes menu
	const [displayCollections, setDisplayCollectionsTo] = use.state.bool(!entries.length && collections?.length);

	// collections prop is a tree where each node has a title and a children field (see TagsInputCollection ts definition).
	// pathInCollections equals something like [1, 2, 1] which are indexes in collections childrens.
	const [pathInCollections, setPathInCollections] = use.state([]);
	// find the current selected list of collections
	const pathAdapted = pathInCollections.flatMap(index => [index, 'children']);
	const selectedCollections = collections && path(pathAdapted, collections);

	function willBrowseInCollectons(nextIndex) {
		// it's a leaf collection if at least on item is a primitive (tag)
		const nextDepthAreLeafCollectionsOfTags = selectedCollections.some(subCollection => subCollection.children.some(item => !item.children));

		if (nextDepthAreLeafCollectionsOfTags) // select a collection of tags
			return () => {
				setEntries(selectedCollections[nextIndex].children.map(createEntry));
				setDisplayCollectionsTo(false);
				setPathInCollections([]); // clean
			};
		// browse in next depth of collections
		return () => setPathInCollections(append(nextIndex));
	}
	function backInCollections() {
		setPathInCollections(dropLast(1));
	}


	// displays in interface to edit single tag
	const [showingEditor, setShowingEditor] = use.state.bool(false);

	// back handler
	const back = showingEditor ? setShowingEditor : (
		displayCollections ? (
			pathInCollections.length ? backInCollections :
				setDisplayCollectionsTo.false
		) : close
	);

	// android back will call back handler
	use.effect(() => BackHandler.listen(pipe(back, true)), [back]);

	function renderItem({ item, index, drag, isActive, disabled, key }) {
		const { value } = item;
		const isLast = (index === entries.lastIndex);
		const removeMe = () => setEntries(remove(index, 1));

		return (
			<TagRow
				key={key}
				disabled={disabled}
				isLast={isLast}
				remove={removeMe}
				isDragging={isActive}
				drag={drag}>
				{value}
			</TagRow>
		);
	}

	const toast = useToast();

	function push(value) {
		setEntries(
			pipe(
				append(createEntry(value)),
				uniqByName
			)
		);

		toast?.(`"${value}" a été ajouté à la liste.`);
	}


	const { Text } = use.theme();
	const [gestureScrollView, setGestureScrollView] = use.state(undefined);

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			{
				!showingEditor ?
					<>
						<StandardAppBar
							statusBarPadding={false}
							style={localStyles.appBar}>
							{
								back &&
								<AppBar.ActionButton
									icon="AntDesign/arrowleft"
									onPress={back} />
							}

							{
								!displayCollections &&
								<Pressable
									onPress={
										function persistTags() {
											pipe(
												map(pipe(prop('value'), trim)),
												filter(Boolean),
												uniq,
												submit,
											)(entries)
										}
									}
									style={[styles.center, { paddingRight: 10 }]}>
									<Text style={{ fontSize: 14 }}>
										{`OK`}
									</Text>
								</Pressable>
							}
						</StandardAppBar>
						{
							displayCollections ?
								<ScrollView
									style={localStyles.scroll.layout}
									contentContainerStyle={localStyles.scroll.content}>
									{
										selectedCollections.map(({ title, children }, index) =>
											<View
												id={index}
												onPress={willBrowseInCollectons(index)}
												style={localStyles.category.layout(index)}>
												<Text
													numberOfLines={1}
													style={localStyles.category.title}>
													{title}
												</Text>

												<Text
													numberOfLines={1}
													style={localStyles.category.subtitle}>
													{
														children.map(child => String(child?.title || child))
															.join(', ')
													}
												</Text>
											</View>
										)
									}
								</ScrollView> :

								<>
									<DraggableFlatList
										ListHeaderComponent={
											<>
												{header}

												{
													reservedKeywords?.map((value, key) => renderItem({
														item: { value },
														key,
														disabled: true
													}))
												}
												{
													!entries.length &&
													<Text style={localStyles.noSize}>
														{strings?.empty || `Aucun tag n'a été entré.`}
													</Text>
												}
											</>
										}
										data={entries}
										renderItem={renderItem}
										keyExtractor={item => String(item.id)}
										onDragEnd={({ data }) => setEntries(data)}
										keyboardShouldPersistTaps="always"
										activationDistance={5}
										ListFooterComponentStyle={{ zIndex: -1 }}
										ListFooterComponent={
											(!entries.last || Boolean(entries.last.value?.trim())) &&
											<>
												<Text
													onPress={setShowingEditor.true}
													style={localStyles.add}>
													{'+  ' + (strings?.add || `AJOUTER UN TAG`)}
												</Text>

												{
													!entries.length &&
													collections?.length > 0 &&
													<Text
														onPress={setDisplayCollectionsTo.true}
														style={{
															paddingVertical: 20,
															bold: true,
															color: styles.color.azure2,
															fontSize: 16,
															textAlign: styles.textAlign.center
														}}>
														{`ou sélectionner une catégorie`}
													</Text>
												}
											</>
										}
										style={{ flex: 1, ...styles.newBorder(3, 'red') }}
									/>


									<KeyboardSpacer />
								</>
						}
					</> :

					<SingleTagEditor
						keywords={reservedKeywords}
						entries={entries}
						onSubmitted={push}
						autocomplete={autocomplete}
						strings={strings}
						cancel={setShowingEditor.false}
					/>
			}
		</RNView>
	);
}


export default React.memo(TagsInputEditor);

function createEntry(value = "") {
	return {
		id: Generator.getNumber(),
		value,
	};
}

const uniqByName = uniqBy(prop('value'));

const localStyles = {
	layout: {
		flex: 1,
	},

	appBar: {
		justifyContent: styles.justifyContent.spaceBetween,
	},


	submit: {
		color: "#0bafee",
		paddingHorizontal: 20,
	},

	scroll: {
		layout: {
			flex: 1,
			...styles.newBorder(5, 'green'),
		},

		content: {
			paddingBottom: 20,
		}
	},

	noSize: {
		textAlign: styles.textAlign.center,
		marginVertical: 20,
	},


	add: {
		marginTop: 25,
		padding: 30,
		alignSelf: styles.alignSelf.center,
		textAlign: 'center',
	},

	category: {
		layout: styles.static.bool(
			{
				paddingVertical: 15,
				borderColor: styles.color.lightgrey,
				marginHorizontal: 15
			},
			{ borderTopWidth: .5 }
		),

		title: {
			bold: true,
			marginBottom: 5,
			fontSize: 16,
		},

		subtitle: {
			fontSize: 12,
			color: "grey",
		},
	}
};
