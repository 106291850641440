import {ActivityIndicator, ScrollView, View as RNView} from "react-native";
import CatalogSectionRow from "@main/component/CatalogSectionRow";
import React from "react";
import use from "library-react/hook";
import useRouteParamState from "library-react/hook/useRouteParamState";
import {append, equals, ifElse, includes, isNil, length, times, tryCatch, uncurryN, when, without} from "ramda";
import CatalogSection from "library-js/app/model/entity/CatalogSection";
import Server from "../../../../library-react/library-js/app/Server";
import TextInput from "library-react/component/input/TextInput";
import {styles} from "@src/res";
import screens from "@main/screens";
import strictAPI from "library-js/app/Server/strictAPI";
import Portal from "library-react/component/Portal";
import Icon from "library-react/component/Icon/v2";
import View from "library-react/component/View/v2";
import delay from "library-js/utils/function/delay";


export default function CatalogSectionSideBar({cupboardLoadable, ...props}) {
	const {value: cupboard, loading} = cupboardLoadable;

	const [sections, setSections] = useRouteParamState('sections', {
		read: tryCatch(JSON.parse, () => []),
		write: ifElse(length, uncurryN(1, JSON.stringify), () => undefined),
	});

	const [addedSectionParent, setAddedSectionParent] = use.state(null);
	const [submitting, setSubmitting] = use.state(false);

	const addSection = parent => setAddedSectionParent(parent?.name || "");

	const submit = async (addedSection) => {
		if (addedSection) {
			setSubmitting(addedSection);
			const section = new CatalogSection([addedSectionParent, addedSection]);

			const editedCupboard = cupboard.copy();
			editedCupboard.add([section]);

			await persistCupboard(editedCupboard)
				.then(() => cupboardLoadable.setValue(editedCupboard))
				.catch(console.error)
		}

		setSubmitting(false);
		setAddedSectionParent(null);
	};

	const willHideInputFor = name => delay(100, () => !submitting && setAddedSectionParent(when(equals(name), () => null)));


	const {Text} = use.theme();
	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.container);
	return (
		<ScrollView {...props}>

			{/* link to edit sections */}
			<Portal to={!loading && screens.sections}>
				<RNView style={[
					styles.phantom(!loading, true),
					localStyles.edit.layout
				]}>
					<Text style={localStyles.edit.text}>
						{`Editer`}
					</Text>

					<Icon name="MaterialIcons/launch" size={15} style={localStyles.edit.icon}/>
				</RNView>
			</Portal>

			{
				loading ?
					times(index =>
						<CatalogSectionRow
							key={index}
							indentationOffset={index % 3 === 0 ? 0 : 30}
							delay={index * 100}
						/>, 5) :
					<>
						{
							cupboard?.getAll(true)
								?.map((section, index, {[index + 1]: next}) =>
								<React.Fragment key={section.name}>
									<CatalogSectionRow
										item={section}
										onPress={() => setSections(
											ifElse(includes(section?.name),
												without([section?.name]),
												append(section?.name)
											)
										)}
										onAddClicked={!section.parentName && (() => addSection(section))}
										inside={includes(section?.name, sections) ? 1 : 0}
										count={section?.quantity}
										submitting={submitting === section?.name}
										indentationOffset={20}
									/>

									{
										// Input for to add sub-section
										(
											Boolean(addedSectionParent) &&
											section.name.startsWith(addedSectionParent)
											&& !next?.name.startsWith(addedSectionParent)
										) &&
										<CatalogSectionInput
											submitting={submitting}
											depth={section?.path.lastIndex + 1}
											onSubmitted={submit}
											onBlur={willHideInputFor(addedSectionParent)}/>
									}
								</React.Fragment>
							)
						}
						{
							addedSectionParent !== "" ?
								<Text onPress={() => addSection()}
									  style={localStyles.add}>
									{`Ajouter une section`}
								</Text> :
								<CatalogSectionInput
									submitting={submitting}
									onSubmitted={submit}
									indentationOffset={20}
									onBlur={willHideInputFor('')}
									style={localStyles.addRootInput}/>
						}

					</>
			}

		</ScrollView>
	);
}

function CatalogSectionInput({depth, indentationOffset, submitting, onSubmitted, onBlur, ...props}) {
	const {Spinner} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.input, [depth, indentationOffset]);
	return (
		<View {...props}>
			<TextInput autoFocus
					   placeholder={`Nouvelle section...`}
					   editable={!submitting}
					   onSubmitted={onSubmitted}
					   onBlur={onBlur}
					   blurOnSubmit={false}
					   style={{fontSize: 16}}/>
			{Boolean(submitting) && <Spinner/>}
		</View>
	);
}

const persistCupboard = strictAPI(Server.retailer.catalog.sections.persist);

const localStyles = {
	container: {
		paddingRight: 15,
		paddingBottom: 50,
	},

	input: (depth = 0, indentationOffset = 0) => ({
		marginLeft: /* icon */ 40 + ((depth || 0) * 25) + (indentationOffset || 0),
		paddingVertical: 15,
		paddingBottom: 10,
		borderBottomWidth: .5,
		borderColor: 'lightgrey',
		marginBottom: 30,
		flexDirection: styles.flexDirection.row,
	}),

	add: {
		paddingVertical: 20,
		marginTop: 20,
		paddingLeft: 40,
		bold: true,
		color: 'grey',
	},

	addRootInput: {
		paddingVertical: 20,
		marginTop: 20,
		paddingLeft: 5,
	},


	edit: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			padding: 20,
			marginVertical: 20,
			alignSelf: styles.alignSelf.flexEnd
		},

		text: {color: 'grey', bold: true},
		icon: {color: 'grey', marginLeft: 10},
	}
}
