import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "../../../../res"
import Icon from "library-react/component/Icon";

export default React.memo(
	function AddButton({...props}) {
		props.style = use.defaultStyle(props.style, localStyles.layout);

		return (
			<Icon
				name="add"
				{...props}
			/>
		);
	}
);


const localStyles = {
	layout: {}
};
