import React from "react";
import UBOItem from "@main/ui/payment/OnlinePaymentConfiguration/UBODeclarationUI/UBOItem";
import use from "library-react/hook";
import {styles} from "@src/res";
import ScrollView from "library-react/component/ScrollView";
import Text from "library-react/component/Text/v2";
import Button from "@main/component/Button";
import View from "library-react/component/View/v2";
import {Server} from "library-js/app";
import {onSwitch} from "library-js/utils";
import UBODeclarationStatus from "library-js/app/model/pay/mango/UBODeclarationStatus";
import SupportButton from "@main/ui/payment/OnlinePaymentConfiguration/SupportButton";
import {__, any, pick, pipe, prop, values, ifElse, F} from "ramda";


export default function UBODeclarationUI({value, next, stateLoadable, ...props}) {
	const [loading, setLoading] = use.state.bool();
	const [uboDeclaration, setUboDeclaration] = use.state(value);

	const state = stateLoadable.value;
	const willBeAutoEnabled = Boolean(
		state?.bankAccountId &&
		['IDENTITY_PROOF', 'REGISTRATION_PROOF', 'ARTICLES_OF_ASSOCIATION']
			.every(
				ifElse(prop(__, state),
					pipe(
						field => state[field]?.is,
						pick(['ASKED', 'VALIDATED']),
						values,
						any(Boolean),
					),
					F,
				)
			)
	);

	const addUbo = use.callback(
		async ubo => {
			const response = await Server.retailer.payment.ubo.add(ubo);
			if (response.ok)
				setUboDeclaration(response.content);
		},
	);

	const updateUbo = use.callback(
		async ubo => {
			const response = await Server.retailer.payment.ubo.update(ubo);
			if (response.ok)
				setUboDeclaration(response.content);
			},
	);
	const submit = async () => {
		setLoading(true);
		const response = await Server.retailer.payment.ubo.submit(uboDeclaration?.Id);
		if (response.ok)
			setUboDeclaration(response.content);
		setLoading(false);
	};

	const declarationIsRefused = uboDeclaration?.Status?.is.REFUSED;

	const ubos = declarationIsRefused ? null : uboDeclaration?.Ubos;
	const canEditUbo = uboDeclaration?.Status?.is.INCOMPLETE;
	const canAddUbo = !uboDeclaration?.Status ||
		declarationIsRefused ||
		uboDeclaration?.Status?.is.INCOMPLETE ||
		(uboDeclaration?.Status?.is.CREATED && ubos?.length < 4);
	const canSubmitDeclaration = Boolean((uboDeclaration?.Status?.is.INCOMPLETE || uboDeclaration?.Status?.is.CREATED) && ubos?.length);

	props.contentContainerStyle = localStyles.content;
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<ScrollView {...props}>
			<SupportButton size={30} style={localStyles.support}/>

			<Text style={localStyles.header}>
				{`La déclaration UBO (Ultimate Beneficial Owner) ou de bénéficiaire effectif d’une entreprise concerne toute personne physique possédant, de manière directe ou indirecte, plus de 25% du capital ou des droits de vote d’une société.`}
				{
					willBeAutoEnabled &&
					`\n\nUne fois que la déclaration sera validée, le paiement en ligne s'activera automatiquement.`
				}
			</Text>

			<Text style={localStyles.uboState}>
				{
					onSwitch(uboDeclaration?.Status)
						.case(UBODeclarationStatus.REFUSED, () => `La précédente déclaration a été refusé. Veuillez ré-entrer une declaration.`)
						.case(UBODeclarationStatus.VALIDATION_ASKED, () => `La déclaration est en validation.`)
						.case(UBODeclarationStatus.INCOMPLETE, () => `La précédente déclaration a été declaré incomplète ${uboDeclaration?.Status ? `(Raison mentionné: ${uboDeclaration?.Status})` : ""}. Veuillez modifier la declaration.`)
						.case(UBODeclarationStatus.VALIDATED, () => `La déclaration est validée.`)
						.run()
				}
			</Text>

			<View style={localStyles.items}>
				{
					ubos?.map(ubo =>
						<UBOItem
							key={ubo.Id}
							item={ubo}
							edit={canEditUbo && updateUbo}
							style={localStyles.item}/>
					)
				}
				{
					canAddUbo &&
					<UBOItem
						key={ubos?.length || 0}
						add={addUbo}
						style={localStyles.item}/>
				}
			</View>

			{
				Boolean(canSubmitDeclaration) ?
					<Button loading={loading}
							onPress={submit}
							style={localStyles.submit}>
						{`Soumettre`}
					</Button> :
					<Button onPress={next}
							style={localStyles.submit}>
						{`Suivant ›`}
					</Button>
			}
		</ScrollView>
	);
}

const localStyles = {
	layout: {
		flex: 1,
		flexGrow: 1,
	},
	content: {
		justifyContent: styles.justifyContent.center,
		padding: 20,
	},
	support: {
		alignSelf: styles.alignSelf.flexEnd,
	},

	header: {
		margin: 30,
		fontSize: 18,
	},

	uboState: {
		bold: true,
		fontSize: 18,
		margin: 30,
	},

	item: {
		margin: 5,
		backgroundColor: "#f8f9fa",
		borderRadius: 20,
		width: 545,
	},
	items: {
		flexBasis: '100%',
		flexDirection: styles.flexDirection.row,
		flexWrap: styles.flexWrap.wrap,
	},
	form: {
		margin: 5,
		backgroundColor: "#f8f9fa",
		borderRadius: 20,
		width: 545,
	},
	submit: {
		alignSelf: styles.alignSelf.flexEnd,
	}
}
