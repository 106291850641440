import React from "react"
import Text from "library-react/component/Text";
import fonts from "../../res/fonts";
import styles from "../../res/styles";
import ComponentUtils from "library-react/ComponentUtils";

export default function AppName({...props}){
	props.style = ComponentUtils.mergeStyles(localStyles.layout, props.style);
	return (
		<Text {...props}>
			<Text>Shop</Text>
			<Text style={localStyles.in}>in</Text>
			<Text>zon</Text>
		</Text>
	);
}

const localStyles = {
	layout: {
		fontSize: 41,
		letterSpacing: .83,
		bold: true,
		font: fonts.sourceSansPro,
		color: styles.color.black,
	},

	in: {color: styles.color.azure}
};
