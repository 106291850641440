import React from 'react'
import { isNil } from "ramda";
import use from "../../../hook";
import { TextStyleContext } from "../../Text/v2";
import ComponentUtils from "../../../ComponentUtils";
import inputComponent from "../../abstract/inputComponent";
import moment from "moment";
import 'moment-timezone'
import toMoment from "library-js/utils/toMoment";


function /*web*/ DateInput({ value, onValueChanged, editable, style, validate, timeZone, timezone, ...props }) {
	timezone = timezone || timeZone;
	props.onChange = ({ target }) => {
		const at = toMoment(timezone, target?.value, 'YYYY-MM-DD');
		onValueChanged(at.isValid() ? at.valueOf() : undefined);
	};

	if (!isNil(value) && !isNaN(value))
		props.value = toMoment(timezone, value)?.format('YYYY-MM-DD');

	if (!isNil(editable) && !editable) {
		props.readOnly = true;
		props.disabled = true;
	}

	const instances = use.instances({ input: null });
	const [focused, setFocusedTo] = use.state.bool();
	if (!props.readOnly && props.placeholder) {
		props.onFocus = () => {
			setFocusedTo(true);
			if (instances.input) {
				instances.input.type = "date";
				instances.input.click(); // open popup
			}
		};
		props.onBlur = setFocusedTo.false;
	}
	props.type = (!props.placeholder || props.value || focused) ? "date" : 'text';


	const invalid = validate instanceof Function ? !validate(value) :
		validate ? !value : false;

	const textStyle = use.context(TextStyleContext)
	props.style = use.memo(() =>
		ComponentUtils.style.merge(textStyle, style),
		[style, textStyle, invalid]
	);

	const id = use.memo(() => 'c' + Date.now());

	return (
		<>
			{
				invalid &&
				<style>
					{`#${id}::placeholder { color: red; }`}
				</style>
			}
			<input ref={instances.set.input} id={id} {...props} />
		</>
	);
}

export default inputComponent(DateInput);
