import React from "react";
import styles from "../../res/styles";
import use from "library-react/hook";
import TextInput from "library-react/component/input/TextInput";


function AppTextInput({...props}, ref) {
	props.errorStyle = use.defaultStyle(props.errorStyle, localStyles.error);
	return <TextInput ref={ref} {...props}/>;
}

export default React.forwardRef(AppTextInput);

const localStyles = {
	error: {
		borderBottomColor: styles.color.error,
	}
};
