import { useEffect } from "react";
import { gql, QueryResult, Query_Root, Retailer, useQuery } from "../../library-js/app/graphql";
import getFragmentName from "../../library-js/app/graphql/fragment/getFragmentName";
import useUser from "../useAuth/user";
import { DEFAULT_RETAILER_FRAGMENT } from "./defaultRetailerFragment";

export default function useLegacyRetailer(fragment = DEFAULT_RETAILER_FRAGMENT, options: useLegacyRetailerOptions = {}): LegacyRetailerLoader {
	const user = useUser();
	const uid = user ? Number(user.uid) : undefined;
	const variables = Object.assign({}, options?.variables, { uid });

	const loader = useQuery(query(fragment), {
		...options,
		variables,
		skip: !uid,
	});

	useEffect(() => {
		if (loader.error)
			console.error(loader.error)
	}, [loader.error]);

	return {
		...loader,
		retailer: loader.data?.retailer_by_pk || undefined,
	};
}

export interface useLegacyRetailerOptions {
	variables?: { [key: string]: any };
}

export interface LegacyRetailerLoader extends QueryResult<Query_Root, any> {
	retailer: Retailer | undefined,
}

const query = (fragment: any) => gql`#graphql
	query useLegacyRetailer($uid: bigint!) {
		retailer_by_pk(id: $uid) {
			id
			email
			userId
			firstname
			lastname
			picture
			shop_id
			
			shop {
				id
				name
				logo
				gallery		  
			}
			
			...${getFragmentName(fragment)}
		}
	}

	${fragment}
`;