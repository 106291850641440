import is from "../library-js/utils/is"


/**
 * A listener for ScrollView to be triggered when end reached.
 * @example
 *     <ScrollView onScroll={new OnEndReachedListener(() => {
 *         // do something
 *     })} />
 *
 *     // --------
 *
 *     <ScrollView onScroll={new OnEndReachedListener({
 *         threshold : 20,
 *         callback : () => {
 *         		// do something..
 *     		}
 *     })} />
 */
export default class OnEndReachedListener {
	constructor(callback){
		let threshold = 0;
		if (!is(callback, Function)){
			threshold = callback.threshold || 0;
			callback = callback.callback;
		}

		return function ({nativeEvent}){
			let {layoutMeasurement, contentOffset, contentSize} = nativeEvent;
			if(layoutMeasurement.height + contentOffset.y >= contentSize.height - threshold)
				callback();
		}
	}
}