import useWebsiteConfig from "./useWebsiteConfig";
import useCallback from "library-react/hook/useCallback";
import {Server} from "library-js/app";
import useMe from "library-react/hook/pro/useMe";

export default function useUpdateConfig() {
	const loadable = useWebsiteConfig.loadable();
	const {shop} = useMe().shop;

	return useCallback(async (config, domain = shop.domain) => {
		const promise = Server.retailer.me.editWebTemplate(domain, config);
		promise.then(response => {
			if (response.ok)
				loadable.setValue(config);
		});

		return promise;
	}, [shop, loadable]);
}
