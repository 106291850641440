import React from "react"
import Image from "../../library-react/component/Image";
import {images} from "../../res";


export default function AppLogo({...props}){
	return (
		<Image
			source={images.logo}
			resizeMode="contain"
			{...props}
		/>
	);
}
