import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@src/res"
import WebDropdown from "library-react/component/web/Dropdown";
import {useNavigation} from "@react-navigation/native";
import screens from "@main/screens";

function BrandPinView({pin, update, cupboardLoadable, submitted, ...props}) {
	const {value: cupboard, loading} = cupboardLoadable;
	const hasNoSections = cupboard && cupboard.getAll().length === 0;
	const section = cupboard?.getSection(pin.name);
	use.effect(() => {
		if (cupboard && !section && pin.name)
			update({name: undefined});
	}, [cupboard, section]);

	const navigation = useNavigation();

	const {TextInput} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			<TextInput
				placeholder={`Titre du bloc (optionnel)`}
				value={pin.title || ''}
				onValueChanged={title => update({title})}
				autoFocus={!pin.name}
				style={localStyles.title}
			/>

			<WebDropdown
				placeholder={
					loading ? `Chargement ...` :
						`Selectionez une catégorie *`
				}
				value={pin.name || null}
				onValueChanged={
					hasNoSections ?
						() => navigation.push(screens.sections)
						: name => update({name})
				}
				options={
					hasNoSections ? [CREATE_SECTION_OPTION] :
						cupboard?.getAll(true).map(section => section.getName())
				}
				invalid={submitted && !section}
				style={localStyles.dropdown}/>
		</RNView>
	);
}

export default BrandPinView;

const CREATE_SECTION_OPTION = `Créer une catégorie   ↗️`;

const localStyles = {
	layout: {
		minHeight: 100
	},

	title: {
		paddingBottom: 10,
		paddingTop: 3,
		fontSize: 25,
		bold: true,
	},

	dropdown: {
		fontSize: 25,
		paddingTop: 2,
		paddingBottom: 2,
		paddingLeft: 10,
		paddingRight: 10,
		cursor: 'pointer',
		alignSelf: styles.alignSelf.flexStart,
		textAlignLast: 'center',
		borderRadius: 5,
	},
};
