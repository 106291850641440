import React, {useState} from "react"
import {Category} from "library-js/app";
import DropdownSelector from "library-react/component/DropdownSelector";
import {strings, styles} from "../../res";
import View from "library-react/component/View";
import ComponentUtils from "library-react/ComponentUtils";

export default function CategoryInput({defaultValue, onValueChanged, dropdownStyle, textStyle, itemStyle, validate, placeholder, ...props}) {
	let [opened, setOpened] = useState(0);
	let [category, setCategory] = useState(() => defaultValue || Category);

	props.style = ComponentUtils.defaultStyle(props.style, localStyles.layout(opened));
	dropdownStyle = ComponentUtils.mergeStyles(dropdownStyle);
	placeholder = placeholder || strings.component.CategoryInput.category;
	return (
		<View {...props}>
			{/*Category*/}
			{
				category &&
				category !== Category &&
				category.pathOfNodes.map((node, index) =>
					<DropdownSelector
						onOpenedChanged={isOpen => setOpened(isOpen ? opened + 1 : opened - 1)}
						key={node.path + index}
						defaultValue={node.name}
						onValueChanged={category => {
							setCategory(category);
							onValueChanged && onValueChanged(category);
						}}
						placeholder={(">".repeat(index)) + placeholder}
						style={[dropdownStyle, {zIndex: category.pathOfNodes.length - index}, styles.if(Boolean(node.children.length), {marginRight: 55})]}
						textStyle={textStyle}
						popup={() => node.parent.children.map(category =>
							<DropdownSelector.Item
								key={category.name}
								title={category.name}
								value={category}
								style={itemStyle}/>
						)}
					/>
				)
			}
			{
				Boolean(category.children.length) &&
				<DropdownSelector
					key={category.path}
					validate={category === Category && validate}
					onOpenedChanged={isOpen => setOpened(isOpen ? opened + 1 : opened - 1)}
					onValueChanged={category => {
						setCategory(category);
						onValueChanged && onValueChanged(category);
					}}
					placeholder={(Boolean(category.pathOfNodes) ? ">".repeat(category.pathOfNodes.length) : "") + placeholder}
					style={dropdownStyle}
					textStyle={textStyle}
					popup={() =>
						category.children.map(category =>
							<DropdownSelector.Item
								key={category.name}
								title={category.name}
								value={category}
								style={itemStyle}/>
						)
					}/>
			}
		</View>
	)
}
let localStyles = {
		layout: (opened) => [
			opened === 0 ? {} : {zIndex: 1},
			{
				flexDirection: styles.flexDirection.row,
				flexWrap: styles.flexWrap.wrap,
			}
		]
	}
;
