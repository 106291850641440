import React from "react";
import CheckBox from "./Checkbox";
import Card from "../../../component/Card";
import strings from "../../../../res/strings";
import View from "library-react/component/View/v2";
import styles from "../../../../res/styles";
import ReceptionMean from "library-js/app/model/entity/Shop/ReceptionMean";
import getResourcesForReceptionMean from "library-js/res/receptionMeans";
import {TextInput} from "react-native-paper";
import {complement, equals} from "ramda";
import useMe from "library-react/hook/pro/useMe";
import use from "library-react/hook";

export default function ReceptionCard({editedShop, submittedTime, willResetFields, ...props}) {
	const {shop} = useMe().shop;

	props.onCanceled = willResetFields(["receptionMeans", "checkoutNote"]);

	const willToggleMean = (mean) => editedShop && (
		() => editedShop.receptionMeans =
			editedShop.receptionMeans?.includes(mean) ?
				editedShop.receptionMeans.filter(complement(equals)(mean)) :
				editedShop.receptionMeans.concat(mean)
	);

	const {Text} = use.theme();

	return (
		<Card
			icon="ReceptionMean.delivery"
			title={strings.fragment.shop.reception.title}
			subtitle={strings.fragment.shop.reception.subtitle}
			{...props}>
			{
				(editable) => {
					const {receptionMeans} = editable ? editedShop : shop;
					return (
						<View style={{padding: 30}}>
							{
								Boolean(submittedTime) &&
								!receptionMeans?.length &&
								<Text style={localStyles.error}>
									{`Veuillez au moins cocher un des moyens de reception`}
								</Text>
							}

							{
								receptionMeans &&
								ReceptionMean.values.map(mean =>
									<CheckBox
										key={mean.value}
										label={getResourcesForReceptionMean(mean).title()}
										value={receptionMeans.includes(mean)}
										onValueChanged={willToggleMean(mean)}
										editable={editable}
										style={localStyles.checkBox}/>)
							}
							{
								editable ?
									<TextInput
										multiline
										label={strings.fragment.shop.reception.checkoutNote}
										style={localStyles.input}
										defaultValue={shop.checkoutNote}
										onChangeText={editedShop.setCheckoutNote}
									/> :
									(
										shop.checkoutNote &&
										<View style={localStyles.displayText.wrapper}>
											<Text style={localStyles.displayText.label}>
												{strings.fragment.shop.reception.checkoutNote}
											</Text>
											<Text numberOfLines={10}
												  style={localStyles.displayText.text}>
												{shop.checkoutNote}
											</Text>
										</View>
									)
							}


						</View>
					);
				}
			}
		</Card>
	);
}


const localStyles = {
	input: {
		marginBottom: 41,
		height: 170,
	},

	error: {
		color: 'red',
		fontSize: 13,
	},

	displayText: {
		wrapper: {
			flexDirection: styles.flexDirection.column,
			marginLeft: 33,
			marginRight: 37,
		},
		label: {
			fontSize: 15,
			lineHeight: 26,
			marginBottom: 5,
		},
		text: {
			minHeight: 240,
			fontSize: 17,
			lineHeight: 26,
			textAlign: styles.textAlign.justify,
		}
	},
	checkBox: {
		marginBottom: 10,
	},
	form: {
		marginBottom: 10,
		paddingLeft: 30,
	},
	loading: {
		padding: 30
	}
};
