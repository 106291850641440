import React from "react"
import {ScrollView, View as RNView} from "react-native";
import use from "library-react/hook";
import {styles} from "library-react/res";
import Icon from "library-react/component/Icon/v2";
import Text from "library-react/component/Text/v2";
import View from "library-react/component/View/v2";
import useApp from "library-react/app/useApp";
import SimpleButton from "library-react/component/SimpleButton";
import screens from "@main/screens";
import {parallel} from "library-js/utils/function";
import FragmentLayout from "@main/component/FragmentLayout";
import ImageInput from "./ImageInput";
import GalleryInput from "./GalleryInput";
import useMe from "library-react/hook/pro/useMe";
import Button from "@main/component/Button";
import Switch from "@main/component/Switch";
import useShowcaseEditor from "library-react/hook/useShowcaseEditor"
import useShowcaseItemEditor from "library-react/hook/useShowcaseItemEditor";
import useShowcaseShopEditor from "library-react/hook/useShowcaseShopEditor";
import showSocialModal from "library-react/utils/showSocialModal";

export default function ShowcaseUI({navigation, route, ...props}) {

	const id = route.params?.id;
	const me = useMe();

	const onceCreated = use.callback((showcase) => {
		navigation.replace(screens.showcase, {id: showcase.id})
	});

	const Editor = useShowcaseEditor(id, {onceCreated});
	const ShowcaseShopEditor = useShowcaseShopEditor(id, me.shop.id);
	const ShowcaseItemEditor = useShowcaseItemEditor(id, me.shop.id, ShowcaseShopEditor.version);

	const isCreation = !id;
	const isPrivate = isCreation || Editor.loadable?.value?.ownerShopId === me.shop.id;


	const app = useApp();
	const confirmBeforeDelete = () => {
		app.openModal(
			<ConfirmDeleteModal
				showcase={Editor.loadable?.value}
				activated={ShowcaseShopEditor.activated}
				onceConfirmed={() => {
					Editor.delete()
						.then(() => Promise.await(3000))
						.then(() => navigation.push(screens.showcasesMarket))
				}}
			/>
		);
	};
	const confirmBeforeRemove = () => {
		app.openModal(
			<ConfirmRemoveModal
				showcase={Editor.loadable?.value}
				onceConfirmed={ShowcaseShopEditor.unlink}
			/>
		);
	};

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			<ScrollView
				contentContainerStyle={localStyles.content}
				style={localStyles.scroll}>
				<Editor
					editable={isPrivate}
					BannerInput={ImageInput}
					PortraitInput={ImageInput}
					GalleryInput={GalleryInput}
					ImageInput={ImageInput}
					style={localStyles.editor}/>

				<View style={localStyles.actions.layout}>
					<ShowcaseShopEditor.Switch Switch={Switch}/>
					{
						isPrivate ?
							Editor.edited ?
								<View onPress={Editor.persist}
									  style={localStyles.actions.save}>
									{
										Editor.persisting ?
											<Text style={{marginHorizontal: 20}}>
												{`Sauvegarde en cours...`}
											</Text> :
											<Icon
												name="MaterialIcons/save"
												size={24}
											/>
									}
								</View> :
								<>
									<View onPress={confirmBeforeDelete}
										  style={localStyles.actions.save}>
										{
											Editor.deleting ?
												<Text style={{marginHorizontal: 20, color: "red"}}>
													{`Suppression en cours...`}
												</Text> :
												<Icon
													name="MaterialIcons/delete"
													size={24}
												/>

										}
									</View>

									{
										Boolean(me.shop.shop.domain) &&
										<View onPress={() => showSocialModal(app, me.shop.shop, Editor.loadable?.value)}
											  style={[localStyles.actions.save, {marginLeft: 10}]}>
											<Icon
												name="MaterialIcons/ios-share"
												size={24}
											/>
										</View>
									}
								</> :
							(Editor?.loadable?.end && ShowcaseShopEditor.end) && (
								ShowcaseShopEditor.linked ?
									<View onPress={confirmBeforeRemove}
										  style={localStyles.actions.save}>
										{
											ShowcaseShopEditor.linking ?
												<Text style={{marginHorizontal: 20}}>
													{`Retrait en cours...`}
												</Text> :
												<Text style={{marginHorizontal: 20}}>
													{`Retirer cette vitrine`}
												</Text>
										}
									</View> :
									<View onPress={ShowcaseShopEditor.link}
										  style={localStyles.actions.save}>
										{
											ShowcaseShopEditor.linking ?
												<Text style={{marginHorizontal: 20}}>
													{`Ajout en cours...`}
												</Text> :
												<Text style={{marginHorizontal: 20}}>
													{`Ajouter cette vitrine`}
												</Text>
										}
									</View>
							)

					}
				</View>
				{
					Editor.loadable?.end &&
					ShowcaseShopEditor.linked &&
					<View style={localStyles.card}>
						<ShowcaseItemEditor.LinkedProducts/>
						{
							!ShowcaseItemEditor.end &&
							<Button
								onPress={ShowcaseItemEditor.load}
								loading={ShowcaseItemEditor.loading}
								style={localStyles.load}>
								{`Charger plus`}
							</Button>
						}
						{
							ShowcaseItemEditor.edited &&
							<RNView style={localStyles.submit.layout}>

								{
									!ShowcaseItemEditor.submitting &&
									<SimpleButton onPress={ShowcaseItemEditor.reset}>
										{`Annuler`}
									</SimpleButton>
								}
								<Button
									onPress={ShowcaseItemEditor.submit}
									loading={ShowcaseItemEditor.submitting}
									style={localStyles.submit.main}>
									{`Enregistrer`}
								</Button>
							</RNView>

						}
					</View>
				}
			</ScrollView>

		</RNView>
	);
}

function ConfirmDeleteModal({onceConfirmed, showcase, close, activated, ...props}) {
	const {Dialog} = use.theme();

	return (
		<Dialog {...props}>
			<Text style={{bold: true, fontSize: 30}}>
				{`Attention`}
			</Text>

			<Text style={{marginVertical: 30, maxWidth: 300}}>
				{`Vous êtes sur le point de supprimer la vitrine ${showcase.title}. Cette action est irréversible.\n`}
				{
					activated ?
						`Pour continuer veuiller d'abord la desactiver.` :
						`Êtes-vous sûr de vouloir continuer ?`
				}
			</Text>

			<RNView style={{
				flexDirection: styles.flexDirection.row,
				justifyContent: styles.justifyContent.spaceEvenly,
				alignItems: styles.alignItems.center
			}}>
				<SimpleButton onPress={close}>
					<Text style={{bold: true}}>
						{`Annuler`}
					</Text>
				</SimpleButton>
				{
					!activated &&
					<SimpleButton onPress={parallel(onceConfirmed, close)}>
						<Text style={{bold: true, color: 'red'}}>
							{`Oui supprimer`}
						</Text>
					</SimpleButton>
				}
			</RNView>
		</Dialog>
	);
}

function ConfirmRemoveModal({onceConfirmed, showcase, close, activated, ...props}) {
	const {Dialog} = use.theme();

	return (
		<Dialog {...props}>
			<Text style={{bold: true, fontSize: 30}}>
				{`Attention`}
			</Text>

			<Text style={{marginVertical: 30, maxWidth: 300}}>
				{`Vous êtes sur le point de retirer la vitrine ${showcase.title} de vos vitrines.\n`}
				{`Êtes-vous sûr de vouloir continuer ?`}
			</Text>

			<RNView style={{
				flexDirection: styles.flexDirection.row,
				justifyContent: styles.justifyContent.spaceEvenly,
				alignItems: styles.alignItems.center
			}}>
				<SimpleButton onPress={close}>
					<Text style={{bold: true}}>
						{`Annuler`}
					</Text>
				</SimpleButton>
				<SimpleButton onPress={parallel(onceConfirmed, close)}>
					<Text style={{bold: true, color: 'red'}}>
						{`Oui retirer`}
					</Text>
				</SimpleButton>
			</RNView>
		</Dialog>
	);
}

const localStyles = {
	layout: {
		flex: 1,
	},

	scroll: {
		flex: 1,
	},

	content: {
		...FragmentLayout.contentContainerStyle,
		marginRight: FragmentLayout.contentContainerStyle.margin / 2,
		flexGrow: 1,
		paddingHorizontal: 20,
		paddingVertical: 30,
		overflow: styles.overflow.hidden,
	},
	card: {
		backgroundColor: "#f8f9fa",
		borderRadius: 20,
		padding: 10,
	},

	editor: {
		flexGrow: 1,
	},
	actions: {
		layout: {
			height: 60,
			flexDirection: styles.flexDirection.row,
			...styles.absolute({top: 20, right: 20}),
			...styles.center,
		},
		save: {
			height: 60,
			minWidth: 60,
			borderRadius: 30,
			...styles.newShadow(0, 2, 5, .2),
			backgroundColor: styles.color.white,
			...styles.center,
		},
		activate: {
			margin: 10
		}
	},
	load: {
		marginVertical: 10,
		alignSelf: styles.alignSelf.center,
		...styles.center,
		height: 60,
	},
	submit: {
		layout: {
			marginVertical: 10,
			flexDirection: styles.flexDirection.row,
			justifyContent: styles.justifyContent.flexEnd,
			alignItems: styles.alignItems.center,
		},

		main: {
			marginHorizontal: 20,
		}
	}
};
