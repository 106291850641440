import smartJoin from "../../../../utils/string/smartJoin";

/**
 * @param {import("../../../graphql").User} user 
 */
export default function formatUserAddress(user){
	if (user)
	return smartJoin(', ', [
		smartJoin([
			user.street_number,
			user.route,
		]),
		smartJoin([
			user.locality,
			user.postal_code,
		]),
		user.country,
	]);
}