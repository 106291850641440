import React from "react";
import { View as RNView } from "react-native"
import ReservationsCalendar from "./ReservationsCalendar";
import DayReservationsList from "./DayReservationsList";
import use from "../../../hook";
import toIsoDate from "../../../library-js/utils/toIsoDate";
import { styles } from "../../../res";

export default function ReservationPlanning({ primaryColor = styles.color.azure2, reservationToPortalParams, ...props }) {
	const [day, setDay] = use.state(() => toIsoDate(Date.now())); // YYYY-MM-DD

	return (
		<RNView {...props}>
			<ReservationsCalendar
				{...{ day, setDay, primaryColor }}
			/>

			<DayReservationsList
				{...{ day, primaryColor }}
				reservationToPortalParams={reservationToPortalParams}
				style={localStyles.list} />
		</RNView>
	);
}

const localStyles = {
	list: { flex: 1 },
};