import "@main/init"; // must be first
import AppLogo from "@main/component/AppLogo";
import Button from "@main/component/Button";
import { createStackNavigator } from "@react-navigation/stack";
import { strings, styles } from "@src/res";
import { retailer as Server } from "library-js/app/Server";
import AuthManager from "library-js/AuthManager";
import Environment from "library-js/Environment";
import AppLayout from "library-react/app/AppLayout";
import Icon from "library-react/component/Icon/v2";
import Text from "library-react/component/Text";
import View from "library-react/component/View";
import use from "library-react/hook";
import useAuth from "library-react/hook/useAuth";
import Navigation from "library-react/library-js/web/Navigation";
import { DefaultTheme, Provider as PaperProvider } from "react-native-paper";

import OrdersAndReservationsUI from "@main/App/OrdersAndReservationsUI";
import ClientListFragment from "@main/fragment/ClientListFragment"
import ProfileFragment from "@main/fragment/ProfileFragment";
import SigninUI from "@main/fragment/SigninUI";
import globals from "@main/globals";
import OrderUI from "@main/ui/order/OrderUI";
import OnlinePaymentConfigurationUI from "@main/ui/payment/OnlinePaymentConfiguration";
import PaymentTransaction from "@main/ui/payment/TransactionList";
import CatalogSectionsUI from "@main/ui/product/CatalogSectionsUI";
import CatalogUI from "@main/ui/product/CatalogUI";
import EditProductUI from "@main/ui/product/EditProductUI";
import ProductUI from "@main/ui/product/ProductUI";
import ReservationUI from "@main/ui/reservation/ReservationUI";
import ShopUI from "@main/ui/ShopUI";
import ShowcasesMarketUI from "@main/ui/showcase/ShowcasesMarketUI";
import ShowcaseUI from "@main/ui/showcase/ShowcaseUI";
import WebsiteUI from "@main/ui/WebsiteUI";
import WelcomeUI from "@main/ui/WelcomeUI";
import isEligibleForReservations from "library-js/app/isEligibleForReservations";
import DefaultToast from "library-react/component/DefaultToast";
import { TextStyleContext } from "library-react/component/Text/v2";
import { meContext } from "library-react/hook/pro/useMe";
import ThemeContext from "library-react/hook/useTheme/ThemeContext";
import ToastContainer from "library-react/hook/useToast/ToastContainer";
import createGraphQLClient from "../../library-react/library-js/app/graphql";
import screens, { linking } from "../screens";
import { appTheme } from "../theme";
import EditReservationClosureUI from "../ui/reservation/EditReservationClosureUI";
import EditReservationSpanUI from "../ui/reservation/EditReservationSpanUI";
import ReservationsCalendarUI from "../ui/reservation/ReservationsCalendarUI";
import ReservationSpansListUI from "../ui/reservation/ReservationSpansListUI";
import Frame from "./Frame";
import ClientUI from "../ui/ClientUI";


export default function App() {
	// check auth token in url
	const [authenticating, setAuthenticating] = use.state.bool(false);
	if (Environment.is.web) {
		use.effect(() => {
			const { params } = Navigation;
			const customToken = params.token;
			if (customToken) {
				delete params.token;
				Navigation.params = params;

				// authenticate
				setAuthenticating(true);
				AuthManager.signOut();
				AuthManager.authenticate(customToken)
					.catch(error => console.warn("An error occurred while authenticating using token from url: ", error))
					.finally(() => setAuthenticating(false));
			}
		});
	}

	const user = useAuth.user();

	const [tryTime, retryLoadMe] = use.version();
	const meLoadable = use.loadable.server(user &&
		(() => Server.me.getInfos().updateContent(me => {
			globals.me = me; // old version support
			return me;
		})),
		[user?.uid, tryTime]
	);
	const shop = meLoadable?.value?.shop?.shop;

	return (
		<GraphQLProvider>
			<ThemeContext.Provider value={appTheme}>
				<TextStyleContext.Provider value={DEFAULT_TEXT_STYLES}>
					<ToastContainer DefaultToast={DefaultToast}>

						<meContext.Provider value={meLoadable}>
							<AppLayout
								screensBackgroundColor="transparent"
								documentTitle={{ formatter: () => 'Shopinzon Pro' }}
								linking={linking}
								style={localStyles.fill}>

								<PaperProvider theme={MATERIAL_THEME}>

									{
										authenticating ? WAITING :

											meLoadable?.value ?
												<Frame>
													<stack.Navigator
														screenOptions={{
															headerShown: false,
															cardStyle: localStyles.navigatorCard
														}}>
														<stack.Screen name={screens.shop} component={ShopUI} />

														<stack.Screen name={screens.catalog} component={CatalogUI} />
														<stack.Screen name={screens.sections}
															component={CatalogSectionsUI} />

														<stack.Screen name={screens.product} component={ProductUI} />
														<stack.Screen name={screens.editProduct}
															component={EditProductUI} />

														<stack.Screen name={screens.showcasesMarket}
															component={ShowcasesMarketUI} />
														<stack.Screen name={screens.createShowcase}
															component={ShowcaseUI} />
														<stack.Screen name={screens.showcase} component={ShowcaseUI} />


														<stack.Screen name={screens.ordersAndReservations}
															component={OrdersAndReservationsUI} />
														<stack.Screen name={screens.order} component={OrderUI} />

														{
															isEligibleForReservations(shop?.categoryId) &&
															<>
																<stack.Screen name={screens.reservation}
																	component={ReservationUI} />
																<stack.Screen name={screens.reservationsCalendar}
																	component={ReservationsCalendarUI} />

																<stack.Screen name={screens.newReservation}
																	component={ReservationUI} />


																<stack.Screen name={screens.reservationSpansList}
																	component={ReservationSpansListUI} />

																<stack.Screen name={screens.editReservationSpan}
																	component={EditReservationSpanUI} />

																<stack.Screen name={screens.editReservationClosure}
																	component={EditReservationClosureUI} />
															</>
														}


														<stack.Screen name={screens.clientList}
															component={ClientListFragment} />
														<stack.Screen name={screens.client} component={ClientUI} />

														{/* <stack.Screen name={screens.paymentSettings}
															component={OnlinePaymentConfigurationUI} /> */}
														{/* <stack.Screen name={screens.paymentTransaction}
															component={PaymentTransaction} /> */}

														<stack.Screen name={screens.website} component={WebsiteUI} />

														<stack.Screen name={screens.profile}
															component={ProfileFragment} />

														<stack.Screen name={screens.welcome} component={WelcomeUI} />
													</stack.Navigator>
												</Frame> :

												// sign-in
												user === null ?
													<SigninUI
														style={localStyles.fill} /> :


													// -- error --
													(meLoadable && !meLoadable.loading) ?
														<Center>
															<Text
																style={{
																	textAlign: styles.textAlign.center,
																	marginHorizontal: 15
																}}>
																{strings.app.error}
															</Text>

															{/*Reload button*/}
															<Button
																raised
																onPress={retryLoadMe}
																style={{ marginTop: 50, paddingVertical: 25 }}>
																{strings.reload.firstUp}
															</Button>

															{/* Escape button */}
															<Icon
																name="SimpleLineIcons/power"
																size={30}
																onPress={AuthManager.signOut}
																style={styles.absolute({ top: 5, right: 5 })}
															/>
														</Center> :

														//  -- loading --
														WAITING
									}
								</PaperProvider>

							</AppLayout>
						</meContext.Provider>
					</ToastContainer>

				</TextStyleContext.Provider>
			</ThemeContext.Provider>
		</GraphQLProvider>
	)
		;
}


const stack = createStackNavigator();

const GraphQLProvider = createGraphQLClient();

const DEFAULT_TEXT_STYLES = {
	fontSize: 16,
};

const Center = ({ children }) => (
	<View style={[styles.fill, styles.center]}>
		{children}
	</View>
);

const WAITING = (
	<Center>
		<AppLogo style={styles.square(200)} />
	</Center>
);

const MATERIAL_THEME = {
	...DefaultTheme,
	colors: {
		...DefaultTheme.colors,
		primary: styles.color.azure2,
		placeholder: styles.color.main,
		background: styles.color.transparent,
		text: styles.color.main,
	}
};

const localStyles = {
	fill: {
		flex: 1,
	},

	navigatorCard: {
		backgroundColor: 'transparent',
	}
};
