import { includes, not, without } from "ramda";
import { useState } from "react";
import { Pressable, PressableProps, ScrollView, TextProps, View as RNView, ViewProps } from "react-native";
import Icon from "../../../../library-react/component/Icon/v2";
import ModalFrame from "../../../../library-react/component/ModalFrame";
import SimpleButton from "../../../../library-react/component/SimpleButton";
import use from "../../../../library-react/hook";
import { ModalProps } from "../../../../library-react/hook/useTheme";
import { allPaymentMeans, Order_V2, PaymentMean } from "../../../../library-react/library-js/app/graphql";
import getResourcesForPaymentMean from "../../../../library-react/library-js/res/getResourcesForPaymentMean";
import { styles } from "../../../../res";

export default function CashInDialog({ order, close, onSelected, ...props }: CashInDialogProps) {
	const [selected, select] = useState(order.expectedPaymentMean);
	const [othersOpened, setOthersOpened] = useState(false);
	const toggleOthers = () => setOthersOpened(not);
	const [submitted, setSubmitted] = useState(false);

	function cashIn() {
		setSubmitted(true);
		if (selected) {
			onSelected?.(selected);
			close?.();
		}
	}

	const total = order.items_aggregate?.aggregate?.sum?.totalPrice!;
	const hasPaymentReady = order.payments.some(payment => payment.amount! >= total * 100);

	const shop = order.shopByShopid;
	const otherMeans = allPaymentMeans.filter(mean => !shop?.paymentMeansList?.includes(mean));

	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<ModalFrame.Dialog {...props}>
			<Text style={localStyles.title}>
				{
					hasPaymentReady ?
						`Encaisser le paiement online, ou selectionnez un autre moyen utilisé`
						: `Comment votre client vous a t-il reglé  ?`
				}

				{/* Error message */}
				<Text style={{ color: 'red', fontSize: 14, opacity: submitted && !selected ? 1 : 0, fontWeight: "bold" }}>
					{`\n`}
					{`Veuillez selectionner un moyen de paiement`}
				</Text>
			</Text>

			<ScrollView style={localStyles.scroll}>

				{/* online mean */}
				{
					hasPaymentReady &&
					<MeanSelection
						mean={"ONLINE"}
						selected={selected}
						onPress={() => select("ONLINE")} />
				}

				{/* offline means */}
				{
					without<PaymentMean>(["ONLINE"], shop?.paymentMeansList || [])
						.map(mean =>
							<MeanSelection
								key={mean}
								{...{ mean, selected }}
								onPress={() => select(mean)} />
						)
				}

				{/* other means */}
				{
					(otherMeans.length > 0) &&
					<>
						<MeanSelection
							onPress={toggleOthers}
							icon={
								<Icon
									name={
										othersOpened ? 'MaterialIcons/arrow-drop-down'
											: 'MaterialIcons/arrow-right'
									}
									size={25}
									style={{ marginRight: 15 }} />
							}>
							{`Autre `}
							{
								!othersOpened && includes(selected, otherMeans) &&
								<Text style={{ fontStyle: "italic", color: styles.color.grey, marginLeft: 10 }}>
									{getResourcesForPaymentMean(selected)?.title()}
								</Text>
							}
						</MeanSelection>

						{
							othersOpened &&
							<RNView style={localStyles.others}>
								{
									otherMeans.map(mean =>
										<MeanSelection
											key={mean}
											{...{ mean, selected }}
											onPress={() => select(mean)} />
									)
								}
							</RNView>
						}

					</>
				}
			</ScrollView>

			<RNView style={localStyles.footer}>
				<SimpleButton onPress={close} style={localStyles.button}>
					{`Annuler`}
				</SimpleButton>

				<SimpleButton
					colors={{ primary: styles.color.deepSkyBlue }}
					onPress={cashIn}
					style={[localStyles.button, { bold: true }]}>
					{`Encaisser`}
				</SimpleButton>
			</RNView>

		</ModalFrame.Dialog>
	);
}

interface CashInDialogProps extends ModalProps {
	order: Order_V2,
	onSelected(mean: PaymentMean): void,
}

function MeanSelection({ mean, selected, icon, children, ...props }: MeanSelectionProps) {
	const { Text } = use.theme();
	return (
		<Pressable  {...props} style={localStyles.mean}>
			{
				icon ||
				<Icon name={"MaterialIcons/check"}
					size={20}
					style={localStyles.icon(mean === selected)} />
			}

			<Text style={localStyles.name(mean)}>
				{getResourcesForPaymentMean(mean)?.title() || children}
			</Text>
		</Pressable>
	);
}

interface MeanSelectionProps extends PressableProps {
	mean?: PaymentMean | undefined,
	selected?: PaymentMean | undefined,
	icon?: ViewProps["children"],
	children?: TextProps["children"],
}

const localStyles = {
	layout: {
		backgroundColor: styles.color.white,
		paddingLeft: 30,
		paddingRight: 50,
		paddingVertical: 0,
		height: 500,
	},

	title: {
		bold: true,
		fontSize: 20,
		marginHorizontal: 20,
		marginTop: 30,
		marginBottom: 15,
	},

	scroll: {
		flex: 1,
	},

	others: {
		paddingLeft: 30,
	},

	mean: {
		flexDirection: styles.flexDirection.row,
		paddingHorizontal: 20,
	},

	icon: styles.static.bool({
		marginRight: 15,
		color: styles.color.azure2,
		alignSelf: styles.alignSelf.center,
	},
		{},
		{ opacity: 0 }),

	name: (mean: PaymentMean | undefined) => ({
		paddingVertical: 15,
		flex: 1,
		color: mean === "ONLINE" ? styles.color.deepSkyBlue : styles.color.black
	}),

	footer: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.flexEnd,
	},

	button: {
		paddingHorizontal: 20,
		paddingVertical: 20,
	},
};
