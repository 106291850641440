import React from "react";
import { Pressable, View as RNView } from "react-native";
import use from "../../../../hook";
import { styles } from "../../../../res";


import getResourcesForReservationState from "../../../../library-js/res/reservationState";
import { map } from "ramda";

function Day({ marking, children: dayNumber, date, onPress, ...props }) {
	const { selected, closed, accepted, waiting } = marking || {};

	const { Text, colors } = use.theme();
	const selectedColor = colors?.primary;

	const isClosedBothSides = closed?.previous && closed?.next;

	if (onPress)
		props.onPress = () => onPress(date);

	props.style = localStyles.layout;
	return (
		<Pressable {...props}>
			<RNView style={[
				{ flexDirection: 'row' },
				isClosedBothSides && { backgroundColor: closedBackgroundColor },
			]}>
				<RNView style={localStyles.side(closed?.previous)} />

				<RNView style={localStyles.closedWrapper(closed)}>
					<RNView style={localStyles.selectedWrapper(selected, selectedColor)}>
						<Text style={[localStyles.text, closed && { color: closedTextColor }, selected && { color: 'white' }]}>
							{dayNumber}
						</Text>
					</RNView>
				</RNView>

				<RNView style={localStyles.side(closed?.next)} />
			</RNView>

			<RNView style={localStyles.labels}>
				{accepted && LABELS.accepted}
				{waiting && LABELS.waiting}
			</RNView>

		</Pressable>
	);
}

export default React.memo(Day);

const LABELS = map(backgroundColor => <RNView style={[styles.circle(8), { marginHorizontal: 1, backgroundColor }]} />, {
	accepted: getResourcesForReservationState.accepted.color(),
	waiting: getResourcesForReservationState.waiting.color(),
});

const closedBackgroundColor = '#e5e5e5';
const closedTextColor = 'rgb(166,166,166)';

const localStyles = {
	layout: {
		alignSelf: 'stretch',
	},

	main: {
		flexDirection: 'row'
	},

	side: styles.static.bool({ flex: 1 }, /* closed */ { backgroundColor: closedBackgroundColor }),


	closedWrapper: (closed) => closed && Object.assign(
		{ backgroundColor: closedBackgroundColor },
		!closed.previous && { borderTopLeftRadius: 5, borderBottomLeftRadius: 5 },
		!closed.next && { borderTopRightRadius: 5, borderBottomRightRadius: 5 },
	),

	selectedWrapper: (selected, color) => ({
		borderRadius: 5,
		width: 30,
		height: 24,
		overflow: 'hidden', // nedded for android https://github.com/facebook/react-native/issues/10807
		...styles.center,
		backgroundColor: selected ? color : undefined,
	}),

	text: {
		fontSize: 14,
		fontWeight: 'bold',
	},

	labels: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'flex-end',
		height: 11,
	},
};