import React from "react";
import {View as RNView} from "react-native"
import CheckBox from "library-react/component/CheckBox";
import View from "library-react/component/View/v2";
import LabeledTextInput from "./input/LabeledTextInput";
import {IBANBankAccount} from "library-js/app/model/pay/mango";
import AddressLocationForm from "library-react/component/AddressLocationForm";
import Button from "@main/component/Button";
import use from "library-react/hook";
import {styles} from "@src/res";
import {Server} from "library-js/app";
import SupportButton from "./SupportButton";
import {useMeLoadable} from "library-react/hook/pro/useMe";
import PaymentMean from "library-js/app/model/entity/Order/PaymentMean";
import {concat, without} from "ramda";

export default function IBANBankAccountUI({value, stateLoadable, ...props}) {
	const state = stateLoadable.value;

	const memo = use.memo({savedBankAccount: value}, [value]);
	const [bankAccount, setBankAccount] = use.state((memo.savedBankAccount?.copy() || new IBANBankAccount()).bindSetters());
	const [willEnablePayment, setWillEnablePaymentTo] = use.state.bool(true);
	const [submitted, setSubmitted] = use.state.bool();
	const [loading, setLoading] = use.state.bool();


	const meLoadable = useMeLoadable();
	const shop = meLoadable.value?.shop.shop;
	const persist = async () => {
		setSubmitted(true);

		const invalidFields = bankAccount.getInvalidFields();
		if (invalidFields.length > 0) {
			console.log("Missing fields: ", bankAccount.getInvalidFields())
			return
		}

		setLoading(true);
		const response = await Server.retailer.payment.bankAccount.persist(bankAccount);
		if (response.ok) {
			memo.savedBankAccount = response.content;
			setBankAccount(memo.savedBankAccount.copy().bindSetters());

			// enable payment
			const needToUpdatePaymentMeans = willEnablePayment !== shop.paymentMeans.includes(PaymentMean.online);
			if (needToUpdatePaymentMeans){
				const editedShop = shop.copy();
				const updatePaymentMeans = (willEnablePayment ? concat : without)([PaymentMean.online]);
				editedShop.paymentMeans = updatePaymentMeans(shop.paymentMeans);
				Server.retailer.me.checkoutConfiguration.persist(editedShop)
					.then(response => {
						if (response.ok){
							const editedMe = meLoadable.value.copy({shop: true});
							editedMe.shop.shop = editedShop;
							meLoadable.setValue(editedMe);
						}
					});
			}
		}

		setLoading(false);
	};

	const edited = !memo.savedBankAccount || !bankAccount.equalsTo(memo.savedBankAccount);
	use.subscribe(bankAccount?.onPropertyChanged, [bankAccount]);

	const {Text} = use.theme();

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<SupportButton size={30} style={localStyles.support}/>

			<Text style={localStyles.header}>
				{`Votre compte bancaire nous permettra de vous transférer l'argent encaissé sur Shopinzon.`}
			</Text>

			<LabeledTextInput label={'Titulaire du compte'}
							  placeholder={'Nom complet'}
							  validate={submitted && IBANBankAccount.generateValidateOf.OwnerName()}
							  onValueChanged={bankAccount.setOwnerName}
							  defaultValue={bankAccount.OwnerName}/>

			<AddressLocationForm label={'Addresse du titulaire'}
								 validate={submitted && IBANBankAccount.generateValidateOf.OwnerAddress()}
								 onAddressChanged={bankAccount.setOwnerAddress}
								 defaultValue={bankAccount.OwnerAddress}
								 TextInputComponent={LabeledTextInput}/>

			<LabeledTextInput label={'IBAN du compte'}
							  placeholder={'IBAN'}
							  validate={submitted && IBANBankAccount.generateValidateOf.IBAN()}
							  onValueChanged={value => bankAccount.IBAN = value.replace(/\s/g, '')}
							  defaultValue={bankAccount.IBAN}/>
			{
				// checkbox: will enable payment
				(edited || !memo.savedBankAccount) && state &&
				!without(['bankAccountId'], state.invalidFields).length && // only if everything else is ready (except iban)
				<View
					onPress={setWillEnablePaymentTo.toggle}
					style={localStyles.willEnablePayment.layout}>
					<CheckBox
						value={willEnablePayment}
						editable={false} // the entire row (with label) is clickable to toggle
						size={20}
						style={localStyles.willEnablePayment.checkbox}/>
					<Text>
						{`Activer le paiement en ligne`}
					</Text>
				</View>
			}

			{
				edited &&
				<>
					<Button loading={loading}
							onPress={persist}
							style={localStyles.submit}>
						{`Enregistrer`}
					</Button>
				</>
			}

		</RNView>
	)
}


const localStyles = {
	layout: {
		backgroundColor: styles.color.white,
		padding: 20,
	},
	support: {
		alignSelf: styles.alignSelf.flexEnd,
	},
	header: {
		marginBottom: 15,
		marginTop: -5
	},
	submit: {
		alignSelf: styles.alignSelf.flexEnd,
	},

	willEnablePayment: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			alignSelf: styles.alignSelf.flexStart,
			padding: 10,
			paddingLeft: 20,
		},

		checkbox: {
			marginRight: 20,
		},
	}
}
