import React from "react"
import { View as RNView } from "react-native"
import use from "../../../../../../hook";
import getResourcesForReservationState from "../../../../../../library-js/res/reservationState";
import { fonts } from "../../../../../../res";
import Icon from "../../../../../../component/Icon/v2";
import { reservationNotificationTxt } from "../../../../ChatFragment/ChatUI/Timeline/EventView/NotificationView/ReservationNotificationView";

function ReservationNotificationView({ event: notification, ...props }) {
	const id = notification?.vReservation.reservation.id;
	const state = notification?.vReservation?.currentState?.type;
	const stateRes = getResourcesForReservationState(state);
	const color = state?.select({
		waiting: 'grey',
		default: stateRes?.color(),
	});
	const { Text } = use.theme();

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			<Icon
				name='reservation-restaurant'
				size={14}
				style={localStyles.icon(color)}
			/>
			<Text
				numberOfLines={1}
				style={localStyles.text(color)}>
				{reservationNotificationTxt(notification)}
			</Text>
		</RNView>
	);
}

export default React.memo(ReservationNotificationView);

const localStyles = {
	layout: {
		flexDirection: 'row',
		alignItems: 'center',
	},

	icon: color => ({
		marginRight: 6,
		color,
	}),

	text: color => ({
		color,
		fontSize: 13,
		font: fonts.Poppins
	}),
}
