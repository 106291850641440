import React from "react"
import { View as RNView } from "react-native"
import use from "../../../../hook"
import getResourcesForReservationState from "../../../../library-js/res/reservationState";
import { styles } from "../../../../res";

export default function Label({ size = 8, state, ...props }) {
	const res = getResourcesForReservationState(state);
	const backgroundColor = res?.color();

	props.style = use.defaultStyle(props.style, {
		backgroundColor,
		...styles.circle(size),
	}, [size, backgroundColor]);
	return <RNView {...props} />
}