import React from "react"
import use from "../../../../hook";
import Server from "../../../../library-js/app/Server";
import useMe from "../../../../hook/pro/useMe";
import { FlatList, View as RNView } from "react-native";
import ReservationRow from "./ReservationRow";
import toMoment from "../../../../library-js/utils/toMoment";
import { styles } from "../../../../res";
import { curry, props } from "ramda";
import VReservation from "../../../../library-js/app/model/view/general/VReservation";
import Icon from "../../../Icon/v2";
import Label from "../ReservationsCalendar/Label";
import moment from "moment";
import SequenceHeader from "./SequenceHeader";
import ReservationState from "../../../../library-js/app/model/entity/Reservation/State";

export default function DayReservationsList({ day, primaryColor, reservationToPortalParams, ...props }) {

	const me = useMe();
	const shop = me?.shop?.shop;
	// --- Day reservations ---
	const { value: items, loading, load } = use.loadable.server(shop?.id &&
		function () {
			const cursor = toMoment(shop.timezone, day)?.valueOf();
			return Server.retailer.reservation.getList(shop.id, {
				cursor,
				wholeDay: true,
				includeStates: [
					ReservationState.Type.waiting,
					ReservationState.Type.accepted
				]
			});
		},
		[shop?.id, day]
	);

	/**
	 * @type {{places: number, accepted: number, waiting: number} | undefined}
	 */
	const insight = items?.reduce((insight, { reservation, currentState }) => {
		const stateKey = currentState?.type?.key;
		if (stateKey)
			insight[stateKey] = (insight[stateKey] || 0) + 1;

		insight.places = (insight.places || 0) + reservation.numberOfPlaces;
		return insight;
	}, {});

	const sequenceOf = toSequence(shop?.timezone);
	/**
	 * @type {{[key: string]: number}
	 */
	const sequencesPlaces = items?.reduce((places, { reservation }) => {
		const sequence = sequenceOf(reservation.time);
		places[sequence] = (places[sequence] || 0) + reservation.numberOfPlaces;
		return places;
	}, {});

	const { Text, Spinner, ErrorView } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout)
	return (
		<RNView {...props}>
			<RNView style={[localStyles.header, { backgroundColor: primaryColor }]}>
				<RNView style={localStyles.headerBody}>
					<RNView style={localStyles.headerDate}>
						<Text style={localStyles.headerDay}>
							{moment(day).format('DD')}
						</Text>
						<Text style={localStyles.headerMonth}>
							{moment(day).format('MMMM').firstUp}
						</Text>
					</RNView>

					<RNView style={localStyles.headerInsight}>
						{
							[
								{
									number: insight?.accepted,
									icon: <Label state="accepted" />
								},
								{
									number: insight?.waiting,
									icon: <Label state="waiting" />
								},
								{
									number: insight?.places,
									icon: <Icon name="reservation-restaurant" size={16} style={{ color: 'white' }} />
								},
							].map(({ number, icon }, index) =>
								(number > 0) &&
								<RNView key={index} style={localStyles.headerReport}>
									{icon}

									<Text style={localStyles.headerReportText}>
										{number}
									</Text>
								</RNView>
							)
						}

						{
							items?.length === 0 &&
							<Text style={localStyles.empty}>
								{`Pas de reservations`}
							</Text>
						}
					</RNView>
				</RNView>
			</RNView>

			<FlatList
				data={items}
				renderItem={({ item, index }) => {
					// sequence = half an hout
					const time = item.reservation.time;
					const sequence = sequenceOf(time);
					const numberOfPlacesInSequence = sequencesPlaces[sequence];
					const previous = items[index - 1];
					const isPreviousInSameSequence = sequenceOf(previous?.time) === sequence;
					return (
						<>
							{
								!isPreviousInSameSequence &&
								<SequenceHeader
									title={sequence}
									places={numberOfPlacesInSequence}
									style={{ marginTop: index ? 15 : 0 }} />
							}

							<ReservationRow item={item} reservationToPortalParams={reservationToPortalParams} />
						</>
					);
				}}

				ListFooterComponent={
					<RNView style={{ marginVertical: 40 }}>
						{
							loading ?
								<RNView style={[{ flexDirection: 'row' }, styles.center]}>
									<Spinner size={30} style={{ marginRight: 20 }} />

									<Text>
										{`Chargement...`}
									</Text>

								</RNView> :

								!items &&
								<ErrorView onLoadClicked={load} />
						}
					</RNView>
				}
				style={localStyles.list}
			/>
		</RNView>
	);
}

const toSequence = curry((timezone, timestamp) => {
	const theMoment = toMoment(timezone, timestamp);
	if (theMoment) {
		const minutes = theMoment.minutes();

		theMoment.minutes(minutes >= 30 ? 30 : 0);

		return theMoment.format('HH[h]mm');
	}
});

const localStyles = {
	layout: {

	},

	list: {
		flex: 1,
	},

	header: {
		height: 53,
		...styles.center,
	},

	headerBody: {
		flexDirection: 'row',
		alignItems: 'center',
		width: '88%',
	},

	headerDate: {
		justifyContent: 'center',
		alignSelf: 'stretch',
	},

	headerDay: {
		color: 'white',
		fontSize: 25,
		bold: true,
		height: 33,
	},

	headerMonth: {
		color: 'white',
		fontSize: 8,
		bold: true,
	},

	headerInsight: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},

	headerReport: {
		flexDirection: 'row',
		alignItems: 'center',
		marginLeft: 22
	},

	headerReportText: {
		color: 'white',
		fontSize: 21,
		fontWeight: 'bold',
		marginLeft: 4,
	},

	empty: { color: 'white', textAlign: 'center', bold: true, flex: 1 },
}