import React from "react"
import OrderView from "../../../../component/OrderView"

export default React.memo(
	function OrderItemNotificationView({event: notification, ...props}) {
		return (
			<OrderView
				gallery={notification?.vOrderItem?.product?.gallery}
				price={notification?.vOrderItem?.price}
				state={notification?.vOrderItem?.state}
				{...props}
			/>
		);
	}
);
