import React from "react"
import {View as RNView} from "react-native"
import View from "library-react/component/View/v2";
import use from "library-react/hook";
import Icon from "library-react/component/Icon/v2";
import {fonts, strings, styles} from "@src/res";
import LoadingPlaceholder from "library-react/component/LoadingPlaceholder";
import Tooltip from "@main/component/Tooltip";


export default function CatalogSectionRow(
	{
		item,
		count,
		inside,
		total,
		onAddClicked,
		indentationOffset,
		size = 1,
		loading,
		delay,
		...props
	}
) {
	const depth = item?.path.lastIndex;

	if (count === undefined)
		count = item?.quantity;

	const isPartiallyInside = inside < 1 && inside > 0;

	const {Text, Spinner} = use.theme();

	props.style = use.defaultStyle(props.style, stylesheet.layout, [depth, indentationOffset]);
	return (
		<View {...props}>
			<Icon
				name={
					isPartiallyInside ? "MaterialCommunityIcons/minus"
						: inside ? "MaterialCommunityIcons/check"
						: undefined
				}
				size={25 * size}
				style={stylesheet.icon(inside)}
			/>

			<RNView style={stylesheet.main}>
				{
					!item &&
					<LoadingPlaceholder
						delay={delay}
						style={stylesheet.loading}/>
				}

				<Text
					numberOfLines={1}
					style={stylesheet.title(inside, size, depth)}>
					{item?.title?.toUpperCase() || strings.invisibleChar}
				</Text>

				{
					(count > 0 && total !== count) &&
					<Text
						numberOfLines={1}
						style={stylesheet.subtitle(size)}>
						{count}{` articles`}
						{
							total > 0 &&
							` sur ${total}`
						}
					</Text>
				}
			</RNView>

			{
				loading ?
					<Spinner/> :
					(
						onAddClicked &&
						<Icon
							name="MaterialIcons/add"
							size={20}
							onPress={onAddClicked}
							hoverable
							activeOpacity={1}
							style={stylesheet.add}>
							{({hover}) =>
								hover &&
								<Tooltip style={{width: 230}}>
									{`Ajouter une sous-section`}
								</Tooltip>
							}
						</Icon>
					)
			}

		</View>
	);
}

const stylesheet = {
	layout: (depth = 0, indentationOffset = 0, size = 1) => ({
		paddingLeft: ((depth || 0) * 25 * size) + (indentationOffset || 0),
		paddingVertical: 15,
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
	}),

	icon: styles.static.bool(
		{marginRight: 20},
		{color: styles.color.azure}
	),

	main: {
		flex: 1,
	},
	loading: {
		borderRadius: 10,
		width: 150,
		height: 20,
	},

	title: (inside, size, depth) => ({
		fontSize: 16 * size,
		color: inside ? styles.color.azure :
			depth === 0 ? 'black'
				: 'dimgrey',
	}),

	subtitle: size => ({
		italic: true,
		color: 'grey',
		fontSize: 14 * size,
	}),

	add: {
		alignSelf: styles.alignSelf.stretch,
		justifyContent: styles.justifyContent.flexStart,
		paddingTop: 3,
		paddingRight: 5,
		paddingLeft: 10,
		color: 'grey',
	},
};
