import React from "react"
import use from "library-react/hook";
import View from "library-react/component/View";
import {strings, styles} from "../../../../../../res";
import AppTextInput from "../../../../../component/AppTextInput";
import Text from "library-react/component/Text";
import TagInput from "../../../../../component/TagInput";
import ImageInput from "./ImageInput";
import NumberInput from "library-react/component/input/NumberInput/v2";
import SectionSelection from "./SectionSelection"

export default React.memo(
	function GeneralInformation({vProdshop, submitted, onSectionsChanged, inputStyle, ...props}) {
		const productId = vProdshop?.product?.id;

		const onNameChanged = use.callback((name) => vProdshop.vProduct.product.name = name, [vProdshop]);
		const onPriceChanged = use.callback((price) => vProdshop.prodshop.price.value = price, [vProdshop]);
		const onTagChanged = use.callback((tags) => vProdshop.vProduct.product.tags = tags, [vProdshop]);

		const [embassador, setEmbassador] = use.state(vProdshop.vProduct.product.gallery.first);
		const onMainChanged = use.callback(
			(image) => {
				vProdshop.vProduct.product.gallery[0] = image;
				setEmbassador(image)
			},
			[vProdshop]
		);

		const onAdditionalChanged = use.callback(
			(gallery) => vProdshop.vProduct.product.gallery = [embassador, ...gallery],
			[embassador, vProdshop]
		);

		props.style = use.defaultStyle(props.style, localStyles.layout);

		return (
			<View {...props}>
				{/*Title*/}
				<Text style={localStyles.title}>
					{strings.fragment.addProduct.generalInformation.title}
				</Text>

				<Text style={localStyles.subtitle}>
					{strings.fragment.addProduct.generalInformation.subtitle}
				</Text>

				<Text style={localStyles.mandatoryFields}>
					{strings.fragment.addProduct.generalInformation.mandatoryFields}
				</Text>

				<View style={localStyles.form.layout}>
					<View style={localStyles.form.info}>
						{/*Name*/}
						<AppTextInput
							placeholder={strings.fragment.addProduct.name.firstUp + " *"}
							onValueChanged={onNameChanged}
							defaultValue={vProdshop.product.name}
							style={inputStyle}
							validate={submitted}
						/>
						<View>
							<NumberInput
								placeholder={strings.fragment.addProduct.price.firstUp + " *"}
								onValueChanged={onPriceChanged}
								defaultValue={vProdshop.prodshop.price.value}
								decimals={2}
								validate={submitted && (value => value > 0)}
								style={inputStyle}
							/>
							<View style={localStyles.form.currency.layout}>
								<Text style={localStyles.form.currency.text}>
									{vProdshop.prodshop.price.symbol}
								</Text>
							</View>
						</View>
						<TagInput
							placeholder={strings.fragment.addProduct.tag.firstUp + " *"}
							onValueChanged={onTagChanged}
							defaultValue={vProdshop.vProduct.product.tags}
							style={inputStyle}
							// validate={submitted}
						/>
					</View>

					<View style={localStyles.form.image.layout}>
						{/*Media*/}

						<ImageInput
							onValueChanged={onMainChanged}
							defaultValue={embassador}
							style={localStyles.form.image.main}
							validate={submitted}
						/>

						<ImageInput
							multiple
							onValueChanged={onAdditionalChanged}
							defaultValue={
								vProdshop.vProduct.product.gallery.slice(1)
							}
							style={localStyles.form.image.additional}
						/>
					</View>

				</View>

				<SectionSelection
					productId={productId}
					onValueChanged={onSectionsChanged}
				/>
			</View>
		);
	}
)

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.column,
	},
	title: {
		fontSize: 26,
		color: styles.color.azure2,
	},
	subtitle: {
		fontSize: 18,
		color: styles.color.main,
	},
	mandatoryFields: {
		italic: true,
		fontSize: 12,
		color: styles.color.main,
	},
	form: {
		layout: {
			flexDirection: styles.flexDirection.row,
			justifyContent: styles.justifyContent.spaceBetween,
		},
		image: {
			layout: {
				flexDirection: styles.flexDirection.row,
				flexWrap: styles.flexWrap.wrap,
				justifyContent: styles.justifyContent.flexEnd,
				alignItems: styles.alignItems.flexStart,
				flexShrink: 1,
				marginLeft: 100,
			},
			main: {
				width: 270,
				height: 230,
				marginBottom: 10,
			},
			additional: {
				marginLeft: 40,
				width: 270,
				minHeight: 230,
			},
		},
		currency: {
			layout: {
				position: styles.position.absolute,
				right: 0,
				bottom: 10,
				alignItems: styles.alignItems.center,
				justifyContent: styles.justifyContent.flexEnd
			},
			text: {
				fontSize: 20,
			}
		},
		info: {
			flex: 1,
		},
	}
};
