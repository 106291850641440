import React from "react";
import {Text} from "react-native";
import styles from "../../res/styles"
import Icon from "library-react/component/Icon";
import View from "library-react/component/View";
import ComponentUtils from "library-react/ComponentUtils";
import Selector from "library-react/component/Selector";
import ScrollView from "library-react/component/ScrollView";
import StaticComponent from "library-react/component/StaticComponent";

export default function Tabs({tabs, selected, onSelectedChanged, ...props}) {
	return (
		<ScrollView
			horizontal
			{...props}>
			<Selector
				onIndexChanged={onSelectedChanged}
				defaultIndex={0}
				style={localStyles.tabBar}
				index={selected}>
				{
					tabs.map(({icon, title}) =>
						<Selector.Item
							key={title}
							style={localStyles.tab.layout}>
							{({selected}) =>
								<Tab
									icon={icon}
									title={title}
									selected={selected}/>
							}
						</Selector.Item>
					)
				}
			</Selector>
		</ScrollView>
	);
}

function Tab({icon, title, selected}) {
	return (
		<React.Fragment>
			<View style={localStyles.tab.content}>
				<Icon name={icon}
					  style={[localStyles.tab.icon, styles.if(selected, localStyles.tab.selected)]}/>

				<Text style={[localStyles.tab.title, styles.if(selected, localStyles.tab.selected)]}>
					{title}
					{" " /*Allow underline to overflow the text a little*/}
				</Text>
			</View>

			{selected && <UnderLine/>}
		</React.Fragment>
	);
}

const UnderLine = new StaticComponent(
	<View style={{height: 1, backgroundColor: styles.color.azure2, marginTop: 10}}/>
);

const localStyles = {
	tabBar: {
		flexDirection: styles.flexDirection.row
	},

	tab: {
		layout: {
			padding: 30,
			paddingTop: 37,
			paddingBottom: 30,
		},

		content: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
		},

		icon: {
			color: styles.color.main,
			width: 30,
			height: 30,
			marginRight: 20,
		},

		title: {
			color: styles.color.main,
			fontSize: 20,
		},

		selected: {
			color: styles.color.azure2
		},
	}
};
