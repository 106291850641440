import React from "react"
import {styles} from "../../../../res"
import View from "library-react/component/View";
import Text from "library-react/component/Text";
import use from "library-react/hook";
import {fonts} from "library-react/res";
import Portal from "library-react/component/Portal";
import {useRoute} from "@react-navigation/native";
import screens from "../screens";
import appScreens from "../../../screens";

export default function SideBar({...props}) {
	const route = useRoute();
	const subRoute = route.state?.routes[route.state?.index || 0];

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<Portal to={screens.home}>
				<View style={localStyles.item.layout}>
					<Text style={localStyles.item.text(subRoute?.name === screens.home)}>
						{`Page d'accueil`}
					</Text>
				</View>
			</Portal>

			<Portal to={screens.menu}>
				<View style={localStyles.item.layout}>
					<Text style={localStyles.item.text(subRoute?.name === screens.menu)}>
						{`Menu du catalogue`}
					</Text>
				</View>
			</Portal>

			<Portal to={appScreens.sections}>
				<View style={localStyles.item.layout}>
					<Text style={localStyles.item.text(false)}>
						{`Catégories du catalogue`}
					</Text>
				</View>
			</Portal>
		</View>
	);
}

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.column,
		paddingLeft: 75,
		paddingRight: 30,
		borderRightWidth: 1,
		borderColor: styles.color.lightgrey,
	},

	item: {
		layout: {},
		text: styles.static.bool(
			{
				fontSize: 20,
				marginVertical: 20,
				font: fonts.Poppins,
			},
			{
				color: styles.color.azure2
			},
			{
				color: styles.color.main
			}),
	}
};
