import React from "react"
import use from "library-react/hook"
import View from "library-react/component/View";
import Text from "library-react/component/Text";
import strings from "../../../res/strings";
import StaticComponent from "library-react/component/StaticComponent";
import styles from "../../../res/styles";
import {Enum} from "library-js/class";
import {TextInput} from "react-native-paper";
import Button from "../../component/Button";

export default function PasswordForm({onSubmitted, onToggleForm, ...props}) {
	let [state, setState] = use.state(null);
	let data = use.memo({});

	const submit = use.callback(() => {
		setState(states.sending);
		onSubmitted(data.email)
			.then(() => setState(states.sent))
			.catch(() => setState(states.error));
	}, [onSubmitted]);

	return (
		<View {...props}>
			<Text style={{paddingBottom: 21}}>
				{strings.fragment.signin.passwordForm.title}
			</Text>

			{
				state &&
				state.select({
					sent: <Confimation/>,
					error: <Error/>,
				})
			}

			<TextInput
				key="email"
				style={{marginBottom: 72}}
				onChangeText={use.callback(email => (data.email = email))}
				onSubmitEditing={submit}
				label={strings.fragment.signin.passwordForm.emailPlaceholder}/>

			<View style={localStyles.bottom.layout}>
				<Text
					onPress={onToggleForm}
					style={localStyles.cancel}>
					{strings.cancel.firstUp}
				</Text>

				<Button
					loading={state === states.sending}
					raised
					onPress={submit}>
					{strings.fragment.signin.passwordForm.submit}
				</Button>
			</View>
		</View>
	);
}

const states = new Enum("sending", "sent", "error");

const Confimation = new StaticComponent(
	<View style={{borderColor: styles.color.good, borderWidth: 1, borderRadius: 8, padding: 10, marginBottom: 18}}>
		<Text style={{color: styles.color.good, fontSize: 14}}>
			{strings.fragment.signin.passwordForm.confirmation}
		</Text>
	</View>
);

const Error = new StaticComponent(
	<View style={{borderColor: styles.color.error, borderWidth: 1, borderRadius: 8, padding: 10, marginBottom: 18}}>
		<Text style={{color: styles.color.error, fontSize: 14}}>
			{strings.fragment.signin.passwordForm.error}
		</Text>
	</View>
);

const localStyles = {
	cancel: {
		fontSize: 15,
		textDecorationLine: styles.textDecorationLine.underline
	},

	bottom: {
		layout: {
			flexDirection: styles.flexDirection.row,
			justifyContent: styles.justifyContent.spaceBetween,
			alignItems: styles.alignItems.center
		}
	}
};
