import React from "react";
import {ActivityIndicator} from "react-native-web";
import use from "../../hook";
import Server from "library-js/app/Server";
import View from "../../component/View/v2";

export default function useShowcaseShopEditor(showcaseId, shopId) {

	const [version, upgrade] = use.version();
	const {value: vShowcaseShop, loading, end} = use.loadable.server(
		() => Server.retailer.showcase.getShowcase(shopId, [showcaseId])
			.updateContent(list => list[0]),
		[showcaseId, shopId, version]
	);


	const [activating, setActivating] = use.state.bool();
	const activate = use.callback(
		async (value) => {
			setActivating(true);
			const response = await Server.retailer.showcase.link(showcaseId, shopId, Boolean(value));
			if (response.ok)
				vShowcaseShop.showcaseShop.activated = value;
			setActivating(false);
		},
		[vShowcaseShop]);

	const [linking, setLinking] = use.state.bool();
	const makeLink = use.callback(
		async (value) => {
			setLinking(true);
			const response = await Server.retailer.showcase.link(showcaseId, shopId, value);
			if (response.ok)
				upgrade();
			setLinking(false);
		},
		[vShowcaseShop]);

	const Switch = use.callback(
		({Switch: PropsSwitch, ...props}) => {
			props.style = use.defaultStyle(props.style, {minWidth: 60});
			return (
				(!vShowcaseShop || linking) ? null :
					<View {...props}>
						{
							(loading || activating) ? <ActivityIndicator size={42}/> :
								<PropsSwitch size={42} onValueChanged={activate}
											 initialValue={vShowcaseShop?.activated}/>
						}
					</View>
			)
		},
		[vShowcaseShop, activating, loading, linking]
	);

	return {

		link: () => makeLink(false),
		unlink: () => makeLink(),
		linking,
		linked: Boolean(vShowcaseShop),

		activating,
		activate,
		activated: vShowcaseShop?.activated,
		showcase: vShowcaseShop?.showcase,

		loading,
		end,
		version,

		Switch,
	};
}
