import React from "react"
import {ScrollView, View as RNView} from "react-native"
import use from "library-react/hook"
import {strings, styles} from "../../res"
import AppName from "../component/AppName";
import Gradient from "../component/Gradiant";
import AppLogo from "../component/AppLogo";
import useRouteForFrame from "./useRoute";
import screens from "../screens";
import Icon from "../../library-react/component/Icon/v2";
import Text from "../../library-react/component/Text";
import globals from "@main/globals";
import View from "library-react/component/View/v2";
import useApp from "library-react/app/useApp";
import isEligibleForReservations from "library-js/app/isEligibleForReservations";
import useMe from "library-react/hook/pro/useMe";
import {propEq} from "ramda";

function Menu({...props}) {
	const [hover, setHover] = use.state.bool();
	props.onHoverChanged = setHover;

	const route = useRouteForFrame();
	const navigation = useApp().navigation;

	props.style = use.defaultStyle(props.style, localStyles.layout, [hover]);
	return (
		<View {...props}>
			<Logo opened={hover}/>

			<ScrollView contentContainerStyle={localStyles.content}>
				<RNView>
					{
						MENU.map((item, index) =>
							// <Portal key={item.screen} to={item.screen}>
								<MenuItem
									key={index}
									item={item}
									selected={Boolean(route && (item.screen === route.name || item.isSelected?.(route)))}
									// todo in navigation v6, fix portal & use it instead
									onPress={() => navigation.navigate(...(Array.isArray(item.screen) ? item.screen : [item.screen]))}
									opened={hover}
								/>
							// </Portal>
						)
					}
				</RNView>

				<RNView>
					{
						links.map(item =>
							<MenuItem
								key={item.title}
								item={item}
								opened={hover}
								href={item.link}
								accessibilityRole="link"
								target="_blank"
							/>
						)
					}
				</RNView>
			</ScrollView>
		</View>
	);
}

export default React.memo(Menu);
export const CLOSED_MENU_WIDTH = 91;

const MENU = [
	{
		icon: "shop",
		title: strings.menu.shop,
		screen: screens.shop,
	},
	{
		icon: "product",
		title: strings.menu.product,
		screen: screens.catalog,
		// isSelected: console.log
	},
	{
		icon: "magazine",
		title: `Vitrines`,
		screen: screens.showcasesMarket,
		// isSelected: console.log
	},
	{
		icon: "orders",
		title: shop => isEligibleForReservations(shop.categoryId) ? `Commandes & Réservations` : `Mes commandes`,
		screen: [screens.ordersAndReservations, {screen: screens.orderList}],
		isSelected: propEq('name', screens.ordersAndReservations),
	},
	{
		icon: "clients",
		title: strings.menu.client,
		screen: screens.clientList,
	},
	{
		icon: "web",
		title: strings.menu.website,
		screen: screens.website,
	},
	// {
	// 	icon: "MaterialCommunityIcons/bank-outline",
	// 	title: strings.menu.payments,
	// 	screen: screens.paymentTransaction,
	// },
	{
		icon: "profile",
		title: strings.menu.profile,
		screen: screens.profile,
	},
];

const links = [
	{
		icon: "MaterialCommunityIcons/apple",
		title: `App Store`,
		link: 'https://apps.apple.com/app/shopinzon-pro/id1257533174',
	},
	{
		icon: "Entypo/google-play",
		title: `Play Store`,
		link: 'https://play.google.com/store/apps/details?id=il.shopinzon.retailer.android',
	},
	{
		icon: "MaterialIcons/contact-support",
		title: `Support`,
		link: globals.contactSupportURL,
	},
]

const Logo = React.memo(({opened}) => (
	<RNView style={{
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		height: 100,
		borderBottomWidth: 1,
		borderBottomColor: "#f9f9f9",
	}}>
		<AppLogo style={{height: 42, width: CLOSED_MENU_WIDTH, marginRight: -10}}/>
		<AppName style={styles.phantom(opened)}/>
	</RNView>
));

const MenuItem = React.memo(({item, selected, opened, ...props}) => {
	const {shop} = useMe().shop;
	return (
		<Gradient
			disabled={!selected || opened}
			style={[localStyles.item, styles.if(selected, {backgroundColor: "#fcfcfc"})]}
			{...props}>
			<Icon
				name={item.icon}
				size={26}
				style={{
					color: selected ? (opened ? styles.color.aquaBlue : styles.color.white)
						: styles.color.main,
					marginLeft: 33.5,
					marginRight: 20,
				}}/>

			<Text style={[styles.phantom(opened), styles.if(selected, {color: styles.color.aquaBlue}), {fontSize: 19}]}>
				{item.title instanceof Function ? item.title(shop) : item.title}
			</Text>
		</Gradient>
	);
});


const localStyles = {
	layout: styles.static.bool(
		{
			...styles.newShadow(0, 1, 15, .16),
			overflow: styles.overflow.hidden,
			position: styles.position.absolute,
			top: 0,
			bottom: 0,
			left: 0,
			zIndex: 2,
		},
		{
			backgroundColor: styles.color.white,
			width: 310,
		},
		{
			backgroundColor: "#f7f7f7",
			width: CLOSED_MENU_WIDTH,
		},
	),

	content: {
		paddingTop: 20,
		justifyContent: styles.justifyContent.spaceBetween,
		flexGrow: 1,
	},

	item: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		height: 60,
	},
};
