import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@src/res";
import Icon from "library-react/component/Icon/v2";
import {PopupContext} from "library-react/component/PopupMenu";

function PopupHeader({children, ...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			<Text style={localStyles.title}>
				{children}
			</Text>

			<PopupContext.Consumer>
				{({setOpenedTo}) =>
					<Icon
						name="MaterialIcons/close"
						size={30}
						onPress={setOpenedTo.false}
						style={localStyles.close}/>
				}
			</PopupContext.Consumer>
		</RNView>
	);
}

export default React.memo(PopupHeader);


const localStyles = {
	layout: {
		justifyContent: styles.justifyContent.center,
		backgroundColor: styles.color.azure,
		height: 50
	},

	title: {
		alignSelf: styles.alignSelf.center,
		textAlign: styles.textAlign.center,
		color: 'white',
		bold: true,
		paddingHorizontal: 20,
	},

	close: {
		color: 'white',
		paddingHorizontal: 15,
		...styles.absolute({top: 0, bottom: 0, right: 0}),
	},
};
