import React from "react"
import {TouchableOpacity, View as RNView} from "react-native"
import use from "library-react/hook"
import {fonts, styles} from "../../../../res"
import NumberInput from "library-react/component/input/NumberInput/v2";
import {__, allPass, gte, lte} from "ramda";
import {getFontFamily} from "library-react/component/Text/Text";
import Icon from "library-react/component/Icon/v2";
import {Prodshop} from "library-js/app/model";

function Pricing({prodshop, persist, disabled, ...props}) {
	const {Text, Spinner} = use.theme();

	const edition = use.memo(() => {
			const edition = prodshop?.copy({
					price: true,
					discount: true,
				})
				|| new Prodshop();

			edition.bindSetters();
			edition.price.bindSetters();
			edition.discount?.bindSetters();

			return edition;
		},
		[prodshop]
	);

	use.subscribe(edition.onPropertyChanged, [edition]);
	use.subscribe(edition.price.onPropertyChanged, [edition.price]);
	use.subscribe(edition.discount?.onPropertyChanged, [edition?.discount]);


	const [priceFocused, setPriceFocusedTo] = use.state.bool();
	const [discountFocused, setDiscountFocusedTo] = use.state.bool();
	const isOneInputFocused = priceFocused || discountFocused;

	const [saving, setSavingTo] = use.state.bool();
	disabled = disabled || saving;
	const edited = (
		!prodshop
		|| prodshop.price.value !== edition.price.value
		|| prodshop.discount?.value !== edition.discount?.value
	);
	const save = edited && !disabled &&
		function save() {
			setSavingTo(true);
			persist(edition)
				.then(() => {
					if (!edition.discount?.value || edition.discount.value === edition.price.value)
						edition.discount = undefined;
					setIsDiscountPercentage(false);
				})
				.finally(setSavingTo.false)
		};

	const [isDiscountPercentage, setIsDiscountPercentage] = use.state.bool();
	const [percentInput, setPercentInput] = use.state(100);
	const discountInputControlProps = edition.discount && (
		isDiscountPercentage ? {
			value: percentInput,
			onValueChanged: (value) => {
				setPercentInput(value);
				edition.discount.setValue(getFinalPriceFromDiscountAndPrice(value, edition.price.value));
			},
		} : {
			value: edition.discount.value,
			onValueChanged: edition.discount.setValue,
		}
	);

	const discountPercent = isDiscountPercentage ? percentInput / 100
		: edition.discountPercent;

	const discountValue = isDiscountPercentage ?
		getFinalPriceFromDiscountAndPrice(percentInput, edition.price.value)
		: edition.discount?.value;

	function switchDiscountUnit() {
		if (isDiscountPercentage) {
			if (isPercentValid(discountPercent))
				edition.discount.value = discountValue;
			else
				edition.discount.value = edition.price.value;
		} else
			setPercentInput(isPercentValid(discountPercent) ? discountPercent * 100 : 100);

		setIsDiscountPercentage.toggle();
	}

	function toggleDiscount() {
		if (edition.discount)
			edition.discount = undefined;
		else {
			setTimeout(() => inputs.discount?.focus(), 100);
			edition.discount = edition.price.copy().bindSetters();
			if (prodshop.price?.value)
				edition.price.value = prodshop.price.value;
		}
	}

	const inputs = use.instances({
		price: null,
		discount: null,
	});

	function focus() {
		(inputs.discount || inputs.price)?.focus();
	}

	const submittable = (edited || isOneInputFocused);

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			{/* main row (discount button, inputs & submit) */}
			<RNView style={localStyles.main}>
				{/* discount button */}
				<Icon
					name={
						edition.discount ?
							"MaterialCommunityIcons/tag-remove-outline" :
							"MaterialCommunityIcons/tag-plus-outline"
					}
					onPress={toggleDiscount}
					size={30}
					style={localStyles.discountTag(edition.discount)}
				/>

				<RNView style={localStyles.inputWrapper}>
					{/* discount price */}
					{
						edition.discount &&
						<>
							{
								isDiscountPercentage &&
								<Text
									style={{
										color: styles.color.azure2,
										width: 60,
										textAlign: styles.textAlign.right,
										fontSize: 28,
										bold: true,
									}}>
									-
								</Text>
							}

							<NumberInput
								key={isDiscountPercentage}
								ref={inputs.set.discount}
								selectTextOnFocus
								{...discountInputControlProps}
								onFocus={setDiscountFocusedTo.true}
								onBlur={() => setTimeout(setDiscountFocusedTo.false, 100)}
								onSubmitted={() => inputs.price.focus()}
								disabled={disabled}
								style={localStyles.discount(isDiscountPercentage)}/>

							<Text style={localStyles.unit(true)}>
								{
									isDiscountPercentage ? '%' :
										edition.discount.symbol
								}
							</Text>
						</>
					}


					{/* main price */}
					<NumberInput
						ref={inputs.set.price}
						selectTextOnFocus
						value={edition.price.value}
						onFocus={setPriceFocusedTo.true}
						onBlur={() => setTimeout(setPriceFocusedTo.false, 100)}
						onValueChanged={edition.price.setValue}
						onSubmitted={save}
						disabled={disabled}
						style={localStyles.price(edition.discount)}/>


					<Text style={localStyles.unit(!edition.discount)}>
						{edition.price.symbol}
					</Text>

					{
						saving ?
							<Spinner size={14} style={localStyles.spinner}/> :
							<Icon
								name={
									submittable ?
										"MaterialCommunityIcons/checkbox-marked-circle-outline" :
										"edit"
								}
								size={17}
								Layout={TouchableOpacity}
								onPress={save || focus}
								style={{
									paddingHorizontal: 30,
									color: (submittable && !disabled) ? styles.color.azure2 : styles.color.main,
								}}/>
					}
				</RNView>
			</RNView>

			{/*	message  */}
			<Text style={localStyles.message}>
				{
					!(edition.price.value >= 0) ?
						<Text style={localStyles.errorMessage}>
							{`Le prix original est érroné`}
						</Text> :
						edition.discount && (
							isDiscountPercentage ? (
								!isPercentValid(percentInput / 100) ?
									<Text style={localStyles.errorMessage}>
										{`Le pourcentage est érroné`}
									</Text> :
									<Text onPress={switchDiscountUnit}>
										{`Prix final:  `}
										<Text style={localStyles.otherUnit}>
											{discountValue}{edition.price.symbol}
										</Text>
									</Text>
							) : (
								!allPass([gte(__, 0), lte(__, edition.price.value)])(edition.discount.value) ?
									<Text style={localStyles.errorMessage}>
										{`La promotion est érroné`}
									</Text> :
									<Text onPress={switchDiscountUnit}>
										{`Soldé à  `}
										<Text style={localStyles.otherUnit}>
											-{discountPercent * 100}%
										</Text>
									</Text>
							)
						)
				}
			</Text>
		</RNView>
	);
}

export default React.memo(Pricing);

const isPercentValid = allPass([gte(__, 0), lte(__, 1)]);

function getFinalPriceFromDiscountAndPrice(discount, price){
	return (100 - discount) * price / 100
}

const localStyles = {
	layout: {},

	main: {
		alignItems: styles.alignItems.center,
		flexDirection: styles.flexDirection.row,
	},

	discountTag: styles.static.bool(
		{
			marginRight: 10,
			color: styles.color.main,
		},
		{},
		{
			transform: [{rotateY: '180deg'}],
		},
	),

	inputWrapper: {
		paddingVertical: 1,
		alignItems: styles.alignItems.center,
		flexDirection: styles.flexDirection.row,
		borderColor: "#707070",
		borderWidth: 1,
		borderRadius: 50,
	},

	price: styles.static.bool({
			fontSize: 28,
			fontFamily: getFontFamily(fonts.poppins, true, false),
		},
		{ // discounted
			width: 110,
			textDecorationLine: styles.textDecorationLine.lineThrough,
			color: styles.color.main,
			textAlign: styles.textAlign.right,
		},
		{ // not discounted
			width: 120,
			fontWeight: styles.fontWeight.bold,
			color: styles.color.azure2,
			textAlign: styles.textAlign.center,
		}
	),

	discount: styles.static.bool(
		{
			fontSize: 28,
			fontFamily: getFontFamily(fonts.poppins, true, false),
			fontWeight: styles.fontWeight.bold,
			color: styles.color.azure2,
			textAlign: styles.textAlign.right,
		},
		{ // percent
			width: 50,
		}, { // price
			width: 110,
		}
	),

	unit: styles.static.bool({
			alignSelf: styles.alignSelf.center,
			fontSize: 28,
			marginLeft: 1,
			bold: true,
			width: 20,
		},
		{
			color: styles.color.azure2,
		},
		{
			color: styles.color.main,
		}),

	spinner: {
		paddingHorizontal: 30,
	},

	message: {
		marginLeft: 50,
		paddingTop: 5,
	},

	otherUnit: {
		color: styles.color.azure2,
		bold: true,
	},

	errorMessage: {
		color: "red",
	}
};
