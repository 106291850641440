import React from "react"
import TextInput from "../TextInput"
import use from "../../../hook";
import {is} from "../../../library-js/utils";
import ComponentUtils from "../../../ComponentUtils";
import pipe from "ramda/src/pipe";
import {parallel} from "library-js/utils/function";

function NumberInput(
	{defaultValue, value: forcedValue, onValueChanged, onSubmitted, min, max, decimals, validate, ...props}, refProp
) {
	const isValueForced = is.defined(forcedValue);
	const textForcedValue = toText(forcedValue);

	// read user input
	const [userTextVersion, upgradeUserVersion] = use.version();
	const [userText, setUserText] = use.state(() => (isValueForced ? textForcedValue : toText(defaultValue)) || '');
	props.onValueChanged = parallel(setUserText, upgradeUserVersion);

	// convert user input to number and notify
	const conversionConfig = {min, max, decimals};
	const userValue = toValue(userText, conversionConfig);
	use.onChange(onValueChanged &&
		(() => onValueChanged(userValue)),
		[userTextVersion]
	);

	// apply forced text
	const finalText = (isValueForced && forcedValue !== userValue ? textForcedValue : userText) || '';
	props.value = finalText;

	// and validate it
	const finalValue = toValue(finalText, conversionConfig);
	props.validate = is(validate, Function) ? (() => validate(finalValue)) : validate;

	// convert on submission
	if (onSubmitted)
		props.onSubmitted = pipe(toValue, onSubmitted);

	// set ref and plug getValue(): number
	props.ref = use.instances({
		input: input => {
			if (input)
				input.getValue = function () {
					return toValue(this.value);
				};

			ComponentUtils.ref.set(refProp, input);
		}
	}).set.input;


	return <TextInput {...props}/>;
}

export default React.forwardRef(NumberInput);

function toText(input) {
	if (is(input, String, true))
		return input;
	return String(input);
}

function toValue(input, config) {
	if (!is.defined(input))
		return input;

	// separate integer and decimals
	const text = String(input)
		.replace(/ /g, ''); // clear spaces

	let splitNumber;
	// explicit decimal char
	if (text.includes('.')) {
		splitNumber = text.replace(/,/g, '')
			.split('.');

		if (splitNumber.length > 2) // if multiple dots, accept only the first
			splitNumber = [splitNumber[0], splitNumber.slice(1).join('')]
	}
	// no dots, then try to guess the decimal char (thanks to frenchies and their stupid comma 🤦 )
	else {
		const splitByComma = text.split(',');
		const numberOfCommas = splitByComma.length - 1;

		if (numberOfCommas > 1) // american format
			splitNumber = [splitByComma.join('')];
		else // by default one comma means the decimal separator (because we're french... snif!)
			splitNumber = splitByComma;
	}


	// set default decimal to 0
	if (!splitNumber[1]) {
		if (DIGITS.includes(splitNumber[0]?.last))
			splitNumber[1] = '0';
		else
			return;
	}

	// --- correct decimals ---
	const decimalsLength = Math.trunc(asNumber(config?.decimals));

	if (decimalsLength > 0)
		splitNumber[1] = splitNumber[1].slice(0, decimalsLength);
	else if (decimalsLength === 0)
		splitNumber[1] = '0';

	// --- # convert to number ---
	let output = asNumber(splitNumber.join('.'));

	// --- output range ---
	if (is(output, Number)) {
		const min = asNumber(config?.min);
		const max = asNumber(config?.max);
		const rangeValid =
			// min check
			(!is(min, Number) || output >= min)
			// max check
			&& (!is(max, Number) || output <= max);

		if (!rangeValid) // correct output
			output = (output > max) ? max : min;
	}

	return output;
}

function asNumber(input) {
	const output = Number(input);
	const valid = input !== '' && !isNaN(output);
	if (valid)
		return output;
}

const DIGITS = Array.range(10).map(String);
