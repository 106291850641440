import React from "react"
import OrderView from "../../../../component/OrderView"

export default React.memo(
	function OrderNotificationView({event, ...props}) {
		return (
			<OrderView
				gallery={event.gallery}
				price={event.vOrder.price}
				state={event.vOrder.state}
				receptionMean={event.vOrder.receptionMean}
				{...props}
			/>
		);
	}
);
