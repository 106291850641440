import React from "react";
import use from "library-react/hook"
import Text from "library-react/component/Text";
import Image from "library-react/component/Image";
import Card from "../../../component/Card";
import strings from "../../../../res/strings";
import globals from "../../../globals";
import useMe from "library-react/hook/pro/useMe";

function AddressCard({ ...props }) {
	const { shop } = useMe().shop;
	const mapSource = use.memo(location =>
		location &&
		({
			uri: `https://maps.googleapis.com/maps/api/staticmap?${URL.Params.encode({
				size: "545x310",
				key: globals.apiKeys.google,
				center: `${location.latitude},${location.longitude}`,
				markers: `|${location.latitude},${location.longitude}`
			})
				}`
		}),
		[shop?.location],
	);

	return (
		<Card
			icon="Entypo/location"
			title={strings.fragment.shop.address.title}
			subtitle={strings.fragment.shop.address.subtitle}
			{...props}>

			{/*Address*/}
			<Text style={localStyles.displayText}>
				{shop.address?.formatted}
			</Text>

			<Image
				source={mapSource}
				resizeMode="cover"
				style={localStyles.map}
			/>
		</Card>
	);
}

export default React.memo(AddressCard);

const localStyles = {
	displayText: {
		marginHorizontal: 33,
		marginVertical: 24,
		fontSize: 17,
		lineHeight: 26,
	},

	map: {
		height: 310
	},
};
