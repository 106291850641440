import Base from "../../class/Base"
import {is} from "../../utils"
import { formatPrice, toCurrencySymbol } from "./utils/price";
import { CURRENCIES, SYMBOLS } from "./utils/price/currencies";

export default class Price extends Base.extendsWith({value: Number}) {
	/**
	 * @param {any} value 
	 * @param {any} currency 
	 */
	constructor(value = undefined, currency = Price.Currencies.default) {
		super();
		this.hydrate({value, currency});
	}

	get symbol() {
		return toCurrencySymbol(this.currency);
	}

	getValue() {
		let value = super.getValue();
		// display 2 decimal if has decimals. None otherwise.
		let hasDecimal = value % 1;
		return value && Number(value.toFixed(hasDecimal ? 2 : 0));
	}

	get formatted() {
		return this.toString();
	}

	get valid(){
		return (this.value > 0)
			&& (this.currency in Price.Currencies);
	}

	toString() {
		return formatPrice(this.value, this.currency);
	}

	static from(from) {
		if (is(from, Number)) // accept number
			from = {value : from};

		if (!from.currency)
			from.currency = Price.Currencies.EUR;

		if (!is(from?.value, Number))
			return undefined;

		return super.from(from);
	}
}

Price.addProperties({
	currency: String
});

Price.Currencies = CURRENCIES;
Price.Symbols = SYMBOLS;